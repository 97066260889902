import React from "react"

import CreatableDDList from "../../../toolkits/CreatableDDList/CreatableDDList"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import useEmployeePositions from "../../../hooks/useEmployeePositions"
import {useCreateEmployeePositionMutation} from "../../../api/employeePositions"
import {useTranslation} from "react-i18next"


export const EmployeePositionsDDList = ({setPosition, setUserMessage, ...props}) => {
    const { t } = useTranslation("common")

    const {employeePositions} = useEmployeePositions()
    const [createEmployeePosition] = useCreateEmployeePositionMutation()

    return (
        <CreatableDDList
            text_label={t("Position")}
            placeholder=""
            listOptions={employeePositions.map((position) => ({value: position.id, label: position.name}))}
            onChange={position => {setPosition({value: position.value, label: position.label})}}
            onCreateOption={(new_option) => {
                const data = {name: new_option}

                createEmployeePosition(data)
                    .unwrap()
                    .then((response) => {
                        setPosition({value: response.data.id, label: response.data.name})
                        setUserMessage({message: t("Employee Position was successfully created!"), code: SUCCESS_CODE})
                    })
                    .catch(error => {
                        setUserMessage({message: error.message, code: ERROR_CODE})
                    })
            }}
            {...props}
        />
    )
}

export default EmployeePositionsDDList
