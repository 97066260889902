import {coinsRelatedApi} from "./base"


export const couponsApi = coinsRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        getCoupons: builder.query({
            query: (params) => ({
                path: "coupons/",
                params,
            }),
            providesTags: ["Coupons"],
        }),
        createCoupon: builder.mutation({
            query: (data) => ({path: "coupons/", method: "POST", data}),
            invalidatesTags: ["Coupons"]
        }),
        updateCoupon: builder.mutation({
            query: ({id, data}) => ({path: `coupons/${id}`, method: "PUT", data}),
            invalidatesTags: ["Coupons"]
        }),
        deactivateCoupon: builder.mutation({
            query: (id) => ({path: `coupons/${id}/deactivate`, method: "POST"}),
            invalidatesTags: ["Coupons"]
        }),
        claimCoupon: builder.mutation({
            query: (data) => ({path: "coupons/claim", method: "POST", data}),
            invalidatesTags: ["Coupons", "BalanceAvailable"]
        }),
    })
})

export const {
    useGetCouponsQuery,
    useClaimCouponMutation,
    useCreateCouponMutation,
    useUpdateCouponMutation,
    useDeactivateCouponMutation,
} = couponsApi
