import "./index.scss"

import React from "react"
import Typography from "./Typography"

// data = [{"title": "Section", items: ["Point 1", "Point 2"]}]
const DescriptionList = ({data}) => {

    return (
        <div className="ns-description-list">
            {data.map((section) => (
                <React.Fragment key={section.title}>
                    <Typography variant="supporting">{section.title}</Typography>
                    {section.items.map((item) => (
                        <Typography variant="supporting" key={item}><span className="ns-description-list-bullet">•</span> {item}</Typography>
                    ))}
                </React.Fragment>
            ))}
        </div>)
}

export default DescriptionList
