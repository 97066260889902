import {getAuthorizedUserData} from "../../../utils/localstorage.utils"
import {
    LOCAL_STORAGE_REQUEST,
    LOCAL_STORAGE_SUCCESS,
} from "../const"


const requestLocalStorage = () => ({type: LOCAL_STORAGE_REQUEST})
const successLocalStorage = (localStorage) => ({
    type: LOCAL_STORAGE_SUCCESS,
    payload: {localStorage}
})

export const receiveLocalStorage = () => async (dispatch) => {
    dispatch(requestLocalStorage())

    const {privateMode} = getAuthorizedUserData()
    dispatch(successLocalStorage({privateMode: privateMode}))
}
