import {GET_CALENDAR_DATA_FAILURE, GET_CALENDAR_DATA_REQUEST, GET_CALENDAR_DATA_SUCCESS} from "../const"

const initialState = {
    timeOffs: new Map(),
    loggedDays: new Map(),
    inReviewDays: new Map(),
    loading: false,
}

const handlers = {
    [GET_CALENDAR_DATA_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_CALENDAR_DATA_SUCCESS]: (state, { payload: { params, timeOffs, loggedDays, inReviewDays } }) => {
        // This is some kind of optimization to eliminate latency while retrieving
        // a calendar data. We cache all records except for records for the month that was updated
        const timeOffsItemsToRetain = [...state.timeOffs].filter((k) => (
            k <= params.start_date && k <= params.end_date) ||
            (k >= params.start_date && k >= params.end_date)
        )

        const loggedDaysItemsToRetain = [...state.loggedDays].filter((k) => (
            k <= params.start_date && k <= params.end_date) ||
            (k >= params.start_date && k >= params.end_date)
        )

        const inReviewDaysItemsToRetain = [...state.inReviewDays].filter((k) => (
            k <= params.start_date && k <= params.end_date) ||
            (k >= params.start_date && k >= params.end_date)
        )

        return ({
            ...state,
            timeOffs: new Map([...timeOffsItemsToRetain, ...timeOffs]),
            loggedDays: new Map([...loggedDaysItemsToRetain, ...loggedDays]),
            inReviewDays: new Map([...inReviewDaysItemsToRetain, ...inReviewDays]),
            loading: false,
        })
    },
    [GET_CALENDAR_DATA_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
}

const calendarDataReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default calendarDataReducer
  

