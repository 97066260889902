import {coinsRelatedApi} from "./base"


export const shopApi = coinsRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: (params) => ({
                path: "purchase-requests/",
                params,
            }),
            providesTags: ["Orders"],
        }),
        createOrder: builder.mutation({
            query: (data) => ({path: "purchase-requests/", method: "POST", data}),
            invalidatesTags: ["Orders", "BalanceAvailable", "BalanceSpent"]
        }),
        // approve/decline
        updateOrder: builder.mutation({
            query: ({id, data}) => ({path: `purchase-requests/${id}`, method: "PUT", data}),
            invalidatesTags: ["Orders", "BalanceAvailable", "BalanceSpent"]
        }),
    })
})

export const {
    useGetOrdersQuery,
    useCreateOrderMutation,
    useUpdateOrderMutation,
} = shopApi
