import "./index.scss"

import React, {useMemo, useState} from "react"
import Helmet from "react-helmet"

import TimeRecordsTable from "./components/TimeRecordsTable"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {FILTER_BY_PROJECTS_NOTE, FILTER_TYPES} from "../../constants/other"
import {getSavedTimeRecordsRange, getUserViewedAs} from "../../utils/localstorage.utils"
import {TimeRecordsPeriodFilter} from "./components/TimeRecordsPeriodFilter"
import {mapDateRangeFilters} from "../../utils/datetime.utils"
import MultipleSelect from "../../toolkits/Multiselect"
import {useMediaQuery} from "react-responsive"
import {DESKTOP_WIDTH} from "../../constants/other"
import InfoMessage from "../../components/InfoMessage"
import useUserProjects from "../../hooks/useUserProjects"
import {useLocation} from "react-router-dom"

export const TimeRecords = () => {
    const location = useLocation()
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const userId = getUserViewedAs() ? getUserViewedAs().user_id : location.state?.userId
    const savedTimeRecordsRange = getSavedTimeRecordsRange()

    const {projects, isLoading} = useUserProjects({userId, all: true})
    const [selectedProjectIds, setSelectedProjectIds] = useState([])
    const [dateFilter, setDateFilter] = useState(Object.values(FILTER_TYPES).includes(savedTimeRecordsRange) ? mapDateRangeFilters(savedTimeRecordsRange) : mapDateRangeFilters(FILTER_TYPES.THIS_MONTH))
    const isAllProjectsSelected = useMemo(() => selectedProjectIds.length === projects.length || selectedProjectIds.length === 0, [projects, selectedProjectIds])

    const hasProjects = projects.length !== 0

    const [userMessage, setUserMessage] = useState(null)

    return (
        <>
            <Helmet>
                <title> Time Records - Newsoft Inside </title>
            </Helmet>
            <div className="time-records">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message}
                            code={userMessage.code}/>
                    }
                </div>
                <div className="time-records-filters">
                    <TimeRecordsPeriodFilter period={dateFilter} setPeriod={setDateFilter} userId={userId}/>
                    {isDesktop && hasProjects &&
                        <div className="time-records-filters-projects">
                            {selectedProjectIds.length !== 0 && selectedProjectIds.length !== projects.length &&
                                <InfoMessage text={FILTER_BY_PROJECTS_NOTE}/>}
                            {projects.length > 1 &&
                                <>
                                    <span className="time-records-filters-projects-filter-text t-s4">Project:</span>
                                    <MultipleSelect placeholder="Choose the project" loading={isLoading}
                                        options={projects.map(p => p.project)} selectedOptionIds={selectedProjectIds}
                                        setSelectedOptionIds={setSelectedProjectIds}/>
                                </>
                            }
                        </div>
                    }
                </div>

                <TimeRecordsTable
                    isAllProjectsSelected={isAllProjectsSelected}
                    dateFilter={dateFilter}
                    setUserMessage={setUserMessage}
                    selectedProjectIds={selectedProjectIds}
                    userId={userId}
                />
            </div>
        </>
    )
}

export default TimeRecords
