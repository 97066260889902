import React from "react"

import CustomPopup from "../../toolkits/CustomPopup/CustomPopup"


const InfoPopup = ({title, value, close}) => {
    return (
        <CustomPopup text_title={title} close={close}>
            <span className="info-popup-note t-s2"> {value} </span>
        </CustomPopup>
    )
}

export default InfoPopup
