import {useMemo} from "react"
import {
    useGetUserCalendarDataQuery,
} from "../api/timeRecords"
import {TIME_OFF_PRIORITIES} from "../constants/other"


const useCalendarData = ({ userId, params, skip } = {}) => {
    const {data, isLoading} = useGetUserCalendarDataQuery({userId, params}, { skip })

    const result = useMemo(() => {
        if (!data || isLoading) {
            return  {
                timeOffs: new Map(),
                loggedDays: new Map(),
                inReviewDays: new Map(),
                isLoading,
            }
        }

        const {time_offs, logged_days, in_review_days} = data

        const updateMapWithHighestPriority = (map, items) => {
            items.forEach(item => {
                const { date, type, created_at, ...rest } = item
                if (!map.has(date)) {
                    map.set(date, { type, created_at, ...rest })
                } else {
                    const existing = map.get(date)
                    if (TIME_OFF_PRIORITIES[type] > TIME_OFF_PRIORITIES[existing.type] ||
                        (TIME_OFF_PRIORITIES[type] === TIME_OFF_PRIORITIES[existing.type] && new Date(created_at) > new Date(existing.created_at))) {
                        map.set(date, { type, created_at, ...rest })
                    }
                }
            })
        }

        const timeOffs = new Map()
        const loggedDays = new Map(logged_days.map(item => [item.date, item]))
        const inReviewDays = new Map(in_review_days.map(item => [item.date, item]))

        updateMapWithHighestPriority(timeOffs, time_offs)

        return {
            timeOffs,
            loggedDays,
            inReviewDays,
            isLoading,
        }

    }, [data, isLoading])

    return result
}

export default useCalendarData