import React from "react"
import {Helmet} from "react-helmet"
import {PRODUCTS_SORT_OPTIONS} from "../../constants/other"
import "./index.scss"
import ShopProductCard from "./component/ShopProductCard"
import InfiniteScroll from "react-infinite-scroll-component"
import ShopHeader from "./component/ShopHeader"
import Protected from "../../components/Protected/Protected"
import {PERMISSIONS} from "../../constants/permissions"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"
import DDFilter from "../../toolkits/DDFilter"
import { useProducts } from "./hooks/useProducts"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import {useTranslation} from "react-i18next"
import SelectPanel from "../../components/Inputs/SelectPanel"

const Shop = () => {
    const { t } = useTranslation("common")

    const {
        products,
        hasMore,
        empty,
        loadNextPage,
        categoryFilter,
        setCategoryFilter,
        sortBy,
        setSortBy,
        categoryStatuses,
    } = useProducts()

    return (
        <>
            <Helmet>
                <title> {t("Products")} - Newsoft Inside </title>
            </Helmet>

            <div className="shop" id="shop-content">
                <div className="shop-header">
                    <ShopHeader/>
                    <div className="shop-header-filters">
                        <SelectPanel
                            title={t("Category")}
                            options={categoryStatuses}
                            selectedOption={categoryFilter}
                            setOptionFilter={setCategoryFilter}
                        />
                        <DDFilter
                            title={t("Sort by")}
                            defaultValue={{label: t(sortBy.label), value: sortBy.key}}
                            options={(Object.values(PRODUCTS_SORT_OPTIONS)).map((o) => ({label: t(o.label), value: o.key}))}
                            onChange={({ label, value }) => setSortBy({label, key: value})}
                        />
                    </div>
                </div>
                <InfiniteScroll
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<StyledCircularProgress />}
                    dataLength={products.length}
                >
                    <div className="shop-content">
                        {products.map((product) =>
                            <Protected key={product.id}
                                permissions={product.public ? [PERMISSIONS.READ_SHOP] : [PERMISSIONS.WRITE_SHOP]}>
                                <ShopProductCard product={product} key={product.id}/>
                            </Protected>
                        )}
                        {empty && <div className="shop-content-empty-result"><EmptyResult/></div>}
                    </div>
                </InfiniteScroll>
            </div>
        </>
    )
}

export default Shop