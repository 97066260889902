import {useGetBalanceQuery} from "../api/balance"


const useCoinsBalance = ({ userId, skip }) => {
    const {data, isLoading} = useGetBalanceQuery(userId, { skip })

    return {
        balance: data?.available,
        isLoading,
    }
}

export default useCoinsBalance