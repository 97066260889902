import React, {useEffect, useState} from "react"

import CloseIcon from "@mui/icons-material/Close"
import AboutUsPopup from "./AboutUsPopup"
import OurPrinciplesPopup from "./OurPrinciplesPopup"
import OurRulesFirstPopup from "./OurRulesFirstPopup"
import OurRulesSecondPopup from "./OurRulesSecondPopup"
import AcquaintanceFormPopup from "./AcquaintanceFormPopup"
import BenefitsPopup from "./BenefitsPopup"
import UsefulContactsPopup from "./UsefulContactsPopup"
import OurTeamPopup from "./OurTeamPopup"
import {getOnboardingPopupSeen, saveOnboardingPopupSeen} from "../../../utils/localstorage.utils"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"


const OnboardingPopup = ({userId, setShowLastStepPopup, step, close}) => {
    const [onboardingPopupSeen, setOnboardingPopupSeen] = useState(false)
    const onboardingPopupSteps = [
        { component: <AboutUsPopup />, title: "About Us", key: "step1" },
        { component: <OurTeamPopup />, title: "Our Team", key: "step2" },
        { component: <OurPrinciplesPopup />, title: "Our Principles", key: "step3" },
        { component: <BenefitsPopup />, title: "Benefits", key: "step4" },
        { component: <OurRulesFirstPopup />, title: "Rules", key: "step5" },
        { component: <OurRulesSecondPopup />, title: "Rules", key: "step6" },
        { component: <UsefulContactsPopup />, title: "Useful Contacts", key: "step7" },
        { component: <AcquaintanceFormPopup />, title: "Acquaintance Form", key: "step8" },
    ]

    const [currentStep, setCurrentStep] = useState(step !== undefined ? step : 0)
    const totalSteps = onboardingPopupSteps.length

    const goToNextStep = () => {
        if (currentStep < totalSteps - 1) {
            setCurrentStep(currentStep + 1)
        } else {
            if (onboardingPopupSeen) {
                close()
            }
            setShowLastStepPopup(true)
            saveOnboardingPopupSeen(userId, true)
        }
    }

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1)
        }
    }

    useEffect(() => {
        const seen = getOnboardingPopupSeen(userId) === true
        setOnboardingPopupSeen(seen)
    }, [userId])

    const onboardingContent = (
        <div className={`greetings-onboarding ${step === undefined ? "" : "shortened"}`}>
            <div className="greetings-onboarding-heading t-h5">{onboardingPopupSteps[currentStep].title}</div>

            <div className="greetings-onboarding-content">
                {onboardingPopupSteps[currentStep].component}
            </div>

            {step === undefined &&
                <div className="greetings-onboarding-footer">
                    <div className="greetings-onboarding-footer-indicator">
                        {[...Array(totalSteps)].map((e, i) => (
                            <span key={i} className={`greetings-onboarding-footer-indicator-step${ i === currentStep ? "-active" : ""}`}></span>
                        ))}
                    </div>
                    <div className="greetings-onboarding-footer-buttons">
                        {currentStep > 0 &&
                            <button onClick={goToPreviousStep} disabled={currentStep === 0} className="greetings-onboarding-footer-button-prev t-button">Back</button>
                        }
                        <button onClick={goToNextStep} className="greetings-onboarding-footer-button-next t-button">Next</button>
                    </div>

                </div>}

            <CloseIcon onClick={close} className="greetings-welcome-button-close"/>
        </div>
    )

    if (step !== undefined) {
        return <FlexiblePopup>{onboardingContent}</FlexiblePopup>
    }

    return onboardingContent

}

export default OnboardingPopup
