import React from "react"

import CreatableDDList from "../../../toolkits/CreatableDDList/CreatableDDList"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useGetEquipmentCategoriesQuery, useCreateEquipmentCategoryMutation} from "../../../api/equipment"
import {useTranslation} from "react-i18next"


export const EquipmentCategoriesDDList = ({setCategory, setUserMessage, ...props}) => {
    const { t } = useTranslation("common")

    const {data} = useGetEquipmentCategoriesQuery({})
    const [createEquipmentCategory] = useCreateEquipmentCategoryMutation()

    return (
        <CreatableDDList
            text_label={t("Category")}
            placeholder=""
            listOptions={data?.items.map((category) => ({value: category.id, label: category.name}))}
            onChange={category => {setCategory({value: category.value, label: category.label})}}            
            onCreateOption={(new_option) => {
                createEquipmentCategory({name: new_option})
                    .then((response) => {
                        setCategory({value: response.data.id, label: response.data.name})
                        setUserMessage({message: "Equipment category was successfully created!", code: SUCCESS_CODE})
                    })
                    .catch(error => {
                        console.log(error)
                        setUserMessage({message: error.message, code: ERROR_CODE})
                    })
            }}
            {...props}
        />
    )
}
