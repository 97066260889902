import http from "../../http"
import {API_V1_URL} from "../../constants/other"

const USERS_URL = `${API_V1_URL}/users`
const USER_REPORT_URL = `${API_V1_URL}/user-report`

export const getSearchReports = (find, cancelToken) =>
    http.get(`${USERS_URL}/`, {params: {name: find}}, {}, cancelToken)

export const  getSalaryEarn = (userId, params) => http.get(`${USER_REPORT_URL}/${userId}/earn-info`, {params})

export const  getOvertimeEarn = (userId, params) => http.get(`${USER_REPORT_URL}/${userId}/overtime-info`, {params})

export const  getBonusEarn = (userId, params) => http.get(`${USER_REPORT_URL}/${userId}/bonus-info`, {params})
