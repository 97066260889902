import "./index.scss"

import React, {createRef, useEffect} from "react"
import {useMediaQuery} from "react-responsive"

import {DESKTOP_WIDTH} from "../../constants/other"


const Textarea = ({text_label = false, ...props}) => {
    const inputRef = createRef()    
    const isDesktop = useMediaQuery({maxWidth: DESKTOP_WIDTH})

    useEffect(() => {
        if (isDesktop) {
            inputRef.current.blur()
        }
    }, [])

    return (
        <div className="toolkit-text-area-wrapper">
            {text_label ? <label className="toolkit-text-area-label t-s3">{text_label}</label> : <></>}
            <textarea className="toolkit-text-area t-s4" ref={inputRef} value={props.value ? props.value : ""} {...props}/>
        </div>
    )
}

export default Textarea
