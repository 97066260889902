import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const equipmentApi = createApi({
    reducerPath: "equipmentApi",
    baseQuery,
    tagTypes: ["Equipment", "UserEquipment", "FreeEquipment"],
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    endpoints: (builder) => ({
        getEquipment: builder.query({
            query: (params) => ({
                path: "equipment/",
                params,
            }),
            providesTags: ["Equipment"],
        }),
        getUserEquipment: builder.query({
            query: (params) => ({
                path: `users/${params.userId}/equipment`,
            }),
            providesTags: (result, error, arg) => [{type: "UserEquipment", id: arg.userId}]
        }),
        getFreeEquipment: builder.query({
            query: () => ({
                path: "equipment/?free=true",
            }),
            providesTags: ["FreeEquipment"],
        }),
        createEquipment: builder.mutation({
            query: (data) => ({path: "equipment/", method: "POST", data}),
            invalidatesTags: ["Equipment", "FreeEquipment"]
        }),
        assignEquipment: builder.mutation({
            query: (data) => ({path: "equipment/assign", method: "POST", data}),
            invalidatesTags: ["UserEquipment", "FreeEquipment"]
        }),
        unassignEquipment: builder.mutation({
            query: (data) => ({path: "equipment/unassign", method: "POST", data}),
            invalidatesTags: ["UserEquipment", "FreeEquipment"]
        }),
        updateEquipment: builder.mutation({
            query: ({id, data}) => ({path: `equipment/${id}`, method: "PUT", data}),
            invalidatesTags: ["Equipment", "FreeEquipment", "UserEquipment"]
        }),
        deleteEquipment: builder.mutation({
            query: (id) => ({path: `equipment/${id}`, method: "DELETE"}),
            invalidatesTags: ["Equipment", "FreeEquipment", "UserEquipment"]
        }),
        updateUserEquipment: builder.mutation({
            query: (data) => ({path: "equipment/update-multiple", method: "POST", data}),
            invalidatesTags: (result, error, arg) => [
                {type: "UserEquipment", id: arg.user_id},
                "FreeEquipment",
            ]
        }),
        getEquipmentCategories: builder.query({
            query: (params) => ({
                path: "equipment-categories/",
                params,
            }),
            providesTags: ["EquipmentCategory"],
        }),
        createEquipmentCategory: builder.mutation({
            query: (data) => ({path: "equipment-categories/", method: "POST", data}),
            invalidatesTags: ["EquipmentCategory"]
        }),
        updateEquipmentCategory: builder.mutation({
            query: ({id, data}) => ({path: `equipment-categories/${id}`, method: "PUT", data}),
            invalidatesTags: ["EquipmentCategory"]
        }),
        deleteEquipmentCategory: builder.mutation({
            query: (id) => ({path: `equipment-categories/${id}`, method: "DELETE"}),
            invalidatesTags: ["EquipmentCategory"]
        }),
    })
})

export const {
    useGetEquipmentQuery,
    useGetUserEquipmentQuery,
    useGetFreeEquipmentQuery,
    useUpdateUserEquipmentMutation,
    useCreateEquipmentMutation,
    useUpdateEquipmentMutation,
    useDeleteEquipmentMutation,
    useAssignEquipmentMutation,
    useUnassignEquipmentMutation,
    useGetEquipmentCategoriesQuery,
    useCreateEquipmentCategoryMutation,
    useUpdateEquipmentCategoryMutation,
    useDeleteEquipmentCategoryMutation,
} = equipmentApi
