import React from "react"
import {useDispatch} from "react-redux"

import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"

import {useDeletePerformanceReviewMutation} from "../../../api/performanceReviews"

const DeletePerformanceReviewPopup = ({performanceReview, refreshTable, setUserMessage, close}) => {
    const dispatch = useDispatch()

    const [deletePerformanceReview] = useDeletePerformanceReviewMutation()

    const handleSubmit = () => {
        deletePerformanceReview(performanceReview.id)
            .unwrap()
            .then(() => {
                refreshTable()
                dispatch(receiveNotifications())
                close()
                setUserMessage({message: "Performance review was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete ${performanceReview.user.first_name || ""}'s performance review?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeletePerformanceReviewPopup
