import {useGetUserEquipmentQuery} from "../api/equipment"


const useUserEquipment = ({ userId, skip }) => {
    const {data, isLoading} = useGetUserEquipmentQuery({ userId }, { skip })

    return {
        equipments: data || [],
        isLoading,
    }
}

export default useUserEquipment