import moment from "moment"
import {DAY_TYPE, DD_MM_YYYY} from "../constants/other"

export const getRangeTimeOff = (startDate, endDate, count) => {
    const start = moment(startDate)
    const end = moment(endDate)
    const isSameYear = start.year() === end.year()
    const isSameDate = start.isSame(end, "day")

    let range
    if (isSameDate) {
        range = start.format(DD_MM_YYYY)
    } else {
        range = isSameYear
            ? `${start.format("DD.MM")}-${end.format(DD_MM_YYYY)}`
            : `${start.format(DD_MM_YYYY)}-${end.format(DD_MM_YYYY)}`
    }

    return {
        period: range,
        days: `${count} day${count !== 1 ? "s" : ""}`
    }
}

export const positionTooltip = (tooltipRef, data) => {
    if (!tooltipRef.current || !data.visible) return

    const tooltipWidth = tooltipRef.current.getBoundingClientRect().width
    const tooltipHeight = tooltipRef.current.getBoundingClientRect().height

    tooltipRef.current.style.left = `${data.left - tooltipWidth / 2 + data.width / 2}px` // Horizontally center the tooltip
    tooltipRef.current.style.top = `${data.top - tooltipHeight - 5}px` // Move it slightly above the element
}

export function mapTimeOffTypeToTitle(type) {
    const typeToTitleMap = {
        ApprovedDayOff: "Day Off 🌴",
        ApprovedIllness: "Illness 🤒",
        ApprovedUnpaidDayOff: "Unpaid Day Off 💸",
        RequestedDayOff: "Time Off Request ⌛️",
        RequestedIllness: "Time Off Request ⌛️",
        RequestedUnpaidDayOff: "Time Off Request ⌛️",
        DeclinedDayOff: "Time Off Decline ❌",
        DeclinedIllness: "Time Off Decline ❌",
        DeclinedUnpaidDayOff: "Time Off Decline ❌",
    }
    return typeToTitleMap[type] || "Unknown Type"
}

export const getTimeOffClass = (type) => {
    switch (type) {
    case DAY_TYPE.REQUESTED_DAY_OFF:
        return "rmdp-requested-day-off"
    case DAY_TYPE.DECLINED_DAY_OFF:
        return "rmdp-declined-day-off"
    case DAY_TYPE.APPROVED_DAY_OFF:
        return "rmdp-approved-day-off"
    case DAY_TYPE.PUBLIC_DAY_OFF:
        return "rmdp-public-day-off"
    case DAY_TYPE.REQUESTED_ILLNESS:
        return "rmdp-requested-illness"
    case DAY_TYPE.DECLINED_ILLNESS:
        return "rmdp-declined-illness"
    case DAY_TYPE.APPROVED_ILLNESS:
        return "rmdp-approved-illness"
    case DAY_TYPE.REQUESTED_UNPAID_DAY_OFF:
        return "rmdp-requested-unpaid-day-off"
    case DAY_TYPE.DECLINED_UNPAID_DAY_OFF:
        return "rmdp-declined-unpaid-day-off"
    case DAY_TYPE.APPROVED_UNPAID_DAY_OFF:
        return "rmdp-approved-unpaid-day-off"
    default:
        return "rmdp-default"
    }
}

export const getTimeOffColor = (type) => {
    switch (type) {
    case DAY_TYPE.REQUESTED_DAY_OFF:
        return "#CDE9C5"
    case DAY_TYPE.DECLINED_DAY_OFF:
        return "#D3D2D2"
    case DAY_TYPE.APPROVED_DAY_OFF:
        return "#82C785"
    case DAY_TYPE.PUBLIC_DAY_OFF:
        return "#F74E1E"
    case DAY_TYPE.REQUESTED_ILLNESS:
        return "#C8EDF8"
    case DAY_TYPE.DECLINED_ILLNESS:
        return "#D3D2D2"
    case DAY_TYPE.APPROVED_ILLNESS:
        return "#79C3DA"
    case DAY_TYPE.REQUESTED_UNPAID_DAY_OFF:
        return "#D2A992"
    case DAY_TYPE.DECLINED_UNPAID_DAY_OFF:
        return "#D3D2D2"
    case DAY_TYPE.APPROVED_UNPAID_DAY_OFF:
        return "#AD7454"
    default:
        return "#F57F17"
    }
}

export const isApprovedOrRequestedTimeOff = (type) => [
    DAY_TYPE.APPROVED_DAY_OFF,
    DAY_TYPE.PUBLIC_DAY_OFF,
    DAY_TYPE.APPROVED_ILLNESS,
    DAY_TYPE.APPROVED_UNPAID_DAY_OFF,
    DAY_TYPE.REQUESTED_DAY_OFF,
    DAY_TYPE.REQUESTED_UNPAID_DAY_OFF,
    DAY_TYPE.REQUESTED_ILLNESS,
].includes(type)

export const isRequestedOrDeclinedTimeOff = (type) => [
    DAY_TYPE.REQUESTED_DAY_OFF,
    DAY_TYPE.REQUESTED_ILLNESS,
    DAY_TYPE.DECLINED_DAY_OFF,
    DAY_TYPE.DECLINED_ILLNESS,
    DAY_TYPE.DECLINED_UNPAID_DAY_OFF,
].includes(type)