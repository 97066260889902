import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {daysDifference, normalizeDate} from "../../../utils/datetime.utils"

import {AUTO_GIVEN_NEWCOINS, CACHED_IMAGES, DAYS_PER_YEAR, SETTINGS} from "../../../constants/other"

import BirthdayCakeImg from "../../../assets/images/birthdayCake.svg"
import BirthdayConfettiImg from "../../../assets/images/birthdayConfetti.svg"
import {useSelector} from "react-redux"
import SettingsEnabled from "../../SettingsEnabled/SettingsEnabled"


const BirthdayPopup = ({birthdate, isDesktop, close}) => {
    const today = new Date()
    const coinsSettings = useSelector((state) => state.profile.myInfo.coins_settings)
    const yearsOld = Math.floor(
        daysDifference(normalizeDate(new Date(birthdate)), normalizeDate(today)) / DAYS_PER_YEAR
    )

    return (
        <div className="greetings-birthday">
            <img src={CACHED_IMAGES.LogoNS} className="greetings-birthday-logo-ns"/>
            <span className={`greetings-birthday-title ${isDesktop ? "" : "t-h1"}`}>
                {"Happy Birthday!"}
            </span>
            <span className={`greetings-birthday-content ${isDesktop ? "t-h2" : "t-s4"}`}>
                {"We wish you aII the best!"}
            </span>
            <SettingsEnabled settingsNames={[SETTINGS.NEWCOINS_ENABLED]}>
                {coinsSettings && coinsSettings["birthday"] &&
                (<span className={`greetings-birthday-content-newcoins ${isDesktop ? "t-h1" : "t-s4"}`}>
                    {`Congratulations on receiving ${AUTO_GIVEN_NEWCOINS.find(item => item.setting_name === "birthday").amount} Newcoins 🎁`}
                </span>)}
            </SettingsEnabled>
            <SmallButton onClick={close}>
                {"THANK YOU"}
            </SmallButton>

            <div className="greetings-birthday-year">
                <span className={isDesktop ? "t-h1" : "t-s3"}>{yearsOld}</span>
            </div>
            <img src={BirthdayCakeImg} className="greetings-birthday-cake" alt="Birthday Cake"/>
            <img src={BirthdayConfettiImg} className="greetings-birthday-confetti" alt="Birthday Confetti"/>
        </div>
    )
}

export default BirthdayPopup
