import {
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,
} from "../const"


const initialState = {
    product: {},
    loading: true,
}

const handlers = {
    [GET_PRODUCT_REQUEST]: (state) => ({...state, loading: true}),
    [GET_PRODUCT_SUCCESS]: (state, {payload: {product}}) => ({
        ...state,
        product,
        loading: false,
    }),
    [GET_PRODUCT_FAILURE]: (state) => ({...state, loading: false}),
    DEFAULT: (state) => state,
}

const viewProductReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default viewProductReducer