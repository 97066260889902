import React, { useEffect } from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {DECIMAL_PATTERN, SUCCESS_CODE} from "../../../constants/other"


const CreateUpdateRatePopup = ({
    currencies,
    exchangeRate,
    primaryCurrency,
    setExchangeRate,
    create,
    setUserMessage,
    setIsGeneratedReport,
    close
}) => {

    const handleSubmit = () => {
        setIsGeneratedReport(true),
        close()
        setUserMessage({
            message: create
                ? "Payment Report was successfully generated!"
                : "Exchange rate was successfully updated",
            code: SUCCESS_CODE,
        })
    }

    useEffect(() => {
        if (primaryCurrency) {
            setExchangeRate((state) => ({...state, [primaryCurrency]: 1}))
        }
    }, [])

    const handleInputChange = (event, currency) => {
        const value = event.target.value
    
        if (DECIMAL_PATTERN.test(value)) {
            setExchangeRate((state) => ({
                ...state,
                [currency]: event.target.value,
            }))
        }
    }

    return (
        <CustomPopup
            className="create-update-equipment-popup"
            wrapperClass="create-update-equipment-popup-wrapper"
            text_title={`${!create ? "Edit" : "Create"} Exchange Rate`}
        >
            <div className="create-update-equipment-popup-content">
                {currencies.map((currency, i) => (
                    <TextInput
                        key={`currency_${i}`}
                        text_label={currency}
                        type="text"
                        value={currency in exchangeRate ? exchangeRate[currency] : null}
                        onChange={(e) => handleInputChange(e, currency)}
                    />
                ))}
                <div className="popup-bottom">
                    <div className="popup-bottom-button-container">
                        <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                        <SmallButton
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {!create ? "SAVE" : "CREATE"}
                        </SmallButton>
                    </div>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateUpdateRatePopup
