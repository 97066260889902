import React from "react"
import {CircularProgress} from "@mui/material"

const StyledCircularProgress = ({style, tag: Tag = "div"}) => {
    return (
        <Tag style={{ display: "flex", width: "100%", justifyContent: "center", marginBottom: "50px", color: "#F57F17", ...style}}>
            <CircularProgress style={{color: "inherit"}}/>
        </Tag>
    )
}

export default StyledCircularProgress
