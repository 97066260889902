import React, {useEffect, useState} from "react"

import {CACHED_IMAGES} from "../../../constants/other"
import CloseIcon from "@mui/icons-material/Close"
import {useSelector} from "react-redux"
import OnboardingPopup from "./OnboardingPopup"
import LastStepPopup from "./LastStepPopup"
import {getOnboardingPopupSeen} from "../../../utils/localstorage.utils"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import {ONBOARDING_STATUS} from "../../../constants/onboarding"
import {useGetUserOnboardingsQuery} from "../../../api/onboardings"


const WelcomePopup = ({firstName, close}) => {
    const [showOnboardingPopup, setShowOnboardingPopup] = useState(false)
    const [showLastStepPopup, setShowLastStepPopup] = useState(false)
    const [onboardingPopupSeen, setOnboardingPopupSeen] = useState(false)
    const userId = useSelector((state) => state.auth.user.uid)
    const {data: userOnboarding} = useGetUserOnboardingsQuery(userId)

    const handleWelcomeButtonClick = () => {
        if (userOnboarding && userOnboarding.onboarding_status === ONBOARDING_STATUS.IN_PROGRESS) {
            setShowOnboardingPopup(true)
        } else {
            close()
        }
    }

    useEffect(() => {
        const seen = getOnboardingPopupSeen(userId) === true
        setOnboardingPopupSeen(seen)
    }, [userId])

    return (
        <FlexiblePopup>
            <div>
                {!showOnboardingPopup && !onboardingPopupSeen && (
                    <div className="greetings-welcome">
                        <img src={CACHED_IMAGES.LogoNS} className="greetings-welcome-logo-ns"/>
                        <div className="greetings-welcome-content">
                            <div className="greetings-welcome-content-title t-h1">
                                <div>Hey <span>{firstName}</span> 👋</div>
                                <div>Welcome to Newsoft Inside 🚀</div>
                            </div>
                            <div className="greetings-welcome-content-text t-b5">
                                <div>We&#39;re thrilled to have you join our dream team!</div>
                            </div>
                        </div>
                        <button onClick={handleWelcomeButtonClick} className="greetings-welcome-button t-button">Let&#39;s do it
                        </button>
                        <CloseIcon onClick={close} className="greetings-welcome-button-close"/>
                    </div>
                )}
                {((showOnboardingPopup || onboardingPopupSeen) && !showLastStepPopup) && (
                    <FlexiblePopup noStyle={true}>
                        <OnboardingPopup userId={userId} setShowLastStepPopup={setShowLastStepPopup} close={close} />
                    </FlexiblePopup>
                )}
                {showLastStepPopup && (
                    <LastStepPopup userOnboarding={userOnboarding} close={close} />
                )}
            </div>
        </FlexiblePopup>
    )
}

export default WelcomePopup
