import React, {useState} from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useCreateEquipmentCategoryMutation, useUpdateEquipmentCategoryMutation} from "../../../api/equipment"


const CreateUpdateCategoryPopup = ({category, setUserMessage, refreshTable, close}) => {
    const [name, setName] = useState(category?.name)

    const [createEquipmentCategory] = useCreateEquipmentCategoryMutation()
    const [updateEquipmentCategory] = useUpdateEquipmentCategoryMutation()

    const handleSubmit = () => {
        const data = {
            name: name
        }

        if (category) {
            updateEquipmentCategory({id: category.id, data})
                .unwrap()
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: "Equipment Category was successfully updated!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    close()
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        } else {
            createEquipmentCategory(data)
                .unwrap()
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: "Equipment Category was successfully created!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    close()
                    console.log(error)
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup text_title={`${category ? "Edit" : "Create"} Category`}>
            <TextInput
                text_label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!name}
                    >{category ? "SAVE" : "CREATE"}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateUpdateCategoryPopup
