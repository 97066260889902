import React from "react"

import TimeRecordForm from "../../TimeTracker/components/TimeRecordForm"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"


export const EditTimeRecordPopup = ({timeRecord, refreshData, setUserMessage, close, userId,
    setRequestOvertimeCoefficient, setDateForOvertimeRequest}) => {

    return (
        <CustomPopup text_title="Edit time record" className="edit-time-record-popup">
            <TimeRecordForm refreshData={refreshData} timeRecord={timeRecord} editFlag={true} 
                close={close} setUserMessage={setUserMessage} userId={userId} 
                setDateForOvertimeRequest={setDateForOvertimeRequest}
                setRequestOvertimeCoefficient={setRequestOvertimeCoefficient}/>
        </CustomPopup>
    )
}

export default EditTimeRecordPopup
