import React, {useEffect, useState} from "react"
import Popup from "reactjs-popup"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import ClosePopupIcon from "../../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import UnassignProjectPopup from "./UnassignProjectPopup"

import {changeDateFormat} from "../../../../utils/datetime.utils"

import {SUCCESS_CODE, ERROR_CODE, DD_MM_YYYY} from "../../../../constants/other"

import RemoveIcon from "@mui/icons-material/Remove"
import CreatableDDList from "../../../../toolkits/CreatableDDList/CreatableDDList"
import useUserProjects from "../../../../hooks/useUserProjects"
import {useAssignProjectMutation, useCreateProjectMutation} from "../../../../api/projects"
import useProjects from "../../../../hooks/useProjects"
import DeprecatedSingleDatePicker from "../../../../toolkits/Calendar/DeprecatedSingleDatePicker"


const EditProjectsPopup = ({user, setUserMessage, close}) => {
    const [assignProject] = useAssignProjectMutation()
    const [createProject] = useCreateProjectMutation()

    const [createMode, setCreateMode] = useState(false)
    
    const [project, setProject] = useState(null)
    const [startDate, setStartDate] = useState(null)

    const {projects} = useProjects()
    const {projects: userProjects} = useUserProjects({userId: user.id})

    const assignProjectSubmit = (e) => {
        e.target.disabled = true

        const data = {
            project_id: project.value,
            user_id: user.id,
            start_date: changeDateFormat(startDate, DD_MM_YYYY, "YYYY-MM-DD"),
        }
        assignProject(data)
            .unwrap()
            .then(() => {
                setUserMessage({
                    message: `The project ${project.label} was successfully assigned to user`,
                    code: SUCCESS_CODE
                })
                setProject(null)
                setCreateMode(false)
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
                e.target.disabled = false
            })
    }

    useEffect(() => {
        if (!userProjects.length) {
            setCreateMode(true)
        }
    }, [userProjects.length])


    const ProjectItem = ({project, isDisabled}) => {
        return (
            <div className={`edit-projects-item ${isDisabled ? "edit-projects-item-disabled" : ""}`}>
                <div className="edit-projects-item-value">
                    <span className="t-s3">{project.name}</span>
                </div>
                {!createMode ?
                    <Popup trigger={
                        <div className="edit-projects-item-button">
                            <RemoveIcon className="remove-icon"/>
                        </div>
                    } modal>
                        {close => (
                            <UnassignProjectPopup
                                userId={user.id}
                                project={project}
                                setUserMessage={setUserMessage}
                                close={close}
                            />
                        )}
                    </Popup>
                    : <div className="edit-projects-item-button">
                        <RemoveIcon className="remove-icon"/>
                    </div>
                }
            </div>
        )
    }

    return (
        <CustomPopup text_title="Edit Projects" className="edit-projects-popup">  
            <>
                <div className="edit-projects-popup-box">
                    {userProjects.length ? <>
                        <label className="toolkit-dd-list-label t-s3">Projects</label>
                        <div className="edit-projects-popup-box-item">
                            {userProjects.map((project, i) => (
                                <ProjectItem
                                    project={project.project}
                                    isDisabled={createMode}
                                    key={`${project.project.name}_user_project_${i}`}
                                />
                            ))}
                        </div>
                    </> : <></>
                    }
                    {!createMode &&
                        <span className="t-b4 clickable" onClick={() => setCreateMode(true)}>+ Project</span>
                    }
                </div>

                {createMode &&
                    <div className="edit-projects-popup-form">
                        <CreatableDDList
                            text_label="Add project"
                            placeholder=""
                            listOptions={projects
                                .map(project => ({value: project.id, label: project.name}))
                                .filter(project => !userProjects.map(x => x.project.id).includes(project.value))
                            }
                            onChange={proj => setProject(proj)} 
                            value={project ? {value: project.value, label: project.label} : null} 
                            onCreateOption={(new_option) => {
                                createProject({name: new_option})
                                    .unwrap()
                                    .then((response) => {
                                        setProject({value: response.data.id, label: response.data.name})
                                        setUserMessage({message: "Project was successfully created!", code: SUCCESS_CODE})
                                    })
                                    .catch(error => {
                                        setUserMessage({message: error.message, code: ERROR_CODE})
                                    })
                            }}
                        />
                        <DeprecatedSingleDatePicker
                            label="Start date"
                            className="single-datepicker-field"
                            defaultValue={null}
                            setNewDate={setStartDate}
                        />
                    </div>
                }
            </>
            {createMode &&
                <div className="popup-bottom">
                    <div className="popup-bottom-button-container">
                        <SmallButton btnType="secondary" onClick={() => {setCreateMode(false); close()}}> CANCEL </SmallButton>
                        <SmallButton
                            type="submit"
                            onClick={assignProjectSubmit}
                            disabled={createMode && (!project || !startDate)}
                        >SAVE</SmallButton>
                    </div>
                </div>
            }

            <ClosePopupIcon onClick={close}/>
        </CustomPopup>
    )
}

export default EditProjectsPopup

