import React, {useState} from "react"

import {updateEnglishLevel} from "../../api"

import SmallButton from "../../../../toolkits/SmallButton/SmallButton"

import DDList from "../../../../toolkits/DDList/DDList"
import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"

import {ENGLISH_LEVELS, ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"


const EnglishLevelPopup = ({
    close, 
    currentLevel,
    receiveUserInfo,
    setUserMessage,
    userId,
}) => { 
    const [englishLevel, setEnglishLevel] = useState(null)

    const handleSubmit = () => {
        updateEnglishLevel(userId, {english_level: englishLevel.value})
            .then(() => {
                receiveUserInfo()
                close(false)
                setUserMessage({message: "English level was successfully changed!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup className="edit-english-popup" text_title="Edit English Level">
            <DDList
                text_label="English level"
                placeholder=""
                defaultValue={({value: currentLevel, label: currentLevel})}
                listOptions={ENGLISH_LEVELS.map((lvl) => ({value: lvl, label: lvl}))}
                onChange={setEnglishLevel}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">    
                    <SmallButton btnType={"secondary"} onClick={() => close(false)}>CANCEL</SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} disabled={!englishLevel || currentLevel === englishLevel}>SAVE</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default EnglishLevelPopup
