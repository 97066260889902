import "./index.scss"

import React, {createRef, useEffect} from "react"
import {CircularProgress} from "@mui/material"


const SmallButton = ({children, btnType, disabled, loading, type="button", extendLoadingStyles, ...props}) => {
    const inputRef = createRef()
    const loadingStyle = {
        color: "#FFFFFF",
        width: "16px",
        height: "16px",
    }

    useEffect(() => {
        inputRef.current.blur()
    }, [])

    return (
        <button
            type={type}
            className={`t-button small-button${btnType === "secondary" ? "-secondary" : ""}${btnType !== "secondary" && loading ? "-default-loading" : ""}`}
            ref={inputRef}
            disabled={disabled || loading}
            {...props}
        >
            {loading ?
                <CircularProgress
                    style={{ ...loadingStyle, ...extendLoadingStyles }}
                />
                : children || "button"
            }
        </button>
    )
}

export default SmallButton
