import React from "react"
import {useSelector} from "react-redux"
import {isTrue, toCamelCase} from "../../utils/other.utils"


const SettingsEnabled = ({children, settingsNames}) => {
    const settingNames = Array.isArray(settingsNames) ? settingsNames.map(toCamelCase) : [toCamelCase(settingsNames)]

    const allSettingsEnabled = useSelector(state =>
        settingNames.every(name => isTrue(state.settings[name]?.value))
    )

    return allSettingsEnabled ? children : <></>
}

export default SettingsEnabled
