import "../../layouts/Navs/NavsLayout.scss"
import "./index.scss"

import React, {useEffect, useState} from "react"
import Popup from "reactjs-popup"
import {Helmet} from "react-helmet"
import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"
import {useMediaQuery} from "react-responsive"

import Protected from "../../components/Protected/Protected"
import EmployeesList from "./components/EmployeesList"
import {EmployeesFilters} from "./components/EmployeesFilters"
import AddEmployeePopup from "./components/AddEmployeePopup"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {getDraft, saveDraft} from "../../utils/localstorage.utils"

import {ProfileRoute} from "../../routes"
import {useCustomHistory} from "../../hooks/useCustomHistory"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetUsersListQuery} from "../../api/users"

import {
    DESKTOP_WIDTH,
    EMPLOYEES_FILTERS,

} from "../../constants/other"

import CloseButtonIcon from "@mui/icons-material/Close"
import {PERMISSIONS} from "../../constants/permissions"
import {useNavCount} from "../../hooks/common/useNavCount"


/**
 * @desc Module "Employees Page".
 * @return {JSX.Element} - module that includes a page, with user list, and possibily to change them (if manager/admin).
 */
const Employees = () => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const history = useCustomHistory()
    const auth = useSelector((state) => state.auth)

    const [searchKey, setSearchKey] = useState()
    const [filterKey, setFilterKey] = useState(EMPLOYEES_FILTERS.UNARCHIVE)
    const [addEmployeePopup, setAddEmployeePopup] = useState(false)
    const [userMessage, setUserMessage] = useState(null)
    const [showDraftCreateUser, setShowDraftCreateUser] = useState(Object.values(getDraft(auth.user.uid)?.createUser ?? {}).length)
    const { setCount } = useNavCount()

    const { items, loadNextPage, hasMore, changeParams, reset, total, empty } = usePaginatedQuery(
        useGetUsersListQuery,
        {
            defaultState: {
                params: {
                    archived: filterKey === EMPLOYEES_FILTERS.ARCHIVE,
                    query: searchKey,
                }
            }
        }
    )

    useEffect(() => changeParams({
        archived: filterKey === EMPLOYEES_FILTERS.ARCHIVE,
        query: searchKey,
    }, false), [filterKey, searchKey])

    useEffect(() => {
        if (total !== undefined && total !== null) {
            setCount(total)
        }
    }, [total])

    /**
     * @desc [Function] Function for choosing profile route.
     * @returns {function}
     */
    const profileClick = (id) => id === auth.user.uid ? history.push(ProfileRoute.path) : history.push(`/profile/${id}`)

    const clearDraftUser = () => {
        saveDraft(auth.user.uid, {createUser: {}})
        setShowDraftCreateUser(false)
    }

    return (
        <>
            <Helmet>
                <title>Employees - Newsoft Inside</title>
            </Helmet>
            <div className="employees">
                {userMessage &&
                    <AlertMessage setMessage={setUserMessage} {...userMessage}/>
                }
                <div className="employees-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500} />
                    <div className="employees-header-right">
                        <Protected permissions={[PERMISSIONS.WRITE_USER]}>
                            <div className="employees-header-right-add">
                                {(isDesktop && showDraftCreateUser) ?
                                    <div className="employees-header-right-add-draft">
                                        <div>
                                            <Popup
                                                trigger={<span className="t-b2 clickable">use employee draft</span>}
                                                closeOnDocumentClick={false}
                                                modal
                                            >
                                                {close => (
                                                    <>
                                                        <AddEmployeePopup
                                                            employee={getDraft(auth.user.uid)?.createUser}
                                                            setShowDraftCreateUser={setShowDraftCreateUser}
                                                            refreshTable={reset}
                                                            setUserMessage={setUserMessage}
                                                            close={close}
                                                        />
                                                    </>
                                                )}
                                            </Popup>
                                            <CloseButtonIcon className="clear-draft-icon clickable" onClick={clearDraftUser}/>
                                        </div>
                                    </div> : <></>
                                }
                                <div className="employees-header-right-add-button">
                                    <SmallButton onClick={() => setAddEmployeePopup(true)}> ADD EMPLOYEE </SmallButton>
                                </div>
                            </div>
                        </Protected>
                        <Protected permissions={[PERMISSIONS.WRITE_ARCHIVED_USER]}>
                            <EmployeesFilters setFilterKey={setFilterKey}/>
                        </Protected>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                >
                    <div className="employees-content">
                        <EmployeesList
                            users={items}
                            profileClick={profileClick}
                            setUserMessage={setUserMessage}
                            empty={empty}
                        />
                    </div>
                </InfiniteScroll>
            </div>

            <Popup open={addEmployeePopup} closeOnDocumentClick={false} onClose={() => setAddEmployeePopup(false)} modal>
                {close => (
                    <AddEmployeePopup
                        setShowDraftCreateUser={setShowDraftCreateUser}
                        refreshTable={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>
    )
}

export default Employees
