import {
    GET_PURCHASES_SUCCESS,
    GET_PURCHASES_REQUEST,
    GET_PURCHASES_FAILURE,
    GET_NEXT_PAGE_PURCHASES_REQUEST
} from "../const"

const initialState = {
    purchases: [],
    total: 0,
    tableLoading: true,
    loading: true,
}

const handlers = {
    [GET_PURCHASES_REQUEST]: (state) => ({ ...state, tableLoading: true, purchases: [], total: 0 }),
    [GET_PURCHASES_SUCCESS]: (state, { payload: {purchases} }) => ({
        ...state,
        purchases: [...state.purchases, ...purchases.items],
        total: purchases.total,
        tableLoading: false,
        loading: false,
    }),
    [GET_PURCHASES_FAILURE]: (state) => ({ ...state, tableLoading: false, loading: false }),

    [GET_NEXT_PAGE_PURCHASES_REQUEST]: (state) => ({ ...state, tableLoading: true }),

    DEFAULT: (state) => state,
}

const personalPurchaseHistoryReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default personalPurchaseHistoryReducer