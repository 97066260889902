import {useGetAllUserProjectsQuery, useGetUserProjectsQuery} from "../api/projects"


const useUserProjects = ({ userId, all, skip }) => {
    const {data: currentProjects, isLoading: currentProjectsLoading} = useGetUserProjectsQuery({ userId }, { skip: skip || all })
    const {data: allProjects, isLoading: allProjectsLoading} = useGetAllUserProjectsQuery({ userId }, { skip: skip || !all })

    const projects = (all ? allProjects : currentProjects) || []
    return {
        projects: projects,
        isLoading: currentProjectsLoading || allProjectsLoading,
    }
}

export default useUserProjects