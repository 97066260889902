import {coinsRelatedApi} from "./base"


export const balanceApi = coinsRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        getBalance: builder.query({
            query: (userId) => ({
                path: `newcoins-transactions/newcoins-balances/${userId}`,
            }),
            providesTags: (result, error, args) => [{type: "BalanceAvailable", "id": args}],
        }),
        getSpentCoins: builder.query({
            query: (userId) => ({
                path: `newcoins-transactions/newcoins-balances/${userId}/spent`,
            }),
            providesTags: (result, error, args) => [{type: "BalanceSpent", "id": args}],
        }),
    })
})

export const {
    useGetBalanceQuery,
    useGetSpentCoinsQuery,
} = balanceApi
