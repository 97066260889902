import React, {useState} from "react"

import TimeRecordForm from "./TimeRecordForm"
import TimeRecordsTable from "./TimeRecordsTable"
import RequestOvertimeCoeffPopup from "./RequestOvertimeCoeffPopup"
import {requestOvertimeCoeff} from "../api"
import { useSelector } from "react-redux"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import { formatDate } from "../../../utils/datetime.utils"
import {
    ERROR_CODE,
    SUCCESS_CODE,
} from "../../../constants/other"
import Popup from "reactjs-popup"


const TimeRecords = ({
    setUserMessage, 
    onDateChanged,
    updateTimeTrackerData,
    loadingTable,
    timeRecords,
    userId,
    timeOffTabActive,
    setTimeOffTabActive,
    timeOffRequests,
}) => {
    const auth = useSelector((state) => state.auth)
    const [requestOvertimeCoefficient, setRequestOvertimeCoefficient] = useState(false)
    const [date, setDate] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [previousWorkingDay, setPreviousWorkingDay] = useState(new Date())
    const [hasPreviousDayRecords, setHasPreviousDayRecords] = useState(false)

    const onRequestOvertimeCoeff = ({close}) => {
        requestOvertimeCoeff({
            user_id: userId || auth.user.uid,
            date: formatDate(date),
        })
            .then(() => {
                setUserMessage({message: "Overtime coefficient was successfully requested!", code: SUCCESS_CODE})
                close()
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <div className="time-tracker-content-time-records">
            <TimeRecordForm
                setUserMessage={setUserMessage} 
                editFlag={false}
                onDateChanged={onDateChanged}
                refreshData={updateTimeTrackerData}
                setRequestOvertimeCoefficient={setRequestOvertimeCoefficient}
                setDateForOvertimeRequest={setDate}
                userId={userId || auth.user.uid}
                timeOffTabActive={timeOffTabActive}
                setTimeOffTabActive={setTimeOffTabActive}
                previousWorkingDay={previousWorkingDay}
                setPreviousWorkingDay={setPreviousWorkingDay}
                hasPreviousDayRecords={hasPreviousDayRecords}
                setHasPreviousDayRecords={setHasPreviousDayRecords}
                setSelectedDate={setSelectedDate}
            />
            <Popup open={requestOvertimeCoefficient} closeOnDocumentClick onClose={() => setRequestOvertimeCoefficient(false)} modal>
                {close => (
                    <RequestOvertimeCoeffPopup close={close} onSave={() => onRequestOvertimeCoeff({close})}/>
                )}
            </Popup>
            {loadingTable
                ? <div className="time-tracker-content-loader"><StyledCircularProgress/></div> 
                : <> {timeRecords.length > 0 || timeOffTabActive && timeOffRequests.length > 0 ?
                    <div className="time-tracker-content-records">
                        <TimeRecordsTable
                            refreshData={updateTimeTrackerData}
                            timeRecords={timeRecords}
                            timeOffRequests={timeOffRequests}
                            timeOffTabActive={timeOffTabActive}
                            setUserMessage={setUserMessage}
                            userId={userId}
                            setRequestOvertimeCoefficient={setRequestOvertimeCoefficient}
                            setDateForOvertimeRequest={setDate}
                        />
                    </div>
                    :
                    !timeOffTabActive &&
                    <div className="time-tracker-content-alt-label">
                        <div className="t-s1">There is no submitted time for {formatDate(selectedDate, "ddd, DD MMM")}</div>
                        <div className="t-s4">{hasPreviousDayRecords ?
                            `You can duplicate from ${formatDate(previousWorkingDay, "ddd, DD MMM")}`
                            :
                            "Select a project you worked on and input the hours"}</div>
                    </div>
                } </>
            }
        </div>
    )
}

export default TimeRecords