import {useGetTimeOffsUsageInfoQuery, useGetTimeOffBalancesUniqueYearsQuery} from "../api/timeOffBalances"


const useTimeOffsUsageInfo = ({ userId, params } = {}) => {
    const {data: infoData, isLoading: isInfoLoading} = useGetTimeOffsUsageInfoQuery({userId, params})
    const {data: yearsData, isLoading: isYearsLoading} = useGetTimeOffBalancesUniqueYearsQuery({user_id: userId})

    return {
        timeOffsUsageInfo: infoData || [],
        uniqueYears: yearsData || [],
        isLoading: isInfoLoading || isYearsLoading,
    }
}

export default useTimeOffsUsageInfo