import {
    GET_PURCHASES_REQUEST,
    GET_PURCHASES_SUCCESS,
    GET_PURCHASES_FAILURE,
    GET_NEXT_PAGE_PURCHASES_REQUEST
} from "../const"
import {getUserPurchases} from "../api"

const request = () => ({ type: GET_NEXT_PAGE_PURCHASES_REQUEST })

const searchRequest = () => ({ type: GET_PURCHASES_REQUEST })

const success = (purchases) => ({
    type: GET_PURCHASES_SUCCESS,
    payload: {purchases},
})

const failure = () => ({ type: GET_PURCHASES_FAILURE })


export const receiveUserPurchases = (params) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receivePurchasesData} = await getUserPurchases(params)

        dispatch(success(receivePurchasesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageUserPurchases = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receivePurchasesData} = await getUserPurchases(params)

        dispatch(success(receivePurchasesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}