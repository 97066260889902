import {
    GET_USERS_REPORTS,
    SET_SELECTED_USER
} from "../const"

const initialState = {
    users: [],
    earn: [],
    overtimes: [],
    bonuses: [],
    currency: {
        id: 0,    
        iso_symbol: "",
        iso_code: ""
    },
    selectedUser: null,
    tableLoading: false,
    loading: false
}

const handlers = {
    [GET_USERS_REPORTS]: (state, { payload: { users } }) => ({
        ...state,
        users: users.items || users,
    }),

    [SET_SELECTED_USER]: (state, {payload: {selectedUser}}) => ({
        ...state,
        selectedUser
    }),

    DEFAULT: (state) => state,
}

const reportsPageReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default reportsPageReducer
  

