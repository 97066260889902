import React, {useContext, useState} from "react"

import {isAdmin} from "../../utils/permission.utils"
import NewYearSleighAnimation from "../Miscellaneous/NewYearSleighAnimation"
import { ThemeContext } from "../../styles/theme-context"


const NavigationContainer = ({children, activeNavs}) => {
    const theme = useContext(ThemeContext)
    const [active, setActive] = useState(false)

    const extraClasses = [theme.navsClassName]

    if (activeNavs) {
        extraClasses.push("navs-active")
    }

    return (isAdmin() ? <></> :
        <div className="wrapper-navs-container">
            <div 
                className={`navs ${extraClasses.join(" ")}`}
                onClick={(event) => event.detail >= 2 && setActive(true)}
            >
                {children}
            </div>

            {theme.navsAnimation ? (<NewYearSleighAnimation active={active}/>) : null}
        </div>
    )
}

export default NavigationContainer
