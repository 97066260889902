import {
    GET_OVERTIME_EARN_SUCCESS,
    GET_OVERTIME_EARN_REQUEST,
    GET_OVERTIME_EARN_FAILURE
} from "../const"

import {getOvertimeEarn} from "../api"

export const request = () => ({ type: GET_OVERTIME_EARN_REQUEST })

const success = (overtimeEarn) => ({
    type: GET_OVERTIME_EARN_SUCCESS,
    payload: { overtimeEarn },
})

const failure = () => ({ type: GET_OVERTIME_EARN_FAILURE })


export const receiveOvertimeEarnInfo = (user_id, params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveSalaryEarnInfoData} = await getOvertimeEarn(user_id, params)
        dispatch(success(receiveSalaryEarnInfoData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}