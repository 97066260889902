import React, {useContext, useState} from "react"
import {PERMISSIONS} from "../../../../constants/permissions"
import Protected from "../../../../components/Protected/Protected"
import PenIcon from "@mui/icons-material/Create"
import {formatDate} from "../../../../utils/datetime.utils"
import {DD_MM_YYYY} from "../../../../constants/other"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import FirstDayPopup from "../Popups/FirstDayPopup"
import Popup from "reactjs-popup"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"
import {useTranslation} from "react-i18next"

const FirstDayCard = ({user, isMyProfile, setUserMessage, receiveUserInfo}) => {
    const { t } = useTranslation("common")

    const [firstDay, setFirstDay] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <>
            <div onClick={() => hasPermission(PERMISSIONS.WRITE_USER) && setFirstDay(true)} className={`profile-info-card ${hasPermission(PERMISSIONS.WRITE_USER) ? `clickable ${theme.profileCardHover}` : ""}`}>
                <Protected permissions={[PERMISSIONS.WRITE_USER]} accessForAdmin>
                    <PenIcon onClick={() => setFirstDay(true)}
                        className="profile-info-card-pen-icon clickable"/>
                </Protected>

                <h3 className="t-h3 profile-font-leading">{t("FIRST DAY")}</h3>
                <h1 className="t-h1">
                    {user.hiring_date ? formatDate(user.hiring_date, DD_MM_YYYY) : <EmptyCard>{t("No date")}</EmptyCard>}
                </h1>
            </div>

            <Popup open={firstDay} closeOnDocumentClick onClose={() => setFirstDay(false)} modal>
                <FirstDayPopup
                    close={setFirstDay}
                    day={user.hiring_date}
                    userId={user.id}
                    setUserMessage={setUserMessage}
                    receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)}
                />
            </Popup>
        </>
    )
}

export default FirstDayCard