import {getUserBonuses} from "../api"

import {
    GET_USER_BONUSES_SUCCESS,
    GET_USER_BONUSES_FAILURE,
    GET_NEXT_PAGE_USER_BONUSES_REQUEST,
    GET_USER_BONUSES_REQUEST,
} from "../const"


const request = () => ({type: GET_USER_BONUSES_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_USER_BONUSES_REQUEST})

const success = (bonuses) => ({
    type: GET_USER_BONUSES_SUCCESS,
    payload: {bonuses},
})

const failure = () => ({type: GET_USER_BONUSES_FAILURE})


export const receiveUserBonuses = (user_id, params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveUserBonusesData} = await getUserBonuses(user_id, params)
        dispatch(success(receiveUserBonusesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageUserBonuses = (user_id, params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveUserBonusesData} = await getUserBonuses(user_id, params)
        dispatch(success(receiveUserBonusesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
