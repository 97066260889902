import {getPaymentReports, getPaymentReportsTotal, getPayments} from "../api"

import {
    GET_PAYMENT_REPORTS_REQUEST,
    GET_PAYMENT_REPORTS_SUCCESS,
    GET_PAYMENT_REPORTS_FAILURE,
    GET_NEXT_PAGE_PAYMENT_REPORTS_REQUEST,

    GET_PAYMENT_REPORTS_TOTAL_REQUEST,
    GET_PAYMENT_REPORTS_TOTAL_SUCCESS,
    GET_PAYMENT_REPORTS_TOTAL_FAILURE,

    GET_PAYMENTS_REQUEST,
    GET_PAYMENTS_FAILURE,
    GET_PAYMENTS_SUCCESS,
} from "../const"

// PAYMENTS
const request = () => ({type: GET_PAYMENTS_REQUEST})

const success = (payments) => ({
    type: GET_PAYMENTS_SUCCESS,
    payload: {payments},
})

const failure = () => ({type: GET_PAYMENTS_FAILURE})

// PAYMENT REPORTS
const requestReports = () => ({type: GET_NEXT_PAGE_PAYMENT_REPORTS_REQUEST})

const requestNextReports = () => ({type: GET_PAYMENT_REPORTS_REQUEST})

const successReports = (paymentReports) => ({
    type: GET_PAYMENT_REPORTS_SUCCESS,
    payload: {paymentReports},
})

const failureReports = () => ({type: GET_PAYMENT_REPORTS_FAILURE})


// PAYMENT REPORTS TOTAL
const requestTotal = () => ({type: GET_PAYMENT_REPORTS_TOTAL_REQUEST})

const successTotal = (paymentTotal) => ({
    type: GET_PAYMENT_REPORTS_TOTAL_SUCCESS,
    payload: {paymentTotal},
})

const failureTotal = () => ({type: GET_PAYMENT_REPORTS_TOTAL_FAILURE})


export const receivePaymentReports = ({params, cancelToken}) => async (dispatch) => {
    dispatch(requestNextReports())
    try {
        const {data: receivePaymentReportsData} = await getPaymentReports(params, cancelToken)

        dispatch(successReports(receivePaymentReportsData))
    } catch (e) {
        dispatch(failureReports(e))
    }
}

export const receiveNextPagePaymentReports = (params) => async (dispatch) => {
    dispatch(requestReports())
    try {
        const {data: receivePaymentReportsData} = await getPaymentReports(params)
        dispatch(successReports(receivePaymentReportsData))
    } catch (e) {
        console.log(e)
        dispatch(failureReports(e))
    }
}

export const receivePaymentReportsTotal = ({params, cancelToken}) => async (dispatch) => {
    dispatch(requestTotal())
    try {
        const {data: receivePaymentTotalData} = await getPaymentReportsTotal(params, cancelToken)

        dispatch(successTotal({
            ...receivePaymentTotalData,
            primary_currency: Object.keys(receivePaymentTotalData.currency_iso_codes)
                .filter((key =>
                    receivePaymentTotalData.currency_iso_codes[key].primary))[0],
        }))
    } catch (e) {
        console.log(e)
        dispatch(failureTotal())
    }
}

export const receivePayments = ({params, cancelToken}) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receivePaymentsData} = await getPayments(params, cancelToken)

        dispatch(success(receivePaymentsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
