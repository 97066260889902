
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpBackend from "i18next-http-backend"
import LocalStorageBackend from "i18next-localstorage-backend"
import detector from "i18next-browser-languagedetector"
import ChainedBackend from "i18next-chained-backend"

i18n
    .use(detector) // Will detect the language based on the browser language
    .use(ChainedBackend) // Will allow using multiple backends
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        supportedLngs: process.env.NODE_ENV === "development" ? ["en", "uk"]: ["en"], // Support "uk" only for development at the moment
        debug: process.env.NODE_ENV === "development",
        backend: {
            backends: [
                // use cached localization first
                LocalStorageBackend,
                HttpBackend,
            ],
            backendOptions: [
                {
                    prefix: "i18n_locale_",
                    expirationTime: process.env.NODE_ENV === "development" ? 0 : 60 * 60 * 1000, // Valid for one hour
                    store: window.localStorage,
                },
                {
                    loadPath: "/locales/{{lng}}/{{ns}}.json",
                },
            ],
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "b"]
        }
    })


export default i18n