import "./index.scss"
import React from "react"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

const StaticBoxList = ({
    className,
    mainLabel,
    items,
    loading,
    getContent,
    getAdditionalInfo,
}) => {
    return (
        <>
            {mainLabel && <span className="static-box-list-label t-s3">{mainLabel}</span>}
            <div className={`static-box-list${className ?? ""}`}>
                {loading ? (
                    <div className="static-box-list-progress">
                        <StyledCircularProgress />
                    </div>
                ) : (
                    <div className="static-box-list-table">
                        {items && items.map((item) => (
                            <div
                                key={`static_box_list_item_${item.id}`}
                                className="static-box-list-table-row"
                            >
                                <span className="t-s4">
                                    {getContent(item)}
                                </span>

                                {getAdditionalInfo && getAdditionalInfo(item) && (
                                    <div className="static-box-list-table-row-additional t-b4">
                                        {getAdditionalInfo(item)}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default StaticBoxList
