import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const projectsApi = createApi({
    reducerPath: "projectsApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["Projects", "UserProjects", "AllUserProjects"],
    endpoints: (builder) => ({
        getProjects: builder.query({
            query: (params) => ({
                path: "projects/",
                params,
            }),
            providesTags: ["Projects"],
        }),
        getUserProjects: builder.query({
            query: ({userId}) => ({
                path: `users/${userId}/projects`,
            }),
            providesTags: (result, error, arg) => [{type: "UserProjects",id: arg.userId}]
        }),
        getAllUserProjects: builder.query({
            query: ({userId}) => ({
                path: `users/${userId}/projects?ended_=true`,
            }),
            providesTags: (result, error, arg) => [{type: "UserProjects",id: arg.userId}]
        }),
        createProject: builder.mutation({
            query: (data) => ({path: "projects/", method: "POST", data}),
            invalidatesTags: ["Projects"]
        }),
        updateProject: builder.mutation({
            query: ({id, data}) => ({path: `projects/${id}`, method: "PUT", data}),
            invalidatesTags: ["Projects"]
        }),
        assignProject: builder.mutation({
            query: (data) => ({path: "projects/assign", method: "POST", data}),
            invalidatesTags: ["Projects", "UserProjects"]
        }),
        unassignProject: builder.mutation({
            query: (data) => ({path: "projects/unassign", method: "POST", data}),
            invalidatesTags: ["Projects", "UserProjects", "AllUserProjects"]
        }),
        batchUnassignProject: builder.mutation({
            query: (data) => ({path: "projects/unassign-multiple", method: "POST", data}),
            invalidatesTags: ["Projects", "UserProjects", "AllUserProjects"]
        }),
        deleteProject: builder.mutation({
            query: (id) => ({path: `projects/${id}`, method: "DELETE"}),
            invalidatesTags: ["Projects", "UserProjects", "AllUserProjects"]
        }),

    })
})

export const {
    useGetProjectsQuery,
    useGetUserProjectsQuery,
    useGetAllUserProjectsQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useAssignProjectMutation,
    useUnassignProjectMutation,
    useBatchUnassignProjectMutation,
    useDeleteProjectMutation,
} = projectsApi
