import React, {useState, useRef, useCallback, useEffect} from "react"
import MUIRichTextEditor from "mui-rte"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import {stateToHTML} from "draft-js-export-html"
import { CompositeDecorator, ContentState, convertFromHTML, convertToRaw, EditorState, RichUtils } from "draft-js"
import {useMediaQuery} from "react-responsive"
import InsertLinkIcon from "@mui/icons-material/InsertLink"
import CheckIcon from "@mui/icons-material/Check"
import { MOBILE_WIDTH } from "../../constants/other"

import "./index.scss"


const RichTextEditor = ({title = false, label, setValue, value=null}) => {
    const [showToolbar, setShowToolbar] = useState(true)
    const [positionOnLink, setPositionOnLink] = useState(false)
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})

    const popover = useRef()
    const [isOpen, setIsOpen] = useState(false)

    const closePopover = useCallback(() => setIsOpen(false), [])

    function findLinkEntities(contentBlock, callback, contentState) {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity()
                return (
                    entityKey !== null &&
          contentState.getEntity(entityKey).getType() === "LINK"
                )
            },
            callback
        )
    }

    const CustomLinkWrapper = (props) => {
        const {url} = props.contentState.getEntity(props.entityKey).getData()
        return (
            <a href={url} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        )
    }

    const CustomLinkComponent = () => {
        const handleMouseDown = (e) => {
            e.stopPropagation()
            promptForLink(e)
        }
        return (
            <div onMouseDown={handleMouseDown} className={`text-editor-custom-link ${positionOnLink ? "active" : ""}`}>
                <InsertLinkIcon/>
            </div>
        )
    }

    const [state, setState] = useState(() => {
        const decorator = new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: CustomLinkWrapper,
            },
        ])

        let editor = EditorState.createEmpty(decorator)
        if (value) {
            let convertedToHTML = decodeURIComponent(value)
            const blocksFromHtml = convertFromHTML(convertedToHTML)
            const { contentBlocks, entityMap } = blocksFromHtml
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            )
            editor = EditorState.createWithContent(contentState, decorator)
        }

        return {
            editorState: editor,
            showURLInput: false,
            urlValue: "",
        }
    })

    const onURLChange = (e) => setState(prevState => ({
        ...prevState,
        showURLInput: true,
        urlValue: e.target.value,
    }))

    const richTextEditorTheme = createTheme({
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                    fontSize: "small"
                },
                styleOverrides: {
                    root: {
                        border: "#999999 !important",
                        borderLeft: "1px solid #999999 !important",
                        borderRadius: "inherit !important",
                        color: "#999999 !important",
                        padding: "0.188rem 0.5rem !important",
                        height: "fit-content",

                        ":active": {color:"#F57F17 !important"},
                        ":hover": {color:"#5F6C72 !important", backgroundColor: "transparent !important"},
                    },
                },
            },
            MuiIconButton: {
                defaultProps: {
                    fontSize: "small"
                },
                styleOverrides: {
                    root: {
                        fontSize: "1.125rem !important",
                        ":first-child":{

                            ":before": {
                                content: "\"H\"",
                                fontSize: "1.125rem",
                                fontWeight: "600",
                                lineHeight: "normal"
                            },
                            svg:{
                                display: "None"
                            }
                        }
                    },
                    colorPrimary: {
                        color: "#F57F17 !important",
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        width: "1.25rem",
                        height: "1.25rem"
                    }
                }
            },
            MUIRichTextEditor: {
                styleOverrides: {
                    root: {
                        overflow: "auto",
                        minHeight: "9.375rem",
                        "& pre": {
                            color: "#212121",
                        },
                    },
                    toolbar: {
                        display: `${showToolbar ? "flex": "none"}`,
                        marginBottom: "0.625rem",
                        position: "absolute",
                        marginTop: "-3.125rem",
                    },
                    container: {
                        marginTop: "0",
                    },
                    editor: {
                        minHeight: "6.25rem",
                        height: "auto",
                        maxHeight: `${isMobile ? "18.75rem" : "25rem"}`,
                        marginTop: `${showToolbar ? "3.125rem": "0"}`,
                        overflow: "auto",
                        paddingRight: "3.125rem !important",
                    },
                    editorContainer: {
                        margin: "0",
                        padding: "0",
                        fontFamily: "Gilroy",
                        fontSize: "0.875rem",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        color: "#5F6C72",
                        minHeight: "6.25rem",
                        height: "auto",

                        "& h2": {
                            fontSize: "1.125rem",
                            fontWeight: "600",
                        },
                    },
                    placeHolder: {
                        width: `${isMobile ? "80%" : "90%"}`,
                        position: "absolute",
                        margin: "0",
                        padding: "0",
                    },
                    anchorLink: {
                        color: "#FFEB3B",
                        textDecoration: "underline",
                    },
                }
            },
        },
    })

    const getLinkKey = (editor) => {
        const contentState = editor.getCurrentContent()
        const startKey = editor.getSelection().getStartKey()
        const startOffset = editor.getSelection().getStartOffset()
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
        return blockWithLinkAtBeginning.getEntityAt(startOffset)
    }

    const promptForLink = (e) =>{
        e.preventDefault()
        const {editorState} = state
        const selection = editorState.getSelection()
        if (!selection.isCollapsed()) {
            setIsOpen(true)
            const linkKey = getLinkKey(editorState)
            let url = ""
            if (linkKey) {
                const linkInstance = editorState.getCurrentContent().getEntity(linkKey)
                url = linkInstance.getData().url
            }
            setState(prevState => ({
                ...prevState,
                showURLInput: true,
                urlValue: url,
            }))
        }
    }

    const confirmLink = (e) => {
        e.preventDefault()
        const {editorState, urlValue} = state
        try {
            let givenURL = new URL(urlValue)
            const contentState = editorState.getCurrentContent()

            const contentStateWithEntity = contentState.createEntity(
                "LINK",
                "MUTABLE",
                {url: givenURL}
            )
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

            let nextEditorState = EditorState.set(editorState,
                { currentContent: contentStateWithEntity }
            )

            nextEditorState = RichUtils.toggleLink( nextEditorState,
                nextEditorState.getSelection(), entityKey
            )
            setState({
                editorState: nextEditorState,
                showURLInput: false,
                urlValue: "",
            })
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                showURLInput: false,
                urlValue: "",
            }))
        }
        closePopover()
    }

    const onChange = (editorState) => {
        setPositionOnLink(getLinkKey(editorState) ? true : false)

        const contentState = editorState.getCurrentContent()
        let htmlContent = stateToHTML(contentState)
        const plainText = htmlContent.replace(/<[^>]*>|&nbsp;/gm, "")

        htmlContent = transformUrlsToLinks(htmlContent)
        setValue(plainText.length === 0 ? "" : htmlContent)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (popover.current && !popover.current.contains(event.target)) {
                closePopover()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [closePopover])

    const handlerEnterKey = (event) => {
        if(event.key === "Enter") {
            confirmLink(event)
        }
    }

    const LinkPopup = () => {
        const inputRef = useRef(null)
        useEffect(() => {
            if (isOpen) {
                inputRef.current.focus()
            }
        }, [isOpen])

        return (
            isOpen ?
                <div className="text-editor-popup-overlay">
                    <div className="text-editor-popover" ref={popover}>
                        <input
                            ref={inputRef}
                            onChange={onURLChange}
                            type="text"
                            placeholder="Type URL then press enter"
                            value={state.urlValue}
                            onSubmit={confirmLink}
                            onKeyDown={(e) => handlerEnterKey(e)}
                        />
                        <div className="text-editor-popover-confirm" onMouseDown={confirmLink}>
                            <CheckIcon/>
                        </div>
                    </div>
                </div> : <></>
        )
    }

    const transformUrlsToLinks = (text) => {
        const urlRegex = /(?<!<a.*?href=["'])(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])(?!["'].*?>)/ig
        return text.replace(urlRegex, (url) => {
            const trailingHtmlTagMatch = url.match(/<\/[a-z]+>$/i)
            let cleanUrl = url
            let trailingHtmlTag = ""

            if (trailingHtmlTagMatch) {
                cleanUrl = url.slice(0, trailingHtmlTagMatch.index)
                trailingHtmlTag = url.slice(trailingHtmlTagMatch.index)
            }

            const hyperlink = cleanUrl.startsWith("http") ? cleanUrl : `http://${cleanUrl}`
            return `<a href="${hyperlink}" target="_blank">${cleanUrl}</a>${trailingHtmlTag}`
        })
    }



    return (
        <div className="text-editor">
            {title ? <label className="text-editor-label t-s3">{title}</label> : <></>}
            <div className="text-editor-wrapper">
                <ThemeProvider theme={richTextEditorTheme}>
                    <div className={`text-editor-wrapper-switcher ${showToolbar ? "open" : ""}`}>
                        <span className="t-s3" onClick={() => setShowToolbar(!showToolbar)}>Aa</span>
                    </div>
                    <LinkPopup/>
                    <MUIRichTextEditor
                        toolbar={true}
                        label={label}
                        controls={["title", "fontSize", "bold", "underline", "numberList", "bulletList"]}
                        customControls={[
                            {
                                name: "custom-link",
                                component: CustomLinkComponent,
                                type: "block",
                                blockWrapper: <CustomLinkWrapper/>
                            }
                        ]}
                        inlineToolbar={false}
                        onChange={(e) => onChange(e)}
                        defaultValue={JSON.stringify(convertToRaw(state.editorState.getCurrentContent()))}
                    />
                </ThemeProvider>
            </div>
        </div>
    )
}

export default RichTextEditor
