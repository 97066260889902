import {getNewcoinsTransactions} from "../api"

import {
    GET_USER_NEWCOINS_TRANSACTIONS_SUCCESS,
    GET_USER_NEWCOINS_TRANSACTIONS_FAILURE,
    GET_USER_NEWCOINS_TRANSACTIONS_REQUEST,
    GET_NEXT_PAGE_USER_NEWCOINS_TRANSACTIONS_REQUEST
} from "../const"


const request = () => ({type: GET_USER_NEWCOINS_TRANSACTIONS_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_USER_NEWCOINS_TRANSACTIONS_REQUEST})

const success = (newcoinsTransactions) => ({
    type: GET_USER_NEWCOINS_TRANSACTIONS_SUCCESS,
    payload: {newcoinsTransactions},
})

const failure = () => ({type: GET_USER_NEWCOINS_TRANSACTIONS_FAILURE})


export const receiveUserNewcoinsTransactions = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveUserNewcoinsTransactionsData} = await getNewcoinsTransactions(params)
        dispatch(success(receiveUserNewcoinsTransactionsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageUserNewcoinsTransactions = (params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveUserNewcoinsTransactionsData} = await getNewcoinsTransactions(params)
        dispatch(success(receiveUserNewcoinsTransactionsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
