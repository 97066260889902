import "../index.scss"

import React, {useCallback, useMemo, useState} from "react"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import DateTimeColumn from "./DateTimeColumn"
import DateColumn from "./DateColumn"
import EllipsisText from "./EllipsisText"
import AccordionContainer from "./AccordionContainer"
import IconButton from "@mui/material/IconButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const getBodyClass = ({index, column, expandable}) => {
    const classes = []

    if (index === 0 && !expandable) {
        classes.push("ns-table-first-column")
    }

    if (column.bodyClassName) {
        classes.push(column.bodyClassName)
    }

    return classes.join(" ")
}


const getRowClass = ({selectable, selected, variant}) => {
    const classes = ["custom-scrollable-table-row", "ns-table-row"]
    if (selectable) {
        classes.push("ns-table-row-selectable")
    }

    if (selected) {
        classes.push("ns-table-row-selected")
    }

    if (variant === "small") {
        classes.push("ns-table-row-small")
    }

    return classes.join(" ")
}

const CellComponent = ({item, column, setUserMessage}) => {
    const value = column.get ? column.get(item) : item[column.field]

    if (column.type === "datetime") {
        return <DateTimeColumn date={value}/>
    } else if (column.type === "date") {
        return <DateColumn date={value}/>
    } else if (column.type === "copy") {
        return <EllipsisText value={value} copy={true} setUserMessage={setUserMessage}/>
    } else if (column.type === "custom") {
        return value || null
    }

    return <EllipsisText value={value}/>
}

const Cell = ({item, column, setUserMessage, className}) => {
    return (
        <div className="ns-table-cell">
            <div className={`ns-table-cell-content ${className}`}>
                <CellComponent item={item} column={column} setUserMessage={setUserMessage}/>
            </div>

        </div>
    )
}

const TableBody = ({items, loading, AccordionComponent, onRowClick, columns, setUserMessage, variant}) => {

    const [selectedRows, setSelectedRows] = useState([])

    const keyField = useMemo(() => columns.find((column) => column.isKey)?.field, [columns])

    if (!keyField) {
        console.error("Key field is missing")
    }

    const handleRowClick = useCallback((row) => {
        if (onRowClick) {
            onRowClick(row)
        } else if (AccordionComponent) {
            if (selectedRows.includes(row[keyField])) {
                setSelectedRows((prev) => prev.filter((v) => v !== row[keyField]))
            } else {
                setSelectedRows((prev) => [...prev, row[keyField]])
            }
        } else {
            return null
        }
    }, [onRowClick, AccordionComponent, selectedRows, setSelectedRows, keyField])


    const selectable = AccordionComponent || onRowClick
    const expandable = !!AccordionComponent

    if (items.length === 0 && !loading) {
        return <EmptyResult/>
    }

    return (
        <tbody>
            {items.map((item) => {

                const selected = selectedRows.includes(item[keyField])
                return (
                    <>
                        <tr
                            key={item[keyField]}
                            className={getRowClass({selectable, selected, variant})}
                            onClick={() => handleRowClick(item)}
                        >
                            {expandable && !selected && <td className="ns-table-body-expand"><IconButton disableRipple><ExpandMoreIcon fontSize="small"/></IconButton></td>}
                            {expandable && selected && <td className="ns-table-body-expand"><IconButton disableRipple><ExpandLessIcon fontSize="small"/></IconButton></td>}
                            {columns.filter((column) => !column.hidden).map((column, i) => (
                                <td key={column.field}>
                                    <Cell column={column} item={item} setUserMessage={setUserMessage}
                                        className={getBodyClass({index: i, column, expandable})}/>
                                </td>
                            ))}
                        </tr>

                        {selected && (
                            <AccordionContainer columns={columns}>
                                <AccordionComponent row={item} setUserMessage={setUserMessage}/>
                            </AccordionContainer>
                        )}

                    </>
                )
            })}
        </tbody>
    )
}

export default TableBody