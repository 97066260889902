import React from "react"
import {useSelector} from "react-redux"

import {isAdmin} from "../../utils/permission.utils"


const Protected = ({children, permissions, accessForAdmin}) => {
    const {hasPermission} = useSelector((state) => state.profile)

    const isAllowedComponent = accessForAdmin && isAdmin()
        ? true
        : permissions && permissions.some((permission) => hasPermission(permission))

    return (
        isAllowedComponent ? children : <></>
    )
}

export default Protected
