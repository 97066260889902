import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const clientsApi = createApi({
    reducerPath: "clientsApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["Clients"],
    endpoints: (builder) => ({
        getClients: builder.query({
            query: (params) => ({
                path: "clients/",
                params,
            }),
            providesTags: ["Clients"],
        }),
        createClient: builder.mutation({
            query: (data) => ({path: "clients/", method: "POST", data}),
            invalidatesTags: ["Clients"]
        }),
        updateClient: builder.mutation({
            query: ({id, data}) => ({path: `clients/${id}`, method: "PUT", data}),
            invalidatesTags: ["Clients"]
        }),
        deleteClient: builder.mutation({
            query: (id) => ({path: `clients/${id}`, method: "DELETE"}),
            invalidatesTags: ["Clients"]
        }),

    })
})

export const {
    useGetClientsQuery,
    useGetClientsMutation,
    useCreateClientMutation,
    useUpdateClientMutation,
    useDeleteClientMutation,
} = clientsApi
