import "../DDList/index.scss"
import "./index.scss"

import React, {useEffect, useRef, useState} from "react"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import RemoveIcon from "@mui/icons-material/Remove"
import InfoIcon from "@mui/icons-material/ErrorOutlineRounded"

import DeleteProjectWarningPopup from "../../pages/SalaryHistory/components/DeleteProjectWarningPopup"
import Popup from "reactjs-popup"
import useProjects from "../../hooks/useProjects"


const MultipleDDList = ({
    listOptions,
    onChange,
    textLabel,
    newItemLabel,
    defaultValues,
    withInfoIcon,
    creatable,
    onCreateOption,
    onUpdate,
    ...props
}) => {
    const { projects: projectOptions } = useProjects({})

    const [cnt, setCnt] = useState(defaultValues.length || 1)
    const [listItems, setListItems] = useState(defaultValues.length ? [...Array(defaultValues.length).keys()] : [1])
    const [deletionCandidate, setDeletionCandidate] = useState(null)
    const listRefs = useRef([])
    const infoCardRefs = useRef([])

    const [result, setResult] = useState(
        [...Array(defaultValues?.length ?? 1).keys()].reduce((accumulator, pos) => ({
            ...accumulator,
            [pos]: {
                value: null,
                label: null,
                selected: false,
            }
        }), {})
    )

    useEffect(() => {
        onChange(
            Object.keys(result)
                .map(pos => result[pos])
                .filter(x => x.selected)
                .map(x => x.value)
        )
    }, [result])

    const updateOptionByPosition = (newOption, pos) => {
        setResult(state => ({
            ...state,
            [pos]: {
                selected: true,
                value: newOption.value,
                label: newOption.label,
            }
        }))
    }

    const getProjectById = (projectId) => {
        const project = projectOptions?.find(project => project.id === projectId)
        return {value: project?.id, label: project?.name}
    }

    const handleDeleteProject = (pos) => {
        setListItems(state => {
            const element = listRefs.current[pos]
            if (element && element.current) {
                element.current.classList.add("toolkit-multiple-dd-list-hide-block")
            }
            state.includes(pos) && state.splice(state.indexOf(pos), 1)
            return state
        })
        setResult(state => ({
            ...state,
            [pos]: {
                value: null,
                label: null,
                selected: false,
            }
        }))
    }

    useEffect(() => {
        if (defaultValues.length) {
            defaultValues.map((newOption, i) => {
                updateOptionByPosition(newOption, i)
            })
        }
    }, [])

    const InputIcon = ({pos}) => {
        return (
            <>
                <div
                    className="toolkit-dd-list-select__indicators-wrapper toolkit-multiple-dd-list-content-button"
                    onClick={() => result[pos]?.selected ? setDeletionCandidate(pos) : handleDeleteProject(pos)}
                >
                    <RemoveIcon
                        className="toolkit-multiple-dd-list-content-img"
                    />
                </div>
                {withInfoIcon && pos in result && result[pos].selected &&
                <>
                    <div className="toolkit-multiple-dd-list-content-info-img">
                        <InfoIcon
                            className="icon-info"
                            onMouseEnter={() => {
                                const element = infoCardRefs.current[pos]
                                if (element && element.current) {
                                    element.current.classList.add("toolkit-multiple-dd-list-show")
                                }
                            }}
                            onMouseLeave={() => {
                                const element = infoCardRefs.current[pos]
                                if (element && element.current) {
                                    element.current.classList.remove("toolkit-multiple-dd-list-show")
                                }
                            }}
                        />
                    </div>
                    <div ref={infoCardRefs.current[pos]} className="toolkit-multiple-dd-list-content-info-wrapper" id={`toolkit_dd_list_info_card_${pos}`}>
                        <div className="toolkit-multiple-dd-list-content-info-card">
                            <span className="toolkit-multiple-dd-list-content-info-card-title t-b3">Assigned to Clients:</span>
                            <span className="toolkit-multiple-dd-list-content-info-card-text t-b4">Newsoft, TryNow</span>
                        </div>
                        <div className="toolkit-multiple-dd-list-content-info-card-triangle"></div>
                    </div>
                </>
                }
                <Popup
                    open={deletionCandidate !== null}
                    closeOnDocumentClick={false}
                    onClose={() => setDeletionCandidate(null)}
                    overlayStyle={{background: "rgba(0, 0, 0, 0.25)"}}
                    modal
                >
                    {close => (
                        <DeleteProjectWarningPopup
                            close={close}
                            project={result[deletionCandidate]?.label}
                            username={props.username}
                            handleDeleteProject={() => {handleDeleteProject(deletionCandidate)
                                setDeletionCandidate(null)}}
                        />
                    )}
                </Popup>
            </>
        )
    }

    return (
        <div className="toolkit-dd-list-wrapper toolkit-multiple-dd-list">
            {textLabel ? <label className="toolkit-dd-list-label t-s3">{textLabel}</label> : <></>}
            <div className="toolkit-multiple-dd-list-content">
                {listItems.map(pos => {
                    if (!listRefs.current[pos]) {
                        listRefs.current[pos] = React.createRef()
                    }
                    if (!infoCardRefs.current[pos]) {
                        infoCardRefs.current[pos] = React.createRef()
                    }
                    const selectProps = {
                        options: listOptions?.length ? listOptions.map(group => ({
                            label: group.label,
                            options: group.options?.length ? group.options.map(option => ({
                                ...option,
                                disabled: Object.values(result).map(x => x?.value).includes(option.value)
                            })) : []
                        })) : [
                            {label: "assigned", options: []},
                            {label: "unassigned", options: []},
                        ],
                        id: `toolkit-dd-list_${pos}`,
                        defaultValue: pos < defaultValues.length && defaultValues[pos],
                        classNamePrefix: "t-s4 toolkit-dd-list-select",
                        components: {
                            DropdownIndicator: () => null,
                        },
                        value: getProjectById(result[pos]?.value),
                        onChange: (newOption) => {
                            onUpdate(newOption)
                            updateOptionByPosition(newOption, pos)
                        },
                        onCreateOption: (newOptionName) => {
                            onCreateOption(newOptionName)
                                .then((newOption) => {
                                    updateOptionByPosition(newOption, pos)
                                })
                        },
                        isOptionDisabled: (option) => option.disabled,
                        ...props,
                    }

                    return (
                        <div ref={listRefs.current[pos]} className="toolkit-multiple-dd-list-button" key={`toolkit-dd-list_${pos}`}>
                            {!creatable
                                ? <Select {...selectProps}/>
                                : <CreatableSelect {...selectProps}/>
                            }
                            <div className="toolkit-multiple-dd-list-button-wrapper clickable">
                                <div className="toolkit-multiple-dd-list-button-inner">
                                    <InputIcon pos={pos}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="toolkit-multiple-dd-list-new-item">
                <span
                    className="t-b4 clickable"
                    onClick={() => {
                        setListItems(state => [...state, cnt + 1])
                        setCnt(cnt + 1)
                    }}
                >{newItemLabel}</span>
            </div>
        </div>
    )
}

export default MultipleDDList
