import React, {useEffect, useMemo, useState, useCallback} from "react"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SingleDatePicker from "../../../toolkits/Calendar/SingleDatePicker"
import CancelIcon from "@mui/icons-material/Cancel"
import {firstDayOfYear, formatDate, lastDayOfYear, isWeekend} from "../../../utils/datetime.utils"

const CalendarEventItem = ({index, selectedYear, event, setEvents, handleRemoveEvent, setDisableSave, setIsDatePickerOpen, refreshData, setRefreshData}) => {
    const [isHovered, setIsHovered] = useState(false)
    const [date, setDate] = useState(event.date ? new Date(event.date) : null)
    const [tempDate, setTempDate] = useState(event.date ? new Date(event.date) : null)

    const tomorrow = useMemo(() => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), [])
    const firstDayForSelectedYear = useMemo(() =>
        tomorrow.getFullYear() === selectedYear ? tomorrow : firstDayOfYear(selectedYear), [selectedYear]
    )
    const defaultSelectedDay = useMemo(() =>
        tomorrow.getFullYear() === selectedYear ? new Date() : firstDayOfYear(selectedYear), [selectedYear]
    )
    const lastDayOfSelectedYear = useMemo(() => lastDayOfYear(selectedYear), [selectedYear])
    const isPastEvent = useMemo(() => new Date() > new Date(event.date), [event.date])

    const validateEventDateInput = useCallback((value) => {

        if (typeof value !== "string") {
            return false
        }
        const [day, month] = value.split(".").map(Number)

        if (!day || !month || day > 31 || month > 12) {
            return false
        }

        const parsedDate = new Date(selectedYear, month - 1, day)
        const isValidRange = parsedDate >= firstDayForSelectedYear && parsedDate <= lastDayOfSelectedYear
        const isNotWeekend = !isWeekend(parsedDate)

        return isValidRange && isNotWeekend
    }, [firstDayForSelectedYear, lastDayOfSelectedYear, selectedYear])

    const onDateChange = (value) => {
        setDate(value)
        const isValid = validateEventDateInput(formatDate(value, "DD.MM"))
        if (isValid) {
            setDisableSave(false)
            setTempDate(formatDate(value))
            setIsHovered(false)
            setEvents((prevEvents) =>
                prevEvents.map((event, i) => ({
                    ...event,
                    date: i === index ? formatDate(value) : event.date
                }))
            )
        } else {
            setDisableSave(true)
        }
    }

    const handleChangeEventName = (e) => {
        setDisableSave(false)
        setEvents((prevEvents) =>
            prevEvents.map((event, i) => ({
                ...event,
                name: i === index ? e.target.value : event.name,
            }))
        )
    }

    const handleDatePickerOpen = () => {
        if (!event.date) {
            setTempDate(defaultSelectedDay)
        }
        setIsDatePickerOpen(true)
    }

    useEffect(() => {
        if (refreshData) {
            setDate(event.date ? new Date(event.date) : null)
            setRefreshData(false)
        }
    }, [refreshData])

    return (
        <div
            className="events-side-panel-content-event"
            key={event.id}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <SingleDatePicker
                value={date ? new Date(date) : tempDate}
                defaultValue={tempDate}
                className="events-side-panel-content-event-date-picker"
                onChange={onDateChange}
                years={false}
                calendarPosition="bottom-left"
                minDate={firstDayForSelectedYear}
                maxDate={lastDayOfSelectedYear}
                disableDays={true}
                disabledInput={isPastEvent}
                disabled={isPastEvent}
                onOpen={handleDatePickerOpen}
                selectedYear={selectedYear}
                validateDateInput={isPastEvent ? null : validateEventDateInput}
                onClose={() => setIsDatePickerOpen(false)}
                portalId="root-portal"
            />
            <TextInput
                placeholder="Name of the event"
                value={event.name}
                onChange={handleChangeEventName}
                disabled={isPastEvent}
            />
            {isHovered && !isPastEvent &&
                <CancelIcon
                    onClick={() => handleRemoveEvent(index, event.id)}
                    className="events-side-panel-content-remove"
                />
            }
        </div>
    )
}

export default CalendarEventItem