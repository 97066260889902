export const GET_ME_REQUEST = "GET_ME_REQUEST"
export const GET_ME_SUCCESS = "GET_ME_SUCCESS"
export const GET_ME_FAILURE = "GET_ME_FAILURE"

export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST"
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE"
export const GET_TIME_TRACKER_CHART_REQUEST = "GET_TIME_TRACKER_CHART_REQUEST"
export const GET_TIME_TRACKER_CHART_SUCCESS = "GET_TIME_TRACKER_CHART_SUCCESS"
export const GET_TIME_TRACKER_CHART_FAILURE = "GET_TIME_TRACKER_CHART_FAILURE"

export const GET_USER_CONFIGS_REQUEST = "GET_USER_CONFIGS_REQUEST"
export const GET_USER_CONFIGS_SUCCESS = "GET_USER_CONFIGS_SUCCESS"
export const GET_USER_CONFIGS_FAILURE = "GET_USER_CONFIGS_FAILURE"

export const GET_USER_PROJECTS = "GET_USER_PROJECTS"

export const GET_USER_ALL_PROJECTS = "GET_USER_ALL_PROJECTS"

export const GENERATE_RANDOM_SMILE = "GENERATE_RANDOM_SMILE"

export const SET_ONBOARDING_ANIMATION = "SET_ONBOARDING_ANIMATION"



