import UserImage from "../../../assets/images/user_photo.png"

import React from "react"
import {useSelector} from "react-redux"
import Popup from "reactjs-popup"

import Protected from "../../../components/Protected/Protected"

import DeletePerformanceReviewPopup from "./DeletePerformanceReviewPopup"
import SubmitPerformanceReviewPopup from "./SubmitPerformanceReviewPopup"
import EditPerformanceReviewPopup from "./EditPerformanceReviewPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"

import {formatDate, isDateInPast} from "../../../utils/datetime.utils"
import {capitalizeFirstLetter, isReviewComing} from "../../../utils/other.utils"

import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {ProfileRoute} from "../../../routes"
import {PERMISSIONS} from "../../../constants/permissions"
import {DD_MM_YYYY} from "../../../constants/other"
import PerformanceReviewInfoPopup from "./PerformanceReviewInfoPopup"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"


const PerformanceReviewsTable = ({
    performanceReviews,
    refreshTable,
    setUserMessage,
    empty,
}) => {
    const history = useCustomHistory()
    
    const auth = useSelector((state) => state.auth)
    
    const {hasPermission} = useSelector((state) => state.profile)

    const profileClick = (id) => id === auth.user.uid ? history.push(ProfileRoute.path) : history.push(`/profile/${id}`)

    const {upcomingPerformanceReview} = useSelector((state) => state.settings)

    const performanceReviewsList = performanceReviews.map((performanceReview, i) => {
        const isReviewInPast = !performanceReview.submitted && isDateInPast(performanceReview.date)
        const isReviewUpcoming = !performanceReview.submitted && isReviewComing(performanceReview.date, upcomingPerformanceReview)

        const popupParams = {
            performanceReview: performanceReview,
            refreshTable: refreshTable,
            setUserMessage: setUserMessage,
        }

        const handleReviewContent = (review) => {
            let content = review.improvements
            if (review.submitted && review.comment) {
                content = review.comment.concat("&nbsp", review.improvements)
            }
            
            var wrapper = document.createElement("div")
            wrapper.innerHTML = content
        
            var htmlContent = wrapper.innerHTML
            var replacedContent = htmlContent.replace(/<a\b[^>]*>(.*?)<\/a>/gi, "<p>$1</p>")
            wrapper.innerHTML = replacedContent
        
            var tags = wrapper.getElementsByTagName("*")
            for (var i = 0; i < tags.length; i++) {
                if (tags[i].tagName.toLowerCase() === "li") {
                    var bullet
                    if (tags[i].parentNode.tagName.toLowerCase() === "ol") {
                        var olChildren = tags[i].parentNode.getElementsByTagName("li")
                        var index = Array.prototype.indexOf.call(olChildren, tags[i])
                        bullet = document.createTextNode((index + 1) + ". ")
                    } else if (tags[i].parentNode.tagName.toLowerCase() === "ul") {
                        bullet = document.createTextNode("• ")
                    }
                    tags[i].parentNode.insertBefore(bullet, tags[i])
                }
                tags[i].classList.add("inner")
            }
        
            return wrapper.innerHTML
        }
        
        const tableActions = (
            <Protected permissions={[PERMISSIONS.WRITE_PERFORMANCE_REVIEW]}>
                <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                    {close => (
                        <EditPerformanceReviewPopup
                            {...popupParams}
                            close={close}
                        />
                    )}
                </Popup>
                <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                    {close => (
                        <DeletePerformanceReviewPopup 
                            {...popupParams}
                            close={close}
                        />
                    )}
                </Popup>
                <Popup trigger={<span className="custom-scrollable-table-link">submit</span>} modal>
                    {close => (
                        <SubmitPerformanceReviewPopup
                            {...popupParams}
                            close={close}
                        />
                    )}
                </Popup>
            </Protected>
        )

        return (
            <tr key={`${i} ${performanceReview.id}`}
                className={`custom-scrollable-table-row-user performance-reviews-content-table-row${
                    isReviewInPast ? "-red" : isReviewUpcoming ? "-yellow" : ""}`}>
                <Protected permissions={[PERMISSIONS.READ_PERFORMANCE_REVIEW]}>
                    <td>
                        <div className="custom-scrollable-table-employee">
                            <span className="custom-scrollable-table-employee-image clickable" onClick={() => profileClick(performanceReview.user.id)}>
                                <img src={performanceReview.user?.image_url ? performanceReview.user.image_url : UserImage}></img>
                            </span>
                            <span className="custom-scrollable-table-employee-name clickable" onClick={() => profileClick(performanceReview.user.id)}>
                                {`${performanceReview.user?.first_name} ${performanceReview.user?.last_name}`}
                            </span>
                        </div>
                    </td>
                </Protected>
                <td className={!hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW) ? "performance-reviews-content-table-first-column" : ""}>
                    {formatDate(performanceReview.date, DD_MM_YYYY)}
                </td>
                <td className="multi-line-cell">
                    <Popup trigger={<div className="cell-limited-text html-collapsed clickable t-b2" dangerouslySetInnerHTML={{ __html: handleReviewContent(performanceReview) }} />}
                        modal>
                        {close => (
                            <PerformanceReviewInfoPopup
                                performanceReview={performanceReview}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    {performanceReview.submitted ?
                        <span className="submitted-performance-review-span">
                            <p className="submitted-performance-review-span-status t-h3">{performanceReview.submitted && "SUBMITTED"}</p>
                            {performanceReview?.submitted_at && performanceReview?.submitted_by_user && 
                            <p className="submitted-performance-review-span-info t-b4">
                                &nbsp;&nbsp;({formatDate(performanceReview?.submitted_at, DD_MM_YYYY)},
                                &nbsp;{capitalizeFirstLetter(performanceReview?.submitted_by_user.last_name)}
                                &nbsp;{capitalizeFirstLetter(performanceReview?.submitted_by_user.first_name)[0]}.)
                            </p>}
                            
                        </span> : tableActions
                    }
                </td>
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="performance-reviews-content-table">
                <thead>
                    <tr>
                        <Protected permissions={[PERMISSIONS.READ_PERFORMANCE_REVIEW]}>
                            <th><span className="custom-scrollable-table-head-span-first">USER</span></th>
                        </Protected>
                        <th><span className={`custom-scrollable-table-head-span${
                            !hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW) ? "-first" : ""}`}>DATE</span></th>
                        <th><span className="custom-scrollable-table-head-span">REVIEW</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {performanceReviewsList}
                    {empty && <EmptyResult/>}
                </tbody>
            </table> 
        </CustomScrollableTable>
    )
}

export default PerformanceReviewsTable
