import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const publicHolidayCalendarsApi = createApi({
    reducerPath: "publicHolidayCalendars",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    endpoints: (builder) => ({
        getPublicHolidayCalendars: builder.query({
            query: (params) => ({
                path: "calendars/",
                params,
            }),
            providesTags: ["PublicHolidayCalendars"]
        }),
        createCalendar: builder.mutation({
            query: (data) => ({path: "calendars/", method: "POST", data}),
            invalidatesTags: ["PublicHolidayCalendars"]
        }),
        updateCalendar: builder.mutation({
            query: ({ id, data }) => ({path: `calendars/${id}`, method: "PUT", data}),
            invalidatesTags: ["PublicHolidayCalendars"]
        }),
        assignCalendarToUsers: builder.mutation({
            query: ({ calendar_id, data }) => ({
                path: `calendars/${calendar_id}/assign-to-users`,
                method: "POST",
                data,
            }),
        }),
        unassignCalendarFromUsers: builder.mutation({
            query: ({ calendar_id, data }) => ({
                path: `calendars/${calendar_id}/unassign-from-users`,
                method: "POST",
                data,
            }),
        }),
        duplicateCalendarEvents: builder.mutation({
            query: ({ calendar_id, params }) => ({
                path: `calendars/${calendar_id}/duplicate-events`,
                method: "POST",
                params,
            }),
            invalidatesTags: ["PublicHolidayCalendars"]
        }),
        deleteCalendar: builder.mutation({
            query: (id) => ({path: `calendars/${id}`, method: "DELETE"}),
            invalidatesTags: ["PublicHolidayCalendars"],
        }),
        markCalendarAsDefault: builder.mutation({
            query: (id) => ({path: `calendars/${id}/mark-as-default`, method: "PUT"}),
            invalidatesTags: ["PublicHolidayCalendars"],
        }),
        updateCalendarEvents: builder.mutation({
            query: ({ calendar_id, data }) => ({
                path: `calendars/${calendar_id}/events`,
                method: "PUT",
                data,
            }),
            invalidatesTags : ["PublicHolidayCalendars"]
        }),
    })
})

export const {
    useGetPublicHolidayCalendarsQuery,
    useCreateCalendarMutation,
    useUpdateCalendarMutation,
    useAssignCalendarToUsersMutation,
    useUnassignCalendarFromUsersMutation,
    useDuplicateCalendarEventsMutation,
    useUpdateCalendarEventsMutation,
    useDeleteCalendarMutation,
    useMarkCalendarAsDefaultMutation,
} = publicHolidayCalendarsApi
