import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const onboardingsApi = createApi({
    reducerPath: "onboardingsApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["Onboardings"],
    endpoints: (builder) => ({
        getOnboardings: builder.query({
            query: (params) => ({
                path: "onboardings/",
                params,
            }),
            providesTags: ["Onboardings"],
        }),
        getUserOnboardings: builder.query({
            query: (userId) => ({
                path: `onboardings/${userId}`,
            }),
            providesTags: ["Onboardings"],
        }),
        updateOnboarding: builder.mutation({
            query: ({id, data}) => ({path: `onboardings/${id}`, method: "PUT", data}),
            invalidatesTags: ["Onboardings"]
        }),

    })
})

export const {
    useGetOnboardingsQuery,
    useGetUserOnboardingsQuery,
    useUpdateOnboardingMutation,
} = onboardingsApi
