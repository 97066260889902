import UserImage from "../../../assets/images/user_photo.png"
import CancelIcon from "@mui/icons-material/Cancel"

import React, {useState} from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {formatDate} from "../../../utils/datetime.utils"

import {DD_MM_YYYY, ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {useBatchUnassignProjectMutation} from "../../../api/projects"


const UnassignProjectPopup = ({project, onSubmit, setUserMessage, close}) => {
    const [batchUnassignProject, { isLoading }] = useBatchUnassignProjectMutation()

    const [userIds, setUserIds] = useState([])
    const [projectTeam, setProjectTeam] = useState(project.team)

    const handleSubmit = () => {
        const data = {
            user_ids: userIds,
            project_id: project.id,
        }
        batchUnassignProject(data)
            .unwrap()
            .then(() => {
                onSubmit()
                close()
                setUserMessage({
                    message: `The project ${project.name} was successfully unassigned from users`,
                    code: SUCCESS_CODE
                })
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    const onSelectUser = (userId) => {
        setProjectTeam(
            projectTeam.filter((user) => user.id !== userId)
        )
        setUserIds([...userIds, userId])
    }

    const usersList = projectTeam.map(user =>
        <div className="projects-unassign-item" key={user.id}>
            <img className="projects-unassign-item-user-img" src={user.image_url || UserImage} alt="user image"/>
            <span className="t-s3">{user.first_name} {user.last_name}</span>
            <span className="t-s4 aligned-tabs">{formatDate(user.start_date, DD_MM_YYYY)}</span>
            <CancelIcon onClick={() => onSelectUser(user.id)}
                className="projects-unassign-item-close"/>
        </div>)

    return (
        <CustomPopup
            text_title={`Who you want to unassign from "${project.name}" ?`}
            isSubtitle={true}
            className="unassign-projects-popup"
        >
            <div className="projects-unassign-items">
                {usersList}
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit} disabled={userIds.length === 0}> SAVE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default UnassignProjectPopup
