import "./index.scss"

import React, {useEffect, useState} from "react"

import {LoginForm} from "./components/LoginForm"
import {ResetPasswordForm} from "./components/ResetPasswordForm"
import {ChangePasswordForm} from "./components/ChangePasswordForm"

import {getAuthorizedUserData} from "../../utils/localstorage.utils"
import {isAdmin} from "../../utils/permission.utils"
import {useCustomHistory} from "../../hooks/useCustomHistory"
import {DashboardRoute, ProfileRoute} from "../../routes"


export const Login = () => {
    const history = useCustomHistory()

    const isUserAuthorized = getAuthorizedUserData()

    const [showChangePassword, setShowChangePassword] = useState(false)

    useEffect(() => {
        if (isUserAuthorized) {
            history.push(isAdmin() ? DashboardRoute.path : ProfileRoute.path)
        }
    }, [])

    return (!showChangePassword
        ? <LoginForm setShowChangePassword={setShowChangePassword}/>
        : <ChangePasswordForm/>
    )
}


export const ResetPassword = () => (<ResetPasswordForm/>)
