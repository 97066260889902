import "./SearchForm.scss"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"


import React, {createRef, useCallback, useContext, useState} from "react"
import {useMediaQuery} from "react-responsive"

import {DESKTOP_WIDTH} from "../../constants/other"
import { ThemeContext } from "../../styles/theme-context"
import {debounce} from "lodash"
import {useTranslation} from "react-i18next"


const SearchForm = ({
    defaultValue,
    selectedValue,
    onClick,
    onChange,
    className,
    debounceMs,
}) => {
    const {t} = useTranslation("common")

    const theme = useContext(ThemeContext)
    const isDesktop = useMediaQuery({maxWidth: DESKTOP_WIDTH})
    const inputRef = createRef()

    const [value, setValue] = useState(defaultValue)

    const onResetField = (e) => {
        onValueChange("")
        onClick?.(e)
        inputRef.current.focus()
    }

    const debounceChange = useCallback(debounce((newValue) => {
        onChange(newValue)
    }, debounceMs || 0), [])

    const onValueChange = (newValue) => {
        if (newValue === null || newValue === undefined) {
            return
        }
        setValue(newValue)
        debounceChange(newValue)
    }

    return (
        <form
            className={`search-input-form ${className ? className : " "}`}
            onSubmit={(e) => e.preventDefault()}
        >
            <input
                type="search"
                className={`search-input ${theme.searchClassName}`}
                ref={inputRef}
                placeholder={(value && !selectedValue) ? "" : t("Search")}
                value={(value && !selectedValue) ? value : ""}
                onChange={(e) => onValueChange(e.target.value)}
                autoFocus={!isDesktop}
            />
            {value || selectedValue
                ? <CloseIcon onClick={(e) => onResetField(e)} className="search-icon" />
                : <SearchIcon className="search-icon" alt="search"/>}
            {selectedValue &&  <span className="search-user-wrap t-s3">{selectedValue}</span>}
        </form>
    )
}

export default SearchForm
