import {useGetUserTimedTrackerChartQuery} from "../api/timeRecords"
import {useMemo} from "react"


const useTimeTrackerChart = ({ userId, params }, { skip } = {}) => {

    const {data, isLoading, isFetching, refetch } = useGetUserTimedTrackerChartQuery({userId, params}, { skip })

    const result = useMemo(() => ({
        chartData: data?.chart_data?.data || [],
        chartType: data?.chart_data?.type,
        workingDays: data?.working_days,
        publicHolidays: data?.public_holidays,
        hoursReport: data?.hours_report || {},
        isLoading,
        isFetching,
        loading: isLoading || isFetching,
        refetch,
    }), [data, isLoading, isFetching, refetch])

    return result
}

export default useTimeTrackerChart