import React from "react"
import "./index.scss"

// DEPRECATED: DO NOT USE
const FilterPanel = ({ title, options, selectedOption, setOptionFilter }) => {

    const handleOptionClick = (status) => {
        setOptionFilter(status)
    }

    return (
        <div className="filter-panel">
            <span className="filter-panel-span t-s4">{title}:</span>
            {Object.values(options).map((option) => (
                <button
                    key={option}
                    type="button"
                    onClick={() => handleOptionClick(option)}
                    className={`filter-panel-button ${selectedOption === option ? "filter-panel-button-selected t-s3" : "t-s4"}`}
                >
                    {option}
                </button>
            ))}
        </div>
    )
}

export default FilterPanel
