import React from "react"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import {useDispatch} from "react-redux"
import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"
import {SUCCESS_CODE, ERROR_CODE, PURCHASE_REQUEST_STATUS} from "../../../constants/other"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import Textarea from "../../../toolkits/Textarea/Textarea"
import {useUpdateOrderMutation} from "../../../api/shop"


const ApprovePurchaseRequestPopup = ({purchaseRequest, approve, refreshTable, setUserMessage, close}) => {
    const [updatePurchaseRequest, {isLoading}] = useUpdateOrderMutation()
    const fullName = `${purchaseRequest.user?.first_name} ${purchaseRequest.user?.last_name}`
    const [reason, setReason] = React.useState("")
    const dispatch = useDispatch()

    const handleSubmit = () => {
        const data = {
            status: approve ? PURCHASE_REQUEST_STATUS.APPROVED : PURCHASE_REQUEST_STATUS.DECLINED,
            reason: reason ? reason : null,
        }

        const promises = [
            updatePurchaseRequest({id: purchaseRequest.id, data}),
            dispatch(receiveNotifications()),
        ]

        Promise.all(promises)
            .then(() => {
                setUserMessage({message: `Purchase request was successfully ${approve ? "approved" : "declined"}!`, code: SUCCESS_CODE })
                if (refreshTable) {
                    refreshTable()
                }
                close()
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to ${approve ? "approve" : "decline"} ${fullName} purchase ${purchaseRequest.product_variant.product.name}?`}
            isSubtitle isAgreePopup
        >
            {!approve &&
                <div className="onboarding-requests-popup-content-reason">
                    <Textarea
                        text_label="Reason"
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                    />
                </div>}
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> {approve ? "APPROVE" : "DECLINE"} </SmallButton>
                </div>
            </div>

        </CustomPopup>
    )
}

export default ApprovePurchaseRequestPopup