import "./index.scss"

import React, {useState} from "react"

import CustomPopup from "../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import {NumberInput} from "../../toolkits/NumberInput/NumberInput"


export const NumberEditPopup = ({title, defaultValue, min, max, label, onSave, close}) => {
    const [value, setValue] = useState(defaultValue)

    return (
        <CustomPopup text_title={title}>
            <span className="number-edit-popup-label t-s3">{label}</span>
            <NumberInput 
                min={min}
                max={max}
                defaultValue={defaultValue}
                onChange={e => setValue(e.target.value)}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> Cancel </SmallButton>
                    <SmallButton type="submit" onClick={() => onSave(value)} disabled={value < min || value > max}> Save </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default NumberEditPopup
