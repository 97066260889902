import React from "react"


const AboutUsPopup = () => (
    <div className="t-b5">
        <div className="greetings-onboarding-content-text">
            <p>We are Newsoft, a team of professionals based in Lviv. As we aim to deliver a premium software development services, we are also eager to build a community of top-notch experts and like-minded people. At Newsoft, we value knowledge, experience, and willingness to get better. Our main focus is to ensure creating value and build a win-win cooperation for both our clients and employees.</p>
            <p>Having 30+ experienced developers, we provide services such as team augmentation for blended, dedicated, and managed teams. We are also proficient in IT strategy consulting, where we provide invaluable insights to enhance business operations, and deliver expert software architecture advisory services.</p>
            <p>We are committed to achieving excellence and creating a nurturing environment for our employees to ensure that our journey together paves the way for future advancements and collective growth.</p>
        </div>
    </div>
)

export default AboutUsPopup
