import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveOvertimeEarnInfo} from "../actions/OvertimeEarnAction"

import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"

import {hoursToString} from "../../../utils/datetime.utils"
import {roundEarn} from "../../../utils/other.utils"


const OvertimeInfoPopup = ({close, userId, dateRange, projects}) => {
    const dispatch = useDispatch()

    const {overtimeEarn, loading} = useSelector((state) => state.overtimeInfo)


    useEffect(() => {
        dispatch(receiveOvertimeEarnInfo(userId,  {...dateRange, ...projects}))
    }, [])

    return (
        <CustomPopup
            text_title="Overtime info"
            className="overtime-info-popup"
        >
            {loading ? <StyledCircularProgress/> : 
                <div className="overtime-info-popup-wrapper">
                    {Object.keys(overtimeEarn).map((clientName, i) => (
                        <div key={`overtime_info_box_${i}`} className="overtime-info-popup-box">
                            <span className="t-s1">{clientName}</span>

                            {overtimeEarn[clientName].map((record, j) =>
                                <div key={`amount_${j}`} className="overtime-info-popup-detail">
                                    <span className="overtime-info-popup-detail-earn t-s1">
                                        <SensitiveInfo value={
                                            `${roundEarn(record.amount)} ${record.currency.iso_code}`
                                        }/>
                                    </span>
                                    <span className="overtime-info-popup-detail-hours t-s3">
                                        {hoursToString(record.hours)}
                                    </span>
                                    <span className="overtime-info-popup-detail-rate t-s3">
                                        <SensitiveInfo value={
                                            <>
                                                <span className="overtime-info-popup-detail-number t-s3">
                                                    {`${record.rate} ${record.currency.iso_code}`}
                                                </span>
                                                <span className="overtime-info-popup-detail-salary-type t-b3">
                                                    {"per hour"}
                                                </span>
                                                <span className="overtime-info-popup-detail-coefficient t-b3">
                                                    {`x${record.coefficient}`}
                                                </span>
                                            </>
                                        } />
                                    </span>
                                </div>
                            )
                            }
                        </div>
                    ))}

                </div>
            }
            
            <ClosePopupIcon onClick={close}/>
        </CustomPopup>
    )
}

export default OvertimeInfoPopup
