import React, { useState } from "react"

const IntegerInput = ({ defaultValue, onChange, label, positiveOnly = false, ...rest }) => {
    const [value, setValue] = useState(defaultValue?.toString())

    const handleChange = (e) => {
        const inputValue = e.target.value

        if (!inputValue) {
            setValue("")
            onChange(undefined)
            return
        }

        if (inputValue === "-") {
            if (positiveOnly) {
                return
            }
            setValue("-")
            onChange(undefined)
            return
        }

        const isInteger = /^-?\d*$/.test(inputValue)

        if (isInteger) {
            let intValue = parseInt(inputValue, 10)

            if (positiveOnly && intValue < 0) {
                return
            }

            setValue(intValue?.toString() || "0")
            onChange(intValue || 0)
        }
    }

    return (
        <div>
            {label && <label className="toolkit-text-input-label t-s3">{label}</label>}
            <input
                className="toolkit-text-input t-s4"
                value={value}
                onChange={handleChange}
                {...rest}
            />
        </div>
    )
}

export default IntegerInput
