import React from "react"
import {Navigate} from "react-router-dom"
import {useSelector} from "react-redux"
import {ErrorRoute, LoginRoute} from "./index"

import {getAuthorizedUserData} from "../utils/localstorage.utils"
import {isTrue, toCamelCase} from "../utils/other.utils"


const ProtectedRoute = ({permissions, settingsRequired, children}) => {
    const {hasPermission} = useSelector(state => state.profile)
    const settings = useSelector(state => state.settings)
    const authorizedUser = getAuthorizedUserData()

    const permitted = !permissions || (permissions?.length === 0) || (permissions && permissions.some(perm => hasPermission(perm)))

    const settingsEnabled = !settingsRequired || settingsRequired.every(setting => isTrue(settings[toCamelCase(setting)]?.value))

    return (authorizedUser && permitted && settingsEnabled) ?
        children :
        authorizedUser ?
            settingsEnabled && !permitted ?
                <Navigate to={{pathname: ErrorRoute.path, state: {errorInfo: "Not allowed"}}} replace />
                : <Navigate to={{pathname: ErrorRoute.path, state: {errorInfo: "Access to this feature is closed"}}} replace />
            : <Navigate to={{pathname: LoginRoute.path}} replace />
}

export default ProtectedRoute
