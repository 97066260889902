import React, {useState, useRef, useEffect} from "react"
import {useDispatch} from "react-redux"

import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE, DD_MM_YYYY, MOBILE_WIDTH} from "../../../constants/other"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import { formatDate } from "../../../utils/datetime.utils"
import RichTextEditor from "../../../toolkits/RichTextEditor"
import { useMediaQuery } from "react-responsive"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { displayHTML } from "../../../utils/other.utils"
import {useUpdatePerformanceReviewMutation} from "../../../api/performanceReviews"


const SubmitPerformanceReviewPopup = ({performanceReview, refreshTable, setUserMessage, close}) => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})

    const [comment, setComment] = useState("")
    const [expandGoals, setExpandGoals] = useState(false)
    const [isContentTooLong, setIsContentTooLong] = useState(false)
    const goalsRef = useRef()
    const buttonBlock = useRef()

    const [updatePerformanceReview] = useUpdatePerformanceReviewMutation()

    const handleSubmit = () => {
        const data = {
            comment: comment || "",
            submitted: true
        }

        updatePerformanceReview({id: performanceReview.id, data})
            .unwrap()
            .then(() => {
                refreshTable()
                dispatch(receiveNotifications())
                close()
                setUserMessage({message: "Performance review was successfully submitted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    useEffect(() => {
        buttonBlock.current.scrollIntoView({ behavior: "smooth" })
    }, [comment])

    useEffect(() => {
        const checkContentLength = () => {
            const scrollHeight = goalsRef.current.scrollHeight
            const clientHeight = goalsRef.current.clientHeight
            setIsContentTooLong(scrollHeight > clientHeight)
        }
        goalsRef.current && checkContentLength()
    }, [])

    return (
        <>
            <FlexiblePopup noStyle={true}>
                <div className="modify-performance-review-popup">
                    <div className="modify-performance-review-popup-title">
                        <span className={`${isMobile ? "t-s1" : "t-h2"}`}> Submit Performance Review</span>
                        <div className="modify-performance-review-popup-title-date">
                            <span className="t-s2">{`(${performanceReview.created_at ? formatDate(performanceReview.created_at, DD_MM_YYYY).concat("-") : ""}${formatDate(performanceReview.date, DD_MM_YYYY)})`}</span>
                        </div>
                    </div>
                    <div className="modify-performance-review-popup-content">
                        {performanceReview.improvements && 
                    <div className="modify-performance-review-popup-goals">
                        <span className="modify-performance-review-popup-goals-title t-s1">Goals</span>
                        <div className={`modify-performance-review-popup-goals-content ${expandGoals ? "expanded" : ""} t-s4`} dangerouslySetInnerHTML={displayHTML(performanceReview.improvements)} ref={goalsRef}/>
                        {isContentTooLong &&
                            <KeyboardArrowDownIcon className={`arrow-icon ${expandGoals ? "up" : "down"}`} onClick={() => setExpandGoals(!expandGoals)}/>
                        }
                    </div> }
                        <RichTextEditor
                            title="Summary"
                            label="Write up the summary of the review here..."
                            setValue={setComment}
                        />
                        <div className="popup-bottom" ref={buttonBlock}>
                            <div className="popup-bottom-button-container">
                                <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                                <SmallButton type="submit" onClick={handleSubmit}> SUBMIT </SmallButton>
                            </div>
                        </div>
                    </div>
                </div>
            </FlexiblePopup>
        </>
    )
}

export default SubmitPerformanceReviewPopup
