import React from "react"


const SettingsCard = ({title, value, children, subtitle}) => {
    return (
        <div className="card settings-box-card">
            <div className="settings-box-card-content">
                <p className="settings-box-card-content-title t-h3"> {title} </p> 
                <p className={`settings-box-card-content${subtitle ? "-sub-value t-s3" : "-value t-h1"}`}> {value} </p>    
            </div>
            <div className="settings-box-card-button-container">
                {children}
            </div>
        </div>
    )
}

export default SettingsCard
