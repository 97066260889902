import {useGetUserTimeRecordsGroupedReportsQuery} from "../api/timeRecords"
import {useMemo} from "react"


const useTimeRecordsReport = ({ userId, params }, { skip } = {}) => {

    const {data, isLoading, isFetching, refetch } = useGetUserTimeRecordsGroupedReportsQuery({userId, params}, { skip })

    const result = useMemo(() => ({
        data,
        isLoading,
        isFetching,
        loading: isLoading || isFetching,
        refetch,
    }), [data, isLoading, isFetching, refetch])

    return result
}

export default useTimeRecordsReport