import React from "react"

import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../../constants/other"
import {useUnassignProjectMutation} from "../../../../api/projects"


const UnassignProjectPopup = ({
    userId,
    project,
    setUserMessage,
    close
}) => {
    const [unassignProject] = useUnassignProjectMutation()

    const handleUnassignProject = (e) => {
        e.target.disabled = true

        unassignProject({user_id: userId, project_id: project.id})
            .unwrap()
            .then(() => {
                close(false)
                setUserMessage({message: "The Project was successfully unassigned!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
                e.target.disabled = false
            })
    }

    return (
        <CustomPopup text_title={`Do you want to unassign "${project.name}" project?`} close={close} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={() => close(false)}>CANCEL</SmallButton>
                    <SmallButton onClick={handleUnassignProject}>SAVE</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default UnassignProjectPopup
