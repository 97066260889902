import React from "react"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import {CACHED_IMAGES, DESKTOP_WIDTH} from "../../../constants/other"
import BirthdayConfettiImg from "../../../assets/images/birthdayConfetti.svg"
import Newcoin from "../../../toolkits/Newcoin"
import {useMediaQuery} from "react-responsive"


const NewcoinsReceivedPopup = ({amount, reason, close, buttonText="THANK YOU"}) => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    return (
        <div className="greetings-newcoins">
            <img src={CACHED_IMAGES.LogoNS} className="greetings-newcoins-logo-ns"/>
            <span className="greetings-newcoins-title">
                +{amount} <Newcoin type={"neutral"}/>
            </span>
            <span className={`greetings-newcoins-content ${isDesktop ? "t-h2" : "t-s1"}`}>
                {"Congratulations! You get coins 🎁"}
            </span>
            <span className={`greetings-newcoins-content ${isDesktop ? "t-s1" : "t-s3"}`}>
                {reason}
            </span>
            <SmallButton onClick={close}>
                {buttonText}
            </SmallButton>
            <img src={BirthdayConfettiImg} className="greetings-newcoins-confetti" alt="Newcoins Confetti"/>
        </div>
    )
}

export default NewcoinsReceivedPopup
