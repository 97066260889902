import React from "react"

import CustomPopup from "../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../toolkits/SmallButton/SmallButton"


const AgreePopup = ({title, buttonText, onSave, close}) => {
    return (
        <CustomPopup isSubtitle={true} close={close} text_title={title}>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={() => onSave()}> {buttonText} </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default AgreePopup
