import axios from "axios"

import firebase from "./adapters/firebase"

import {LOCALSTORAGE_KEYS} from "./constants/other"

/**
 * @desc Wrapper for HTTP request.
 * Pass method
 * @param method {string} - HTTP method (GET, POST, PUT, PATCH etc.)
 * @return {function} - function for making the HTTP request
 */
export const httpMethod =
    (method) =>
        async (url, payload = {}, headers = {}, cancelToken = null) => {
            const {params = {}, data = {}} = payload

            const token = await firebase.auth.currentUser.getIdToken()

            headers = {
                ...headers,
                Authorization: `Bearer ${token}`
            }

            return axios({
                method,
                url,
                params,
                data,
                headers,
                cancelToken,
            })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem(LOCALSTORAGE_KEYS.SETTINGS)
                        setTimeout(() => {window.location.href = "/login"}, 1000)
                    }
                    else {
                        throw error
                    }
                })
        }

// Same but slightly different. Should get rid of httpMethod when replaced with rtk completely
export const httpMethodRtk =
    (method) =>
        async (url, payload = {}, headers = {}, cancelToken = null) => {
            const {params = {}, data = {}} = payload

            const token = await firebase.auth.currentUser.getIdToken()

            headers = {
                ...headers,
                Authorization: `Bearer ${token}`
            }

            try {
                const result = await axios({
                    method,
                    url,
                    params,
                    data,
                    headers,
                    cancelToken,
                })
                return result
            } catch(error) {
                if (!error.response) {
                    console.error(`Response is missing from the error object: ${JSON.stringify(error)}`)
                    return {
                        error: {
                            ...error,
                            message: "Unexpected error",
                        }}
                }

                if (error.response.status === 401) {
                    localStorage.removeItem(LOCALSTORAGE_KEYS.SETTINGS)
                    setTimeout(() => {window.location.href = "/login"}, 1000)
                }
                else {
                    return {
                        error: {
                            ...error,
                            message: error.response.data?.message || error.response.data?.detail,
                            errorCode: error.response.data?.error_code,
                        }}
                }
            }

        }


export const generateCancelToken = (cancelToken, setCancelToken) => {
    cancelToken &&
        cancelToken.cancel("Operation canceled due to new request.")

    const newCancelToken = axios.CancelToken.source()

    setCancelToken(newCancelToken)

    return newCancelToken.token
}

/**
 * @desc Object with methods for making the HTTP requests.
 * @const
 * @type {object}
 */
const http = {
    get: httpMethod("get"),
    post: httpMethod("post"),
    put: httpMethod("put"),
    patch: httpMethod("patch"),
    delete: httpMethod("delete"),
}

export default http
