import React, {useEffect} from "react"
import {blockBackground} from "../../utils/other.utils"

import "./index.scss"
const FlexiblePopup = ({noStyle=false, ...props}) => {

    useEffect(() => {
        // block background if popup active
        return blockBackground()
    }, [])

    return (
        <div className={`${noStyle ? "" : "flexible-popup"}`}>
            {props.children}
        </div>
    )
}

export default FlexiblePopup