import "../index.scss"

import React from "react"
import {useSelector} from "react-redux"
import {useMediaQuery} from "react-responsive"

import NotificationsTable from "./NotificationsTable"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {MOBILE_WIDTH, LIMIT_NOTIFICATIONS_FOR_BELL} from "../../../constants/other"


const UserNotificationsPopup = ({setNotificationsIds, close}) => {
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})

    const {
        userNotifications,
        unreadNotificationsCount
    } = useSelector((state) => state.userNotifications)

    return (
        <div className="notifications-popup">
            <CustomPopup close={close}>
                <div className="notifications-popup-header">
                    <span className={`notifications-popup-header-title ${isMobile ? "t-s1" : "t-h2"}`}>Notifications</span>
                    <div className="notifications-popup-header-circle">
                        <span className={isMobile ? "t-s3" : "t-s1"}>
                            {unreadNotificationsCount < LIMIT_NOTIFICATIONS_FOR_BELL ? unreadNotificationsCount : "9+"}
                        </span>
                    </div>
                </div>
                <div className="notifications-popup-content">
                    <NotificationsTable
                        data={userNotifications}
                        setNotificationsIds={setNotificationsIds}
                        isMobile={isMobile}
                    />
                </div>
            </CustomPopup>
        </div>
    )
}

export default UserNotificationsPopup
