import "../index.scss"

import React from "react"

const getHeaderClass = ({ column }) => {
    if (column.headerClassName) {
        return column.headerClassName
    }

    if (column.type === "date") {
        return "ns-table-column-date"

    }
}

const TableHeader = ({ columns, expandable }) => {
    return (
        <thead>
            <tr>
                {expandable && (<th key="expand_action" className="ns-table-column-expand-action"></th>)}
                {columns.filter((column) => !column.hidden).map((column) => (
                    <th key={column.field} className={getHeaderClass({ column })}>
                        <span className="custom-scrollable-table-head-span">{column.title}</span>
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default TableHeader