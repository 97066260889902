import React, {useState} from "react"
import DefaultProductImage from "../../../assets/images/user_photo.png"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import Newcoin from "../../../toolkits/Newcoin"
import {DESKTOP_WIDTH, MOBILE_WIDTH, PRODUCT_STATUS} from "../../../constants/other"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {useMediaQuery} from "react-responsive"
import {useTranslation} from "react-i18next"

const ShopProductCard = ({product}) => {
    const {t} = useTranslation("common")

    const [hovered, setHovered] = useState(false)
    const isAvailable = [PRODUCT_STATUS.IN_STOCK, PRODUCT_STATUS.PRE_ORDER].includes(product.status)
    const isOutOfStock = product.status === PRODUCT_STATUS.OUT_OF_STOCK
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})
    const mainImage = product.images.length > 0 && product.images.find((image) => image.is_main === true)
    const history = useCustomHistory()
    const isPublic = product.public

    return (
        <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={() => history.push(`/product/${product.id}`, {title: product.name})} className="shop-content-product-card">
            <div className="shop-content-product-card-overlay">
                {product.labels.length > 0 &&
                    <div className="shop-content-product-card-overlay-labels">
                        {product.labels.map((label, index) => (
                            <span key={index} style={{backgroundColor: label.color}} className="shop-content-product-card-overlay-labels-item t-s4">{label.name}</span>
                        ))}
                    </div>
                }
                {product.pre_order_delivery_time &&
                    <div className="shop-content-product-card-overlay-delivery-time t-s4">
                        <LocalShippingOutlinedIcon /> {product.pre_order_delivery_time}
                    </div>
                }
            </div>
            <img className={`shop-content-product-card-image ${isAvailable ? "" : "unavailable"}`} src={mainImage?.image_url || DefaultProductImage} alt={product.name}/>
            <div className="shop-content-product-card-info">
                <div className={`shop-content-product-card-info-name t-h3 ${isAvailable ? "available" : "unavailable"}`}>{product.name}</div>
                <div className="shop-content-product-card-info-bottom">
                    <span className={`shop-content-product-card-info-status t-s4 ${isAvailable ? "green" : "red"}`}>{t(product.status)}</span>
                    <span className={`t-b5 shop-content-product-card-info-price ${isAvailable ? "available" : "unavailable"}`}>{product.raw_price} <Newcoin type={isAvailable ? "active" : "empty"}/></span>
                </div>
            </div>
            {!isPublic && (isDesktop ? !hovered : true) && <span className={`${isMobile ? "t-h2" : ""} shop-content-product-card-unpublished`}>{t("Unpublished").toUpperCase()}</span>}
            {isDesktop ?
                <div
                    className={`shop-content-product-card-hover ${isOutOfStock && isPublic ? "out-of-stock" : ""} ${!isPublic ? "unpublished" : ""}`}
                >
                    <span
                        className={`shop-content-product-card-hover-text ${isOutOfStock && isPublic ? "out-of-stock" : ""}`}
                    >{isPublic ? t("Details").toUpperCase() : t("Unpublished").toUpperCase()} &rarr;</span>
                </div> :
                <div className={`shop-content-product-card-hover ${isOutOfStock && isPublic ? "out-of-stock" : ""} ${!isPublic ? "unpublished" : ""}`}></div>
            }
        </div>
    )
}

export default ShopProductCard