import React, {useState} from "react"
import {PRODUCT_OPTION_TYPE} from "../../../constants/other"
import Popup from "reactjs-popup"
import ViewProductDetailsPopup from "./ViewProductDetailsPopup"
import {getColorOptionValues} from "../../../utils/other.utils"

const ProductOption = ({option, selectedValues, setSelectedValues, availableOptionValues}) => {
    const isColorOption = option.type === PRODUCT_OPTION_TYPE.COLOR
    const selectedValue = selectedValues[option.position]
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <div className="view-product-content-options-item">
            <div className="view-product-content-options-item-name t-button">{option.name}</div>
            <div className="view-product-content-options-item-values">
                {option.values.map((value, index) => {
                    const optionValue = isColorOption ? getColorOptionValues(value.value) : {text: value.value, color: null}

                    const isDisabled =
                        !availableOptionValues[option.position]?.has(optionValue.text)
                    return (
                        <span className={`${isColorOption ? "view-product-content-options-item-values-value-border" : ""} ${
                            selectedValue === optionValue.text ? "selected" : ""
                        } ${isDisabled ? "not-available" : ""}`} key={index}>
                            <span
                                key={index}
                                className={`view-product-content-options-item-values-value t-s4 ${isColorOption ? "color-option" : ""} ${selectedValue === optionValue.text ? "selected" : ""}`}
                                style={isColorOption ? { backgroundColor: optionValue.color } : {}}
                                onClick={() => {setSelectedValues(prevValues => ({...prevValues, [option.position]: optionValue.text}))}}
                                onMouseOver={() => setShowTooltip(index)}
                                onMouseOut={() => setShowTooltip(false)}
                            >
                                {showTooltip === index && isColorOption &&
                                    <span className="view-product-content-options-item-values-value-tooltip">{optionValue.text}</span>
                                }
                                {isColorOption ? "" : optionValue.text}
                            </span>
                        </span>
                    )
                })}
            </div>
            {option.details_name &&
                <Popup trigger={<span className="view-product-content-options-item-details t-button-underline">{option.details_name}</span>} modal>
                    {close =>
                        <ViewProductDetailsPopup
                            details_name={option.details_name}
                            details_image_url={option.details_image_url}
                            close={close}
                        />
                    }
                </Popup>
            }
        </div>
    )
}

export default ProductOption