import React from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {useDeleteRoleMutation} from "../../../api/roles"


const DeleteRolePopup = ({role, refreshTable, setUserMessage, close}) => {
    const [deleteRole, {isLoading}] = useDeleteRoleMutation()

    const handleSubmit = () => {
        deleteRole(role.id)
            .unwrap()
            .then(() => {
                refreshTable()
                close()
                setUserMessage({message: "Role was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete "${role.role_name}" role?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteRolePopup
