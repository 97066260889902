import React from "react"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

const DeleteCategoryPopup = ({category, handleSubmit, close}) => {
    return (
        <CustomPopup text_title={`Products within the category '${category.label}' already exist. Are you sure you want to delete this category?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={() => {handleSubmit(category), close()}}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteCategoryPopup