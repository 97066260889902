import "./index.scss"

import React, {useCallback} from "react"
import {Helmet} from "react-helmet"
import InfiniteScroll from "react-infinite-scroll-component"

import TimeOffBalanceTable from "./components/TimeOffBalanceTable"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import DDListYear from "../../toolkits/DDListYear"

import {EMPLOYEE_STATUS} from "../../constants/other"
import FilterPanel from "../../toolkits/FilterPanel"
import {useGetTimeOffBalancesQuery, useGetTimeOffBalancesUniqueYearsQuery} from "../../api/timeOffBalances"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"


const TimeOffBalance = () => {
    const {data: uniqueYears, isLoading} = useGetTimeOffBalancesUniqueYearsQuery()

    const transformParams = useCallback((params) => ({
        ...params,
        employeeStatus: undefined,
        year: params.year || new Date().getFullYear(),
        with_last_date: params.employeeStatus === EMPLOYEE_STATUS.ALL ? undefined : params.employeeStatus !== EMPLOYEE_STATUS.CURRENT,
    }), [])

    const {
        loadNextPage,
        empty,
        items,
        hasMore,
        params,
        changeParams,
    } = usePaginatedQuery(useGetTimeOffBalancesQuery, {
        transformParams,
        defaultState: {
            params: {
                year: new Date().getFullYear(),
                employeeStatus: EMPLOYEE_STATUS.ALL,
            }
        }
    })

    const onYearChange = (year) => changeParams({year})
    const onEmployeeStatusChange = (value) => changeParams({ employeeStatus: value})
    const onSearchChange = (value) => changeParams({username: value})

    return (
        <>
            <Helmet>
                <title>Time Off Balance - Newsoft Inside</title>
            </Helmet>
            <div className="time-offs-balance">
                <div className="time-offs-balance-header">
                    <SearchForm onChange={onSearchChange} debounceMs={500} />
                    <div className="time-offs-balance-header-select-year">
                        {!isLoading && uniqueYears?.length > 0 &&
                            <DDListYear
                                options={uniqueYears.map((x) => ({value: x.year, label: x.year}))}
                                defaultValue={{value: new Date().getFullYear(), label: new Date().getFullYear()}}
                                onChange={(year) => onYearChange(year.value)}
                            />
                        }
                    </div>
                </div>
                <div className="time-offs-balance-header-status-filter">
                    <FilterPanel
                        title="Employee status"
                        options={EMPLOYEE_STATUS}
                        selectedOption={params.employeeStatus || ""}
                        setOptionFilter={onEmployeeStatusChange}
                    />
                </div>
                <InfiniteScroll
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<StyledCircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                >
                    <TimeOffBalanceTable
                        year={params.year}
                        data={items}
                        empty={empty}
                    />
                </InfiniteScroll>
            </div>
        </>
    )
}

export default TimeOffBalance
