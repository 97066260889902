import {salaryRelatedApi} from "./base"


export const bonusesApi = salaryRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        createBonus: builder.mutation({
            query: (data) => ({path: "bonuses/", method: "POST", data}),
            invalidatesTags: ["Bonuses"]
        }),
        updateBonus: builder.mutation({
            query: ({id, data}) => ({path: `bonuses/${id}`, method: "PUT", data}),
            invalidatesTags: ["Bonuses"]
        }),
        deleteBonus: builder.mutation({
            query: (id) => ({path: `bonuses/${id}`, method: "DELETE"}),
            invalidatesTags: ["Bonuses"]
        }),
    })
})

export const {
    useCreateBonusMutation,
    useUpdateBonusMutation,
    useDeleteBonusMutation,
} = bonusesApi
