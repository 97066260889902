import {CLEAR_STATE, POP_STATE, PUSH_STATE} from "../const"


export const pushState = (tab, path, state) => async (dispatch) => {
    dispatch({
        type: PUSH_STATE,
        payload: {
            tab,
            path,
            pageState: state,
        }
    })
}

export const popState = (tab) => async (dispatch) => {
    dispatch({
        type: POP_STATE,
        payload: {
            tab
        }
    })
}

export const clearState = () => async (dispatch) => {
    dispatch({type: CLEAR_STATE})
}
