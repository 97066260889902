import React from "react"


const OurRulesSecondPopup = () => (
    <div className="greetings-onboarding-content-text t-b5">
        <div className="greetings-onboarding-content-paragraph">
            <div className="greetings-onboarding-content-heading t-s5">Overtimes</div>
            <p>Overtime is an exception. Overtime is agreed with the manager and paid according to the overtime coefficients (x1, x1.5, x2).</p>
        </div>
        <div>
            <div className="greetings-onboarding-content-heading t-s5">Time off</div>
            <p>Your annual leave includes 20 paid days off for vacation, personal, or sick days. This increases by one day yearly, reaching a maximum of 25 days after five years. You can also carry forward up to 10 unused days to the next year.</p>
            <p>Please inform us at least one month in advance if you plan to take a vacation and ensure you’ve communicated it to your team and clients.</p>
        </div>
    </div>
)

export default OurRulesSecondPopup
