import {useGetClientsQuery} from "../api/clients"


const useClients = ({ params, skip } = {}) => {
    const {data, isLoading} = useGetClientsQuery(params, { skip })

    return {
        clients: data?.items || [],
        total: data?.total,
        isLoading,
    }
}

export default useClients
