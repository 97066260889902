import React, { useContext } from "react"

import Protected from "../../../components/Protected/Protected"
import CustomLink from "../../../toolkits/CustomLink"
import { ThemeContext } from "../../../styles/theme-context"
import SettingsEnabled from "../../../components/SettingsEnabled/SettingsEnabled"


const DashboardCard = ({title, link, notification, permissions, settingsNames}) => {
    const theme = useContext(ThemeContext)

    return (
        <Protected permissions={!permissions ? [] : permissions}>
            <SettingsEnabled settingsNames={!settingsNames ? [] : settingsNames}>
                <CustomLink to={{pathname: link}}>
                    <div className={`${theme.dashboardCard} dashboard-box-card`}>
                        <div className="dashboard-box-card-content">
                            {notification &&
                                <div className="dashboard-box-card-content-notification t-h2">
                                    <span className="t-s1 dashboard-box-card-content-notification-text">
                                        {notification}
                                    </span>
                                </div>
                            }
                            <span className="dashboard-box-card-content-title t-h2">
                                {title}
                            </span>
                        </div>
                    </div>
                </CustomLink>
            </SettingsEnabled>
        </Protected>
    )
}

export default DashboardCard
