import "./index.scss"

import React, {useContext} from "react"
import {ThemeContext} from "../../styles/theme-context"
import InfiniteScroll from "react-infinite-scroll-component"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import TableHeader from "./components/TableHeader"
import TableBody from "./components/TableBody"



const getTableClass = ({ variant }) => {
    const classes = ["ns-table"]
    if (variant === "small") {
        classes.push("ns-table-small")
    }
    return classes.join(" ")
}



// For SidePanelComponent to work properly the parent component should have some static height (100vh for example) and overflow: auto
const Table = ({
    items,
    columns,
    loadNextPage,
    loading,
    hasMore,
    setUserMessage,
    AccordionComponent,
    onRowClick,
    variant="normal", // normal | small
    endMessage,
}) => {
    const theme = useContext(ThemeContext)

    const tableId = `ns-table-${columns.map((column) => (column.field?.[0] || "") + (column.title?.[0] || "")).join("")}`

    return (
        <div className="ns-table-container" id={tableId}>
            <InfiniteScroll
                pageStart={0}
                next={loadNextPage}
                hasMore={hasMore}
                endMessage={ endMessage ? (
                    <p style={{ textAlign: "center" }}>
                        <b>{endMessage}</b>
                    </p>
                ) : undefined}
                loader={<CircularProgress key="circular-progress"/>}
                dataLength={items.length}
            >
                <div className={`custom-scrollable-table ${theme.tableClassName}`}>
                    <table className={getTableClass({variant })}>
                        <TableHeader columns={columns} expandable={!!AccordionComponent}/>
                        <TableBody
                            items={items}
                            columns={columns}
                            loading={loading}
                            onRowClick={onRowClick}
                            AccordionComponent={AccordionComponent}
                            setUserMessage={setUserMessage}
                            variant={variant}
                        />
                    </table>
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default Table