import moment from "moment"

import {endOfYear, startOfYear} from "date-fns"

import {
    MILLISECONDS_PER_SECOND,
    SECONDS_PER_MINUTE,
    MINUTES_PER_HOUR,
    HOURS_PER_DAY,
    DAYS_PER_WEEK, DD_MM_YYYY, FILTER_TYPES,
} from "../constants/other"


// Set hours, minutes, seconds to 0
export const normalizeDate = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate())

export const minutesToString = (n) => {
    const hours = (n / MINUTES_PER_HOUR)
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * MINUTES_PER_HOUR
    const rminutes = Math.round(minutes)
    return `${rhours < 10 ? "0" + rhours : rhours}:${rminutes < 10 ? "0" + rminutes : rminutes}`
}

export const hoursToString = (hours) => {
    return minutesToString(hours * MINUTES_PER_HOUR)
}

export const minutesToHours = (minutes) => {
    return minutes / MINUTES_PER_HOUR
}

export const stringToMinutes = (s) => {
    return s.split(":").reduce((hours, minutes) => (MINUTES_PER_HOUR * hours) + +minutes)
}

export const changeDateFormat = (sourceDate, sourceFormat = "YYYY-MM-DD", targetFormat = DD_MM_YYYY) => {
    return moment(sourceDate, sourceFormat).format(targetFormat)
}

export const validateDate = (date, format = DD_MM_YYYY) => {
    return moment(date, format, true).isValid()
}

export const formatDate = (date, format = "YYYY-MM-DD") => {
    return moment(date).format(format)
}

export const formatReadableDate = (date) => {
    return moment(date).format("MMM D, YYYY")
}

export const getTime = (date, format = "HH:mm:ss") => {
    return moment(date).format(format)
}

export const startOfMonth = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

export const startOfWeek = (date = new Date()) => {
    const day = date.getDay(), diff = date.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(date.setDate(diff))
}

export const endOfWeek = (date = new Date()) => {
    const first = date.getDate() - date.getDay()
    const last = first + 7
    return new Date(date.setDate(last))
}

export const endOfMonth = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const oneYearFrom = (date = new Date()) => {
    return new Date(date.setFullYear(date.getFullYear() + 1))
}

export const lastDayOfYear = (year = new Date().getFullYear()) => new Date(year, 11, 31)

export const firstDayOfYear = (year = new Date().getFullYear()) => new Date(year, 0, 1)

export const isWeekend = (date) => new Date(date).getDay() === 0 || new Date(date).getDay() === 6

export const oneYearBefore = (date = new Date()) => {
    return new Date(date.setFullYear(date.getFullYear() - 1))
}

export const isDateBetween = (date, after, before) => {
    return moment(date).isBetween(after, before, undefined, "[]")
}

export const isDateInFuture = (date) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    date = new Date(date).setHours(0, 0, 0, 0)
    return today < date
}

export const isDateInPast = (date) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    date = new Date(date).setHours(0, 0, 0, 0)
    return today > date
}

export const nMonthsLater = (months, date = new Date()) => {
    return new Date(date.setMonth(date.getMonth() + months))
}

function convertFromUTC(utcDateString) {
    let utcDate = new Date(utcDateString)
    let offset = utcDate.getTimezoneOffset()

    utcDate.setHours(utcDate.getHours() - (offset / 60))
    return utcDate
}

export const wasSent = (date) => {
    const today = new Date()
    date = convertFromUTC(date)

    const minutesAgo = Math.abs(today.getTime() - date.getTime()) / (SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND)
    const hoursAgo = minutesAgo / MINUTES_PER_HOUR
    const daysAgo = hoursAgo / HOURS_PER_DAY

    if (minutesAgo < 1) {
        return "just sent"
    } else if (minutesAgo <= MINUTES_PER_HOUR) {
        return `${Math.floor(minutesAgo)} min`
    } else if (hoursAgo <= HOURS_PER_DAY) {
        return Math.floor(hoursAgo) === 1 ? "1 hour" : `${Math.floor(hoursAgo)} hours`
    } else if (daysAgo <= DAYS_PER_WEEK) {
        return Math.floor(daysAgo) === 1 ? "1 day" : `${Math.floor(daysAgo)} days`
    } else return moment(date).format("MMM D, YYYY")
}

export const getRangeDates = (startDate, period) => [...Array(period).keys()].map(x =>
    normalizeDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + x)))

export const daysDifference = (startDate, endDate) => {
    const millisecondsPerDay =
        MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY

    return (normalizeDate(endDate).getTime() - normalizeDate(startDate).getTime()) / millisecondsPerDay
}

export const isStartOfYear = (date) => {
    const firstYearDay = startOfYear(new Date(date.getFullYear(), 1, 1))

    return !daysDifference(firstYearDay, date)
}

export const isEndOfYear = (date) => {
    const firstYearDay = endOfYear(new Date(date.getFullYear(), 1, 1))

    return !daysDifference(firstYearDay, date)
}

export const isEndOfMonth = (date) => {
    const lastMonthDay = endOfMonth(new Date(date.getFullYear(), date.getMonth(), 1))

    return !daysDifference(date, lastMonthDay)
}

export const mapDateRangeFilters = (filter, date = null) => {
    const now = new Date()

    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const weekStart = startOfWeek(now, {weekStartsOn: 1})
    const weekEnd = endOfWeek(now, {weekStartsOn: 1})
    const monthStart = startOfMonth(now)
    const monthEnd = endOfMonth(now)
    const lastMonthStart = startOfMonth(lastMonth)
    const lastMonthEnd = endOfMonth(lastMonth)
    const yearStart = startOfYear(now)
    const yearEnd = endOfYear(now)

    const lastYear = new Date(now)
    lastYear.setFullYear(now.getFullYear() - 1)
    const lastYearStart = startOfYear(lastYear)
    const lastYearEnd = endOfYear(lastYear)

    const customStartDate = formatDate(date?.created_after)
    const customEndDate = formatDate(date?.created_before)

    switch (filter) {
    case FILTER_TYPES.THIS_WEEK:
        return {created_after: weekStart, created_before: weekEnd}
    case FILTER_TYPES.THIS_MONTH:
        return {created_after: monthStart, created_before: monthEnd}
    case FILTER_TYPES.LAST_MONTH:
        return {created_after: lastMonthStart, created_before: lastMonthEnd}
    case FILTER_TYPES.THIS_YEAR:
        return {created_after: yearStart, created_before: yearEnd}
    case FILTER_TYPES.LAST_YEAR:
        return {created_after: lastYearStart, created_before: lastYearEnd}
    case FILTER_TYPES.CUSTOM:
        return {created_after: customStartDate, created_before: customEndDate}
    default:
        return {created_after: customStartDate, created_before: customEndDate}
    }
}

export const getPreviousWorkingDay = (inputDate) => {
    let formattedDate = changeDateFormat(inputDate, DD_MM_YYYY, "YYYY-MM-DD")

    let date = new Date(formattedDate)
    const dayOfWeek = date?.getDay()
    let subtractDays
    if (dayOfWeek === 1) {
        subtractDays = 3
    } else if (dayOfWeek === 0) {
        subtractDays = 2
    } else {
        subtractDays = 1
    }
    const previousWorkingDay = new Date(date)
    previousWorkingDay.setDate(date.getDate() - subtractDays)
    return previousWorkingDay
}

export const getDaysTakenFromTimeOffBalanceMessage = (days) =>
    `*If you send this request it will take ${days} day${days !== 1 ? "s" : ""} from your Time Off balance.`

export const hidePeriodYears = (period) => {
    if (period.includes("-")) {
        const [startDateStr, endDateStr] = period.split("-")
        const startDayMonth = changeDateFormat(startDateStr.trim(), DD_MM_YYYY, "DD.MM")
        const endDayMonth = changeDateFormat(endDateStr.trim(), DD_MM_YYYY, "DD.MM")

        return `${startDayMonth}-${endDayMonth}`
    } else {
        return changeDateFormat(period.trim(), DD_MM_YYYY, "DD.MM")
    }
}


export const hidePeriodStartYear = (period) => {
    if (period.includes("-")) {
        const [startDateStr, endDateStr] = period.split("-")
        const startYear = changeDateFormat(startDateStr.trim(), DD_MM_YYYY, "YYYY")
        const endYear = changeDateFormat(endDateStr.trim(), DD_MM_YYYY, "YYYY")

        if (startYear === endYear) {
            const startDayMonth = changeDateFormat(startDateStr.trim(), DD_MM_YYYY, "DD.MM")
            return `${startDayMonth}-${endDateStr.trim()}`
        } else {
            return period
        }
    } else {
        return period
    }
}
