import "../index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import AlertMessage from "toolkits/AlertMessage/AlertMessage"
import SmallButton from "toolkits/SmallButton/SmallButton"
import {useSelector} from "react-redux"
import {PERMISSIONS} from "constants/permissions"
import {
    useGetGoogleCalendarIntegrationQuery,
    useRemoveGoogleCalendarIntegrationMutation,
    useSetupGoogleCalendarIntegrationMutation
} from "api/googleIntegration"
import {ERROR_CODE, INTEGRATION_TYPES, SUCCESS_CODE} from "constants/other"
import Card from "components/containers/Card"
import Divider from "components/layout/Divider"
import {formatReadableDate} from "utils/datetime.utils"
import GoogleCalendars from "assets/images/GoogleCalendars.svg"
import Typography from "components/typography/Typography"
import DescriptionList from "components/typography/DescriptionList"
import GoogleOOOCalendarCompleteSetupCard from "./GoogleOOOCalendarCompleteSetupCard"
import Popup from "reactjs-popup"


const GoogleOOOCalendarIntegrationsCard = () => {
    const {hasPermission} = useSelector((state) => state.profile)

    const [isSyncing, setIsSyncing] = useState(false)

    const {
        data: integration,
        isLoading: integrationLoading,
        isFetching: integrationFetching
    } = useGetGoogleCalendarIntegrationQuery({ integration_type: INTEGRATION_TYPES.GOOGLE_OOO}, isSyncing ? {pollingInterval: 1000} : {})
    const [setupGoogleCalendarIntegration, {isLoading: setupLoading}] = useSetupGoogleCalendarIntegrationMutation()
    const [removeGoogleCalendarIntegration, {isLoading: removeLoading}] = useRemoveGoogleCalendarIntegrationMutation()

    const isLoading = integrationLoading || integrationFetching || removeLoading || setupLoading
    const [userMessage, setUserMessage] = useState(null)

    const [setupKey, setSetupKey] = useState()
    const [completeSetupModal, setCompleteSetupModal] = useState(false)


    useEffect(() => {
        setIsSyncing(!!integration?.is_syncing)
    }, [integration])

    const connect = async () => {
        const client = window.google.accounts.oauth2.initCodeClient({
            client_id: process.env.REACT_APP_GOOGLE_INTEGRATION_CLIENT_ID,
            scope: "https://www.googleapis.com/auth/calendar",
            access_type: "offline",
            ux_mode: "popup",
            callback: async (response) => {
                if (response.error) {
                    if (response.error === "access_denied") {
                        setUserMessage({message: "Oops! You can't access this.", code: ERROR_CODE})
                    } else {
                        setUserMessage({message: "Authorization issue. Please try again.", code: ERROR_CODE})
                    }
                    return
                }

                try {
                    const data = await setupGoogleCalendarIntegration({integrationType: INTEGRATION_TYPES.GOOGLE_OOO, code: response.code}).unwrap()
                    const setupKey = data.setup_key
                    setSetupKey(setupKey)
                    setCompleteSetupModal(true)
                } catch (error) {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                }
            }
        })
        client.requestCode()
    }
    const remove = async () => {
        try {
            await removeGoogleCalendarIntegration(INTEGRATION_TYPES.GOOGLE_OOO).unwrap()
            setUserMessage({message: "Integration removed", code: SUCCESS_CODE})
        } catch (error) {
            setUserMessage({message: error.message, code: ERROR_CODE})
        }
    }

    return (
        <>
            <Helmet>
                <title>Google Integration - Newsoft Inside</title>
            </Helmet>

            {userMessage && <AlertMessage setMessage={setUserMessage} {...userMessage}/>}

            <Popup open={completeSetupModal} onClose={() => setCompleteSetupModal(false)} modal>
                <GoogleOOOCalendarCompleteSetupCard
                    setupKey={setupKey}
                    setUserMessage={setUserMessage}
                    close={() => setCompleteSetupModal(false)}
                />
            </Popup>

            <Card className="google-integration">
                <div className="google-integration-header">
                    <div className="google-integration-header-title-container">
                        <div className="google-integration-header-title-container-icon">
                            <img src={GoogleCalendars} alt="Google Calendars"/>
                        </div>
                        <div>
                            <Typography variant="header">Google Calendar {integration?.is_syncing ? "(Syncing events...)" : null}</Typography>
                            <Typography variant="supporting1">Integrate your company’s calendar to mark “OOO”</Typography>
                        </div>
                    </div>
                    {!integration && hasPermission(PERMISSIONS.WRITE_INTEGRATIONS) && (
                        <SmallButton
                            loading={setupLoading}
                            disabled={isLoading}
                            onClick={connect}
                        >
                            Connect
                        </SmallButton>
                    )}
                    {integration && hasPermission(PERMISSIONS.WRITE_INTEGRATIONS) &&  (
                        <SmallButton
                            loading={removeLoading}
                            disabled={isLoading || integration?.is_syncing}
                            onClick={remove}
                        >
                            Remove
                        </SmallButton>
                    )}
                </div>

                <Divider/>

                <div className="google-integration-body">
                    <div className="google-integration-body-description">
                        <Typography variant="leading">Description</Typography>
                        <Typography variant="supporting">The system integration with Google Calendar allows seamless management of employee out-of-office (OOO) statuses.</Typography>
                        <Typography variant="supporting">With this integration, you can:</Typography>
                        <br/>
                        <DescriptionList data={[
                            {
                                title: "",
                                items: [
                                    "Create or select one of the company’s designated “OOO” calendars to track all team members’ OOO schedules.",
                                    "Automatically mark employees as OOO in the calendar when they request a day off in the system, ensuring up-to-date information.",
                                    "Simplify team coordination by reducing scheduling conflicts and enhancing transparency, with a centralized, shared view of OOO statuses across departments."
                                ],
                            },
                        ]}/>
                        <br/>
                        <Typography variant="supporting">This integration helps maintain clear communication and efficient planning by automatically updating the calendar with every day-off request.</Typography>
                    </div>
                    <Divider/>

                    {integration && (
                        <>
                            <div className="google-integration-body-authorization">
                                <div>
                                    <Typography variant="leading">Your authorization</Typography>
                                    <Typography variant="supporting"><span style={{color: "black", fontWeight: "600"}}>“{integration.calendar_name}”</span> selected by <span style={{color: "black", fontWeight: "600"}}>{integration.user_email}</span> on {formatReadableDate(integration.authorization_date)}</Typography>
                                </div>
                                {hasPermission(PERMISSIONS.WRITE_INTEGRATIONS) && (
                                    <SmallButton
                                        btnType="secondary"
                                        loading={setupLoading}
                                        disabled={(!setupLoading && isLoading) || integration?.is_syncing}
                                        onClick={connect}
                                    >
                                    Change
                                    </SmallButton>
                                )}

                            </div>

                            <Divider/>
                        </>
                    )}
                </div>
            </Card>
        </>)

}

export default GoogleOOOCalendarIntegrationsCard
