import React from "react"
import { formatDate } from "../../../../utils/datetime.utils"
import CopyButton from "../../../../components/Miscellaneous/CopyButton"
import TelegramButton from "../../../../toolkits/TelegramButton"
import {DD_MM_YYYY} from "../../../../constants/other"
import {useTranslation} from "react-i18next"

const UserInfo = ({ user, setUserMessage }) => {
    const { t } = useTranslation("common")

    return (
        <div className="profile-user-info">
            <div>
                <span className="t-h3 profile-font-leading">{t("BIRTH DATE")}</span>
                <span className="t-s4 profile-font-supporting aligned-tabs">
                    {formatDate(user.birthdate, DD_MM_YYYY)}
                </span>
            </div>
            <div>
                <span className="t-h3 profile-font-leading">{t("PHONE")}<TelegramButton phone_number={user.phone_number} color={"#1E1E1E"}/></span>
                <span className="t-s4 profile-font-supporting aligned-tabs">
                    {user.phone_number}
                </span>
            </div>
            <div>
                <span className="t-h3 profile-font-leading">{t("EMAIL")}<CopyButton value={user.email} type="Email" setUserMessage={setUserMessage}/></span>
                <span className="profile-user-info-email t-s4 profile-font-supporting">
                    {user.email}
                </span>
            </div>
        </div>
    )
}

export default UserInfo
