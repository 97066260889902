import React, {useState} from "react"

import SmallButton from "../../../../toolkits/SmallButton/SmallButton"

import DDList from "../../../../toolkits/DDList/DDList"
import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"

import {ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"
import {
    useAssignCalendarToUsersMutation,
    useUnassignCalendarFromUsersMutation,
    useGetPublicHolidayCalendarsQuery
} from "../../../../api/publicHolidayCalendars"


const UpdateCalendarPopup = ({
    user,
    close,
    receiveUserInfo,
    setUserMessage,
}) => {
    const noCalendarOption = { id: null, value: "no-calendar", label: "No calendar", name: "No calendar" }
    const [currentCalendar, setCurrentCalendar] = useState(user.active_calendar?.id && user.active_calendar?.name ? user.active_calendar : noCalendarOption)
    const [assignCalendarToUsers] = useAssignCalendarToUsersMutation()
    const [unassignCalendarFromUsers] = useUnassignCalendarFromUsersMutation()
    const { data: calendars } = useGetPublicHolidayCalendarsQuery()

    const getCalendarOptions = (calendars) => {
        return !calendars ? [noCalendarOption] : [
            noCalendarOption,
            ...calendars.map((calendar) => ({
                value: calendar,
                label: calendar.name,
            })),
        ]
    }

    const handleSubmit = () => {
        const data = {
            user_ids: [user.id]
        }

        if (currentCalendar === "no-calendar") {
            unassignCalendarFromUsers({calendar_id: user.active_calendar.id, data})
                .unwrap()
                .then(() => {
                    receiveUserInfo()
                    close(false)
                    setUserMessage({message: "Calendar was successfully unassigned!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        } else {
            assignCalendarToUsers({calendar_id: currentCalendar.id, data})
                .unwrap()
                .then(() => {
                    receiveUserInfo()
                    close(false)
                    setUserMessage({message: "Calendar was successfully assigned!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        }
    }

    const customSelectStyles = {
        singleValue: (provided, state) => {
            return {
                ...provided,
                color: state.data.value === "no-calendar" ? "#999999 !important" : provided.color,
            }
        },
        option: (provided, state) => {
            return {
                ...provided,
                color: state.data.value === "no-calendar" ? "#999999 !important" : provided.color,
            }
        }
    }

    return (
        <CustomPopup className="edit-calendar-popup" text_title="Edit Calendar">
            <DDList
                text_label="Calendar"
                placeholder=""
                defaultValue={{value: currentCalendar, label: currentCalendar.name}}
                listOptions={getCalendarOptions(calendars)}
                onChange={calendar => setCurrentCalendar(calendar.value)}
                styles={customSelectStyles}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">    
                    <SmallButton btnType={"secondary"} onClick={() => close(false)}>CANCEL</SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} disabled={currentCalendar.id === user.active_calendar.id}>SAVE</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default UpdateCalendarPopup
