import {
    GET_BONUS_EARN_SUCCESS,
    GET_BONUS_EARN_FAILURE,
    GET_BONUS_EARN_REQUEST
} from "../const"
import {getBonusEarn} from "../api"

export const request = () => ({ type: GET_BONUS_EARN_REQUEST })

const success = (bonuses) => ({
    type: GET_BONUS_EARN_SUCCESS,
    payload: { bonuses },
})

const failure = () => ({ type: GET_BONUS_EARN_FAILURE })


export const receiveBonusInfo = (user_id, params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveBonusInfoData} = await getBonusEarn(user_id, params)
        dispatch(success(receiveBonusInfoData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}