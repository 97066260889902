import {getMe, getUserById} from "../api"

import {getUserViewedAs, saveAuthorizedUserData} from "../../../utils/localstorage.utils"

import {
    GENERATE_RANDOM_SMILE,
    GET_CURRENT_USER_FAILURE,
    GET_CURRENT_USER_REQUEST,
    GET_CURRENT_USER_SUCCESS,
    GET_ME_FAILURE,
    GET_ME_REQUEST,
    GET_ME_SUCCESS,
} from "../const"
import {NUMBER_OF_SMILES} from "../../../constants/other"


const requestGetMe = () => ({type: GET_ME_REQUEST})

export const successGetMe = (myInfo) => ({
    type: GET_ME_SUCCESS,
    payload: {myInfo}
})

const failureGetMe = () => ({type: GET_ME_FAILURE})

const requestCurrentUser = () => ({type: GET_CURRENT_USER_REQUEST})

const successCurrentUser = (currentUser) => ({
    type: GET_CURRENT_USER_SUCCESS,
    payload: {currentUser}
})

const successGenerateSmile = (privateModeSmile) => ({
    type: GENERATE_RANDOM_SMILE,
    payload: {privateModeSmile}
})


const failureCurrentUser = () => ({type: GET_CURRENT_USER_FAILURE})


export const receiveMyInfo = () => async (dispatch) => {
    dispatch(requestGetMe())
    try {
        let {data: userData} = await getMe()
        const userViewedAs = getUserViewedAs()

        if(userViewedAs){
            userData = (await getUserById(userViewedAs.user_id)).data
        }

        saveAuthorizedUserData({
            privateMode: userData.private_mode,
        })
        dispatch(successGetMe(userData))
    } catch (e) {
        console.log(e)
        dispatch(failureGetMe())
    }
}


export const receiveCurrentUser = (UserId) => async (dispatch) => {
    dispatch(requestCurrentUser())
    try {
        const {data: getCurrentUserData} = await getUserById(UserId)

        dispatch(successCurrentUser(getCurrentUserData))
    } catch (e) {
        console.log(e)
        dispatch(failureCurrentUser())
    }
}

export const generateRandomSmile = (theme) => async (dispatch) => {
    const smilesList = theme.smiles
    const privateModeSmile = smilesList[Math.floor(Math.random()*smilesList.length)].repeat(NUMBER_OF_SMILES)
    dispatch(successGenerateSmile(privateModeSmile))
}
