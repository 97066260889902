import React from "react"

import moment from "moment"

import {endOfMonth, formatDate, startOfMonth} from "../../utils/datetime.utils"

import {DAYS_PER_WEEK} from "../../constants/other"

import Arrow from "@mui/icons-material/KeyboardArrowLeft"


export const CustomButton = ({direction, handleClick, onCalendarBoundariesChange, calendarRef}) => {
    const onArrowClick = () => {
        handleClick()
        let startDate, endDate
        if (calendarRef) {
            startDate = moment(
                startOfMonth(calendarRef.current.date.toDate())
            ).add(-DAYS_PER_WEEK, "days").toDate()
            endDate = moment(
                endOfMonth(calendarRef.current.date.toDate())
            ).add(DAYS_PER_WEEK, "days").toDate()
        } else {
            const year = moment().year()

            startDate = moment().year(year).startOf("year").toDate()
            endDate = moment().year(year+1).endOf("year").toDate()
        }
        onCalendarBoundariesChange(formatDate(startDate), formatDate(endDate))
    }
    return direction === "right" ? (
        <Arrow onClick={onArrowClick} className="cursor-pointer cursor-pointer-right"/>
    ) : (
        <Arrow onClick={onArrowClick} className="cursor-pointer cursor-pointer-left"/>
    )
}
