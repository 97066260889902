import {useSelector} from "react-redux"
import {getUserViewedAs} from "../utils/localstorage.utils"

const useCurrentUserId = () => {
    const auth = useSelector((state) => state.auth)
    const userId = getUserViewedAs() ? getUserViewedAs().user_id : auth.user.uid

    return userId
}

export default useCurrentUserId