import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useDeleteEquipmentMutation} from "../../../api/equipment"
import {useTranslation} from "react-i18next"


const DeleteEquipmentPopup = ({equipment, refreshTable, setUserMessage, close}) => {
    const { t } = useTranslation("common")

    const [deleteEquipment] = useDeleteEquipmentMutation()

    const handleSubmit = () => {
        deleteEquipment(equipment.id)
            .unwrap()
            .then(() => {
                refreshTable()
                close()
                setUserMessage({message: t("Equipment was successfully deleted!"), code: SUCCESS_CODE})
            })
            .catch(error => {
                close()
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={t("Do you want to delete {{equipment}}?", {equipment: equipment.name} )} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> {t("Cancel").toUpperCase()} </SmallButton>
                    <SmallButton onClick={handleSubmit}> {t("Delete").toUpperCase()} </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteEquipmentPopup
