import React from "react"
import { reformatPhoneNumber } from "../../utils/other.utils"
import { TELEGRAM_URL } from "../../constants/other"

const TelegramButton = ({phone_number, color = "1E1E1E"}) => {

    return (
        <a className="telegram-icon" href={`${TELEGRAM_URL}${reformatPhoneNumber(phone_number)}`} target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                <g clipPath="url(#clip0_23042_64826)">
                    <path d="M14.4113 1.08765C14.8456 1.08765 15.1732 1.4686 14.9979 2.17717L12.8722 12.1962C12.7237 12.9086 12.2932 13.08 11.6989 12.7486L6.62842 9.00384C6.60876 8.98973 6.59274 8.97114 6.58169 8.94961C6.57063 8.92808 6.56487 8.90423 6.56487 8.88003C6.56487 8.85583 6.57063 8.83197 6.58169 8.81044C6.59274 8.78891 6.60876 8.77033 6.62842 8.75622L12.4837 3.4686C12.7503 3.23241 12.4265 3.11812 12.076 3.33146L4.72747 7.96765C4.70518 7.98218 4.67988 7.99145 4.65347 7.99475C4.62707 7.99805 4.60027 7.9953 4.57509 7.98669L1.45509 7.00003C0.761753 6.79812 0.761753 6.32193 1.61128 5.98288L14.0951 1.16765C14.1941 1.12015 14.3016 1.09295 14.4113 1.08765Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_23042_64826">
                        <rect width="16" height="13.7143" fill="white" transform="translate(0 0.142822)"/>
                    </clipPath>
                </defs>
            </svg>
        </a>
    )
}

export default TelegramButton
