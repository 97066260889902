import React from "react"

import { CACHED_IMAGES } from "../../../constants/other"


const BenefitItem = ({ icon, label }) => (
    <div className="benefit-item">
        <img src={icon} alt={label}/>
        <span className="t-h3">{label}</span>
    </div>
)

const BenefitsPopup = () => (
    <div className="benefit-popup-grid">
        <BenefitItem icon={CACHED_IMAGES.Food} label="FOOD" />
        <BenefitItem icon={CACHED_IMAGES.Equipment} label="EQUIPMENT" />
        <BenefitItem icon={CACHED_IMAGES.GymPlace} label="GYM PLACE" />
        <BenefitItem icon={CACHED_IMAGES.Vacations} label="VACATIONS" />
        <BenefitItem icon={CACHED_IMAGES.SelfImprovement} label="SELF IMPROVEMENT" />
        <BenefitItem icon={CACHED_IMAGES.ChillingRoom} label="CHILLING ROOM" />
        <BenefitItem icon={CACHED_IMAGES.EnglishLessons} label="ENGLISH LESSONS" />
        <BenefitItem icon={CACHED_IMAGES.FlexibleSchedule} label="FLEXIBLE SCHEDULE" />
    </div>
)

export default BenefitsPopup
