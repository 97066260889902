import React, { useState } from "react"
import { SketchPicker } from "react-color"
import "./index.scss"

const ColorPicker = ({color, setColor}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false)

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    }

    const handleClose = () => {
        setDisplayColorPicker(false)
    }

    const handleChange = (color) => {
        setColor(color.hex)
    }

    const colorStyle = {
        width: "0.813rem",
        height: "0.875rem",
        borderRadius:"0.125rem",
        background: color,
    }

    return (
        <div>
            <div className="color-picker-swatch" onClick={handleClick}>
                <div className="color-picker-color" style={colorStyle} />
            </div>
            {displayColorPicker ? (
                <div className="color-picker-color-popover">
                    <div className="color-picker-color-popover-cover" onClick={handleClose} />
                    <SketchPicker color={color} onChangeComplete={handleChange} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    )
}

export default ColorPicker
