import React, {useEffect, useRef, useState} from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import "./index.scss"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined"

const MultipleSelect = ({ loading, placeholder, options, selectedOptionIds, setSelectedOptionIds }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef()

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }

    const handleSelect = (option) => {
        setSelectedOptionIds(
            selectedOptionIds.includes(option.id)
                ? selectedOptionIds.filter(id => id !== option.id)
                : [...selectedOptionIds, option.id]
        )
    }

    const handleResetAll = () => {
        setSelectedOptionIds([])
    }

    const isOptionSelected = (optionId) => {
        return selectedOptionIds.includes(optionId)
    }


    const getSelectedOptionNames = () => {
        const selectedOptions = options.filter(option =>
            selectedOptionIds.includes(option.id)
        )

        return selectedOptions.map(option => option.name).join(", ")
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isOpen])

    return(
        <div ref={dropdownRef} className={`multiple-select ${isOpen ? "open" : ""}`}>
            <button className="multiple-select-button" onClick={() => setIsOpen(!isOpen)}>
                <span onClick={() => setIsOpen(!isOpen)} className={`multiple-select-text ${selectedOptionIds.length === 0 && placeholder !== "All" ? "t-b2" : "t-b1" }`}>{selectedOptionIds.length === 0 ? placeholder : getSelectedOptionNames()}</span>
                {isOpen ?
                    <KeyboardArrowUpIcon className="multiple-select-icon " /> : <KeyboardArrowDownIcon className="multiple-select-icon" />
                }
            </button>
            {isOpen && (
                <div className="multiple-select-content">
                    {options.map((option, index) => (
                        <div key={index} className="multiple-select-item t-s4" onClick={() => handleSelect(option)}>
                            {isOptionSelected(option.id) ? <CheckBoxIcon className="multiple-select-checkmark" /> : <CheckBoxOutlineBlankIcon className="multiple-select-checkmark-unselected" />}
                            {option.name}
                        </div>
                    ))}
                </div>
            )}
            {selectedOptionIds.length !== 0 || (selectedOptionIds.length === options.length && !loading)  ?
                <span className="multiple-select-reset t-b3" onClick={handleResetAll}>reset all</span>
                :
                <></>
            }
        </div>
    )
}

export default MultipleSelect

