import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveSalaryEarnInfo} from "../actions/SalaryEarnAction"

import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"

import {roundEarn} from "../../../utils/other.utils"


const EarnInfoPopup = ({close, userId, dateRange, projects}) => {
    const dispatch = useDispatch()
    
    const {earn, loading} = useSelector((state) => state.earnInfo)
    
    useEffect(() => {
        dispatch(receiveSalaryEarnInfo(userId, {...dateRange, ...projects}))
    }, [])

    return (
        <CustomPopup
            text_title="Earn info"
            className="earn-info-popup"
        >
            {loading ? <StyledCircularProgress/> :
                <div className="earn-info-popup-wrapper">
                    {Object.keys(earn).map((clientName, i) => (
                        <div key={i} className="earn-info-popup-box">
                            <span className="t-s1">{clientName}</span>

                            {Object.keys(earn[clientName]).map((currency, i) =>
                                <span key={`earnDetail-${i}`} className="t-s1">
                                    <SensitiveInfo
                                        className="t-s1"
                                        value={`${roundEarn(earn[clientName][currency])} ${currency}`}
                                    />
                                </span>)
                            }
                        </div>
                    ))}
                </div>
            }

            <ClosePopupIcon onClick={close}/>           
        </CustomPopup>
    )
}

export default EarnInfoPopup
