import {getGreetingEvent, saveGreetingEvent} from "./localstorage.utils"
import {getRangeDates, normalizeDate, daysDifference} from "./datetime.utils"

import {
    DAYS_PER_YEAR,
    GREETINGS_COUNT_DAYS_SHOW,
    ANNIVERSARY_GREETING_TYPES,
    GREETING_TYPES,
    DESKTOP_WIDTH,
} from "../constants/other"
import {useDispatch, useSelector} from "react-redux"
import {markAsReadUserNotifications} from "../pages/Notifications/api"
import {receiveUserNotifications} from "../pages/Notifications/actions/userNotificationsActions"
import {useMediaQuery} from "react-responsive"


const refreshEventBase = (userId, event, period = GREETINGS_COUNT_DAYS_SHOW) => {
    const today = new Date()
    const dates = getRangeDates(event.date, period)
    const isShownEvent = getGreetingEvent(userId, event.type)

    if (dates.some(x =>
        today.getMonth() == x.getMonth() && today.getDate() == x.getDate()
    )) {
        if (!isShownEvent) {
            saveGreetingEvent(userId, event.type, true)
            return true
        }
    } else if (isShownEvent) {
        saveGreetingEvent(userId, event.type, "")
    }
    return false
}

const refreshWelcomeEvent = (userId, event) => {
    const today = normalizeDate(new Date())

    if (daysDifference(event.date, today) < DAYS_PER_YEAR - GREETINGS_COUNT_DAYS_SHOW) {
        return refreshEventBase(userId, event)
    } else return false
}

const refreshAnniversaryEvent = (userId, event) => {
    const today = normalizeDate(new Date())

    if (daysDifference(event.date, today) >= DAYS_PER_YEAR - GREETINGS_COUNT_DAYS_SHOW) {
        return refreshEventBase(userId, event)
    } else return false
}

const refreshBirthdayEvent = (userId, event) => refreshEventBase(userId, event)


export const initialEvents = (userId, hiringDate, birthdate) => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})
    const dispatch = useDispatch()
    const notifications = useSelector((state) => state.userNotifications.userNotifications)
    const anniversaryNotifications = notifications?.filter(
        notif => ANNIVERSARY_GREETING_TYPES.includes(notif.notification.type) && !notif.shown
    )
    const newcoinsNotifications = notifications?.filter(notif => notif.notification.type === "NEWCOINS RECEIVED" && !notif.shown)

    const events = [
        {
            type: GREETING_TYPES.birthday,
            date: normalizeDate(new Date(birthdate)),
        }
    ]

    if (isDesktop) {
        events.push({
            type: GREETING_TYPES.welcome,
            date: normalizeDate(new Date(hiringDate)),
        })
    }

    anniversaryNotifications.forEach(notif => {
        const match = notif.notification.title.split("Thanks for your efforts and contribution🚀\n")

        events.push({
            type: GREETING_TYPES.anniversary,
            date: normalizeDate(new Date(hiringDate)),
            content: {
                message: match.length > 1 ? match[1] : "",
            }
        })
    })

    newcoinsNotifications.forEach(notif => {
        const amount = notif.notification.title.match(/\d+/)
        events.push({
            type: GREETING_TYPES.newcoins,
            content: {reason: notif.notification.content, amount: amount}
        })
        markAsReadUserNotifications({user_id: userId, notification_ids: [notif.notification_id]}).then(() => {
            dispatch(receiveUserNotifications(userId))
        })
    })

    const isShowEvent = (event) => {
        switch (event.type) {
        case GREETING_TYPES.welcome:
            return refreshWelcomeEvent(userId, event)
        case GREETING_TYPES.anniversary:
            return refreshAnniversaryEvent(userId, event)
        case GREETING_TYPES.birthday:
            return refreshBirthdayEvent(userId, event)
        case GREETING_TYPES.newcoins:
            return true
        default:
            return false
        }
    }

    return events.reduce((result, event) => {
        if (isShowEvent(event)) {
            const eventInfo = { type: event.type }
            if (event.content) {
                eventInfo.content = {...event.content}
            }
            return [...result, eventInfo]
        }
        return result
    }, [])
}
