import {useCallback, useMemo} from "react"

import { createSlice } from "@reduxjs/toolkit"
import {getTitleOfPage} from "../../utils/other.utils"
import {useDispatch, useSelector} from "react-redux"
import {useCustomHistory} from "../useCustomHistory"

export const entityCountSlice = createSlice({
    name: "entityCounts",
    initialState: {},
    reducers: {
        setEntityCount: (state, action) => {
            const { title, count } = action.payload
            state[title] = count
        }
    }
})

export const getCount = (key) => (state) => state.entityCounts[key] || null

export const useNavCount = () => {
    const dispatch = useDispatch()
    const history = useCustomHistory()
    const title = useMemo(() => getTitleOfPage(history.location.pathname), [history.location.pathname])
    const count = useSelector(getCount(title))

    const setCount = useCallback((count) => {
        const {setEntityCount} = entityCountSlice.actions
        dispatch(setEntityCount({ title, count }))
    }, [title])

    return {count, setCount}
}
