import React from "react"

import {createPayments, completePayments} from "../api"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const SubmitReportPopup = ({
    month,
    includedUsers,
    excludedUsers,
    exchangeRate,
    currencies,
    refreshTable,
    setUserMessage,
    close,
}) => {

    const handleSubmit = (e) => {
        e.target.disabled = true

        const data = {
            date: month,
            included_users: includedUsers,
            excluded_users: excludedUsers,
            currencies: Object.keys(exchangeRate).map(key => ({
                currency_id: currencies[key].id,
                exchange_rate: Number(exchangeRate[key]),
            })),
        }

        createPayments(data)
            .then(({data: createdPayments}) => {
                const sendEmailData = {
                    payment_ids: createdPayments.map(payment => payment.id)
                }

                completePayments(sendEmailData)                
                    .then(() => {
                        close()
                        refreshTable()
                        setUserMessage({
                            message: "Payments were successfully created and emails were sucсessfully sent!",
                            code: SUCCESS_CODE
                        })                        
                    })
                    .catch(error => {
                        setUserMessage({message: error.response.data.message, code: ERROR_CODE})        
                        e.target.disabled = false
                    })
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                e.target.disabled = false
            })
    }

    return (
        <CustomPopup text_title="Submit Payment Report">
            <div className="popup-content paym-rep-popup-content t-s4">
                When you submit this payment report, it will sent email to employees and mark payments as paid.<br/>
                Do you want to submit this payment report?
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit}> SUBMIT </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default SubmitReportPopup
