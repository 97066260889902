import React from "react"
import Popup from "reactjs-popup"
import DeleteProductPopup from "./DeleteProductPopup"
import EditVariantsPopup from "./EditVariantsPopup"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import DefaultProductImage from "../../../assets/images/user_photo.png"
import Newcoin from "../../../toolkits/Newcoin"
import {PRODUCT_STATUS} from "../../../constants/other"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {EditProductRoute} from "../../../routes"
import {useTranslation} from "react-i18next"


const ProductsTable = ({
    products,
    refreshTable,
    setUserMessage,
    isDesktop,
    empty,
}) => {
    const { t } = useTranslation("common")

    const history = useCustomHistory()
    const productsList = products.map((product, i) => {
        const popupParams = {
            product: product,
            refreshTable: refreshTable,
            setUserMessage: setUserMessage,
        }

        const mainImage = product.images.length > 0 && product.images.find((image) => image.is_main === true)

        const tableActions = (
            <>
                {product.variants.length !== 0 &&
                    <Popup trigger={<span className="custom-scrollable-table-link">{t("Inventory")}</span>} modal>
                        {close => (
                            <EditVariantsPopup
                                {...popupParams}
                                close={close}
                            />
                        )}
                    </Popup>
                }

                <span onClick={(e) => {e.stopPropagation(), history.push(`${EditProductRoute.path.replace(":id", product.id)}`, {id: product.id, title: t(EditProductRoute.name)})}} className="custom-scrollable-table-link">
                    {t("Edit")}
                </span>

                <Popup trigger={<span className="custom-scrollable-table-link">{t("Delete")}</span>} modal>
                    {close => (
                        <DeleteProductPopup
                            {...popupParams}
                            close={close}
                        />
                    )}
                </Popup>
            </>
        )

        return (
            <tr onClick={() => history.push(`/product/${product.id}`, {title: product.name, edit: true})} key={`${i} ${product.id}`}
                className="custom-scrollable-table-row products-content-table-row">
                <td className="products-content-table-first-column">
                    <img src={mainImage?.image_url || DefaultProductImage} alt="Product Image"/>
                    <span className="t-s1">{product.name}</span>
                </td>
                <td>
                    <span className="t-b2">{product.category?.name}</span>
                </td>
                <td>
                    <span className={`t-b2 products-content-table-${[PRODUCT_STATUS.IN_STOCK, PRODUCT_STATUS.PRE_ORDER].includes(product.status) ? "green-span" : "red-span"}`}>
                        {t(product.status)}
                    </span>
                </td>
                <td>
                    <span className="t-b2 products-content-table-price">{product.raw_price} <Newcoin type={"default"} /></span>
                </td>
                {isDesktop &&
                <td className="products-content-table-last-column">
                    {product.status !== PRODUCT_STATUS.DELETED ? tableActions : null}
                    {!product.public && <span className="products-content-table-unpublished-label t-b3">{t("Unpublished")}</span>}
                </td>}
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="products-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">{t("Name")}</span></th>
                        <th><span className="custom-scrollable-table-head-span">{t("Category")}</span></th>
                        <th><span className="custom-scrollable-table-head-span">{t("Status")}</span></th>
                        <th><span className="custom-scrollable-table-head-span">{t("Price")}</span></th>
                        {isDesktop && <th><span className="custom-scrollable-table-head-span">{t("Actions")}</span></th>}
                    </tr>
                </thead>
                <tbody>
                    {productsList}
                    {empty && <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default ProductsTable
