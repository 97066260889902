import "./index.scss"

import React from "react"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"

const SliderInput = ({text_label = false, value, active, setActive}) => {

    return (
        <div className="toolkit-slider-input-wrapper">
            {text_label && <label className="toolkit-slider-input-label t-s3">{text_label}</label>}
            <input readOnly className="toolkit-slider-input t-s4" value={ value ? value : active ? "Enabled" : "Disabled"}/>
            <div className={value ? "" : "slider-div"}  onClick={() => setActive(!active)}>
                {active ? <ToggleOnIcon className="slider"/> : <ToggleOffIcon className="slider-inactive"/>}
            </div>
        </div>
    )
}

export default SliderInput