import {getSearchReports,} from "../api"
import {
    GET_USERS_REPORTS,
    SET_SELECTED_USER
} from "../const"


export const setSelectedUser = (selectedUser) => ({
    type: SET_SELECTED_USER,
    payload: {selectedUser}
})

const searchUsers = (users) => ({
    type: GET_USERS_REPORTS,
    payload: { users },
})


export const receiveReportsSearch = (find, cancelToken) => async (dispatch) => {
    try {
        const { data: receiveEmployeesData } = find !== "" ?  await getSearchReports(find, cancelToken) : {data: []}     
        dispatch(searchUsers(receiveEmployeesData))
    } catch (e) {
        console.log(e)
    }
}
