import "./index.scss"

import React from "react"
import Select from "react-select"


const DDFilter = ({title, defaultValue, onChange, options}) => {
    return (
        <div className="toolkit-dd-filter">
            <span className="toolkit-dd-filter-label t-s4">{title}:</span>
            <Select
                isSearchable={false}
                components={{
                    IndicatorSeparator: () => null
                }}
                classNamePrefix="t-s3 toolkit-dd-filter-react-select"
                defaultValue={defaultValue}
                onChange={onChange}
                onClick={(e) => e.stopPropagation()}
                options={options}
            />
        </div>
    )
}

export default DDFilter
