export const ONBOARDING_STATUS = {
    IN_PROGRESS: "IN PROGRESS",
    IN_REVIEW: "IN REVIEW",
    APPROVED: "APPROVED",
    DECLINED: "DECLINED",
}
export const NEWSOFT_SOCIAL_MEDIAS = {
    LINKEDIN_URL: "https://www.linkedin.com/company/newsoft-ns/",
    INSTAGRAM_URL: "https://www.instagram.com/newsoft_official/",
    FACEBOOK_URL: "https://www.facebook.com/newsoftns/",
    YOUTUBE_URL: "https://www.youtube.com/@newsoft_official",
}
export const ACQUAINTANCE_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSfWmzlKsCM5MCDP9vj77l2_z1bJHWiXUtsKb0shN_IgH5MsFA/alreadyresponded?vc=0&c=0&w=1&flr=0"
export const OOO_CALENDAR_LINK = "google-calendar"
export const ONBOARDING_STEPS = {
    WELCOME: {
        title: "Finish \"Welcome Popup\"",
        description: ""
    },
    ACQUAINTANCE_FORM: {
        title: "Fill out Acquaintace Form",
        description: [
            {type: "paragraph", content: "Please, fill out this form."},
            {
                type: "list-title",
                content: "The information you enter there will not be disclosed and will be used for:"
            },
            {
                type: "list",
                content: [
                    {content: "events organization;"},
                    {content: "expansion of the company's benefits;"},
                    {content: "introduction of new ideas."}
                ]
            },
            {type: "link", content: "Acquaintance Form", href: ACQUAINTANCE_FORM_URL},
        ]
    },
    CORPORATIVE_EMAIL: {
        title: "Sign In to corporative email",
        description: [
            {
                type: "paragraph",
                content: "Please sign in to your corporate email using the credentials provided to you. It's essential for staying up-to-date with company communications and participating in team discussions."
            }
        ]
    },
    SUBSCRIBE_TO_OOO_CALENDAR: {
        title: "Subscribe to OOO Calendar",
        description: [
            {
                type: "paragraph",
                content: "Connect to the OOO company calendar to stay organized by tracking your team’s time off. With this connection, you’ll see all time off in your Google Calendar, making it easier to plan your work effectively."
            },
            {type: "link", content: "subscribe", href: OOO_CALENDAR_LINK},
        ]
    },
    CALENDAR: {
        title: "Accept events in Google Calendar",
        description: [
            {
                type: "paragraph",
                content: "Don't forget to check your company's Google Calendar! There, you'll see all your upcoming meetings. Just click \"accept\" to confirm your attendance and stay in the loop with the team."
            }
        ]
    },
    SLACK: {
        title: "Setup Slack",
        description: [
            {
                type: "paragraph",
                content: "Please update your Slack profile by filling in your full name, position, and don't forget to add a profile picture. This helps everyone recognize and connect with you easily within our communication platform."
            }
        ]
    },
    LINKEDIN: {
        title: "Update your Current Role in LinkedIn",
        description: [
            {
                type: "paragraph",
                content: "Make sure to update your LinkedIn profile with your new position. Showcase your role and contributions to keep your professional network informed about the exciting changes in your career."
            }
        ]
    },
    SOCIAL_MEDIAS: {
        title: "Subscribe to our social medias",
        description: [
            {
                type: "paragraph",
                content: "Here is the link for all our social media channels, please subscribe to stay updated with the latest company news and events:"
            },
            {
                type: "list",
                content: [
                    {content: "LinkedIn", href: NEWSOFT_SOCIAL_MEDIAS.LINKEDIN_URL},
                    {content: "Instagram", href: NEWSOFT_SOCIAL_MEDIAS.INSTAGRAM_URL},
                    {content: "Facebook", href: NEWSOFT_SOCIAL_MEDIAS.FACEBOOK_URL},
                    {content: "YouTube", href: NEWSOFT_SOCIAL_MEDIAS.YOUTUBE_URL}
                ]
            }
        ]
    }
}
export const USEFUL_CONTACTS = {
    VOLODYMYR_TKACH: {
        name: "Volodymyr Tkach",
        position: "Co-Founder",
        photo: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/people-icons/VolodymyrTkach.webp",
        infoList: [
            "project management",
            "performance reviews",
            "client related questions"
        ]
    },
    KHRYSTYNA_SKOP: {
        name: "Khystyna Skop",
        position: "Head of Accounting",
        photo: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/people-icons/KhrystynaSkop.webp",
        infoList: [
            "payments",
            "taxes",
            "legal related questions"
        ]
    },
    OLEH_ROSHKA: {
        name: "Oleh Roshka",
        position: "Co-Founder",
        photo: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/people-icons/OlehRoshka.webp",
        infoList: [
            "technical questions",
            "performance reviews",
            "client related questions"
        ]
    },
    NIKA_BOEVA: {
        name: "Nika Boeva",
        position: "Head of People",
        photo: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/people-icons/NikaBoeva.webp",
        infoList: [
            "work related questions",
            "recruitment process questions",
            "equipment",
            "self development"
        ]
    }
}

export const OUR_TEAM_MEDIA = {
    video: {
        src: "https://www.youtube.com/embed/w8rK2l1ZZoA",
        type: "video",
        alt: "Our Team Video"
    },
    images: [
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team1.webp",
            alt: "Team Image 1"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team2.webp",
            alt: "Team Image 2"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team3.webp",
            alt: "Team Image 3"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team4.webp",
            alt: "Team Image 4"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team5.webp",
            alt: "Team Image 5"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team6.webp",
            alt: "Team Image 6"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team7.webp",
            alt: "Team Image 7"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team8.webp",
            alt: "Team Image 8"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/out_team9.webp",
            alt: "Team Image 9"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team10.webp",
            alt: "Team Image 10"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team11.webp",
            alt: "Team Image 11"
        },
        {
            src: "https://s3.amazonaws.com/staging.newsoft-website-v2/media/inside/our_team12.webp",
            alt: "Team Image 12"
        }
    ]
}

export const USEFUL_INFO = {
    ABOUT_US: {
        title: "About us",
        step: 0,
    },
    OUR_TEAM: {
        title: "Our team",
        step: 1,
    },
    OUR_PRINCIPLES: {
        title: "Our principles",
        step: 2,
    },
    BENEFITS: {
        title: "Benefits",
        step: 3,
    },
    PAYMENTS_AND_MEETING_ROOMS: {
        title: "Payments/Meeting rooms",
        step: 4,
    },
    OVERTIMES_AND_DAYS_OFF: {
        title: "Overtimes/Time off",
        step: 5,
    },
    USEFUL_CONTACTS: {
        title: "Useful contacts",
        step: 6,
    },
    OOO_COMPANY_CALENDAR: {
        title: "OOO company calendar",
        step: 7,
        isLink: true,
        link: OOO_CALENDAR_LINK
    },
}
