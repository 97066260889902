import {
    GET_USER_BONUSES_REQUEST,
    GET_USER_BONUSES_SUCCESS,
    GET_USER_BONUSES_FAILURE,
    GET_NEXT_PAGE_USER_BONUSES_REQUEST,
} from "../const"


const initialBonusState = {
    bonuses: [],
    total: 0,
    tableLoading: true,
}

const bonusHandlers = {
    [GET_USER_BONUSES_REQUEST]: (state) => ({...state, tableLoading: true, bonuses: [], total: 0}),
    [GET_USER_BONUSES_SUCCESS]: (state, {payload: {bonuses}}) => ({
        ...state,
        bonuses: [...state.bonuses, ...bonuses.items],
        total: bonuses.total,
        tableLoading: false,
    }),
    [GET_USER_BONUSES_FAILURE]: (state) => ({...state, tableLoading: false}),

    [GET_NEXT_PAGE_USER_BONUSES_REQUEST]: (state) => ({...state}),

    DEFAULT: (state) => state,
}

export const bonusReducer = (state = initialBonusState, action) => {
    const handle = bonusHandlers[action.type] || bonusHandlers.DEFAULT
    return handle(state, action)
}

