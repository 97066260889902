import React from "react"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import {formatDate} from "../../../utils/datetime.utils"
import { DD_MM_YYYY, MOBILE_WIDTH } from "../../../constants/other"
import CloseIcon from "@mui/icons-material/Close"
import { useMediaQuery } from "react-responsive"
import { displayHTML } from "../../../utils/other.utils"

const PerformanceReviewInfoPopup = ({performanceReview, close}) => {
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})

    return (
        <FlexiblePopup noStyle={true} text_title="Performance Review">
            <div className="review-info">
                <CloseIcon onClick={close} className="review-info-close"/>
                <span className={`review-info-title ${isMobile ? "t-s1" : "t-h2"}`}>Performance Review</span>
                <div className="review-info-date">
                    <span className="t-s2">{`(${performanceReview.created_at ? formatDate(performanceReview.created_at, DD_MM_YYYY).concat("-") : ""}${formatDate(performanceReview.date, DD_MM_YYYY)})`}</span>
                </div>
                {performanceReview.improvements && 
                <div className="review-info-goals">
                    <span className="review-info-goals-title t-s1">Goals</span>
                    <div className="review-info-goals-content t-s4" dangerouslySetInnerHTML={displayHTML(performanceReview.improvements)} />
                </div> }
                {performanceReview.comment && <div className="review-info-summary">
                    <span className="review-info-summary-title t-s1">Summary</span>
                    <div className="review-info-summary-content t-s4" dangerouslySetInnerHTML={displayHTML(performanceReview.comment)} />
                </div> }
            </div>
        </FlexiblePopup>
    )
}

export default PerformanceReviewInfoPopup
