import http from "../../http"

import {API_V1_URL} from "../../constants/other"


const PAYMENTS_URL = `${API_V1_URL}/payments/`


export const getPayments = (params, cancelToken) => http.get(PAYMENTS_URL, {params}, {}, cancelToken)

export const getPaymentReports = (params, cancelToken) => http.get(`${PAYMENTS_URL}reports`, {params}, {}, cancelToken)

export const getPaymentReportsTotal = (params, cancelToken) => {
    const urlParams = [{
        key: "month",
        param: params.month
    }].concat(
        params?.include_users === null
            ? []
            : params?.include_users?.length > 0
                ? params.include_users.map(user_id => ({
                    key: "include_users",
                    param: user_id
                }))
                : [{
                    key: "include_users",
                    param: null
                }]
    ).concat(
        params?.excluded_users === null
            ? []
            : params?.excluded_users?.length > 0
                ? params.excluded_users.map(user_id => ({
                    key: "excluded_users",
                    param: user_id
                }))
                : [{
                    key: "excluded_users",
                    param: null
                }]
    ).concat(
        params?.project_ids
            ? [{
                key: "project_ids",
                param: params.project_ids
            }]
            : []
    )

    return http.get(`${PAYMENTS_URL}reports/total?${urlParams.map((x) => `${x.key}=${x.param}`).join("&")}`, {}, {}, cancelToken)
}

export const createPayments = (data) => http.post(`${PAYMENTS_URL}`, {data})

export const completePayments = (data) => http.post(`${PAYMENTS_URL}complete`, {data})
