import React from "react"

import MultipleDDList from "../../../toolkits/MultipleDDList"
import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useCreateProjectMutation} from "../../../api/projects"
import useUserProjects from "../../../hooks/useUserProjects"


export const ProjectsDDList = ({userId, onChange, setUserMessage, setShowAssignProjectPopup, ...props}) => {
    const [createProject] = useCreateProjectMutation()

    const {projects: userProjectOptions} = useUserProjects({ userId: userId})

    return (
        <MultipleDDList
            creatable={true}
            onChange={onChange}
            username={props.username}
            onUpdate={(option) => {
                !userProjectOptions?.map(project => project.project.id).includes(option.value)
                    ? setShowAssignProjectPopup(option)
                    : null
            }}
            onCreateOption={(new_option) =>
                new Promise((resolve, reject) => {
                    createProject({name: new_option})
                        .unwrap()
                        .then((response) => {
                            setUserMessage({message: "Project was successfully created!", code: SUCCESS_CODE})
                            setShowAssignProjectPopup(response.data)
                            resolve({value: response.data.id, label: response.data.name})
                        })
                        .catch(error => {
                            setUserMessage({message: error.message, code: ERROR_CODE})
                            reject(error)
                        })
                })
            }
            {...props}
        />
    )
}

export default ProjectsDDList
