import {useMemo} from "react"
import {useGetTimeRecordsQuery, useGetUserTimeRecordsQuery} from "../api/timeRecords"


const useTimeRecords = ({ userId, ...params }, { skip } = {}) => {
    const {data: globalTimeData, isLoading: globalTimeRecordsLoading, isFetching: globalTimeRecordsFetching, refetch: globalRefetch} = useGetTimeRecordsQuery(params, { skip: skip || userId })
    const {data: userTimeData, isLoading: userTimeRecordsLoading, isFetching: userTimeRecordsFetching, refetch: userRefetch} = useGetUserTimeRecordsQuery({userId, params}, { skip: skip || !userId })

    const result = useMemo(() => {
        const isLoading = userId ? userTimeRecordsLoading : globalTimeRecordsLoading
        const isFetching = userId ? userTimeRecordsFetching : globalTimeRecordsFetching
        const data = userId ? userTimeData : globalTimeData
        const total = userId ? userTimeData?.total : globalTimeData?.total
        const timeRecords = (userId ? userTimeData?.items : globalTimeData?.items) || []
        const refetch = userId ? userRefetch : globalRefetch

        return {
            data,
            timeRecords,
            total,
            isLoading,
            isFetching,
            refetch
        }

    }, [userId, globalTimeData, userTimeData, globalTimeRecordsLoading, userTimeRecordsLoading, globalTimeRecordsFetching, userTimeRecordsFetching])

    return result
}

export default useTimeRecords