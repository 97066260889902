import React from "react"
import UserImage from "../../../../assets/images/user_photo.png"

const UserAvatar = ({ user, hasWriteUserPermission, editImageButtonEnabled, setShowCropImagePopup, setShowDefaultImagePopup, setEditImageButtonEnabled }) => {

    return (
        <div className="profile-user-avatar">
            <div
                className="profile-user-avatar-wrapper"
                onMouseEnter={() => setEditImageButtonEnabled(true)}
                onMouseLeave={() => setEditImageButtonEnabled(false)}
            >
                <img
                    src={user.image_url ? user.image_url : UserImage}
                    className={hasWriteUserPermission ? "clickable" : ""}
                    alt="user-photo"
                    style={hasWriteUserPermission && editImageButtonEnabled ? { opacity: 0.7 } : null}
                    onClick={() => user.image_url ? setShowCropImagePopup(true) : setShowDefaultImagePopup(true)}
                />
                <label
                    className={`profile-user-avatar-upload${!editImageButtonEnabled || !hasWriteUserPermission ? "-hidden" : ""} ${
                        hasWriteUserPermission ? "clickable" : ""
                    }`}
                ></label>
            </div>
            <div className="profile-user-avatar-name">
                <h3 className="t-h2 profile-font-leading">{user.first_name} {user.last_name}</h3>
                <span className="t-s4 profile-font-supporting">{user.job_position?.name}</span>
            </div>
        </div>
    )
}

export default UserAvatar
