import React from "react"
import "../index.scss"


import SmallButton from "../../../toolkits/SmallButton/SmallButton"


const DeleteProjectWarningPopup = ({close, project, username, handleDeleteProject}) => {

    return(
        <div className="delete-project-warning-popup">
            <span className=" t-h2">Are you sure?</span>
            <div className="delete-project-warning-popup-body t-s4">
                If {username} has recorded working hours for the {project} project and you delete this project from the current salary,
                any earnings linked to this project will not be reflected in reports. It is recommended to create a new salary for such
                adjustments. Are you sure you want to proceed with the deleting of the {project} project?
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleDeleteProject}> DELETE </SmallButton>
                </div>
            </div>
        </div>
    )
}

export default DeleteProjectWarningPopup
