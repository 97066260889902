import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {useDeleteOvertimeMutation} from "../../../api/overtime"


const DeleteOvertimePopup = ({username, overtime, setUserMessage, refreshTable, close}) => {
    const [deleteOvertime, {isLoading}] =useDeleteOvertimeMutation()

    const handleSubmit = () => {
        deleteOvertime(overtime.id)
            .unwrap()
            .then(() => {
                refreshTable()
                setUserMessage({message: "Overtime was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete ${username}'s overtime with coefficient ${overtime.coefficient}?`}
            isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteOvertimePopup
