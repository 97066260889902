import {getAuthorizedUserData} from "../../../utils/localstorage.utils"
import {
    FIREBASE_GET_USER_REQUEST,
    FIREBASE_GET_USER_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOCAL_STORAGE_REQUEST,
    LOCAL_STORAGE_SUCCESS
} from "../const"


const initialState = {
    user: null,
    loginRequested: false,
    loginError: "",
    localStorage: {
        privateMode: getAuthorizedUserData()?.privateMode,
    },
    // firebase custom attributes
    claims: {
        is_first_login: false,
    }
}

const handlers = {
    [FIREBASE_GET_USER_REQUEST]: (state) => ({...state}),
    [FIREBASE_GET_USER_SUCCESS]: (state, {payload: {user}}) => ({
        ...state,
        user
    }),

    [LOGIN_REQUEST]: (state) => ({...state, loginRequested: true, loginError: ""}),
    [LOGIN_SUCCESS]: (state, {payload: {claims}}) => ({
        ...state,
        claims,
        loginRequested: false,
        loginError: "OK"
    }),
    [LOGIN_FAILURE]: (state, {payload: {error}}) => ({
        ...state,
        loginRequested: false,
        loginError: error,
    }),

    [LOGOUT_REQUEST]: (state) => ({...state}),
    [LOGOUT_SUCCESS]: (state) => ({
        ...state,
        loginError: "",
    }),

    [LOCAL_STORAGE_REQUEST]: (state) => ({...state}),
    [LOCAL_STORAGE_SUCCESS]: (state, {payload: {localStorage}}) => ({
        ...state,
        localStorage,
    }),

    DEFAULT: (state) => state,
}

const authReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default authReducer
