import React from "react"
import SettingsEnabled from "../../../components/SettingsEnabled/SettingsEnabled"
import {SETTINGS} from "../../../constants/other"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import Newcoin from "../../../toolkits/Newcoin"
import useSpentCoins from "../../../hooks/useSpentCoins"
import {useTranslation} from "react-i18next"

const SpendCard = ({user}) => {
    const {t} = useTranslation("common")

    const { spent } = useSpentCoins({userId: user.id})

    return (
        <SettingsEnabled settingsNames={[SETTINGS.NEWCOINS_ENABLED]}>
            <div className="spend-card">
                <h3 className="t-s6 spend-card-title">{t("Spent").toUpperCase()}</h3>
                <h1 className="t-h1">
                    <SensitiveInfo className="sensitive-info">
                        <div className="spend-card-newcoins t-b5">
                            {Math.abs(spent)} <Newcoin type={"withdrawn"}/>
                        </div>
                    </SensitiveInfo>
                </h1>
            </div>
        </SettingsEnabled>
    )
}

export default SpendCard