import {SalaryHistoryRoute} from "../../../../routes"
import CustomLink from "../../../../toolkits/CustomLink"
import {SensitiveInfo} from "../../../../components/SensitiveInfo/SensitiveInfo"
import {SALARY_TYPES} from "../../../../constants/other"
import {isNotZero} from "../../../../utils/other.utils"
import React, {useContext} from "react"
import {useCustomHistory} from "../../../../hooks/useCustomHistory"
import {ThemeContext} from "../../../../styles/theme-context"
import InfoIcon from "@mui/icons-material/Pending"
import {useTranslation} from "react-i18next"

const MultipleSalaryCard = ({salaries, user}) => {
    const { t } = useTranslation("common")
    const history = useCustomHistory()
    const theme = useContext(ThemeContext)

    return (
        <div className={`profile-info-card clickable  ${theme.profileCardHover}`}>
            <div onClick={() => history.push(SalaryHistoryRoute.path, {user})}>
                <h3 className="t-h3 profile-font-leading">{t("SALARY")}</h3>

                <div className="profile-info-card-links">
                    <CustomLink
                        className="t-s3"
                        to={{
                            pathname: SalaryHistoryRoute.path,
                            state: {
                                user,
                            }
                        }}
                    ><InfoIcon className="profile-info-card-improvements clickable"/>
                    </CustomLink>
                </div>

                <SensitiveInfo value={
                    salaries.slice(-3).map((salary) =>
                        <div key={salary.id} className="profile-info-salary-row t-s1">
                            {`${salary.amount} ${salary.currency.iso_code}`}
                            {SALARY_TYPES.PER_HOUR === salary.salary_type &&
                                <small className="per-hour-text t-h4"> {t(salary?.salary_type)}</small>}
                            {SALARY_TYPES.FIXED_PRICE === salary.salary_type &&
                                <small className="per-hour-text t-h4"> {t(SALARY_TYPES.PER_MONTH)}</small>}
                            {isNotZero(salary.active_overtime_coefficient) &&
                                <span className="t-b3 text-top">{`x${salary.active_overtime_coefficient}`}</span>}
                        </div>
                    )
                }/>
            </div>
        </div>)
}

export default MultipleSalaryCard