import React, {useRef} from "react"
import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import UserImage from "../../../../assets/images/user_photo.png"
import ClosePopupIcon from "../../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import {ERROR_CODE} from "../../../../constants/other"

const UploadPhotoPopup = ({hasWriteUserPermission, editImageButtonEnabled, setShowCropImagePopup, setImage, setUserMessage, close}) => {
    const inputAvatarRef = useRef()

    const ImageUploadInput = () => {
        return (
            <input
                type="file"
                accept="image/*"
                ref={inputAvatarRef}
                onChange={(e) => {
                    const file = e.target.files[0]
                    if (file.type.startsWith("image/")){
                        setImage(URL.createObjectURL(file))
                        hasWriteUserPermission && setShowCropImagePopup(true)
                    } else {
                        setUserMessage({
                            message: "File format isn't accepted",
                            code: ERROR_CODE
                        })
                    }
                }}
                disabled={!hasWriteUserPermission}
            />
        )
    }


    return (
        <CustomPopup text_title={"Upload Photo"} close={close}>
            <div className="default-photo-content">
                <img src={UserImage} className={hasWriteUserPermission ? "clickable" : ""} alt="user-photo" style={hasWriteUserPermission && editImageButtonEnabled ? {opacity: 0.7} : null} onClick={() => inputAvatarRef.current.click()}
                />
                <label
                    className={`profile-user-avatar-upload${!editImageButtonEnabled || !hasWriteUserPermission ? "-hidden" : ""} ${
                        hasWriteUserPermission ? "clickable" : ""}`
                    }
                >
                    <ImageUploadInput/>
                </label>
                <label className="small-button-secondary t-h3">
                    <ImageUploadInput/>
                    Upload
                </label>
            </div>
            <ClosePopupIcon onClick={close}/>
        </CustomPopup>
    )
}

export default UploadPhotoPopup