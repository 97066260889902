import React from "react"

import MenuIcon from "@mui/icons-material/Menu"


const MenuButton = ({...props}) => {
    return (
        <div className="menu-button" {...props}>
            <MenuIcon className="menu-icon"/>
        </div>
    )
}

export default MenuButton
