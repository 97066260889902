import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import InfiniteScroll from "react-infinite-scroll-component"

import Protected from "../../components/Protected/Protected"
import EquipmentCategoriesTable from "./components/EquipmentCategoriesTable"
import CreateUpdateCategoryPopup from "./components/CreateUpdateCategoryPopup"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PERMISSIONS} from "../../constants/permissions"

import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetEquipmentCategoriesQuery} from "../../api/equipment"


const EquipmentCategories = () => {

    const [addNewCategoryPopup, setAddNewCategoryPopup] = useState(false)
    const [searchKey, setSearchKey] = useState("")
    const [userMessage, setUserMessage] = useState(null)

    const {
        items, loadNextPage, hasMore, changeParams, reset, empty
    } = usePaginatedQuery(
        useGetEquipmentCategoriesQuery,
        {
            defaultState: {
                params: { name: searchKey }
            }
        }
    )

    useEffect(() =>
        changeParams({name: searchKey}, false), [find, searchKey]
    )

    return (
        <>
            <Helmet>
                <title>Equipment Categories - Newsoft Inside</title>
            </Helmet>

            <div className="equipment-categories">
                {userMessage &&
                    <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                }
                <div className="equipment-categories-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500} />
                    <Protected permissions={[PERMISSIONS.WRITE_EQUIPMENT]}>
                        <div className="equipment-categories-header-button">
                            <SmallButton onClick={() => setAddNewCategoryPopup(true)}> ADD NEW CATEGORY </SmallButton>
                        </div>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                >
                    <div className="equipment-categories-content">
                        <EquipmentCategoriesTable
                            equipmentCategories={items}
                            refreshTable={reset}
                            setUserMessage={setUserMessage}
                            empty={empty}
                        />
                    </div>
                </InfiniteScroll>
            </div>

            <Popup open={addNewCategoryPopup} closeOnDocumentClick={false} onClose={() => setAddNewCategoryPopup(false)} modal>
                {close => (
                    <CreateUpdateCategoryPopup
                        refreshTable={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>)
}

export default EquipmentCategories
