import React, { useState, useRef, useEffect } from "react"
import InfoIcon from "@mui/icons-material/Info"

import "./index.scss"

const InfoMessage = ({text}) => {
    const [anchor, setAnchor] = useState(false)
    const popupRef = useRef()

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setAnchor(false)
        }
    }
    
    useEffect(() => {
        if (anchor) {
            document.addEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [anchor])

    return (
        <div className="info-message" ref={popupRef}>
            <InfoIcon className="info-message-icon" onClick={() => setAnchor(!anchor)}/>

            {anchor ? 
                <div className="info-message-content">
                    <p className="t-b1">{text}</p>
                </div> :
                <></>
            }
        </div>
    )
}

export default InfoMessage
