import {getUserSalaries} from "../api"

import {
    GET_NEXT_PAGE_USER_SALARIES_REQUEST,
    GET_USER_SALARIES_FAILURE,
    GET_USER_SALARIES_REQUEST,
    GET_USER_SALARIES_SUCCESS,
} from "../const"


const request = () => ({type: GET_USER_SALARIES_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_USER_SALARIES_REQUEST})

const success = (salaries) => ({
    type: GET_USER_SALARIES_SUCCESS,
    payload: {salaries},
})

const failure = () => ({type: GET_USER_SALARIES_FAILURE})


export const receiveUserSalaries = (user_id, params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveUserSalariesData} = await getUserSalaries(user_id, params)

        dispatch(success(receiveUserSalariesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageUserSalaries = (user_id, params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveUserSalariesData} = await getUserSalaries(user_id, params)
        dispatch(success(receiveUserSalariesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
