import PendingIcon from "@mui/icons-material/Pending"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import React from "react"
import {hoursToString} from "../../../utils/datetime.utils"
import {roundEarn} from "../../../utils/other.utils"

const IncomeCard = ({income, title, showZeroIncome, withPlus, setInfoPopup, isWithoutOvertimeBonusesText={}}) => {
    const isSingleOvertimeRecord = income.length === 1 && title === "Overtimes"

    return (
        <div className="reports-info-card-big">
            {income.length > 0 && !isSingleOvertimeRecord && <PendingIcon
                className="reports-info-card-big-expand-icon clickable"
                onClick={() => setInfoPopup(true)}
            />}
            <div className="reports-info-card-header">
                <span className="t-h3">{title}</span>
                {Object.values(isWithoutOvertimeBonusesText).some(value => value !== 0) ?
                    <span className="reports-info-card-label t-b4">
                        {income.length ? `WITHOUT ${isWithoutOvertimeBonusesText.overtime && isWithoutOvertimeBonusesText.bonuses ? "OVERTIME AND BONUSES" :
                            isWithoutOvertimeBonusesText.overtime ?  "OVERTIME" : "BONUSES"} `: ""}
                    </span> : <></>}
            </div>
            {
                income.length > 0
                    ? <SensitiveInfo value={income.slice(0, 2).map((income_by_currency, j) => <div key={`${title}-${income_by_currency.currency.iso_code}-${j}`}>
                        <span className={`reports-info-main-text ${income.length > 1 ? "t-h2" : "t-h1"}`}>
                            {`${withPlus ? "+ " : ""}${roundEarn(income_by_currency.amount)} ${income_by_currency.currency.iso_code}`}
                        </span>
                        {isSingleOvertimeRecord ?
                            <div className="reports-info-card-bottom">
                                <>
                                    <span className="reports-info-card-bottom-hours t-s1">{hoursToString(income_by_currency.hours)}</span>
                                    <span className="reports-info-card-bottom-rate t-s1">
                                        {roundEarn(income_by_currency.rate)}&nbsp;{income_by_currency.currency.iso_code}
                                        <span className="reports-info-card-bottom-salary-type t-h4">per hour</span>
                                        <span className="reports-info-card-bottom-coefficient t-b3">{`x${income_by_currency.coefficient.toFixed(1)}`}</span>
                                    </span>
                                </>
                            </div>
                            : <></>}
                    </div>
                    )}/>
                    : <>
                        {showZeroIncome ?
                            <SensitiveInfo value={
                                <span className="t-h1 reports-info-main-text">0</span>
                            }/> : <></>}
                    </>
            }
        </div>)
}

export default IncomeCard