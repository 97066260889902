import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import { INFO_ALERT, SUCCESS_CODE, MIN_ALERT_DURATION } from "../../constants/other"

// size = "inherit" | "small | "medium" | "large"
// setUserMessage is deprecated. Provide proper onCopy function to show any form of feedback
const CopyButton = ({value, setUserMessage, type, onCopy, children, size="inherit" }) => {
    return (
        <span onClick={(e) => e.stopPropagation()}>
            <CopyToClipboard text={value} onCopy={() => {
                onCopy?.()
                setUserMessage?.({message: `${type || ""} copied!`, code: SUCCESS_CODE, type: INFO_ALERT, duration: MIN_ALERT_DURATION})
            }}>
                {children || <span className="clickable copy-icon"><ContentCopyIcon fontSize={size} /></span>}
            </CopyToClipboard>
        </span>
    )
}

export default CopyButton
