import React from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {useMarkCalendarAsDefaultMutation} from "../../../api/publicHolidayCalendars"


const MarkCalendarAsDefaultPopup = ({calendar, setUserMessage, close}) => {
    const [markCalendarAsDefault, {isLoading}] = useMarkCalendarAsDefaultMutation()

    const handleSubmit = () => {
        markCalendarAsDefault(calendar.id)
            .unwrap()
            .then(() => {
                close()
                setUserMessage({message: "Calendar was successfully marked as default!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={"Mark as Default"} isAgreePopup>
            <div className="calendars-popup-subtitle t-b1">Do you want to mark as default the `{calendar.name}` calendar?</div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> SAVE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default MarkCalendarAsDefaultPopup
