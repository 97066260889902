import React from "react"

import {ACQUAINTANCE_FORM_URL} from "../../../constants/onboarding"

const AcquaintanceFormPopup = () => (
    <div className="greetings-onboarding-content-text t-b5">
        <p>
            <span>It&#39;s time to tell a little more about yourself 😎<br/></span>
            <span>You will get an email with the <a href={ACQUAINTANCE_FORM_URL}>Acquaintance Form</a>. Please, fill out this form within the next 3 days.</span>
        </p>
        <p>
            <span>The information you enter there will not be disclosed and will be used for:</span>
            <ul>
                <li>events organization;</li>
                <li>expansion of the company&#39;s benefits;</li>
                <li>introduction of new ideas.</li>
            </ul>
        </p>
        <p>
            So try to share everything that you think might be interesting.
        </p>
    </div>
)

export default AcquaintanceFormPopup
