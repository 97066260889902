import React, {useEffect, useMemo} from "react"

import DefaultUserPhoto from "../../../assets/images/user_photo.png"
import {usePaginatedQuery} from "../../../hooks/common/usePaginatedQuery"
import Table from "../../../components/Table/Table"
import {useGetOrdersQuery} from "../../../api/shop"
import {PRODUCT_STATUS, PURCHASE_REQUEST_STATUS} from "../../../constants/other"
import Newcoin from "../../../toolkits/Newcoin"
import InfoPopup from "../../../components/InfoPopup"
import Popup from "reactjs-popup"
import ApprovePurchaseRequestPopup from "./ApprovePurchaseRequestPopup"


export const PurchaseRequestsTable = ({ searchKey, statusFilter, setUserMessage }) => {
    const { items: auditLogs, loading, hasMore, loadNextPage, changeParams } = usePaginatedQuery(
        useGetOrdersQuery,
        {
            defaultState: {
                params: {username: searchKey, status: statusFilter}
            }
        }
    )

    useEffect(() => {
        changeParams({ username: searchKey, status: statusFilter })
    }, [searchKey, statusFilter])

    const columns = useMemo(() => [
        {
            field: "id",
            hidden: true,
            isKey: true,
        },
        {
            field: "date",
            title: "Employee",
            type: "custom",
            headerClassName: "purchase-requests-content-table-header-employee",
            bodyClassName: "purchase-requests-content-table-body-employee",
            get: (rec) => {
                return (
                    <div>
                        <img src={rec.user.image_url || DefaultUserPhoto} alt="User Photo"/>
                        <span>
                            <span className="t-s1">{rec.user.first_name}</span>
                            <span className="t-s1">{rec.user.last_name}</span>
                        </span>
                    </div>
                )
            },
        },
        {
            field: "created_at",
            title: "Date",
            type: "date",
        },
        {
            field: "",
            title: "Purchase (Description)",
            type: "custom",
            headerClassName: "purchase-requests-content-table-header-description",
            bodyClassName: "purchase-requests-content-table-body-description",
            get: (rec) => (
                <>
                    <div className="t-s1">{rec.product_variant.product.name}</div>
                    <span className="t-b2">{rec.product_variant.formatted_options}</span>
                </>
            ),
        },
        {
            field: "status",
            title: "Status (Delivery Time)",
            type: "custom",
            headerClassName: "purchase-requests-content-table-header-status",
            get: (rec) =>  rec.status === PURCHASE_REQUEST_STATUS.IN_REVIEW && (
                <div className="purchase-requests-content-table-status">
                    <span className={`t-b2 purchase-requests-content-table-${[PRODUCT_STATUS.IN_STOCK, PRODUCT_STATUS.PRE_ORDER].includes(rec.product_variant.product.status) ? "green-span" : "red-span"}`}>{rec.product_variant.product.status}</span>
                    <span>{rec.product_variant.product.pre_order_delivery_time ? `(${rec.product_variant.product.pre_order_delivery_time})` : ""}</span>
                </div>
            )
        },
        {
            field: "amount",
            title: "Amount",
            type: "custom",
            headerClassName: "purchase-requests-content-table-header-amount",
            get: (rec) =>  <span className="t-b2">{rec.quantity}</span>
        },
        {
            field: "total_price",
            title: "Total Price",
            type: "custom",
            headerClassName: "purchase-requests-content-table-header-price",
            bodyClassName: "purchase-requests-content-table-body-price",
            get: (rec) => (
                <span className=" t-b2">{rec.total_price} <Newcoin type={"default"}/></span>
            )
        },
        {
            field: "reason",
            title: "Reason",
            type: "custom",
            headerClassName: "purchase-requests-content-table-body-reason",
            get: (rec) => (
                <Popup trigger={<span className="clickable t-b2"> {rec.reason} </span>} modal>
                    {close => (
                        <InfoPopup
                            title="Purchase Request Reason"
                            value={rec.reason}
                            close={close}
                        />
                    )}
                </Popup>
            )
        },
        {
            title: "Actions",
            type: "custom",
            headerClassName: "purchase-requests-content-table-header-actions",
            bodyClassName: "purchase-requests-content-table-body-actions",
            get: (rec) => {
                if (rec.status === PURCHASE_REQUEST_STATUS.IN_REVIEW) {
                    return (<>
                        <Popup trigger={<span className="custom-scrollable-table-link"> decline </span>} modal>
                            {close => (
                                <ApprovePurchaseRequestPopup
                                    setUserMessage={setUserMessage}
                                    purchaseRequest={rec}
                                    approve={false}
                                    close={close}
                                />
                            )}
                        </Popup>
                        <Popup trigger={<span className="custom-scrollable-table-link"> approve </span>} modal>
                            {close => (
                                <ApprovePurchaseRequestPopup
                                    setUserMessage={setUserMessage}
                                    purchaseRequest={rec}
                                    approve={true}
                                    close={close}
                                />
                            )}
                        </Popup>
                    </>)
                }
                if (rec.status === PURCHASE_REQUEST_STATUS.APPROVED) {
                    return <span className="purchase-requests-content-table-green-span t-h3">APPROVED</span>
                }
                return <span className="purchase-requests-content-table-red-span t-h3">DECLINED</span>
            }
        }
    ], [setUserMessage])

    return <Table
        items={auditLogs}
        columns={columns}
        loading={loading}
        hasMore={hasMore}
        loadNextPage={loadNextPage}
        setUserMessage={setUserMessage}
        variant="small"
    />
}


export default PurchaseRequestsTable
