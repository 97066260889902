import React, {useState} from "react"

import {updateHiringDate} from "../../api"

import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../../constants/other"
import DeprecatedSingleDatePicker from "../../../../toolkits/Calendar/DeprecatedSingleDatePicker"

const FirstDayPopup = ({
    close, 
    day,
    userId,
    setUserMessage,
    receiveUserInfo,
}) => {
    const [newDate, setNewDate] = useState(null)

    const handleSubmit = () => {
        const formattedDate = newDate.split(".").reverse().join("-")
        updateHiringDate(userId, {hiring_date: formattedDate})
            .then(() => {
                receiveUserInfo()
                close(false)
                setUserMessage({message: "First day was successfully changed!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup
            wrapperClass="custom-popup-card-unscrollable-wrapper"
            className="first-day-popup"
            text_title="Edit First Day"
        >
            <DeprecatedSingleDatePicker
                label="First day"
                className="single-datepicker-field"
                defaultValue={day}
                setNewDate={setNewDate}
            /> 
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={() => close(false)}>CANCEL</SmallButton>
                    <SmallButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!newDate || day === newDate.split(".").reverse().join("-")}
                    >
                        SAVE
                    </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default FirstDayPopup
