export const GET_USER_SALARIES_REQUEST = "GET_USER_SALARIES_REQUEST"
export const GET_USER_SALARIES_SUCCESS = "GET_USER_SALARIES_SUCCESS"
export const GET_USER_SALARIES_FAILURE = "GET_USER_SALARIES_FAILURE"
export const GET_NEXT_PAGE_USER_SALARIES_REQUEST = "GET_NEXT_PAGE_USER_SALARIES_REQUEST"

export const GET_USER_BONUSES_REQUEST = "GET_USER_BONUSES_REQUEST"
export const GET_USER_BONUSES_SUCCESS = "GET_USER_BONUSES_SUCCESS"
export const GET_USER_BONUSES_FAILURE = "GET_USER_BONUSES_FAILURE"
export const GET_NEXT_PAGE_USER_BONUSES_REQUEST = "GET_NEXT_PAGE_USER_BONUSES_REQUEST"

export const GET_USER_NEWCOINS_TRANSACTIONS_REQUEST = "GET_USER_NEWCOINS_TRANSACTIONS_REQUEST"
export const GET_USER_NEWCOINS_TRANSACTIONS_SUCCESS = "GET_USER_NEWCOINS_TRANSACTIONS_SUCCESS"
export const GET_USER_NEWCOINS_TRANSACTIONS_FAILURE = "GET_USER_NEWCOINS_TRANSACTIONS_FAILURE"
export const GET_NEXT_PAGE_USER_NEWCOINS_TRANSACTIONS_REQUEST = "GET_NEXT_PAGE_USER_NEWCOINS_TRANSACTIONS_REQUEST"