import NavsLayout from "../layouts/Navs/NavsLayout"
import AuthLayout from "../layouts/Auth/AuthLayout"

import {Login, ResetPassword} from "../pages/Auth"
import Profile from "../pages/Profile"
import TimeTracker from "../pages/TimeTracker"
import Employees from "../pages/Employees"
import Equipment from "../pages/Equipment"
import Settings from "../pages/Settings"
import TimeOffRequests from "../pages/TimeOffRequests"
import PerformanceReviews from "../pages/PerformanceReviews"
import TimeRecords from "../pages/TimeRecords"
import Reports from "../pages/Reports"
import Error from "../pages/Error"
import Dashboard from "../pages/Dashboard"
import TimeOffBalances from "../pages/TimeOffBalances"
import Projects from "../pages/Projects"
import EmployeePositions from "../pages/EmployeePositions"
import EquipmentCategories from "../pages/EquipmentCategories"
import Roles from "../pages/Roles"
import Clients from "../pages/Clients"
import SalaryHistory from "../pages/SalaryHistory"
import PaymentReports from "../pages/PaymentReports"
import OnboardingRequests from "../pages/OnboardingRequests"
import NewcoinsBalances from "../pages/NewcoinsBalances"
import PurchaseRequests from "../pages/PurchaseRequests"

import {DASHBOARD_PERMISSIONS, PERMISSIONS, USER_PROFILE_PERMISSIONS} from "../constants/permissions"
import {SETTINGS} from "../constants/other"
import Products from "../pages/Products"
import CreateProduct from "../pages/CreateProduct"
import Shop from "../pages/Shop"
import ViewProduct from "../pages/ViewProduct"
import PersonalPurchaseHistory from "../pages/PersonalPurchaseHistory"
import Coupons from "../pages/Coupons"
import ClaimCoupon from "../pages/ClaimCoupon"
import AuditLogs from "../pages/AuditLogs"
import TimeRecordsRequests from "../pages/TimeRecordsRequests"
import {Navigate, useRoutes} from "react-router-dom"
import React from "react"
import ProtectedRoute from "./ProtectedRoute"
import GoogleIntegrations from "../pages/GoogleIntegrations"
import PublicHolidayCalendars from "../pages/PublicHolidayCalendars"

export const ErrorRoute = {
    path: "/error",
    name: "Error",
}

export const LoginRoute = {
    path: "login",
    name: "Login",
}

export const ResetPasswordRoute = {
    path: "/reset-password",
    name: "Reset password",
}

export const ProfileRoute = {
    path: "/",
    name: "Profile",
    permissions: [],
}

export const UserProfileRoute = {
    path: "/profile/:id/",
    name: "User profile",
    permissions: USER_PROFILE_PERMISSIONS,
}

export const EmployeesRoute = {
    path: "/employees",
    name: "Employees",
    permissions: [PERMISSIONS.READ_USER]
}


export const TimeTrackerRoute = {
    path: "/time-tracker",
    name: "Time Tracker",
    permissions: [
        PERMISSIONS.READ_OWN_TIME_RECORD,
        PERMISSIONS.WRITE_OWN_TIME_OFF_REQUEST,
    ]
}

export const EquipmentRoute = {
    path: "/equipment",
    name: "Equipment",
    permissions: [PERMISSIONS.READ_EQUIPMENT],
}

export const EquipmentCategoriesRoute = {
    path: "/equipment-categories",
    name: "Equipment Categories",
    permissions: [PERMISSIONS.READ_EQUIPMENT],
}

export const SettingsRoute = {
    path: "/settings",
    name: "Settings",
    permissions: [PERMISSIONS.WRITE_SETTINGS],
}


export const AuditLogsRoute = {
    path: "/audit-logs",
    name: "Audit Logs",
    permissions: [PERMISSIONS.READ_AUDIT_LOGS],
}


export const TimeOffRequestsRoute = {
    path: "/time-off-requests",
    name: "Time Off Requests",
    permissions: [
        PERMISSIONS.READ_TIME_OFF_REQUEST,
        PERMISSIONS.READ_OWN_TIME_OFF_REQUEST,
    ]
}

export const PerformanceReviewsRoute = {
    path: "/performance-reviews",
    name: "Performance Reviews",
    permissions: [
        PERMISSIONS.READ_PERFORMANCE_REVIEW,
        PERMISSIONS.READ_OWN_PERFORMANCE_REVIEW,
    ]
}

export const ReportsRoute = {
    path: "/reports",
    name: "Time Reports",
    permissions: [PERMISSIONS.READ_OWN_REPORTS,]
}

export const UserReportsRoute = {
    path: "/time-reports",
    name: "Time Reports",
    permissions: [PERMISSIONS.READ_REPORTS,]
}

export const TimeRecordsRoute = {
    path: "/time-records",
    name: "Time Records",
    permissions: [
        PERMISSIONS.READ_TIME_RECORD,
        PERMISSIONS.READ_OWN_TIME_RECORD,
    ]
}

export const TimeRecordRequestsRoute = {
    path: "/time-record-requests",
    name: "Time Record Requests",
    permissions: [PERMISSIONS.READ_TIME_RECORD],
}

export const OnboardingRequestsRoute = {
    path: "/onboarding-requests",
    name: "Onboarding Requests",
    permissions: [PERMISSIONS.READ_ONBOARDING, PERMISSIONS.WRITE_ONBOARDING],
}

export const DashboardRoute = {
    path: "/dashboard",
    name: "Dashboard",
    permissions: DASHBOARD_PERMISSIONS,
}

export const TimeOffBalancesRoute = {
    path: "/time-off-balances",
    name: "Time Off Balance",
    permissions: [PERMISSIONS.READ_TIME_OFF_BALANCE],
}

export const ProjectsRoute = {
    path: "/projects",
    name: "Projects",
    permissions: [PERMISSIONS.READ_PROJECT],
}

export const EmployeePositionsRoute = {
    path: "/employee-positions",
    name: "Employee Positions",
    permissions: [PERMISSIONS.READ_EMPLOYEE_POSITION],
}

export const PublicDayOffsRoute = {
    path: "/public-day-offs",
    name: "National Days Off",
    permissions: [PERMISSIONS.READ_CALENDAR_EVENTS],
}

export const SalaryHistoryRoute = {
    path: "/salary-history",
    name: "Payments",
    permissions: [
        PERMISSIONS.READ_OWN_SALARY,
        PERMISSIONS.READ_SALARY,
        PERMISSIONS.READ_OWN_BONUS,
        PERMISSIONS.READ_BONUS,
    ],
}

export const RolesRoute = {
    path: "/roles",
    name: "Roles",
    permissions: [PERMISSIONS.READ_ROLE],
}

export const ClientsRoute = {
    path: "/clients",
    name: "Clients",
    permissions: [PERMISSIONS.READ_CLIENT],
}

export const PaymentReportsRoute = {
    path: "/payment-reports",
    name: "Payment Reports",
    permissions: [PERMISSIONS.READ_PAYMENT_REPORTS],
}

export const NewcoinsBalancesRoute = {
    path: "/newcoins-balance",
    name: "Newcoins Balance",
    permissions: [PERMISSIONS.READ_NEWCOINS],
    settingsRequired: [SETTINGS.NEWCOINS_ENABLED]
}

export const CouponsRoute = {
    path: "/coupons",
    name: "Coupons",
    permissions: [PERMISSIONS.READ_COUPONS],
    settingsRequired: [SETTINGS.NEWCOINS_ENABLED]
}

export const ClaimCouponRoute = {
    path: "/coupons/claim",
    name: "Claim Coupon",
    permissions: [PERMISSIONS.READ_OWN_COUPONS],
    settingsRequired: [SETTINGS.NEWCOINS_ENABLED]
}

export const ProductsRoute = {
    path: "/products",
    name: "Products",
    permissions: [PERMISSIONS.WRITE_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const CreateProductRoute = {
    path: "/create-product",
    name: "Create Product",
    permissions: [PERMISSIONS.WRITE_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const EditProductRoute = {
    path: "/edit-product/:id",
    name: "Edit Product",
    permissions: [PERMISSIONS.WRITE_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const PurchaseRequestsRoute = {
    path: "/purchase-requests",
    name: "Purchase Requests",
    permissions: [PERMISSIONS.READ_PURCHASE_REQUEST],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const PersonalPurchaseHistoryRoute = {
    path: "/purchase-history/:id",
    name: "Purchase History",
    permissions: [PERMISSIONS.READ_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const ShopRoute = {
    path: "/shop",
    name: "Shop",
    permissions: [PERMISSIONS.READ_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const ViewProductRoute = {
    path: "/product/:id",
    name: "View Product",
    permissions: [PERMISSIONS.READ_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const GoogleIntegrationRoute = {
    path: "/integrations/google",
    name: "Google Integration",
    permissions: [PERMISSIONS.READ_INTEGRATIONS],
}

export const PublicHolidayCalendarsRoute = {
    path: "/calendars",
    name: "Public Holiday Calendars",
    permissions: [PERMISSIONS.READ_CALENDAR_EVENTS],
}


export const routes = [
    LoginRoute,
    AuditLogsRoute,
    ResetPasswordRoute,
    ProfileRoute,
    UserProfileRoute,
    TimeTrackerRoute,
    EmployeesRoute,
    EquipmentRoute,
    SettingsRoute,
    TimeOffRequestsRoute,
    PerformanceReviewsRoute,
    TimeRecordsRoute,
    TimeRecordRequestsRoute,
    OnboardingRequestsRoute,
    ReportsRoute,
    UserReportsRoute,
    ErrorRoute,
    DashboardRoute,
    TimeOffBalancesRoute,
    ProjectsRoute,
    EmployeePositionsRoute,
    EquipmentCategoriesRoute,
    PublicDayOffsRoute,
    SalaryHistoryRoute,
    RolesRoute,
    ClientsRoute,
    PaymentReportsRoute,
    NewcoinsBalancesRoute,
    ProductsRoute,
    CreateProductRoute,
    EditProductRoute,
    PurchaseRequestsRoute,
    PersonalPurchaseHistoryRoute,
    ShopRoute,
    ViewProductRoute,
    CouponsRoute,
    ClaimCouponRoute,
    GoogleIntegrationRoute,
    PublicHolidayCalendarsRoute,
]


const AppRoutes = () => {
    return useRoutes([
        { path: LoginRoute.path, element: <AuthLayout><Login/></AuthLayout> },
        { path: ResetPasswordRoute.path, element: <AuthLayout><ResetPassword/></AuthLayout> },
        { path: ErrorRoute.path, element: <Error/> },
        { path: "/", element: <ProtectedRoute><NavsLayout/></ProtectedRoute>, children: [
            { index: true, element: <ProtectedRoute permissions={ProfileRoute.permissions}><Profile/></ProtectedRoute> },
            { path: UserProfileRoute.path, element: <ProtectedRoute permissions={UserProfileRoute.permissions}><Profile/></ProtectedRoute> },
            { path: EmployeesRoute.path, element: <ProtectedRoute permissions={EmployeesRoute.permissions}><Employees/></ProtectedRoute> },
            { path: DashboardRoute.path, element: <ProtectedRoute permissions={DashboardRoute.permissions}><Dashboard/></ProtectedRoute> },
            { path: TimeTrackerRoute.path, element: <ProtectedRoute permissions={TimeTrackerRoute.permissions}><TimeTracker/></ProtectedRoute> },
            { path: EquipmentRoute.path, element: <ProtectedRoute permissions={EquipmentRoute.permissions}><Equipment/></ProtectedRoute> },
            { path: EquipmentCategoriesRoute.path, element: <ProtectedRoute permissions={EquipmentCategoriesRoute.permissions}><EquipmentCategories/></ProtectedRoute> },
            { path: SettingsRoute.path, element: <ProtectedRoute permissions={SettingsRoute.permissions}><Settings/></ProtectedRoute> },
            { path: TimeOffRequestsRoute.path, element: <ProtectedRoute permissions={TimeOffRequestsRoute.permissions}><TimeOffRequests/></ProtectedRoute> },
            { path: PerformanceReviewsRoute.path, element: <ProtectedRoute permissions={PerformanceReviewsRoute.permissions}><PerformanceReviews/></ProtectedRoute> },
            { path: PaymentReportsRoute.path, element: <ProtectedRoute permissions={PaymentReportsRoute.permissions}><PaymentReports/></ProtectedRoute> },
            { path: ReportsRoute.path, element: <ProtectedRoute permissions={ReportsRoute.permissions}><Reports/></ProtectedRoute> },
            { path: UserReportsRoute.path, element: <ProtectedRoute permissions={UserReportsRoute.permissions}><Reports/></ProtectedRoute> },
            { path: TimeRecordsRoute.path, element: <ProtectedRoute permissions={TimeRecordsRoute.permissions}><TimeRecords/></ProtectedRoute> },
            { path: TimeRecordRequestsRoute.path, element: <ProtectedRoute permissions={TimeRecordRequestsRoute.permissions}><TimeRecordsRequests/></ProtectedRoute> },
            { path: OnboardingRequestsRoute.path, element: <ProtectedRoute permissions={OnboardingRequestsRoute.permissions}><OnboardingRequests/></ProtectedRoute> },
            { path: TimeOffBalancesRoute.path, element: <ProtectedRoute permissions={TimeOffBalancesRoute.permissions}><TimeOffBalances/></ProtectedRoute> },
            { path: ProjectsRoute.path, element: <ProtectedRoute permissions={ProjectsRoute.permissions}><Projects/></ProtectedRoute> },
            { path: ClientsRoute.path, element: <ProtectedRoute permissions={ClientsRoute.permissions}><Clients/></ProtectedRoute> },
            { path: EmployeePositionsRoute.path, element: <ProtectedRoute permissions={EmployeePositionsRoute.permissions}><EmployeePositions/></ProtectedRoute> },
            { path: SalaryHistoryRoute.path, element: <ProtectedRoute permissions={SalaryHistoryRoute.permissions}><SalaryHistory/></ProtectedRoute> },
            { path: RolesRoute.path, element: <ProtectedRoute permissions={RolesRoute.permissions}><Roles/></ProtectedRoute> },
            { path: NewcoinsBalancesRoute.path, element: <ProtectedRoute permissions={NewcoinsBalancesRoute.permissions}><NewcoinsBalances/></ProtectedRoute> },
            { path: CouponsRoute.path, element: <ProtectedRoute permissions={CouponsRoute.permissions}><Coupons/></ProtectedRoute> },
            { path: ClaimCouponRoute.path, element: <ProtectedRoute permissions={ClaimCouponRoute.permissions}><ClaimCoupon/></ProtectedRoute> },
            { path: ProductsRoute.path, element: <ProtectedRoute permissions={ProductsRoute.permissions}><Products/></ProtectedRoute> },
            { path: CreateProductRoute.path, element: <ProtectedRoute permissions={CreateProductRoute.permissions}><CreateProduct/></ProtectedRoute> },
            { path: EditProductRoute.path, element: <ProtectedRoute permissions={EditProductRoute.permissions}><CreateProduct/></ProtectedRoute> },
            { path: PurchaseRequestsRoute.path, element: <ProtectedRoute permissions={PurchaseRequestsRoute.permissions}><PurchaseRequests/></ProtectedRoute> },
            { path: PersonalPurchaseHistoryRoute.path, element: <ProtectedRoute permissions={PersonalPurchaseHistoryRoute.permissions}><PersonalPurchaseHistory/></ProtectedRoute> },
            { path: ShopRoute.path, element: <ProtectedRoute permissions={ShopRoute.permissions}><Shop/></ProtectedRoute> },
            { path: ViewProductRoute.path, element: <ProtectedRoute permissions={ViewProductRoute.permissions}><ViewProduct/></ProtectedRoute> },
            { path: AuditLogsRoute.path, element: <ProtectedRoute permissions={AuditLogsRoute.permissions}><AuditLogs/></ProtectedRoute> },
            { path: GoogleIntegrationRoute.path, element: <ProtectedRoute permissions={GoogleIntegrationRoute.permissions}><GoogleIntegrations/></ProtectedRoute> },
            { path: PublicHolidayCalendarsRoute.path, element: <ProtectedRoute permissions={PublicHolidayCalendarsRoute.permissions}><PublicHolidayCalendars/></ProtectedRoute> },
        ]},
        { path: "*", element: <Navigate to={{pathname: ErrorRoute.path, state: {errorInfo: "404 error"}}} replace /> },
    ])
}

export default AppRoutes