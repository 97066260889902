import "../DDList/index.scss"

import React, {createRef, useEffect} from "react"
import CreatableSelect from "react-select/creatable"
import {useMediaQuery} from "react-responsive"

import ArrowDown from "@mui/icons-material/KeyboardArrowDown"

import {DESKTOP_WIDTH} from "../../constants/other"


const CreatableDDList = ({
    listOptions,
    onChange,
    text_label,
    ...props
}) => {
    const isDesktop = useMediaQuery({maxWidth: DESKTOP_WIDTH})
    const inputRef = createRef()

    useEffect(() => {
        if (isDesktop) {
            inputRef.current.blur()
        }
    }, [])
    
    const InputIcon = () => {
        return (
            <div className="toolkit-dd-list-select__indicators-wrapper">
                <ArrowDown className="arrow"/>
            </div>
        )
    }

    return (
        <div className="toolkit-dd-list-wrapper">
            {text_label ? <label className="toolkit-dd-list-label t-s3">{text_label}</label> : <></>}
            <CreatableSelect
                ref={inputRef}
                options={listOptions}
                classNamePrefix="t-s4 toolkit-dd-list-select"
                components={{DropdownIndicator: () => <InputIcon/>}}
                onChange={onChange}
                {...props}
            />
        </div>
    )
}

export default CreatableDDList
