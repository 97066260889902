import React, {useEffect, useRef, useState} from "react"
import DefaultProductImage from "../../../assets/images/user_photo.png"
import {ALLOWED_IMAGE_FORMATS, ERROR_CODE, NOT_ALLOWED_IMAGE_FORMAT_ERROR_MESSAGE} from "../../../constants/other"
import CancelIcon from "@mui/icons-material/Cancel"

const GalleryCard = ({product, setImages, isEdit, setUserMessage}) => {
    const inputRef = useRef()
    const [imageUrls, setImageUrls] = useState([])
    const [hoveredIndex, setHoveredIndex] = useState(null)

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files)
        const validImages = files.filter(file => file && ALLOWED_IMAGE_FORMATS.includes(file.type))

        if (validImages.length !== files.length) {
            setUserMessage({
                message: NOT_ALLOWED_IMAGE_FORMAT_ERROR_MESSAGE,
                code: ERROR_CODE
            })
        }

        setImageUrls([...imageUrls, ...validImages.map(file => URL.createObjectURL(file))])
        setImages(prevImages => ({
            images_to_add: [...prevImages.images_to_add, ...validImages],
            images_to_remove: prevImages.images_to_remove,
            is_main_list: [...prevImages.is_main_list, ...validImages.map(() => false)]
        }))
        inputRef.current.value = ""
    }

    const handleRemoveImage = (index) => {
        setImageUrls(prevUrls => prevUrls.filter((_, i) => i !== index))
        setImages(prevImages => ({
            images_to_add: prevImages.images_to_add.filter((_, i) => i !== index),
            images_to_remove: isEdit ? [...prevImages.images_to_remove, imageUrls[index]] : prevImages.images_to_remove,
            is_main_list: prevImages.is_main_list.filter((_, i) => i !== index)
        }))
    }

    useEffect(() => {
        if (product.images) {
            setImageUrls(product.images.filter(image => !image.is_main).map(image => image.image_url))
        }
    }, [product.images])

    return (
        <div className="create-product-right-gallery">
            <div className="t-s6">Gallery</div>
            <div className="create-product-right-gallery-images">
                {imageUrls.map((imageUrl, index) => (
                    <div
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        className="create-product-right-gallery-images-item"
                    >
                        <img src={imageUrl} alt={"product-image"}/>
                        {hoveredIndex === index &&
                        <CancelIcon className="create-product-right-gallery-images-remove" onClick={() => handleRemoveImage(index)}/>}
                    </div>))
                }
                <img src={DefaultProductImage} alt={"default-image"} onClick={() => inputRef.current.click()}/>
                <input
                    type="file"
                    accept={ALLOWED_IMAGE_FORMATS.join(",")}
                    ref={inputRef}
                    onChange={handleImageChange}
                    multiple
                />
            </div>
        </div>
    )
}

export default GalleryCard