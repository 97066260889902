import {getUserNotifications} from "../api"

import {
    GET_USER_NOTIFICATION_FAILURE,
    GET_USER_NOTIFICATION_REQUEST,
    GET_USER_NOTIFICATION_SUCCESS,
    UPDATE_UNREAD_NOTIFICATION_COUNT,
} from "../const"

import {PERMISSIONS} from "../../../constants/permissions"


const request = () => ({type: GET_USER_NOTIFICATION_REQUEST})

const success = (payload) => ({
    type: GET_USER_NOTIFICATION_SUCCESS,
    payload: payload,
})

const failure = () => ({type: GET_USER_NOTIFICATION_FAILURE})

export const updateUnreadNotificationsCount = (payload)  => ({
    type: UPDATE_UNREAD_NOTIFICATION_COUNT,
    payload,
})


export const receiveUserNotifications = (userId) => async (dispatch, getState) => {
    const {hasPermission} = getState().profile
    if(!hasPermission(PERMISSIONS.READ_NOTIFICATION)){
        return
    }
    dispatch(request())
    try {
        const {data: userNotificationsData}  = await getUserNotifications(userId)

        dispatch(success({
            userNotifications: userNotificationsData.user_notifications,
            unreadNotificationsCount: userNotificationsData.unread_notifications_count,
        }))
    }
    catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
