import React, {useState} from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import DDList from "../../../toolkits/DDList/DDList"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useAssignEquipmentMutation, useUnassignEquipmentMutation} from "../../../api/equipment"
import {useGetFlatUsersListQuery} from "../../../api/users"
import {useTranslation} from "react-i18next"


const AssignEquipmentPopup = ({equipment, assign, refreshTable, setUserMessage, close}) => {
    const { t } = useTranslation("common")

    const [assignEquipment, {isLoading: assignLoading}] = useAssignEquipmentMutation()
    const [unassignEquipment, {isLoading: unassignLoading}] = useUnassignEquipmentMutation()
    const isLoading = assignLoading || unassignLoading

    const { data } = useGetFlatUsersListQuery({})
    const users = data?.items || []

    const full_name = equipment.user && `${equipment.user.first_name} ${equipment.user.last_name}`
    const [user, setUser] = useState({})
    
    const handleSubmit = () => {
        if (assign) {
            assignEquipment({
                equipment_id: equipment.id,
                user_id: user.value,
            })
                .unwrap()
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: t("Equipment was successfully assigned!"), code: SUCCESS_CODE})
                })
                .catch(error => {
                    close()
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        } else {
            unassignEquipment({
                equipment_id: equipment.id,
                user_id: equipment.user.id,
            })
                .unwrap()
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: t("Equipment was successfully unassigned!"), code: SUCCESS_CODE})
                })
                .catch(error => {
                    close()
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup
            className="assign-equipment-popup"
            wrapperClass="assign-equipment-popup-wrapper"
            isSubtitle={!assign}
            isAgreePopup={!assign}
            text_title={assign ?
                t("Assign {{equipment}}", {equipment: equipment.name}) :
                t("Do you want to unassign {{equipment}} from {{user}}?", {equipment: equipment.name, user: full_name})}
        >
            {assign &&
                <DDList
                    placeholder="" 
                    listOptions={users &&
                        users.map((user) => ({value: user.id, label: `${user.first_name} ${user.last_name}`}))}
                    onChange={setUser}
                />
            }
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> {t("Cancel").toUpperCase()} </SmallButton>
                    <SmallButton loading={isLoading} type="submit" onClick={handleSubmit}>{assign ? t("Assign").toUpperCase() : t("Unassign").toUpperCase()}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )  
}

export default AssignEquipmentPopup
