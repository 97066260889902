import React, {useEffect} from "react"

import Table from "../../../components/Table/Table"
import {usePaginatedQuery} from "../../../hooks/common/usePaginatedQuery"
import {useGetAuditLogsQuery} from "../../../api/auditLogs"
import AuditLogsAccordionContent from "./AuditLogsAccordionContent"
import {getAction, getObject, getSource} from "../utils"
import {useTranslation} from "react-i18next"



export const AuditLogsList = ({ startDate, endDate, setUserMessage }) => {
    const {t} = useTranslation("common")

    const { items: auditLogs, loading, hasMore, loadNextPage, changeParams } = usePaginatedQuery(
        useGetAuditLogsQuery,
        {
            defaultState: {
                params: {start_date: startDate, end_date: endDate}
            }
        }
    )

    useEffect(() => {
        changeParams({ start_date: startDate, end_date: endDate })
    }, [startDate, endDate])

    const columns = [
        {
            field: "id",
            hidden: true,
            isKey: true,
        },
        {
            field: "date",
            title: t("Date"),
            type: "datetime",
        },
        {
            field: "source",
            title: t("Source"),
            get: (row) => t(getSource(row)),
        },
        {
            field: "event_type",
            title: t("Action"),
            get: (row) => t(getAction(row)),
        },
        {
            field: "object_type",
            title: t("Object"),
            get: (row) => t(getObject(row)),
        },
        {
            field: "object_id",
            title: t("Object ID"),
            type: "copy",
        },
    ]

    return <Table
        items={auditLogs}
        columns={columns}
        loading={loading}
        hasMore={hasMore}
        loadNextPage={loadNextPage}
        setUserMessage={setUserMessage}
        variant="small"
        AccordionComponent={AuditLogsAccordionContent}
    />
}

export default AuditLogsList
