export const GET_PAYMENT_REPORTS_REQUEST = "GET_PAYMENT_REPORTS_REQUEST"
export const GET_PAYMENT_REPORTS_FAILURE = "GET_PAYMENT_REPORTS_FAILURE"
export const GET_PAYMENT_REPORTS_SUCCESS = "GET_PAYMENT_REPORTS_SUCCESS"
export const GET_NEXT_PAGE_PAYMENT_REPORTS_REQUEST = "GET_NEXT_PAGE_PAYMENT_REPORTS_REQUEST"

export const GET_PAYMENT_REPORTS_TOTAL_REQUEST = "GET_PAYMENT_REPORTS_TOTAL_REQUEST"
export const GET_PAYMENT_REPORTS_TOTAL_FAILURE = "GET_PAYMENT_REPORTS_TOTAL_FAILURE"
export const GET_PAYMENT_REPORTS_TOTAL_SUCCESS = "GET_PAYMENT_REPORTS_TOTAL_SUCCESS"

export const GET_PAYMENTS_REQUEST = "GET_PAYMENTS_REQUEST"
export const GET_PAYMENTS_FAILURE = "GET_PAYMENTS_FAILURE"
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS"

export const FILTER_TYPES = {
    THIS_MONTH: "THIS_MONTH",
    LAST_MONTH: "LAST_MONTH",
    CUSTOM: "CUSTOM",
}

export const CANCEL_TOKEN_TYPES = {
    PAYMENTS: "payments",
    PAYMENT_REPORTS: "paymentReports",
    PAYMENT_REPORTS_TOTAL: "paymentReportsTotal",
}