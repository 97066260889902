import React from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

const RequestOvertimeCoeffPopup = ({close, onSave}) => {
    return (
        <CustomPopup text_title="Overtime Request Denied" className="request-overtime-popup">
            <div className="request-overtime-popup-body t-b1">
                <span>You cannot log overtime as you do not have a coefficient. Kindly request an overtime coefficient from your manager before proceeding.</span>
            </div>
            <div className="request-overtime-popup-button-container">
                <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                <SmallButton onClick={() => onSave()}> Request </SmallButton>
            </div>
        </CustomPopup>
    )
}

export default RequestOvertimeCoeffPopup
