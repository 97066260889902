import React from "react"
import Popup from "reactjs-popup"

import DeleteBonusPopup from "./DeleteBonusPopup"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import {formatDate} from "../../../utils/datetime.utils"
import CreateUpdateBonusPopup from "./CreateUpdateBonusPopup"
import Protected from "../../../components/Protected/Protected"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import {PERMISSIONS} from "../../../constants/permissions"
import {DD_MM_YYYY} from "../../../constants/other"


export const BonusHistoryTable = ({bonuses, refreshTable, setUserMessage, accessToEditBonus}) => {
    const bonusesList = bonuses.map((bonus, i) => {
        const popupParams = {
            currentBonus: bonus,
            refreshTable: refreshTable,
            setUserMessage: setUserMessage,
        }

        const tableActions = (
            <>
                <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                    {close => (
                        <CreateUpdateBonusPopup
                            close={close}
                            {...popupParams}
                        />
                    )}
                </Popup>
                <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                    {close => (
                        <DeleteBonusPopup
                            close={close}
                            {...popupParams}
                        />
                    )}
                </Popup>
            </>
        )

        return (
            <tr key={`${i} ${bonus.id}`} className="custom-scrollable-table-row">
                <td className="bonus-history-content-table-first-column">
                    <SensitiveInfo value={<span className="t-s1">+{bonus?.amount} {bonus.currency.iso_code}</span>}/>
                </td>
                <td>{`${formatDate(bonus?.as_of_date, DD_MM_YYYY)}`}</td>
                <td><div className="bonus-reason">{bonus?.reason}</div></td>
                <td className="bonus-history-content-table-last-column">
                    <Protected permissions={[PERMISSIONS.WRITE_BONUS]}>
                        {(new Date().setDate(new Date().getDate() - accessToEditBonus.value) > new Date(bonus?.as_of_date)) ? null : tableActions}
                    </Protected>
                </td>
            </tr>
        )


    })

    return (
        <CustomScrollableTable>
            <table className="bonus-history-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">AMOUNT</span></th>
                        <th><span className="custom-scrollable-table-head-span">DATE</span></th>
                        <th><span className="custom-scrollable-table-head-span">REASON</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {bonuses.length > 0 ? bonusesList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default BonusHistoryTable