import React from "react"
import {useDispatch} from "react-redux"

import {receiveMyInfo} from "../../Profile/actions/userActions"


import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useDeleteTimeRecordMutation} from "../../../api/timeRecords"


const DeleteTimeRecordPopup = ({timeRecord, setUserMessage, close, onItemRemoved}) => {
    const [deleteTimeRecord, { isLoading }] = useDeleteTimeRecordMutation()
    const dispatch = useDispatch()

    const handleSubmit = () => {
        deleteTimeRecord(timeRecord.id)
            .unwrap()
            .then(() => {
                onItemRemoved(timeRecord.id)
                setUserMessage({message: "Your time record was successfully deleted!", code: SUCCESS_CODE})

                // update profile data
                dispatch(receiveMyInfo())
                close()
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title="Do you want to delete this time record?" isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteTimeRecordPopup
