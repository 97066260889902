import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {useDeleteSalaryMutation} from "../../../api/salary"


const DeleteSalaryPopup = ({username, currentSalary, setUserMessage, refreshTable, close}) => {
    const [deleteSalary, {isLoading}] = useDeleteSalaryMutation()

    const handleSubmit = () => {

        deleteSalary(currentSalary.id)
            .unwrap()
            .then(() => {
                refreshTable()
                setUserMessage({message: "Salary was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete ${username}'s salary with amount ${currentSalary.amount}?`}
            isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteSalaryPopup
