import "./index.scss"

import React from "react"
import GoogleOOOCalendarIntegrationsCard from "./components/GoogleOOOCalendarIntegrationCard"

const GoogleIntegrations = () => {
    return <GoogleOOOCalendarIntegrationsCard/>

}

export default GoogleIntegrations
