import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import { changeDateFormat } from "../../../utils/datetime.utils"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"


const SalaryChangesPopup = ({salaries, close}) => {
    return (
        <FlexiblePopup>
            <div className="salary-changes-popup">
                <div className="salary-changes-popup-title t-h2">Salary changes</div>
                <table className="salary-changes-popup-table">
                    <thead>
                        <tr className="salary-changes-popup-table-column heading-color t-h3">
                            <th className="salary-changes-popup-table-column">
                                <span>Salary</span>
                            </th>
                            <th className="salary-changes-popup-table-column">Clocked Hours</th>
                            <th className="salary-changes-popup-table-column">Period</th>
                            <th className="salary-changes-popup-table-column">Client</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!salaries ? <StyledCircularProgress/>
                            :
                            <>
                                {salaries && salaries.toReversed().map((record, i) => (
                                    (<div key={i}>
                                        <tr key={i} className="salary-changes-popup-table-row t-s4">
                                            <td className="salary-changes-popup-table-row t-s3">{record.amount} {record.currency}</td>
                                            <td className="salary-changes-popup-table-row"><span><span className={record.clocked_hours.current > record.clocked_hours.total ? "hours-overlogged-warning" : ""}>{record.clocked_hours.current}</span>/{record.clocked_hours.total}</span></td>
                                            <td className="salary-changes-popup-table-row">{changeDateFormat(record.start_date)}{`${record.end_date ? `-${changeDateFormat(record?.end_date)}` : ""}`}</td>
                                            <td className="salary-changes-popup-table-row">{record.client?.name}</td>
                                        </tr>
                        
                                        {i !== (salaries.length-1) && <hr className="salary-changes-popup-table-divider"/>}
                                    </div>)
                                ))}
                            </>
                        }
                    </tbody>
                </table>
                <CloseIcon onClick={close} className="salary-changes-popup-button-close"/>
            </div>
        </FlexiblePopup>
    )
}

export default SalaryChangesPopup