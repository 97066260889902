import UserImage from "../../../assets/images/user_photo.png"

import React from "react"
import {useSelector} from "react-redux"

import {useCustomHistory} from "../../../hooks/useCustomHistory"

import {ProfileRoute} from "../../../routes"

import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import Checkbox from "../../../toolkits/Checkbox/Checkbox"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"

import {isNotZero, roundEarn} from "../../../utils/other.utils"

import {DESKTOP_WIDTH, SALARY_TYPES} from "../../../constants/other"
import { hoursToString } from "../../../utils/datetime.utils"
import Popup from "reactjs-popup"
import InfoPopup from "../../../components/InfoPopup"
import InfoIcon from "@mui/icons-material/Info"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import SalaryChangesPopup from "./SalaryChangesPopup"
import {useMediaQuery} from "react-responsive"
import BonusesPopup from "./BonusesPopup"

const TITLES = {
    EMPLOYEE: (
        <span className="custom-scrollable-table-head-span-first paym-rep-table-title-employee">EMPLOYEE</span>
    ),
    CLIENT: (
        <span className="custom-scrollable-table-head-span paym-rep-table-title">CLIENT</span>
    ),
    SALARY: (
        <div className="paym-rep-table-title">
            <span className="custom-scrollable-table-head-span">SALARY</span>
            <span className="custom-scrollable-table-span paym-rep-table-salary-coeff text-top">Overtime coeff</span>
        </div>
    ),
    CLOCKED_HOURS: (
        <span className="custom-scrollable-table-head-span paym-rep-table-title">CLOCKED HOURS</span>
    ),
    NOT_PAID_DAY_OFFS: (
        <span className="custom-scrollable-table-head-span paym-rep-table-title">NOT PAID DAY OFFS</span>
    ),
    EARN: (
        <span className="custom-scrollable-table-head-span paym-rep-table-title">EARN</span>
    ),
    OVERTIME: (
        <span className="custom-scrollable-table-head-span paym-rep-table-title">
            <span>OVERTIME&nbsp;</span>
            <span className="paym-rep-table-title-ext">(HOURS)</span>
        </span>
    ),
    BONUSES: (
        <span className="custom-scrollable-table-head-span paym-rep-table-title">BONUSES</span>
    ),
    TOTAL: (
        <span className="custom-scrollable-table-head-span paym-rep-table-title">TOTAL</span>
    ),
}


const PaymentReportsTable = ({
    data,
    isGeneratedReport,
    isAllSelected,
    includedUsers,
    excludedUsers,
    setters

}) => {
    const history = useCustomHistory()

    const auth = useSelector(state => state.auth)

    const profileClick = (userId) => userId === auth.user.uid
        ? history.push(ProfileRoute.path)
        : history.push(`/profile/${userId}`)
    
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const TableRow = ({userRow, userRowIndex, isSinglePeriod}) => {
        const isSingleAndNoBonuses = isSinglePeriod && userRow.bonuses.length === 0

        const getBonusesTotal = () => {
            const groupedBonuses = {}
            userRow.bonuses.forEach((bonus) => {
                bonus.currency.iso_code in groupedBonuses
                    ? groupedBonuses[bonus.currency.iso_code] += bonus.amount
                    : groupedBonuses[bonus.currency.iso_code] = bonus.amount
            })
            
            return groupedBonuses
        }

        const getSummaryTotal = () => {
            const total = userRow.clients.reduce((result, client) => {
                client.total.forEach(t => {
                    t.currency in result
                        ? result[t.currency] += t.amount
                        : result[t.currency] = t.amount
                })
                return result
            }, {})

            const bonuses = getBonusesTotal()
            Object.keys(bonuses).map(key => {
                key in total
                    ? total[key] += bonuses[key]
                    : total[key] = bonuses[key]
            })
              
            return Object.keys(total).map(key =>
                `${roundEarn(total[key])} ${key}`)
        }

        const userInfo = {
            full_name: `${userRow.last_name} ${userRow.first_name[0]}.`,
            image_url: userRow?.image_url
                ? userRow.image_url
                : UserImage,
            bonuses:
                Object.keys(getBonusesTotal()).map(key => {
                    return `${roundEarn(getBonusesTotal()[key])} ${key}`
                }),
            clients: userRow.clients.map(client => ({
                client: client.client.name,
                clocked_hours:
                    `${client.clocked_hours.current}/${client.clocked_hours.total}`,
                not_paid_day_offs:
                    `${client.earn.not_paid_days} ${client.earn.not_paid_days === 1 ? "day" :  "days"} (${
                        roundEarn(client.earn.not_paid_amount)} ${client.earn.currency.iso_code})`,
                earn:`${roundEarn(client.earn.amount)} ${client.earn.currency.iso_code}`,
                overtimes: client.overtimes.map((overtime) => ({
                    overtime: `+${roundEarn(overtime.amount)} ${overtime.currency.iso_code} `,
                    hours: `(${hoursToString(overtime.hours)})`,
                })),

                total:
                    client.total.map(total => {
                        return `${roundEarn(total.amount)} ${total.currency}`}
                    )
            })),
            total: getSummaryTotal(),
        }

        const UserColumn = () => (
            <div className="paym-rep-table-user">
                <div className={`paym-rep-table-user-checkbox ${userRow?.is_paid || isGeneratedReport ? "paym-rep-hidden-block" : ""}`}>
                    <Checkbox
                        checked={!isAllSelected
                            ? includedUsers.get().includes(userRow.user_id)
                            : !excludedUsers.get().includes(userRow.user_id)
                        }
                        onClick={() => {
                            !isAllSelected
                                ? includedUsers.set(state => !includedUsers.get().includes(userRow.user_id)
                                    ? [...state, userRow.user_id]
                                    : state.filter(x => x !== userRow.user_id)
                                )
                                : excludedUsers.set(state => !excludedUsers.get().includes(userRow.user_id)
                                    ? [...state, userRow.user_id]
                                    : state.filter(x => x !== userRow.user_id)
                                )
                        }}
                    />
                </div>
                <div className="paym-rep-table-user-img">
                    <img
                        className="clickable"
                        src={userInfo.image_url}
                        onClick={() => profileClick(userRow.user_id)}
                        alt="user"
                    />
                </div>
                <span className="t-s1 clickable" onClick={() => profileClick(userRow.user_id)}>{userInfo.full_name}</span>
            </div>
        )

        const SalaryColumn = ({salary, showCurrency=true}) => {
            return (
                <div className="t-b2">
                    {`${salary.amount}${showCurrency ? ` ${salary.currency}` : ""}`}
                    {salary.salary_type === SALARY_TYPES.PER_HOUR &&
                        <small className="per-hour-text t-b4"> {salary.salary_type}</small>
                    }
                    <span className="paym-rep-table-salary-coeff t-b4 text-top">
                        {isNotZero(salary.overtime_coefficient) && `x${salary.overtime_coefficient}`}
                    </span>
                </div>
            )
        }

        const SummaryTotalRow = () => {
            return (
                <tr className="paym-rep-table-total-row">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span className="t-s1">Total:</span>
                    </td>
                    <td>
                        <div className={`t-s1 ${userRow?.is_paid ? "paym-rep-success" : ""}`}>
                            <SensitiveInfo>
                                {userInfo.total.map((total, s) => (
                                    <p key={`summary_row_total ${s}`}>{total}</p>
                                ))}
                            </SensitiveInfo>
                        </div>
                    </td>
                </tr>
            )
        }

        const BonusesRow = () => {
            return (
                <>
                    {isSinglePeriod && <hr/>}
                    <tr className="paym-rep-table-bonuses-row">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            {isDesktop ?
                                <Popup trigger={<span className="clickable"><InfoIcon/></span>} modal>
                                    {close => (
                                        <BonusesPopup
                                            bonuses={userRow.bonuses}
                                            close={close}
                                        />
                                    )}
                                </Popup> : <InfoIcon/>
                            }
                            <span className="t-s4">Bonuses: </span>
                        </td>
                        <td>
                            <div className={"t-s4"}>
                                <SensitiveInfo>
                                    {userInfo.bonuses.map((bonus, s) => (
                                        <p key={`summary_row_bonuses ${s}`}>{bonus}</p>
                                    ))}
                                </SensitiveInfo>
                            </div>
                        </td>
                    </tr>
                </>
            )
        }

        return (
            <table
                className={`paym-rep-table${!isSinglePeriod ? "-ext" : ""}`}
                key={`payment-reports-table-row ${userRowIndex}`}
            >
                <tbody>
                    {userRow.clients.map((row, index) => {
                        const isShowTitle = !userRowIndex && !index

                        return (<>
                            <tr key={`user_row ${index}`}>
                                {/* USER */}
                                <td>
                                    {!index &&
                                        <UserColumn/>
                                    }

                                    {isShowTitle && TITLES.EMPLOYEE}
                                </td>

                                {/* CLIENT */}
                                <td>
                                    <div>
                                        <Popup trigger={<span className="paym-rep-table-client-label t-b2 cell-limited-text clickable"> {userInfo.clients[index].client} </span>} modal>
                                            {close => (
                                                <InfoPopup
                                                    title="Client"
                                                    value={userInfo.clients[index].client}
                                                    close={close}
                                                />
                                            )}
                                        </Popup>
                                    </div>
                                    {isShowTitle && TITLES.CLIENT}
                                </td>

                                {/* SALARIES */}
                                <td>
                                    <SensitiveInfo>
                                        {row.salaries.length > 1 ? 
                                            <div className="paym-rep-table-salary">
                                                {isDesktop ?
                                                    <Popup trigger={<span className="changed-salary-info-icon clickable"><InfoIcon/></span>} modal>
                                                        {close => (
                                                            <SalaryChangesPopup
                                                                salaries={row.salaries}
                                                                close={close}
                                                            />
                                                        )}
                                                    </Popup> : <InfoIcon/>
                                                }
                                                <SalaryColumn salary={row.salaries[0]} 
                                                    showCurrency={!row.salaries[0].currency == row.salaries[row.salaries.length-1].currency}/>
                                                <ArrowForwardIcon/>
                                                <SalaryColumn salary={row.salaries[row.salaries.length-1]}/>
                                            </div> : 
                                            <SalaryColumn salary={row.salaries[0]}/>
                                        }
                                    </SensitiveInfo>

                                    {isShowTitle && TITLES.SALARY}
                                </td>

                                {/* CLOCKED HOURS */}
                                <td>
                                    <div className="t-b2">
                                        {userInfo.clients[index].clocked_hours}
                                    </div>
                                    {isShowTitle && TITLES.CLOCKED_HOURS}
                                </td>

                                {/* NOT PAID DAY OFFS */}
                                <td>
                                    <div className="t-b2">
                                        <SensitiveInfo>
                                            {userInfo.clients[index].not_paid_day_offs}
                                        </SensitiveInfo>
                                    </div>

                                    {isShowTitle && TITLES.NOT_PAID_DAY_OFFS}
                                </td>

                                {/* EARN */}
                                <td>
                                    <div>
                                        <SensitiveInfo>
                                            <div className="t-b1">
                                                <span>{userInfo.clients[index].earn}</span>
                                            </div>
                                        </SensitiveInfo>
                                    </div>

                                    {isShowTitle && TITLES.EARN}
                                </td>
                                
                                {/* OVERTIMES */}
                                <td>
                                    <div className="t-b2" >
                                        <SensitiveInfo>
                                            {row.overtimes.length ?
                                                userInfo.clients[index].overtimes.map((overtime, j) => (
                                                    <div key={`c_overtime ${j}`}
                                                        className="t-b1 clickable"
                                                        onClick={() => {
                                                            setters.overtimePopup(true)
                                                            setters.inspectUser(userRow.user_id)
                                                        }}
                                                    >
                                                        <span>{overtime.overtime}</span>
                                                        <span className="t-b2">{overtime.hours}</span>
                                                    </div>
                                                ))
                                                : <div className="t-b1">
                                                    <span>{`+0 ${row.earn.currency.iso_code}`}</span>
                                                    <span className="t-b2">{" (00:00)"}</span>
                                                </div>
                                            }
                                        </SensitiveInfo>
                                    </div>

                                    {isShowTitle && TITLES.OVERTIME}
                                </td>

                                
                                {/* TOTAL */}
                                <td>
                                    <div>
                                        <SensitiveInfo>
                                            {row.total.length ?
                                                userInfo.clients[index].total.map((total, j) => (
                                                    <div
                                                        key={`total ${j}`}
                                                        className={`${isSingleAndNoBonuses ? "t-s1" : "t-s3"} ${userRow?.is_paid && isSingleAndNoBonuses ? "paym-rep-success" : ""}`}
                                                    >
                                                        {total}
                                                    </div>
                                                ))
                                                : <div className={`t-s1 ${userRow?.is_paid ? "paym-rep-success" : ""}`}>
                                                    {`0 ${row.not_paid_day_offs.currency}`}
                                                </div>
                                            }
                                        </SensitiveInfo>
                                    </div>

                                    {isShowTitle && TITLES.TOTAL}
                                </td>
                            </tr>
                            {!isSinglePeriod && <hr/>}
                        </>)
                    })}
                    {
                        userInfo.bonuses.length > 0 &&
                            <BonusesRow/>
                    }
                    {/* SUMMARY TOTAL */}
                    {(!isSinglePeriod || userInfo.bonuses.length > 0) &&
                        <SummaryTotalRow/>
                    }
                </tbody>
            </table>
        )
    }

    return (
        <CustomScrollableTable>
            <table className="paym-rep-wrapper-table">
                <tbody>
                    {data.length ? data.map((userRow, i) => (
                        <tr key={i} className="custom-scrollable-table-row">
                            <td className="custom-scrollable-table-row">
                                <TableRow
                                    userRow={userRow}
                                    isSinglePeriod={userRow.clients.length <= 1}
                                    userRowIndex={i}
                                />
                            </td>
                        </tr>
                    )) :
                        // EMPTY STATE
                        <div className="paym-rep-table-empty">
                            <tr className="custom-scrollable-table-row">
                                <table className="paym-rep-table">
                                    <tbody>
                                        <tr>
                                            {Object.keys(TITLES).map((title, i) => (
                                                <td key={`empty_table_title_${i}`}>
                                                    {TITLES[title]}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                        </div>
                    }
                </tbody>
                {/* EMPTY STATE */}
                {!data.length &&
                    <EmptyResult/>
                }
            </table>
        </CustomScrollableTable>
    )
}

export default PaymentReportsTable
