import {PERMISSIONS} from "../../../../constants/permissions"
import InfoIcon from "@mui/icons-material/Pending"
import {formatDate} from "../../../../utils/datetime.utils"
import {DD_MM_YYYY} from "../../../../constants/other"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"
import React, {useContext, useState} from "react"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import PerformanceReviewInfoPopup from "../Popups/PerformanceReviewPopup"
import Popup from "reactjs-popup"
import PenIcon from "@mui/icons-material/Create"
import {PerformanceReviewsRoute} from "../../../../routes"
import {useCustomHistory} from "../../../../hooks/useCustomHistory"
import {useTranslation} from "react-i18next"

const PerformanceReviewCard = ({user}) => {
    const { t } = useTranslation("common")

    const [showPerformanceReviewPopup, setShowPerformanceReviewPopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)
    const history = useCustomHistory()

    return (
        <>
            <div className={`profile-info-card ${user.next_performance_review || hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW) ? `clickable ${theme.profileCardHover}` : ""}`}
                onClick={() => user.next_performance_review ? setShowPerformanceReviewPopup(true) : hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW) && history.push(PerformanceReviewsRoute.path, {userId:user.id})}>
                <div>
                    {user.next_performance_review
                        ? <InfoIcon className="profile-info-card-improvements clickable"/>
                        : hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW) && <PenIcon className="profile-info-card-pen-icon clickable"/>}
                    <h3 className="t-h3 profile-font-leading">{t("NEXT PERFORMANCE REVIEW")}</h3>
                    {user.next_performance_review ? <h1 className="t-h1">
                        {formatDate(user.next_performance_review.date, DD_MM_YYYY)}
                    </h1> : <EmptyCard>{t("No reviews")}</EmptyCard>}
                </div>
            </div>
            <Popup open={showPerformanceReviewPopup} closeOnDocumentClick onClose={() => setShowPerformanceReviewPopup(false)} modal>
                {close =>
                    <PerformanceReviewInfoPopup
                        userId={user.id}
                        close={close}
                        reviewId={user.next_performance_review?.id}
                    />
                }
            </Popup>
        </>
    )
}

export default PerformanceReviewCard