import {createSlice} from "@reduxjs/toolkit"
import {usePaginatedQuery} from "./usePaginatedQuery"
import {useDispatch, useSelector} from "react-redux"
import {useEffect} from "react"

const getDefaultState = (rest) => ({
    page: 1,
    sort: {},
    params: {},
    pageItems: {},
    ...rest,
})

export const tableDataSlice = createSlice({
    name: "tableData",
    initialState: {
        states: {}
    },
    reducers: {
        setSort: (state, action) => {
            const newState = {...(state.states[action.payload.key] || getDefaultState()), sort: action.payload.sort}
            state.states[action.payload.key] = newState
        },
        setParams: (state, action) => {
            const newState = {...(state.states[action.payload.key] || getDefaultState()), params: action.payload.params}
            state.states[action.payload.key] = newState
        },
        setPage: (state, action) => {
            if (state.states[action.payload.key]) {
                state.states[action.payload.key].page = action.payload.page
            } else {
                state.states[action.payload.key] = getDefaultState({ page: action.payload.page })
            }
        },
        setPageItems(state, action) {
            if (state.states[action.payload.key]) {
                state.states[action.payload.key].pageItems = action.payload.pageItems
            } else {
                state.states[action.payload.key] = getDefaultState({ pageItems: action.payload.pageItems })
            }
        },
        reset: (state, action) => {
            state.states[action.payload.key] = getDefaultState({})
        },
    },
})

export const getPage = (key) => (state) => state.tableData.states?.[key]?.page || 1
export const getParams = (key) => (state) => state.tableData.states?.[key]?.params
export const getSort = (key) => (state) => state.tableData.states?.[key]?.sort
export const getItems = (key) => (state) => state.tableData.states?.[key]?.pageItems || {}


const { setPage, setParams, setSort, setPageItems} = tableDataSlice.actions


export const useTableData = (useQuery, key, { defaultState, ...restOptions} = {}) => {
    const tablePage = useSelector(getPage(key))
    const tableParams = useSelector(getParams(key)) || defaultState?.params || {}
    const tableSort = useSelector(getSort(key)) || defaultState?.sort || {}
    const tableItems = useSelector(getItems(key))

    const dispatch = useDispatch()

    const { page, params, sort, pageItems, ...rest } = usePaginatedQuery(useQuery, {
        defaultState: { page: tablePage, sort: tableSort, params: tableParams },
        defaultData: { pageItems: tableItems },
        ...restOptions,
    })

    useEffect(() => {
        dispatch(setPage({ key, page }))
    }, [page])

    useEffect(() => {
        dispatch(setPageItems({ key, pageItems }))
    }, [pageItems])

    useEffect(() => {
        dispatch(setSort({ key, sort }))
    }, [sort])

    useEffect(() => {
        dispatch(setParams({ key, params }))
    }, [params])

    return { page, params, sort, ...rest }
}