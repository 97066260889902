import React, {useState} from "react"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import DynamicBoxList from "../../../../toolkits/DynamicBoxList"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"
import {useGetFreeEquipmentQuery, useUpdateUserEquipmentMutation} from "../../../../api/equipment"


const EditEquipmentPopup = ({user, userEquipment, setUserMessage, close}) => {
    const [updateUserEquipment] = useUpdateUserEquipmentMutation()
    const {data: equipmentRes, isLoading} = useGetFreeEquipmentQuery()
    const equipment = equipmentRes?.items || []
    const [equipmentIds, setEquipmentIds] = useState([])

    const handleSubmit = () => {
        updateUserEquipment({
            user_id: user.id,
            equipment_ids: equipmentIds,
        })
            .unwrap()
            .then(() => {
                close()
                setUserMessage({message: "Equipment was successfully assigned!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title="Change Equipment">
            <DynamicBoxList
                defaultValues={
                    userEquipment.reduce((result, category) => 
                        result.concat(category.equipment.map(x => x.id))
                    , [])
                }
                items={userEquipment.reduce((result, category) =>
                    result.concat(category.equipment)
                , []).concat(equipment)
                }
                loading={isLoading}
                onChange={setEquipmentIds}
                searchFunc={(eq, searchKey) =>
                    eq.name.match(new RegExp(searchKey, "i")) ||
                    (eq.serial_number &&
                        eq.serial_number.match(new RegExp(searchKey, "i")))
                }
                getContent={eq => eq.name}
                getAdditionalInfo={eq => eq.serial_number}
            />

            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit}>SAVE</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default EditEquipmentPopup
