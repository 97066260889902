import "./index.scss"

import React, {useState} from "react"
import {Helmet} from "react-helmet"

import OnboardingRequestsTable from "./components/OnboardingRequestsTable"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import {useCustomHistory} from "../../hooks/useCustomHistory"


const OnboardingRequests = () => {
    const history = useCustomHistory()

    const [userMessage, setUserMessage] = useState(null)
    const [searchKey, setSearchKey] = useState(history.location.state?.username ? history.location.state.username : "")


    return (
        <>
            <Helmet>
                <title>Onboarding requests - Newsoft Inside</title>
            </Helmet>
            {userMessage && <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>}
            <div className="onboarding-requests-header">
                <SearchForm onChange={setSearchKey} debounceMs={500} />
            </div>
            <OnboardingRequestsTable searchKey={searchKey} setUserMessage={setUserMessage}/>
        </>
    )
}

export default OnboardingRequests