import store from "../redux/store"

import {getAuthorizedUserData} from "./localstorage.utils"

import {USER_ROLES} from "../constants/other"


export const isAdmin = () => {
    const roles = store.getState().profile.myInfo.roles.map((role) => role.role_name)
    return roles?.includes(USER_ROLES.ADMIN)
}

export const canEditTimeRecord = (date) => {
    const {accessToTimeTracker} = getAuthorizedUserData() || {}
    
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    date = new Date(date).setHours(0, 0, 0, 0)
    if (accessToTimeTracker) {
        const startEditDay = today.setDate(today.getDate() - accessToTimeTracker.value)
        return date > startEditDay
    }
    return false
}
