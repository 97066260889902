import React from "react"
import {useSelector} from "react-redux"


export const SensitiveInfo = ({value, children}) => {
    const privateMode = useSelector((state) => state.auth.localStorage.privateMode)
    const smile = useSelector((state) => state.profile.privateModeSmile)

    return (

        <div className={privateMode ? "sensitive-info" : null}>
            {privateMode ? smile : value || children !== undefined ? <>{value || children}</> : null}
        </div>
    )
}
