import {
    GET_USER_NOTIFICATION_FAILURE,
    GET_USER_NOTIFICATION_REQUEST,
    GET_USER_NOTIFICATION_SUCCESS,
    UPDATE_UNREAD_NOTIFICATION_COUNT,
} from "../const"


const initialState = {
    userNotifications: [],
    unreadNotificationsCount: 0,
}

const handlers = {
    [GET_USER_NOTIFICATION_REQUEST]: (state) => ({...state, userNotifications: []}),
    [GET_USER_NOTIFICATION_SUCCESS]: (state, {payload}) => ({
        ...state,
        ...payload,
    }),
    [UPDATE_UNREAD_NOTIFICATION_COUNT]: (state, {payload: {unreadNotificationsCount}}) => ({
        ...state,
        unreadNotificationsCount,
    }),
    [GET_USER_NOTIFICATION_FAILURE]: (state) => ({...state}),

    DEFAULT: (state) => state,
}

const userNotificationsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default userNotificationsReducer
