import React from "react"
import CircleRoundedIcon from "@mui/icons-material/CircleRounded"

const CalendarCard = ({key, selectedCalendar, calendar, onClick}) => {
    const daysAmount = calendar.events.filter(event => new Date(event.date).getFullYear() === new Date().getFullYear()).length
    const isSelected = selectedCalendar?.id === calendar.id
    
    return (
        <div key={key} className={`calendars-content-card ${isSelected ? "selected" : ""}`} onClick={onClick}>
            <div className="calendars-content-card-name">
                <span className="t-h3">{calendar.name}</span>
                {calendar.is_default && <CircleRoundedIcon/>}
            </div>
            <div className="calendars-content-card-days t-s4">{`${daysAmount} day${daysAmount === 1 ? "" : "s"}`}</div>
        </div>
    )
}

export default CalendarCard
