import "./index.scss"

import React from "react"
import NewYearReindeer from "../../../assets/images/NewYearReindeer.svg"
import NewYearSleigh from "../../../assets/images/NewYearSleigh.svg"


const NewYearSleighAnimation = ({ active }) => {
    const [startHeight, setStartHeight] = React.useState(null)

    React.useEffect(() => {
        if (active) {
            setStartHeight(Math.max(20, Math.min(Math.round(Math.random() * 100) * 1.2, 70)))
        }
    }, [active])


    if (!startHeight) {
        return null
    }

    return (

        <div className="scene">
            <div className="scene--opening" style={{top: `${startHeight}%`}}>
                <div className="deer--first"><img src={NewYearReindeer}/></div>
                <div className="deer--second"><img src={NewYearReindeer}/></div>
                <div className="deer--third"><img src={NewYearReindeer}/></div>
                <div className="deer--forth"><img src={NewYearReindeer}/></div>
                <div className="deer--sleigh" onAnimationEnd={() => setStartHeight(null)}><img src={NewYearSleigh}/></div>
            </div>

        </div>

    )
}


export default NewYearSleighAnimation
