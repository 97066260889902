import React, {useContext} from "react"
import {PERMISSIONS} from "../../../../constants/permissions"
import CustomLink from "../../../../toolkits/CustomLink"
import {ReportsRoute, UserReportsRoute} from "../../../../routes"
import {setSelectedUser} from "../../../Reports/actions/reportsDataActions"
import {useDispatch, useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import InfoIcon from "@mui/icons-material/Pending"
import {useTranslation} from "react-i18next"

const MonthTotalCard = ({user, isMyProfile}) => {
    const { t } = useTranslation("common")

    const {hasPermission} = useSelector((state) => state.profile)
    const dispatch = useDispatch()
    const theme = useContext(ThemeContext)

    return (
        <>
            <CustomLink
                to={{pathname: isMyProfile ? ReportsRoute.path :
                    hasPermission(PERMISSIONS.READ_REPORTS) && UserReportsRoute.path, state: {
                    user: user}
                }}
                onClick={() => hasPermission(PERMISSIONS.READ_REPORTS) && dispatch(setSelectedUser(user))}
            >
                <div className={`profile-info-card clickable ${theme.profileCardHover}`}>
                    <InfoIcon className="profile-info-card-improvements clickable"/>
                    <h3 className="t-h3 profile-font-leading">{t("Month Total")}</h3>
                    <h1 className="t-h1">
                        {`${user.month_total.current} / ${user.month_total.total}`}
                    </h1>
                </div>
            </CustomLink>

        </>
    )
}

export default MonthTotalCard