import React from "react"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"

const ViewProductDetailsPopup = ({details_name, details_image_url, close}) => {
    return (
        <FlexiblePopup>
            <div className="view-product-details-popup">
                <div className="t-h2">{details_name}</div>
                <img src={details_image_url} alt={details_name}/>
                <ClosePopupIcon className="view-product-details-popup-close" onClick={() => close()}/>
            </div>
        </FlexiblePopup>
    )
}

export default ViewProductDetailsPopup