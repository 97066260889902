import React from "react"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"

const ItemCard = ({submitDisabled, loading, isEdit, product, setProduct, handleSubmitProduct}) => {
    return (
        <div className="create-product-right-item">
            <div className="create-product-right-item-public t-b1"><span>Public</span><span onClick={() => setProduct(prevState => ({...prevState, public: !prevState.public}))}>{product.public ? <ToggleOnIcon className="active"/> : <ToggleOffIcon className="inactive"/>}</span></div>
            <SmallButton loading={loading} disabled={submitDisabled} onClick={() => handleSubmitProduct()}>{isEdit ? "Save" : "Create"}</SmallButton>
        </div>
    )
}

export default ItemCard