import React, {useState} from "react"

import {useCreateClientMutation, useUpdateClientMutation} from "../../../api/clients"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useTranslation} from "react-i18next"


const CreateUpdateClientPopup = ({client, setUserMessage, onSubmit, close}) => {
    const { t } = useTranslation("common")

    const [name, setName] = useState(client ? client.name : "")

    const [createClient, { isLoading: createLoading }] = useCreateClientMutation()
    const [updateClient, { isLoading: updateLoading }] = useUpdateClientMutation()
    const isLoading = createLoading || updateLoading

    const handleSubmit = () => {
        const data = {
            name,
        }
        if (client) {
            updateClient({id: client.id, data})
                .unwrap()
                .then(() => {
                    onSubmit()
                    close()
                    setUserMessage({
                        message: t("Client was successfully updated!"),
                        code: SUCCESS_CODE
                    })
                })
                .catch(error => {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        } else {
            createClient(data)
                .unwrap()
                .then(() => {
                    onSubmit()
                    close()
                    setUserMessage({message: t("Client was successfully created!"), code: SUCCESS_CODE})
                })
                .catch(error => {
                    console.log(error)
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup text_title={`${client ? t("Edit Client") : t("Create Client")}`}>
            <TextInput
                text_label={t("Title")}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> {t("Cancel").toUpperCase()} </SmallButton>
                    <SmallButton
                        loading={isLoading}
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!name}
                    >
                        {client ? t("Save").toUpperCase() : t("Create").toUpperCase()}
                    </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateUpdateClientPopup
