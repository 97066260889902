import {useGetTimeRecordTemplatesQuery} from "../api/timeRecords"


const useTimeRecordTemplates = ({ userId, skip } = {}) => {
    const {data, isLoading, refetch} = useGetTimeRecordTemplatesQuery({user_id: userId}, { skip  })

    return {
        timeRecordTemplates: data,
        isLoading,
        refetch,
    }
}

export default useTimeRecordTemplates