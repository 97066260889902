import "./Checkbox.scss"

import React from "react"


const Checkbox = ({
    label,
    checked,
    onChange,
    className,
    ...props
}) => {

    return (
        <div className={className ? className : ""}>
            <label className="checkbox-container t-b1">
                <span>{label}</span>
                
                <input
                    type="checkbox"
                    {...props}
                    checked={checked}
                    onChange={onChange}
                />
                <span className="checkbox"/>
            </label>
        </div>
    )
}

export default Checkbox
