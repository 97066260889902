import React, {useMemo, useState} from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useCompleteGoogleCalendarIntegrationMutation, useGetGoogleCalendarsQuery} from "../../../api/googleIntegration"
import DDList from "../../../toolkits/DDList/DDList"

const INTEGRATION_TYPE = "google_ooo"


const GoogleOOOCalendarCompleteSetupCard = ({setupKey, setUserMessage, close}) => {
    const [completeSetup, {isLoading: setupCompleteLoading}] = useCompleteGoogleCalendarIntegrationMutation()

    const {data: calendars, isLoading: calendarsLoading} = useGetGoogleCalendarsQuery({ integration_type: INTEGRATION_TYPE }, {skip: !setupKey})

    const [selectedCalendar, setSelectedCalendar] = useState()

    const calendarsOptions = useMemo(() => calendars?.map((calendar) => ({label: calendar.name, value: calendar.id})), [calendars])

    const handleSubmit = async () => {
        try {
            await completeSetup({
                setupKey,
                integrationType: INTEGRATION_TYPE,
                calendarId: selectedCalendar.value,
                calendarName: selectedCalendar.label
            }).unwrap()
            setUserMessage({message: "Calendar changed", code: SUCCESS_CODE})
            close()
        } catch (error) {
            setUserMessage({message: error.message, code: ERROR_CODE})
        }
    }


    return (
        <CustomPopup wrapperClass="google-integration-body-ooo-calendar-popup" text_title="Please select the calendar you want to use as OOO Calendar?" isSubtitle isAgreePopup>
            <div className="google-integration-body-ooo-calendar-dd">
                <DDList
                    placeholder="Choose a calendar"
                    listOptions={calendarsOptions}
                    value={selectedCalendar}
                    onChange={setSelectedCalendar}
                    isDisabled={calendarsLoading || setupCompleteLoading}
                />
            </div>
            <div className="popup-bottom">

                <div className="popup-bottom-button-container">
                    <SmallButton disabled={setupCompleteLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={setupCompleteLoading} onClick={handleSubmit}> SELECT </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default GoogleOOOCalendarCompleteSetupCard
