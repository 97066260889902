import React from "react"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import {ERROR_CODE, PRODUCT_STATUS, SUCCESS_CODE} from "../../../constants/other"
import useCoinsBalance from "../../../hooks/useCoinsBalance"
import {useCreateOrderMutation} from "../../../api/shop"

const BuyProductPopup = ({product, amount, selectedProductVariant, close, data, setUserMessage}) => {
    const [createPurchaseRequest, {isLoading}] = useCreateOrderMutation()
    const { balance } = useCoinsBalance({userId: data.user_id})

    const onClick = () => {
        if (data.total_price > balance) {
            setUserMessage({message: "You have insufficient balance for this purchase.", code: ERROR_CODE})
            close()
            return
        }

        createPurchaseRequest(data)
            .unwrap()
            .then(() => {
                setUserMessage({message: "Your purchase is submitted", code: SUCCESS_CODE})
                close()
            })
            .catch(error => {
                console.error("Error submitting the purchase", error)
                setUserMessage({message: "Error submitting the purchase.", code: ERROR_CODE})
                close()
            })
        
    }

    return (
        <CustomPopup
            text_title={
                `Do you want to buy ${amount > 1 ?  `${amount} ` : ""}${product.name} ${selectedProductVariant.formatted_options} 
                 with total price ${amount * product.raw_price}?`}
            isSubtitle
            isAgreePopup
        >
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={onClick}>
                        {selectedProductVariant?.status === PRODUCT_STATUS.IN_STOCK ? "Buy" : "Pre-order"}
                    </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default BuyProductPopup