import "./index.scss"

import React, { useContext } from "react"
import { ThemeContext } from "../../styles/theme-context"


const CustomScrollableTable = ({children}) => {
    const theme = useContext(ThemeContext)
    return (
        <div className={`custom-scrollable-table ${theme.tableClassName}`}>
            {children}
        </div>
    )
}

export default CustomScrollableTable
