import {useGetUserReportQuery} from "../api/timeRecords"
import {useMemo} from "react"


const useUserReport = ({ userId, params }, { skip } = {}) => {

    const {data, isLoading, isFetching, refetch } = useGetUserReportQuery({userId, params}, { skip })

    const result = useMemo(() => ({
        overtimes: data?.overtime.filter((o) => o.amount > 0) || [],
        bonuses: data?.bonus || [],
        earn: data?.salary || [],
        isLoading,
        isFetching,
        loading: isLoading || isFetching,
        refetch,
    }), [data, isLoading, isFetching, refetch])

    return result
}

export default useUserReport