import {LOCALSTORAGE_KEYS} from "../constants/other"


// AUTH
export const saveAuthorizedUserData = (settings) => {
    const oldSettings = getAuthorizedUserData()
    localStorage.setItem(LOCALSTORAGE_KEYS.SETTINGS, JSON.stringify({
        ...oldSettings,
        ...settings,
    }))
}

export const getAuthorizedUserData = () => {
    const settings = localStorage.getItem(LOCALSTORAGE_KEYS.SETTINGS)
    return settings ? JSON.parse(settings) : null
}

// REPORTS
export const saveReportsRange = (range, user=false) => {
    user ? localStorage.setItem(LOCALSTORAGE_KEYS.USER_REPORTS_RANGE, range) : localStorage.setItem(LOCALSTORAGE_KEYS.REPORTS_RANGE, range)
}

export const getSavedReportsRange = (user=false) => {
    return user ? localStorage.getItem(LOCALSTORAGE_KEYS.USER_REPORTS_RANGE) : localStorage.getItem(LOCALSTORAGE_KEYS.REPORTS_RANGE)
}

// PAYMENT REPORTS
export const savePaymentReportsMonth = (month) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.PAYMENT_REPORTS_MONTH, month)
}

export const getSavedPaymentReportsMonth = () => {
    return localStorage.getItem(LOCALSTORAGE_KEYS.PAYMENT_REPORTS_MONTH)
}

// TIME RECORDS
export const saveTimeRecordsRange = (month) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.TIME_RECORDS_RANGE, month)
}

export const getSavedTimeRecordsRange = () => {
    return localStorage.getItem(LOCALSTORAGE_KEYS.TIME_RECORDS_RANGE)
}

// DRAFT
export const saveDraft = (userId, draft) => {
    const oldDraft = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.DRAFT))

    localStorage.setItem(LOCALSTORAGE_KEYS.DRAFT, JSON.stringify({
        ...oldDraft,
        [userId]: draft,
    }))
}

export const getDraft = (userId) => {
    const draft = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.DRAFT))
    return (draft && (userId in draft)) ? draft[userId] : null
}

// GREETINGS
export const getGreetingEvent = (userId, type) => {
    const state = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.USER_GREETINGS))

    return (state && (userId in state) && type in state[userId]) ? state[userId][type] : null
}

export const saveGreetingEvent = (userId, type, value) => {
    const oldState = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.USER_GREETINGS))

    localStorage.setItem(LOCALSTORAGE_KEYS.USER_GREETINGS, JSON.stringify({
        ...oldState,
        [userId]: {
            ...(oldState && userId in oldState ? oldState[userId] : {}),
            [type]: value,
        }
    }))
}

export const getOnboardingPopupSeen = (userId) => {
    const state = localStorage.getItem(LOCALSTORAGE_KEYS.ONBOARDING_POPUP_SEEN)

    if (state) {
        const parsedState = JSON.parse(state)
        return parsedState[userId] ? parsedState[userId] : null
    }

    return null
}

export const saveOnboardingPopupSeen = (userId, value) => {
    const oldState = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.ONBOARDING_POPUP_SEEN))

    localStorage.setItem(LOCALSTORAGE_KEYS.ONBOARDING_POPUP_SEEN,
        JSON.stringify({
            ...oldState,
            [userId]: value,
        })
    )
}

// VIEW AS MEMBER
export const enableViewAsMemberMode = (user) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.VIEW_AS_MEMBER_MODE, JSON.stringify({
        "user_id": user.id,
        "full_name": `${user.first_name} ${user.last_name}`,
        "image_url": user.image_url,
    }))
}

export const disableViewAsMemberMode = () => {
    localStorage.setItem(LOCALSTORAGE_KEYS.VIEW_AS_MEMBER_MODE, null)
}

export const getUserViewedAs = () => {
    return JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.VIEW_AS_MEMBER_MODE))
}

export const saveSelectedHoursType = (type) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.SELECTED_HOURS_TYPE, type)
}

export const getSelectedHoursType = () => {
    return localStorage.getItem(LOCALSTORAGE_KEYS.SELECTED_HOURS_TYPE)
}

export const savePinTutorialSeen = () => {
    localStorage.setItem(LOCALSTORAGE_KEYS.PIN_TUTORIAL_SEEN, true)
}

export const getPinTutorialSeen = () => {
    return localStorage.getItem(LOCALSTORAGE_KEYS.PIN_TUTORIAL_SEEN)
}