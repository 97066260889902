import {salaryRelatedApi} from "./base"


export const salaryApi = salaryRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        createSalary: builder.mutation({
            query: (data) => ({path: "users/salaries", method: "POST", data}),
            invalidatesTags: ["Salaries"]
        }),
        updateSalary: builder.mutation({
            query: ({id, data}) => ({path: `users/salaries/${id}`, method: "PUT", data}),
            invalidatesTags: ["Salaries"]
        }),
        deleteSalary: builder.mutation({
            query: (id) => ({path: `users/salaries/${id}`, method: "DELETE"}),
            invalidatesTags: ["Salaries"]
        }),
    })
})

export const {
    useCreateSalaryMutation,
    useUpdateSalaryMutation,
    useDeleteSalaryMutation,
} = salaryApi
