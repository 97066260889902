import React from "react"
import { ReactComponent as PrivateModeOn } from "../assets/images/privateMode.svg"
import { ReactComponent as NewYearPrivateModeOn }  from "../assets/images/newYearPrivateMode.svg"
import { ReactComponent as PrivateModeOff } from "../assets/images/privateModeOff.svg"
import { ReactComponent as NewYearPrivateModeOff }  from "../assets/images/newYearPrivateModeOff.svg"
import { ReactComponent as EasterPrivateModeOn } from "../assets/images/easterPrivateMode.svg"
import { ReactComponent as EasterPrivateModeOff }  from "../assets/images/easterPrivateModeOff.svg"

import NewYearLogo from "../assets/images/newYearLogo.svg"
import NewYearHatImage from "../assets/images/new_year_hat_updated.svg"

import { CACHED_IMAGES, NEW_YEAR_SMILES, SMILES, EASTER_SMILES, WEBSITE_THEMES } from "../constants/other"


export const themes = {
    [WEBSITE_THEMES.STANDARD]: {
        privateModeOn: <PrivateModeOn className="navs-footer-private-mode-icon" />,
        privateModeOff: <PrivateModeOff className="navs-footer-private-mode-icon" />,

        mainContentClassName: "",
        navsClassName: "",
        navsAnimation: false,
        navsExtraLogo: <></>,
        toolbarClassName: "",

        loaderClassName: "newsoft-logo-loader",
        loadingLogo: CACHED_IMAGES.LogoNS,

        tableClassName: "",
        searchClassName: "",
        chipsClassName: "",

        profileCardHover: "profile-info-card-custom-hover",
        dashboardCard: "",

        smiles: SMILES
    },
    [WEBSITE_THEMES.NEW_YEAR]: {
        privateModeOn: <NewYearPrivateModeOn className="navs-footer-private-mode-icon-new-year-style" />,
        privateModeOff: <NewYearPrivateModeOff className="navs-footer-private-mode-icon-new-year-style" />,

        mainContentClassName: "new-year-style-main-content",
        navsClassName: "new-year-style-navs",
        navsAnimation: true,
        navsExtraLogo: <img className="new-year-hat" src={NewYearHatImage} alt="New Year Hat"/>,
        toolbarClassName: "new-year-style-toolbar",

        loaderClassName: "new-year-style-newsoft-logo-loader",
        loadingLogo: NewYearLogo,

        tableClassName: "new-year-style-table",
        searchClassName: "",
        chipsClassName: "",

        profileCardHover: "custom-new-year-style-hover",
        dashboardCard: "new-year-style-dashboard-card",

        smiles: NEW_YEAR_SMILES,
    },
    [WEBSITE_THEMES.EASTER]: {
        privateModeOn: <EasterPrivateModeOn className="navs-footer-private-mode-icon-easter-style" />,
        privateModeOff: <EasterPrivateModeOff className="navs-footer-private-mode-icon-easter-style" />,

        mainContentClassName: "easter-style-main-content",
        navsClassName: "easter-style-navs",
        navsAnimation: false,
        navsExtraLogo: <></>,
        toolbarClassName: "easter-style-toolbar",

        loaderClassName: "newsoft-logo-loader",
        loadingLogo: CACHED_IMAGES.LogoNS,

        tableClassName: "easter-style-table",
        searchClassName: "easter-search",
        chipsClassName: "easter-chips",

        profileCardHover: "custom-easter-style-hover",
        dashboardCard: "custom-easter-style-hover",

        smiles: EASTER_SMILES
    },
}


export const ThemeContext = React.createContext(
    themes[WEBSITE_THEMES.STANDARD]
)