import React, {useState} from "react"
import {Helmet} from "react-helmet"
import AuditLogsList from "./components/AuditLogsList"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SingleDatePicker from "../../toolkits/Calendar/SingleDatePicker"
import {useTranslation} from "react-i18next"


const AuditLogs = () => {
    const {t} = useTranslation("common")
    const [userMessage, setUserMessage] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    return (
        <>
            <Helmet>
                <title>{t("Audit Logs")} - Newsoft Inside</title>
            </Helmet>

            <div style={{ display: "flex", paddingTop: "1rem", gap: "1rem", paddingLeft: "1.75rem" }}>
                <SingleDatePicker
                    label={t("From")}
                    defaultValue={startDate}
                    className="single-datepicker-field"
                    onChange={setStartDate}
                    minutes={true}
                    reset={true}
                    calendarPosition="bottom-left"
                />

                <SingleDatePicker
                    label={t("To")}
                    defaultValue={endDate}
                    className="single-datepicker-field"
                    onChange={setEndDate}
                    minutes={true}
                    reset={true}
                    calendarPosition="bottom-left"
                />
            </div>

            {userMessage && <AlertMessage setMessage={setUserMessage} {...userMessage}/> }

            <AuditLogsList startDate={startDate} endDate={endDate} setUserMessage={setUserMessage}/>

        </>)
}

export default AuditLogs
