import "./index.scss"

import React from "react"
import CopyButton from "../Miscellaneous/CopyButton"

const TextSnippet = ({title, content, canCopy, onCopy }) => {
    return (
        <div className="text-snippet">
            <div className="text-snippet-header">
                {title}
                {canCopy && (<CopyButton value={content} onCopy={onCopy}/>)}
            </div>
            <div className="text-snippet-content">
                <pre >
                    {content}
                </pre>
            </div>

        </div>


    )
}

export default TextSnippet
