import {useGetEmployeePositionsQuery} from "../api/employeePositions"


const useEmployeePositions = ({ params, skip } = {}) => {
    const {data, isLoading} = useGetEmployeePositionsQuery(params, { skip })

    return {
        employeePositions: data?.items || [],
        total: data?.total,
        isLoading,
    }
}

export default useEmployeePositions
