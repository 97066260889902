import React from "react"

import {CACHED_IMAGES} from "../../../constants/other"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import YoutubeIcon from "@mui/icons-material/YouTube"
import {NEWSOFT_SOCIAL_MEDIAS, ONBOARDING_STEPS,} from "../../../constants/onboarding"
import {useUpdateOnboardingMutation} from "../../../api/onboardings"

const LastStepPopup = ({userOnboarding, close}) => {
    const [updateOnboarding] = useUpdateOnboardingMutation()

    const checkOnboardingFirstStep = () => {
        const data = {
            steps: {
                ...userOnboarding.steps,
                [ONBOARDING_STEPS[0]]: {
                    ...userOnboarding.steps[ONBOARDING_STEPS[0]],
                    checked: true,
                },
            },
        }

        updateOnboarding({id: userOnboarding.id, data})
    }

    return (
        <div className="greetings-welcome">
            <img src={CACHED_IMAGES.LogoNS} className="greetings-welcome-logo-ns"/>
            <div className="greetings-welcome-content">
                <div className="greetings-welcome-content-title t-h1">
                    <div>We are glad to see you in our team 👀</div>
                </div>
                <br />
                <div className="greetings-welcome-content-text t-b5">
                    <div>Subscribe to our social networks:</div>
                </div>
                <div className="greetings-welcome-social-medias">
                    <a href={NEWSOFT_SOCIAL_MEDIAS.FACEBOOK_URL}>
                        <FacebookIcon className="media-icon" target="__blank" rel="noopener noreferrer"/>
                    </a>
                    <a href={NEWSOFT_SOCIAL_MEDIAS.INSTAGRAM_URL}>
                        <InstagramIcon className="media-icon" target="__blank" rel="noopener noreferrer"/>
                    </a>
                    <a href={NEWSOFT_SOCIAL_MEDIAS.LINKEDIN_URL}>
                        <LinkedInIcon className="media-icon" target="__blank" rel="noopener noreferrer"/>
                    </a>
                    <a href={NEWSOFT_SOCIAL_MEDIAS.YOUTUBE_URL}>
                        <YoutubeIcon className="media-icon" target="__blank" rel="noopener noreferrer"/>
                    </a>
                </div>
            </div>
            <button onClick={() => {close(); checkOnboardingFirstStep()}} className="greetings-welcome-button t-button">Let&#39;s start working together</button>
        </div>
    )
}

export default LastStepPopup
