import React from "react"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import {PIN_TUTORIAL_VIDEO_LINK} from "../../../constants/other"
import PinTutorialImage from "../../../assets/images/PinTutorialImage.png"

const PinTutorialPopup = ({close}) => {
    return (
        <FlexiblePopup>
            <div className="pin-tutorial-popup">
                <div className="t-h2">Pin as Template</div>
                <span className="t-s4">If you wish, you can pin your clocked hours as a template. Simply go to the Records table on this page and pin the desired record.</span>
                <video autoPlay loop src={PIN_TUTORIAL_VIDEO_LINK} poster={PinTutorialImage}></video>
                <SmallButton btnType={"secondary"} onClick={close}>OK</SmallButton>
            </div>
        </FlexiblePopup>
    )
}

export default PinTutorialPopup