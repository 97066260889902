import "./NumberInput.scss"

import React, {createRef, useEffect} from "react"
import {useMediaQuery} from "react-responsive"

import {DESKTOP_WIDTH} from "../../constants/other"


export const NumberInput = (props) => {
    const inputRef = createRef()
    const isDesktop = useMediaQuery({maxWidth: DESKTOP_WIDTH})

    useEffect(() => {
        if (isDesktop) {
            inputRef.current.blur()
        }
    }, [])

    return  <input {...props} ref={inputRef} type='number' className="number-input"/>
}
