import React, {useEffect, useState} from "react"
import Popup from "reactjs-popup"

import Protected from "../../../components/Protected/Protected"
import InfoPopup from "../../../components/InfoPopup"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import CreateUpdateSalaryPopup from "./CreateUpdateSalaryPopup"
import CreateUpdateOvertimePopup from "./CreateUpdateOvertimePopup"
import DeleteSalaryPopup from "./DeleteSalaryPopup"
import DeleteOvertimePopup from "./DeleteOvertimePopup"
import ProjectsConflictPopup from "./ProjectsConflictPopup"

import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"

import {formatDate, isDateInPast} from "../../../utils/datetime.utils"
import {isNotZero} from "../../../utils/other.utils"

import ArrowDown from "@mui/icons-material/KeyboardArrowDown"
import InfoIcon from "@mui/icons-material/Info"

import {DD_MM_YYYY, FAR_FUTURE_DATE, SALARY_TYPES} from "../../../constants/other"
import {PERMISSIONS} from "../../../constants/permissions"


export const SalaryHistoryTable = ({username, salaries, setShowDraftCreateSalary, refreshTable, setUserMessage}) => {
    const [dropDownFlags, setDropDownFlags] = useState([])
    const [projectOccurrences, setProjectOccurrences] = useState({})


    useEffect(() => {
        const farFutureDate = new Date(FAR_FUTURE_DATE)
        const projectPeriodsMap = salaries.reduce((acc, salary) => {
            salary.projects.forEach(project => {
                if (!acc[project.name]) {
                    acc[project.name] = []
                }
                const periodEnd = salary.end_date ? new Date(salary.end_date) : farFutureDate
                acc[project.name].push({ start: new Date(salary.start_date), end: periodEnd, salaryId: salary.id })
            })
            return acc
        }, {})

        const doPeriodsOverlap = (period1, period2) => {
            return !(period1.end < period2.start || period2.end < period1.start)
        }

        let salariesWithConflicts = {}
        Object.keys(projectPeriodsMap).forEach(projectName => {
            const periods = projectPeriodsMap[projectName]
            periods.forEach((period1, i) => {
                periods.forEach((period2, j) => {
                    if (i !== j && doPeriodsOverlap(period1, period2)) {
                        if (!salariesWithConflicts[period1.salaryId]) {
                            salariesWithConflicts[period1.salaryId] = new Set()
                        }
                        if (!salariesWithConflicts[period2.salaryId]) {
                            salariesWithConflicts[period2.salaryId] = new Set()
                        }

                        salariesWithConflicts[period1.salaryId].add(projectName)
                        salariesWithConflicts[period2.salaryId].add(projectName)
                    }
                })
            })
        })

        let formattedSalariesWithConflicts = {}
        Object.keys(salariesWithConflicts).forEach(salaryId => {
            formattedSalariesWithConflicts[salaryId] = Array.from(salariesWithConflicts[salaryId])
        })

        setProjectOccurrences(formattedSalariesWithConflicts)
    }, [salaries])

    const salariesList = salaries.map((salary, i) => {
        const popupParams = {
            currentSalary: salary,
            userId: salary.user_id,
            setUserMessage: setUserMessage,
            refreshTable: refreshTable,
        }

        const salaryHasEnded = salary.end_date && isDateInPast(new Date(salary.end_date))

        const projectsWithConflicts = salary.projects.filter(p => projectOccurrences[salary.id]?.includes(p.name)).map(p => p.name)

        const hasConflict = projectsWithConflicts.length > 0

        const projectsInfo = (
            <>
                {hasConflict && !salaryHasEnded && <Popup trigger={<InfoIcon className="salary-history-info-icon"/>} modal>
                    {close => (
                        <ProjectsConflictPopup
                            title="Project Assignment Conflict"
                            value={conflictMessage}
                            close={close}
                        />
                    )}
                </Popup>}
                {salary.projects.map((project, index) => (
                    <span key={project.name}>
                        {project.name}{index < salary.projects.length - 1 ? ", " : ""}</span>
                ))}
            </>
        )


        const formatConflictMessage = (projects) => {
            if (projects.length === 0) return ""

            if (projects.length === 1) {
                return `The '${projects[0]}' project is currently linked to two active salaries. Please remove this project from one of the active salaries.`
            }

            const projectsList = projects.slice(0, -1).join("', '")
            const lastProject = projects[projects.length - 1]
            return `The '${projectsList}' and '${lastProject}' projects are currently linked to two active salaries. Please remove these projects from one of the active salaries.`
        }

        const conflictMessage = formatConflictMessage(projectsWithConflicts)

        const updatedInfo =`${formatDate(salary.updated_at, DD_MM_YYYY)} (${
            salary.updated_by_user
                ? `${salary.updated_by_user.last_name} ${salary.updated_by_user.first_name[0]}.`
                : ""})`

        const tableActions = (
            <>
                <Popup trigger={<span className="custom-scrollable-table-link">add coeff</span>} modal>
                    {close => (
                        <CreateUpdateOvertimePopup
                            salaryId={salary.id}
                            minDate={salary.start_date}
                            maxDate={salary.end_date}
                            setUserMessage={setUserMessage}
                            refreshTable={refreshTable}
                            close={close}
                        />
                    )}
                </Popup>
                <Popup trigger={
                    <span className="custom-scrollable-table-link">copy as draft</span>
                } closeOnDocumentClick={false} modal>
                    {close => (
                        <CreateUpdateSalaryPopup
                            username={username}
                            create={true}
                            resetStartDate={true}
                            setShowDraftCreateSalary={setShowDraftCreateSalary}
                            close={close}
                            {...popupParams}
                        />
                    )}
                </Popup>
                <Popup trigger={
                    <span className="custom-scrollable-table-link">edit</span>
                } closeOnDocumentClick={false} modal>
                    {close => (
                        <CreateUpdateSalaryPopup
                            username={username}
                            close={close}
                            {...popupParams}
                        />
                    )}
                </Popup>
                <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                    {close => (
                        <DeleteSalaryPopup
                            username={username}
                            close={close}
                            {...popupParams}
                        />
                    )}
                </Popup>
            </>
        )

        const OvertimeTable = ({tableId}) => {
            const getActions = (overtime) => (
                <>
                    <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                        {close => (
                            <CreateUpdateOvertimePopup
                                overtime={overtime}
                                salaryId={salary.id}
                                minDate={salary.start_date}
                                maxDate={salary.end_date}
                                refreshTable={refreshTable}
                                setUserMessage={setUserMessage}
                                close={close}
                            />
                        )}
                    </Popup>
                    <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                        {close => (
                            <DeleteOvertimePopup
                                username={username}
                                overtime={overtime}
                                refreshTable={refreshTable}
                                setUserMessage={setUserMessage}
                                close={close}
                            />
                        )}
                    </Popup>
                </>
            )
            return (
                <table
                    id={tableId}
                    className={`salary-history-overtimes t-s2 ${
                        dropDownFlags.includes(salary.id)
                            ? "salary-history-overtimes-show"
                            : ""}
                    `}
                >
                    <tbody>
                        {salary.overtimes.map(overtime => (
                            <tr key={`overtime-coeff-${overtime.id}`}>
                                <td>
                                    <span className={
                                        `t-s1 ${(overtime.end_date && isDateInPast(new Date(overtime.end_date)) || salaryHasEnded)
                                            ? "salary-history-overtimes-inactive" : ""}`
                                    }
                                    >{`x${overtime.coefficient.toFixed(1)}`}</span>
                                </td>
                                <td>
                                    <span className={
                                        `${overtime.end_date && isDateInPast(new Date(overtime.end_date))
                                            ? "salary-history-overtimes-inactive" : ""}`
                                    }
                                    >{`${formatDate(overtime.start_date, DD_MM_YYYY)}${
                                            overtime.end_date ? "-" + formatDate(overtime.end_date, DD_MM_YYYY) : ""}`}</span>
                                </td>
                                <td>
                                    <Protected permissions={[PERMISSIONS.WRITE_SALARY]}>
                                        {getActions(overtime)}
                                    </Protected>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )
        }

        return (
            <>
                <tr key={`${salary.id}`} className={
                    `custom-scrollable-table-row salary-history-content-table-row${
                        (!salary.end_date || new Date(salary.end_date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0))
                            ? (new Date(salary.start_date) >= new Date().setHours(0, 0, 0, 0) ? "-white" : "-yellow")
                            : ""
                    }`
                }>
                    <td>
                        <td className="salary-history-content-table-first-column">
                            <span className={`t-s1 ${salaryHasEnded ? "salary-ended" : ""}`}>
                                <SensitiveInfo value={<>
                                    {`${salary.amount} ${salary.currency.iso_code}`}
                                    { salary.salary_type === SALARY_TYPES.PER_HOUR && <small className="per-hour-text t-h4"> {salary?.salary_type}</small>}
                                    { salary.salary_type === SALARY_TYPES.FIXED_PRICE && <small className="per-hour-text t-h4"> {SALARY_TYPES.PER_MONTH}</small>}
                                    {
                                        <span className="t-b3 text-top">
                                            {isNotZero(salary.active_overtime_coefficient) && `x${salary.active_overtime_coefficient}`}
                                            {salary.overtimes.length ?
                                                <ArrowDown
                                                    className={dropDownFlags.includes(salary.id)
                                                        ? "arrow salary-history-overtimes-rotate"
                                                        : "arrow"
                                                    }
                                                    onClick={() =>
                                                        setDropDownFlags(state => !dropDownFlags.includes(salary.id)
                                                            ? [...state, salary.id]
                                                            : state.filter(x => x != salary.id)
                                                        )
                                                    }
                                                /> : <></>
                                            }
                                        </span>
                                    }
                                </>
                                }>
                                </SensitiveInfo>
                            </span>
                            <div className={`salary-history-payment-type-${salary.primary ? "primary" : "secondary"}${salary.end_date ? "-ext" : ""}`}>
                                {salary.primary ? "primary" : "secondary"}
                            </div>
                            {!i && <div className="salary-history-header-span">
                                <span className="custom-scrollable-table-head-span">SALARY</span>
                                <span className="custom-scrollable-table-span t-b3 text-top">Overtime coeff</span>
                            </div>}
                        </td>
                        <td><span className={`${salaryHasEnded ? "salary-ended" : ""}`}>{`${formatDate(salary.start_date, DD_MM_YYYY)}${salary.end_date ? "-" + formatDate(salary.end_date, DD_MM_YYYY) : ""}`}</span>
                            {!i &&
                                <span className="custom-scrollable-table-head-span salary-history-header-span">PERIOD</span>
                            }
                        </td>
                        <td>
                            <Popup trigger={<span className={`cell-limited-text clickable ${salaryHasEnded ? "salary-ended" : ""}`}> {salary.client?.name} </span>} modal>
                                {close => (
                                    <InfoPopup
                                        title="Client"
                                        value={salary.client?.name}
                                        close={close}
                                    />
                                )}
                            </Popup>
                            {!i &&
                                <span className="custom-scrollable-table-head-span salary-history-header-span">CLIENT</span>
                            }
                        </td>
                        <Protected permissions={[PERMISSIONS.READ_SALARY]}>
                            <td>
                                <div className="salary-history-content-table-projects-cell">
                                    <Popup trigger={<span className={`cell-limited-text clickable ${salaryHasEnded ? "salary-ended" : ""}`}> {projectsInfo} </span>} modal>
                                        {close => (
                                            <InfoPopup
                                                title="Projects"
                                                value={projectsInfo}
                                                close={close}
                                            />
                                        )}
                                    </Popup>
                                    {!i &&
                                        <span className="custom-scrollable-table-head-span salary-history-header-span">PROJECTS</span>
                                    }
                                </div>
                            </td>

                            <td>
                                <Popup trigger={<span className={`cell-limited-text clickable ${salaryHasEnded ? "salary-ended" : ""}`}>{updatedInfo}</span>} modal>
                                    {close => (
                                        <InfoPopup
                                            title="Updated"
                                            value={updatedInfo}
                                            close={close}
                                        />
                                    )}
                                </Popup>
                                {!i &&
                                    <span className="custom-scrollable-table-head-span salary-history-header-span">UPDATED</span>
                                }
                            </td>
                        </Protected>
                        <td>
                            <Popup trigger={<span className={`cell-limited-text clickable ${salaryHasEnded ? "salary-ended" : ""}`}> {salary.note} </span>} modal>
                                {close => (
                                    <InfoPopup
                                        title="Salary note"
                                        value={salary.note}
                                        close={close}
                                    />
                                )}
                            </Popup>
                            {!i &&
                                <span className="custom-scrollable-table-head-span salary-history-header-span">NOTE</span>
                            }
                        </td>
                        <Protected permissions={[PERMISSIONS.WRITE_SALARY]}>
                            <td>
                                {tableActions}
                                <Protected permissions={[PERMISSIONS.WRITE_SALARY]}>
                                    <span className="custom-scrollable-table-head-span salary-history-header-span">ACTIONS</span>
                                </Protected>
                            </td>
                        </Protected>
                        <OvertimeTable tableId={`salary-history-overtimes-table-${salary.id}`}/>
                    </td>
                </tr>
            </>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="salary-history-content-table">
                <thead>
                    {salaries.length > 0 ? <tr><th></th></tr> :
                        <tr>                        
                            <th>
                                <span className="custom-scrollable-table-head-span-first">SALARY</span>
                                <span className="custom-scrollable-table-span t-b3 text-top">Overtime coeff</span>
                            </th>
                            <th><span className="custom-scrollable-table-head-span">PERIOD</span></th>
                            <th><span className="custom-scrollable-table-head-span">CLIENT</span></th>
                            <th><span className="custom-scrollable-table-head-span">PROJECTS</span></th>
                            <th><span className="custom-scrollable-table-head-span">UPDATED</span></th>
                            <th><span className="custom-scrollable-table-head-span">NOTE</span></th>
                            <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                        </tr>
                    }
                </thead>
                <tbody>
                    {salaries.length > 0 ? salariesList  : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}
