import {timeRelatedApi} from "./base"


export const timeOffRequests = timeRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        getTimeOffRequests: builder.query({
            query: (params) => ({
                path: "time-off-requests/",
                params,
            }),
            providesTags: ["TimeOffRequests"]
        }),
        getUserTimeOffRequests: builder.query({
            query: ({userId, ...params}) => ({
                path: `users/${userId}/time-off-requests`,
                params,
            }),
            providesTags: (result, error, arg) => [{type: "UserTimeOffRequests", id: arg.userId}]
        }),
        createTimeOffRequest: builder.mutation({
            query: (data) => ({path: "time-off-requests/", method: "POST", data}),
            invalidatesTags: ["UserCalendarData", "TimeOffsUsageInfo", "TimeOffBalances", "TimeOffRequests", "UserTimeOffRequests"]
        }),
        updateTimeOffRequest: builder.mutation({
            query: ({id, data}) => ({path: `time-off-requests/${id}`, method: "PUT", data}),
            invalidatesTags: ["UserCalendarData", "TimeOffsUsageInfo", "TimeOffBalances", "TimeOffRequests", "UserTimeOffRequests"]
        }),
        deleteTimeOffRequest: builder.mutation({
            query: (id) => ({path: `time-off-requests/${id}`, method: "DELETE"}),
            invalidatesTags: ["UserCalendarData", "TimeOffsUsageInfo", "TimeOffBalances", "TimeOffRequests", "UserTimeOffRequests"]
        }),
    })
})

export const {
    useGetTimeOffRequestsQuery,
    useGetUserTimeOffRequestsQuery,
    useCreateTimeOffRequestMutation,
    useUpdateTimeOffRequestMutation,
    useDeleteTimeOffRequestMutation,
} = timeOffRequests
