/* eslint-disable no-unused-vars */
import React, {useState} from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE, COUPON_TYPE} from "../../../constants/other"
import DDList from "../../../toolkits/DDList/DDList"
import Textarea from "../../../toolkits/Textarea/Textarea"
import {useCreateCouponMutation, useUpdateCouponMutation} from "../../../api/coupons"
import {useTranslation} from "react-i18next"


const CreateUpdateCouponPopup = ({coupon, setUserMessage, refreshTable, close}) => {
    const { t } = useTranslation("common")

    const [createCoupon, {isLoading: createLoading}] = useCreateCouponMutation()
    const [updateCoupon, {isLoading: updateLoading}] = useUpdateCouponMutation()
    const isLoading = createLoading || updateLoading

    const [amount, setAmount] = useState(coupon?.coins_reward)
    const [code, setCode] = useState(coupon?.code)
    const [type, setType] = useState(coupon?.type)
    const [reason, setReason] = useState(coupon?.reason)
    const [message, setMessage] = useState(coupon?.message)

    const handleSubmit = () => {
        const body = {
            type: type.value,
            coins_reward: amount,
            code: code,
            reason: reason,
            message: message,
        }
        if (coupon) {
            updateCoupon({id: coupon.id, data: body})
                .unwrap()
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: t("Coupon was successfully updated!"), code: SUCCESS_CODE})
                })
                .catch(error => {
                    console.log(error)
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        } else {
            createCoupon(body)
                .unwrap()
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: t("Coupon was successfully created!"), code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup text_title={coupon ? t("Edit Coupon") : t("Add Coupon")}>
            {!coupon &&<DDList
                text_label={t("Type")}
                placeholder={t("Choose a type")}
                listOptions={[COUPON_TYPE.ONE_TIME, COUPON_TYPE.ONE_TIME_PER_USER]}
                onChange={type => setType(type)}
                value={type}
            />}
            <div className="coupons-amount-container">
                <span className="coupons-create-amount">+</span>
                <TextInput
                    text_label={t("Amount")}
                    placeholder="0"
                    type="number"
                    defaultValue={amount}
                    min={0}
                    style={{"paddingLeft": "30px"}}
                    onChange={(e) => setAmount(e.target.value)}
                />
            </div>
            {!coupon && <TextInput
                text_label={t("Code")}
                placeholder={t("Optional")}
                value={code}
                onChange={(e) => setCode(e.target.value)}
            />}
            <Textarea
                text_label={t("Reason")}
                placeholder={t("Why this coupon was created...")}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
            />
            <Textarea
                text_label={t("Message")}
                placeholder={t("Message user will see when coupon is claimed")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> {t("Cancel").toUpperCase()} </SmallButton>
                    <SmallButton
                        type="submit"
                        loading={isLoading}
                        onClick={handleSubmit}
                        disabled={!type | !amount}
                    >{coupon ? t("Save").toUpperCase() : t("Create").toUpperCase()}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateUpdateCouponPopup
