import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"


const DeleteDefaultCalendarPopup = ({calendar, close}) => {

    return (
        <FlexiblePopup text_title="Delete Default Calendar">
            <div className="calendars-popup">
                <div className="t-h2 calendars-popup-title">Delete Default Calendar</div>
                <div className="t-b1 calendars-popup-subtitle">The `{calendar.name}` calendar is set as the default. Please select a new default before deleting the current one.</div>

                <div className="calendars-popup-bottom">
                    <SmallButton btnType="secondary" onClick={close}> OK </SmallButton>
                </div>
            </div>
        </FlexiblePopup>
    )
}

export default DeleteDefaultCalendarPopup
