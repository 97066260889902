import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import StyledCircularProgress from "../../../../toolkits/CircularProgress/CircularProgress"
import FlexiblePopup from "../../../../toolkits/FlexiblePopup"
import ClosePopupIcon from "../../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import {
    receiveUserNewcoinsTransactions
} from "../../../SalaryHistory/actions/newcoinsHistoryAction"
import {MOBILE_WIDTH, PAYMENTS_TABS} from "../../../../constants/other"
import {formatDate} from "../../../../utils/datetime.utils"
import Select from "react-select"
import {useMediaQuery} from "react-responsive"
import Newcoin from "../../../../toolkits/Newcoin"
import {PERMISSIONS} from "../../../../constants/permissions"
import CustomLink from "../../../../toolkits/CustomLink"
import {SalaryHistoryRoute} from "../../../../routes"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import {SensitiveInfo} from "../../../../components/SensitiveInfo/SensitiveInfo"
import useCoinsBalance from "../../../../hooks/useCoinsBalance"


const NewcoinsUsagePopup = ({user, close}) => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const { hasPermission } = useSelector((state) => state.profile)
    const {
        tableLoading,
        newcoinsTransactions,
    } = useSelector((state) => state.newcoinsTransactions)
    const userId = user?.id
    const { balance } = useCoinsBalance({ userId })
    const startYear = new Date(user.hiring_date).getFullYear()
    const currentYear = new Date().getFullYear()
    const [yearOptions, setYearOptions] = useState([])
    const [selectedYear, setSelectedYear] = useState({ value: currentYear, label: `${currentYear}` })

    useEffect(() => {
        const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
            return { value: startYear + i, label: `${startYear + i}` }
        }).sort((a, b) => b.value - a.value)
        setYearOptions(years)
    }, [user])

    const getParams = () => ({
        user_id: user?.id,
        year: selectedYear.value,
    })

    const getUserNewcoinsTransactions = () => {
        const params = getParams()

        dispatch(receiveUserNewcoinsTransactions(params))
    }

    const handleYearChange = async (selectedOption) => {
        setSelectedYear(selectedOption)
    }

    useEffect(() => {
        getUserNewcoinsTransactions()
    }, [selectedYear])

    return (
        <FlexiblePopup>
            <div className="newcoins-popup">
                <div className="newcoins-popup-title t-h2">Newcoins Usage</div>
                <div className="newcoins-popup-header">
                    <span className={`newcoins-popup-header-info-text${balance > 0 ? "" : "-inactive"} ${isMobile ? "t-s3" : "t-s1"}`}>
                        BALANCE:
                    </span>
                    <SensitiveInfo className="sensitive-info">
                        <span className={`newcoins-popup-header-info-balance${balance > 0 ? "" : "-inactive"} ${isMobile ? "t-s3" : "t-s1"}`}>
                            {balance} <Newcoin type={balance > 0 ? "active" : "empty"}/>
                        </span>
                    </SensitiveInfo>
                    <div className="newcoins-popup-header-filter">
                        <Select
                            isSearchable={false}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={yearOptions}
                            value={selectedYear}
                            onChange={handleYearChange}
                            classNamePrefix="custom-select t-s1 working-year-react-select"
                        />
                    </div>
                </div>
                <table className="newcoins-popup-table">
                    <thead className="newcoins-popup-table-head">
                        <tr className="newcoins-popup-table-row heading-color t-h3">
                            <th className="newcoins-popup-table-row-date">Date</th>
                            <th className="newcoins-popup-table-row-note">Note</th>
                            <th className="newcoins-popup-table-row-transaction">Transaction</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableLoading ? <div className="newcoins-popup-loading"><StyledCircularProgress/></div>
                            :
                            (newcoinsTransactions && newcoinsTransactions.map((transaction, i) => (
                                (<>
                                    <hr className={` ${ i===0 ? "first" : ""} newcoins-popup-table-divider`}/>
                                    <tr key={i} className="newcoins-popup-table-row t-b2">
                                        <td className="newcoins-popup-table-row-date t-b2">{transaction.pending ? <span className="newcoins-popup-pending t-b3">pending</span> : formatDate(transaction.created_at, "DD.MM")}</td>
                                        <td className="newcoins-popup-table-row-note t-b2"
                                            onClick={(e) => {
                                                e.target.classList.contains("newcoins-popup-table-row-note-show")
                                                    ? e.target.classList.remove("newcoins-popup-table-row-note-show")
                                                    : e.target.classList.add("newcoins-popup-table-row-note-show")
                                            }}
                                        >{transaction.reason}</td>
                                        <td className="newcoins-popup-table-row-transaction t-s4">
                                            <SensitiveInfo>
                                                {transaction.amount > 0
                                                    ? <><span className={`${transaction.pending ? "" : "newcoins-history-received"}`}>+{transaction.amount}</span></>
                                                    : <><span className={`${transaction.pending ? "" : "newcoins-history-withdrawn"}`}>{transaction.amount}</span></>
                                                }
                                            </SensitiveInfo>
                                        </td>
                                    </tr>

                                </>)
                            )))}
                    </tbody>
                </table>
                {hasPermission(PERMISSIONS.READ_NEWCOINS) && !tableLoading && <div className="popup-bottom">
                    <CustomLink to={{
                        pathname: SalaryHistoryRoute.path,
                        state: {
                            user,
                            tab: PAYMENTS_TABS.NEWCOINS,
                        }
                    }}>
                        <SmallButton style={{marginBottom: 40}} btnType="secondary"> See all </SmallButton>
                    </CustomLink>
                </div>}
                <ClosePopupIcon className="newcoins-popup-close" onClick={close}/>
            </div>
        </FlexiblePopup>
    )
}

export default NewcoinsUsagePopup
