import {getDashboardNotifications} from "../api"
import {GET_DASHBOARD_NOTIFICATION_REQUEST, GET_DASHBOARD_NOTIFICATION_SUCCESS,} from "../const"

import {PERMISSIONS} from "../../../constants/permissions"


const request = () => ({type: GET_DASHBOARD_NOTIFICATION_REQUEST})

const success = (payload) => ({
    type: GET_DASHBOARD_NOTIFICATION_SUCCESS,
    payload: payload,
})


export const receiveNotifications = (cancelToken) => async (dispatch, getState) => {
    const {hasPermission} = getState().profile
    if(!hasPermission(PERMISSIONS.READ_DASHBOARD_NOTIFICATION)){
        return
    }

    dispatch(request())
    try {
        const {data: dashboardData}  = await getDashboardNotifications(cancelToken)

        dispatch(success({
            performanceReviewCount: dashboardData.performance_review_count,
            timeOffRequestsCount: dashboardData.time_off_requests_count,
            timeRecordRequestsCount: dashboardData.time_record_requests_count,
            onboardingRequestsCount: dashboardData.onboarding_requests_count,
            purchaseRequestsCount: dashboardData.purchase_requests_count,
            total_count: dashboardData.total_count
        }))
    }
    catch (e) {
        console.log(e)
    }
}
