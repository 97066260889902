import {getProductById} from "../api"
import {GET_PRODUCT_FAILURE, GET_PRODUCT_REQUEST, GET_PRODUCT_SUCCESS} from "../const"


const request = () => ({type: GET_PRODUCT_REQUEST})

const success = (product) => ({
    type: GET_PRODUCT_SUCCESS,
    payload: {product},
})

const failure = () => ({type: GET_PRODUCT_FAILURE})

export const receiveProduct = (id) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveProductData} = await getProductById(id)

        dispatch(success(receiveProductData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}