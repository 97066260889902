import React from "react"
import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import {useDeleteProductMutation} from "../../../api/products"
import {useTranslation} from "react-i18next"

const DeleteProductPopup = ({product, refreshTable, setUserMessage, close}) => {
    const { t } = useTranslation("common")

    const [deleteProduct] = useDeleteProductMutation()
    // DELETE https://api.dev.inside.newsoft-ns.com/api/v1/products/157
    const handleSubmit = () => {
        deleteProduct({ productId: product.id })
            .unwrap()
            .then(() => {
                refreshTable()
                setUserMessage({message: t("Product was successfully deleted!"), code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={t("Do you want to delete {{product}}?", {product: product.name })} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> {t("Cancel").toUpperCase()} </SmallButton>
                    <SmallButton onClick={handleSubmit}> {t("Delete").toUpperCase()} </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteProductPopup