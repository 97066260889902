import React, {useState} from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {changeDateFormat} from "../../../utils/datetime.utils"

import {SUCCESS_CODE, ERROR_CODE, DD_MM_YYYY} from "../../../constants/other"
import {useAssignProjectMutation} from "../../../api/projects"
import DeprecatedSingleDatePicker from "../../../toolkits/Calendar/DeprecatedSingleDatePicker"


const AssignProjectPopup = ({
    userId,
    project,
    username,
    setUserMessage,
    close,
}) => {
    const [assignProject] = useAssignProjectMutation()

    const [newDate, setNewDate] = useState(null)

    const handleSubmit = () => {
        const data = {
            project_id: project?.id || project.value,
            user_id: userId,
            start_date: changeDateFormat(newDate, DD_MM_YYYY, "YYYY-MM-DD"),
        }
    
        assignProject(data)
            .unwrap()
            .then(() => {
                setUserMessage({
                    message: `The project ${project?.name || project.label} was successfully assigned to ${username}`,
                    code: SUCCESS_CODE,
                })
                close()
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup
            wrapperClass="custom-popup-card-unscrollable-wrapper"
            className="first-day-popup"
            text_title={`When has ${username} started to work on “${project?.name || project.label}”?`}
            isSubtitle={true}
        >
            <DeprecatedSingleDatePicker
                label="Start date"
                className="single-datepicker-field"
                setNewDate={setNewDate}
                defaultValue={null}
            /> 
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}>CANCEL</SmallButton>
                    <SmallButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!newDate}
                    >
                        SAVE
                    </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default AssignProjectPopup
