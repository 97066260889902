import React from "react"


import {USEFUL_CONTACTS} from "../../../constants/onboarding"

const ContactItem = ({ photo, name, position, infoList }) => (
    <div className="useful-contacts-item">
        <div className="useful-contacts-person">
            <img loading="lazy" src={photo} alt={name} className="useful-contacts-person-photo"/>
            <div>
                <span className="useful-contacts-person-name t-s1">{name}</span>
                <span className="useful-contacts-position t-s4">{position}</span>
            </div>
        </div>
        <ul className="useful-contacts-info-list">
            {infoList.map((info, index) => (
                <li key={index} className="t-s4">{info}</li>
            ))}
        </ul>
    </div>
)

const UsefulContactsPopup = () => (
    <div className="useful-contacts-popup-grid">
        {Object.values(USEFUL_CONTACTS).map((contact) => (
            <ContactItem key={contact.name} photo={contact.photo} name={contact.name} position={contact.position} infoList={contact.infoList}/>
        ))}
    </div>
)

export default UsefulContactsPopup
