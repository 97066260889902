import React from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"


const PermissionsConflictPopup = ({permissions, close}) => {
    return (
        <CustomPopup text_title="Permissions Conflict" className="roles-popup">
            <div className="popup-content">
                <div className="t-s2 roles-popup-message">
                    You can not view as member this profile, because it has more permissions than you:
                </div>
                <span className="roles-popup-label t-s3">Permissions</span>
                <div className="roles-popup-box-ext">
                    <div className="roles-popup-table-ext">
                        {permissions.map((perm, i) => (
                            <div key={`role_permission_${i}`} className="roles-popup-table-row-ext">
                                <span className="t-s3">{perm.permission_name}</span>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>

            <ClosePopupIcon onClick={close}/>
        </CustomPopup>
    )
}

export default PermissionsConflictPopup
