import "../TextInput/index.scss"

import React from "react"
import TimeField from "react-simple-timefield"

import TextInput from "../TextInput/TextInput"


const DurationPicker = ({value, text_label, ...props}) => {
    return (
        <div className="toolkit-text-input-wrapper">
            {text_label ? <label className="toolkit-text-input-label t-s3">{text_label}</label> : <></>}
            <TimeField className="toolkit-text-input t-s4" value={value} {...props} input={
                <TextInput props={props} />
            }/>
        </div>
    )
}

export default DurationPicker
