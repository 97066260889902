import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["Users", "FlatUsers", "UserPerformanceReviews"],
    endpoints: (builder) => ({
        getUsersList: builder.query({
            query: (params) => ({
                path: "users/",
                params,
            }),
            providesTags: ["Users"],
        }),
        getFlatUsersList: builder.query({
            query: (params) => ({
                path: "users/flat",
                params,
            }),
            providesTags: ["FlatUsers"],
        }),
        createUser:  builder.mutation({
            query: (data) => ({path: "users/", method: "POST", data}),
            invalidatesTags: ["Users", "FlatUsers"],
        }),
        getUserPerformanceReviews: builder.query({
            query: ({userId, params}) => ({
                path: `users/${userId}/performance-reviews`,
                params,
            }),
            providesTags: ["UserPerformanceReviews"],
        }),
    })
})

export const {
    useGetUsersListQuery,
    useGetFlatUsersListQuery,
    useCreateUserMutation,
    useGetUserPerformanceReviewsQuery,
} = usersApi
