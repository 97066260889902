import "./CustomPopup.scss"

import React, {useEffect} from "react"
import {blockBackground} from "../../utils/other.utils"


export const CustomPopup = ({wrapperClass = "", className = "", text_title, isSubtitle, style, isAgreePopup, ...props}) => {

    useEffect(() => {
        // block background if popup active
        return blockBackground()
    }, [])

    return (
        <div className={`custom-popup-container ${wrapperClass !== "" ? wrapperClass : "custom-popup-card-wrapper"}`}>
            <div className={`custom-popup-card ${className} ${isAgreePopup ? "custom-popup-agree" : ""}`} style={style}>
                <span className={`custom-popup-card-${isSubtitle ? "sub" : ""}title ${
                    !isSubtitle ? "t-h2" : "t-s3"}`}>{text_title}</span>
                <div className="custom-popup-card-content">
                    <form onSubmit={(e) => {e.preventDefault()}}>
                        {props.children}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CustomPopup
