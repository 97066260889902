import React from "react"
    
import {wasSent} from "../../../utils/datetime.utils"


export const NotificationsTable = ({data, setNotificationsIds, isMobile}) => {

    const notificationsList = data?.length && data.map((nt) => {
        if (!nt.shown) {
            setNotificationsIds((arr) => arr.indexOf(nt.notification_id) === -1 ? [...arr, nt.notification_id] : arr)
        }

        return (
            <tr key={`${nt.notification_id} ${nt.title}`} className="notifications-table-row">
                <td></td>
                <td>
                    <div className="notifications-table-row-wrapper">
                        <div>
                            <span className={`notifications-table-row-shown${!nt.shown ? "-active" : ""}`}></span>
                        </div>
                        <div className="notifications-table-row-content">
                            <div className={`notifications-table-row-content-header${nt.shown ? "-alt" : ""} t-s3`}>
                                {nt.notification.title}
                            </div>
                            {nt.notification.content &&
                                <div className={`notifications-table-row-content-body${nt.shown ? "-alt" : ""} t-b4`}
                                    onClick={(e) => {
                                        e.target.classList.contains("notifications-show-content")
                                            ? e.target.classList.remove("notifications-show-content")
                                            : e.target.classList.add("notifications-show-content")
                                        !nt.shown &&
                                            setNotificationsIds((arr) => arr.indexOf(nt.notification_id) === -1 ? [...arr, nt.notification_id] : arr)
                                    }}
                                >
                                    {nt.notification.content}
                                </div>
                            }
                        </div>
                        <div className={`notifications-table-row-date${nt.shown ? "-alt" : ""} ${isMobile ? "t-b3" : "t-b2"}`}>
                            {wasSent(nt.notification.created_at)}
                        </div>
                    </div>
                </td>
                <td></td>
            </tr>
        )
    })
    return (data?.length ?
        <>
            <table className="notifications-table">
                <tbody>
                    {notificationsList}
                </tbody>
            </table>
            <span className={`notifications-footer ${isMobile ? "t-b3" : "t-b2"}`}>
                Previous notifications had been removed
            </span>
        </> : <div className="notifications-table-empty t-h1">Empty</div>
    )
}

export default NotificationsTable
