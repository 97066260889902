import {
    GET_NEXT_PAGE_PAYMENT_REPORTS_REQUEST,
    GET_PAYMENT_REPORTS_FAILURE,
    GET_PAYMENT_REPORTS_REQUEST,
    GET_PAYMENT_REPORTS_SUCCESS,

    GET_PAYMENT_REPORTS_TOTAL_REQUEST,
    GET_PAYMENT_REPORTS_TOTAL_FAILURE,
    GET_PAYMENT_REPORTS_TOTAL_SUCCESS,

    GET_PAYMENTS_REQUEST,
    GET_PAYMENTS_FAILURE,
    GET_PAYMENTS_SUCCESS,
} from "../const"


const initialState = {
    paymentReports: [],
    paymentTotal: {
        need_to_pay: {},
        paid: {},
    },
    payments: [],
    total: 0,
    tableLoading: true,
    paymentTotalLoading: true,
}

const handlers = {
    [GET_PAYMENT_REPORTS_REQUEST]: (state) => ({...state, tableLoading: true, paymentReports: [], total: 0}),
    [GET_PAYMENT_REPORTS_SUCCESS]: (state, {payload: {paymentReports}}) => ({
        ...state,
        paymentReports: [...state.paymentReports, ...paymentReports.items],
        total: paymentReports.total,
        tableLoading: false,
    }),
    [GET_PAYMENT_REPORTS_FAILURE]: (state) => ({...state}),

    [GET_NEXT_PAGE_PAYMENT_REPORTS_REQUEST]: (state) => ({...state}),

    [GET_PAYMENT_REPORTS_TOTAL_REQUEST]: (state) => ({...state, paymentTotal: {}, paymentTotalLoading: true}),
    [GET_PAYMENT_REPORTS_TOTAL_SUCCESS]: (state, {payload: {paymentTotal}}) => ({
        ...state,
        paymentTotal,
        paymentTotalLoading: false,
    }),
    [GET_PAYMENT_REPORTS_TOTAL_FAILURE]: (state) => ({...state}),

    [GET_PAYMENTS_REQUEST]: (state) => ({...state, payments: []}),
    [GET_PAYMENTS_SUCCESS]: (state, {payload: {payments}}) => ({
        ...state,
        payments,
    }),
    [GET_PAYMENTS_FAILURE]: (state) => ({...state}),

    DEFAULT: (state) => state,
}

const paymentReportsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default paymentReportsReducer
