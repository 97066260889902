import React, { useContext } from "react"

import {isAdmin} from "../../utils/permission.utils"
import { ThemeContext } from "../../styles/theme-context"


const MainContent = ({children}) => {
    const theme = useContext(ThemeContext)

    return (
        <div id="main-content" className={`${theme.mainContentClassName} main-content${isAdmin() ? "-admin" : ""}`}>
            {children}
        </div>
    )
}

export default MainContent
