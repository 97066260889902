import {
    GET_PRODUCT_CATEGORIES_REQUEST,
    GET_PRODUCT_CATEGORIES_SUCCESS,
    GET_PRODUCT_CATEGORIES_FAILURE,
    GET_PRODUCT_LABELS_REQUEST,
    GET_PRODUCT_LABELS_SUCCESS,
    GET_PRODUCT_LABELS_FAILURE,
} from "../const"

const initialState = {
    categories: [],
    labels: [],
    loading: false,
}

const handlers = {
    [GET_PRODUCT_CATEGORIES_REQUEST]: (state) => ({...state, loading: true}),
    [GET_PRODUCT_CATEGORIES_SUCCESS]: (state, {payload: {categories}}) => ({...state, categories, loading: false}),
    [GET_PRODUCT_CATEGORIES_FAILURE]: (state) => ({...state, loading: false}),

    [GET_PRODUCT_LABELS_REQUEST]: (state) => ({...state, loading: true}),
    [GET_PRODUCT_LABELS_SUCCESS]: (state, {payload: {labels}}) => ({...state, labels, loading: false}),
    [GET_PRODUCT_LABELS_FAILURE]: (state) => ({...state, loading: false}),

    DEFAULT: (state) => state,
}

const createProductReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default createProductReducer