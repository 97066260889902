import {API_V1_URL} from "../../constants/other"
import http from "../../http"

const USERS_URL = `${API_V1_URL}/users/`
const NEWCOINS_TRANSACTIONS_URL = `${API_V1_URL}/newcoins-transactions/`

export const getUserSalaries = (user_id, params) => http.get(`${USERS_URL}${user_id}/salaries`, {params})

export const getUserBonuses = (user_id, params) => http.get(`${USERS_URL}${user_id}/bonuses`, {params})

export const getNewcoinsTransactions = (params) => http.get(`${NEWCOINS_TRANSACTIONS_URL}`, {params})

export const updateUserNewcoinsSettings = (userId, data) => http.put(`${USERS_URL}${userId}/newcoins-settings`, {data})
