import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import { changeDateFormat } from "../../../utils/datetime.utils"


const BonusesPopup = ({bonuses, close}) => {
    return (
        <FlexiblePopup>
            <div className="bonuses-popup">
                <div className="bonuses-popup-title t-h2">Bonuses Info</div>
                <table className="bonuses-popup-table">
                    <thead>
                        <tr className="bonuses-popup-table-column heading-color t-h3">
                            <th className="bonuses-popup-table-column">
                                <span>AMOUNT</span>
                            </th>
                            <th className="bonuses-popup-table-column">DATE</th>
                            <th className="bonuses-popup-table-column">REASON</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!bonuses ? <StyledCircularProgress/>
                            :
                            <>
                                {bonuses.map((record, i) => (
                                    (<div key={i}>
                                        {bonuses.length === 1 && <hr className="bonuses-popup-table-divider-individual"/>}

                                        <tr key={i} className="bonuses-popup-table-row t-s4">
                                            <td className="bonuses-popup-table-row t-s3">{record.amount} {record.currency?.iso_code}</td>
                                            <td className="bonuses-popup-table-row">{changeDateFormat(record.as_of_date)}</td>
                                            <td className="bonuses-popup-table-row">{record.reason}</td>
                                        </tr>
                        
                                        {i !== (bonuses.length-1) && <hr className="bonuses-popup-table-divider"/>}
                                    </div>)
                                ))}
                            </>
                        }
                    </tbody>
                </table>
                <CloseIcon onClick={close} className="bonuses-popup-button-close"/>
            </div>
        </FlexiblePopup>
    )
}

export default BonusesPopup