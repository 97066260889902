import React, {useEffect, useState} from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import DDList from "../../../toolkits/DDList/DDList"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {useAssignProjectMutation} from "../../../api/projects"
import {useGetFlatUsersListQuery} from "../../../api/users"
import DeprecatedSingleDatePicker from "../../../toolkits/Calendar/DeprecatedSingleDatePicker"


const AssignProjectPopup = ({project, onSubmit, setUserMessage, close}) => {
    const [assignProject, { isLoading }] = useAssignProjectMutation()

    const [userId, setUserId] = useState(null)
    const [date, setDate] = useState(null)
    const [listOptions, setListOptions] = useState([])

    const { data } = useGetFlatUsersListQuery({})
    const users = data?.items || []

    const handleSubmit = () => {
        const formatedDate = date.split(".").reverse().join("-")
        
        const data = {
            project_id: project.id,
            user_id: userId.value,
            start_date: formatedDate,
        }
        assignProject(data)
            .unwrap()
            .then(() => {
                onSubmit()
                close()
                setUserMessage({
                    message: `The project ${project.name} was successfully assigned to user`,
                    code: SUCCESS_CODE
                })
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    useEffect(() => {
        const teamUsersIds = project.team.map((user) => user.id)
        setListOptions(
            users.filter((employee) => teamUsersIds.indexOf(employee.id) === -1).map((employee) => ({
                value: employee.id,
                label: `${employee.first_name} ${employee.last_name}`
            }))
        )
    }, [users])

    return (
        <CustomPopup
            wrapperClass="custom-popup-card-unscrollable-wrapper"
            className="assign-project-popup"
            text_title={`Who you want to assign to "${project.name}" ?`}
            isSubtitle={true}
        >
            <div className="popup-content">
                <DDList
                    text_label="User"
                    placeholder=""
                    listOptions={listOptions}
                    onChange={employee => setUserId(employee)}
                />
                <DeprecatedSingleDatePicker
                    className="single-datepicker-field"
                    label="Start date"
                    defaultValue={date}
                    setNewDate={setDate}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} type="submit" onClick={handleSubmit} disabled={!date || !userId}> ASSIGN </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default AssignProjectPopup
