import {React, useState, useEffect, useRef, useMemo, useCallback} from "react"
import KebabIcon from "@mui/icons-material/MoreVert"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CarouselFilter from "../../../toolkits/CarouselFilter"
import CalendarEventItem from "./CalendarEventItem"
import {
    useCreateCalendarMutation,
    useDuplicateCalendarEventsMutation, useUpdateCalendarMutation,
} from "../../../api/publicHolidayCalendars"
import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import {useUpdateCalendarEventsMutation} from "../../../api/publicHolidayCalendars"
import Menu from "@material-ui/core/Menu"
import {CustomMenuItem} from "../../Profile/components/Other/CustomMenuItem"
import DeleteCalendarPopup from "./DeleteCalendarPopup"
import Popup from "reactjs-popup"
import MarkCalendarAsDefaultPopup from "./MarkCalendarAsDefaultPopup"
import DeleteDefaultCalendarPopup from "./DeleteDefaultCalendarPopup"
import DeleteAssignedCalendarPopup from "./DeleteAssignedCalendarPopup"

const EventsSidePanel = ({calendar, setCalendarId, isOpen, onClose, setUserMessage, calendarLoading }) => {
    const currentYear = new Date().getFullYear()
    const [events, setEvents] = useState([{date: "", name: "", year: currentYear}])
    const [yearsOptions, setYearsOptions] = useState([])
    const [selectedYear, setSelectedYear] = useState(currentYear)
    const [duplicateCalendarEvents, { isLoading: duplicatingEventsLoading }] = useDuplicateCalendarEventsMutation()
    const [disableSave, setDisableSave] = useState(true)
    const [disableYearSelector, setDisableYearSelector] = useState(false)
    const [eventIdsToDelete, setEventIdsToDelete] = useState([])
    const [updateCalendarEvents, {isLoading: updateEventsLoading}] = useUpdateCalendarEventsMutation()
    const [anchorEl, setAnchorEl] = useState(null)
    const [showDeleteCalendarPopup, setShowDeleteCalendarPopup] = useState(false)
    const [showDeleteDefaultCalendarPopup, setShowDeleteDefaultCalendarPopup] = useState(false)
    const [showDeleteAssignedCalendarPopup, setShowDeleteAssignedCalendarPopup] = useState(false)
    const [showMarkCalendarAsDefaultPopup, setShowMarkCalendarAsDefaultPopup] = useState(false)
    const [createCalendar] = useCreateCalendarMutation()
    const [updateCalendar] = useUpdateCalendarMutation()
    const [calendarName, setCalendarName] = useState(calendar?.name)
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const inputRef = useRef(null)

    const adjustInputWidth = () => {
        if (!inputRef.current) return

        const tempSpan = document.createElement("span")
        tempSpan.style.fontSize = window.getComputedStyle(inputRef.current).fontSize
        tempSpan.style.visibility = "hidden"
        tempSpan.style.position = "absolute"
        tempSpan.style.whiteSpace = "pre"
        tempSpan.textContent = calendarName || inputRef.current.placeholder
        document.body.appendChild(tempSpan)
        inputRef.current.style.width = `${tempSpan.offsetWidth + 35}px`
        document.body.removeChild(tempSpan)
    }

    useEffect(() => setSelectedYear(currentYear), [isOpen])

    useEffect(() => {
        if (calendar?.events?.length > 0) {
            const eventYears = [...new Set(calendar.events.map(event => new Date(event.date).getFullYear()))]
            const yearsToInclude = [...eventYears, currentYear, currentYear + 1]
            const sortedYears = [...new Set(yearsToInclude)].sort((a, b) => a - b)
            setYearsOptions(sortedYears)
        } else {
            setYearsOptions([currentYear, currentYear + 1])
        }

        if (calendar) {
            setEvents(calendar?.events.map((event) =>
                ({...event, year: new Date(event.date).getFullYear()})
            ))
            setCalendarName(calendar?.name)
        } else {
            setEvents([])
            setCalendarName("")
        }
        setDisableSave(true)
        setDisableYearSelector(false)
        setEventIdsToDelete([])
    }, [calendar, duplicatingEventsLoading, isOpen])

    const handleOverlayClick = (e) => {
        if (e.target.className === "events-side-panel-overlay") {
            onClose()
        }
    }

    const hasPreviousYearEvents = () => {
        const previousYear = selectedYear - 1
        return calendar?.events.some(event => new Date(event.date).getFullYear() === previousYear)
    }

    const handleAddEvent = (currentYear) => {
        setEvents([...events, {date: "", name: "", year: currentYear}])
    }

    const handleRemoveEvent = (index, id) => {
        setEvents((prevEvents) => prevEvents.filter((_, i) => i !== index))

        if (id) {
            setEventIdsToDelete(prevIds => [...prevIds, id])
        }
    }

    const checkAndCreateCalendarIfNeeded = () => {
        return new Promise((resolve, reject) => {
            if (!calendar) {
                createCalendar({ name: calendarName, is_default: false })
                    .unwrap()
                    .then((response) => {
                        setCalendarId(response.id)
                        resolve(response.id)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                if (calendar.name !== calendarName) {
                    updateCalendar({id: calendar.id, data: {name: calendarName}})
                }
                resolve(calendar.id)
            }
        })
    }

    const saveEvents = useCallback((calendar_id) => {
        const eventsToAdd = events
            // eslint-disable-next-line no-unused-vars
            .map(({year, ...rest}) => rest)
            .filter((event) => {
                if (!event.date || !event.name) {
                    return false
                }

                const originalEvent = calendar?.events.find((e) => e.id === event.id)
                return !event.id || JSON.stringify(event) !== JSON.stringify(originalEvent)
            })
            .map(({date, name, ...rest}) => {
                const originalEvent = calendar?.events.find((e) => e.id === rest.id)
                if (originalEvent) {
                    if (originalEvent.name === name) {
                        return {...rest, date: date}
                    } else if (originalEvent.date === date) {
                        return {...rest, name: name}
                    }
                }
                return {...rest, name: name, date: date}
            })

        const data = {
            events_to_add: eventsToAdd,
            event_ids_to_remove: eventIdsToDelete,
        }

        updateCalendarEvents({ calendar_id, data })
            .unwrap()
            .then(() => {
                setDisableSave(true)
                setDisableYearSelector(false)
                setUserMessage({ message: "Holidays were successfully updated!", code: SUCCESS_CODE })
            })
            .catch(() => {
                setUserMessage({ message: "Something went wrong!", code: ERROR_CODE })
            })
    }, [events, updateCalendarEvents, setUserMessage])

    const handleSave = () => {
        checkAndCreateCalendarIfNeeded().then((calendar_id) => {
            saveEvents(calendar_id)
        }).catch((error) => {
            setUserMessage({ message: error.response?.data?.message || "An error occurred", code: ERROR_CODE })
        })
    }

    useEffect(() => {
        if (calendar) {
            setCalendarName(calendar.name)
        }

        return () => {
            setCalendarName(null)
        }
    }, [calendar])

    useEffect(() => {
        adjustInputWidth()
    }, [calendarName])

    const handleCopyPrevious = () => {
        const previousYear = selectedYear - 1
        duplicateCalendarEvents({
            calendar_id: calendar.id,
            params: { source_year: previousYear, target_year: selectedYear },
        })
            .unwrap()
            .then(() => {
                setUserMessage({message: "Holidays were successfully duplicated!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const handleCancelEventsUpdate = () => {
        if (calendar) {
            setCalendarName(calendar.name)
            setEvents(calendar?.events.map((event) =>
                ({...event, year: new Date(event.date).getFullYear()})
            ))
        } else {
            setCalendarName("")
            setEvents([{date: "", name: "", year: new Date().getFullYear()}])
        }
        setRefreshData(true)
        setEventIdsToDelete([])
    }

    const dirty = useMemo(() => {
        const nameChanged = calendarName !== calendar?.name
        // eslint-disable-next-line no-unused-vars
        const eventsChanged = JSON.stringify(events?.map(({ year, ...rest }) => rest)) !== JSON.stringify(calendar?.events)
        return eventsChanged || nameChanged
    }, [calendar, events, calendarName])

    useEffect(() => {
        const eventsDates = events?.map(event => event.date)
        const dateDuplicates = eventsDates.length !== new Set(eventsDates).size
        const isAllEventsValid = events?.every(event => event.date && event.name)
        setDisableSave(!dirty || !isAllEventsValid || dateDuplicates || !calendarName)
        if (!calendar) {
            setDisableYearSelector(false)
        } else {
            setDisableYearSelector(dirty || !isAllEventsValid)
        }
    }, [events, calendarName, dirty])

    const anythingLoading = calendarLoading || duplicatingEventsLoading || updateEventsLoading

    const footer =  (
        <div className="events-side-panel-footer">
            {dirty ?
                <SmallButton onClick={handleCancelEventsUpdate} disabled={anythingLoading} btnType="secondary">Discard</SmallButton> :
                <SmallButton onClick={onClose} disabled={anythingLoading} btnType="secondary">Close</SmallButton>
            }
            <SmallButton onClick={handleSave} loading={updateEventsLoading} disabled={disableSave}>Save</SmallButton>
        </div>
    )

    if (!isOpen) return null

    return (
        <>
            <div className="events-side-panel-overlay" onClick={handleOverlayClick}></div>
            <div className="events-side-panel">
                <div className="events-side-panel-content">
                    <div className="events-side-panel-header">
                        <div className="events-side-panel-header-title">
                            <span>
                                <input
                                    className="t-s6 events-side-panel-header-title-input"
                                    value={calendarName}
                                    id="calendarNameInput"
                                    type={"text"}
                                    ref={inputRef}
                                    placeholder={"Calendar Name"}
                                    onChange={(e) => {
                                        setCalendarName(e.target.value)
                                    }}
                                />
                                <span className="t-b2 events-side-panel-header-title-default-label">{calendar?.is_default ? "(default)" : ""}</span></span>
                            <KebabIcon onClick={(e) => setAnchorEl(e.currentTarget)}/>
                            <Menu
                                id="simple-menu"
                                disableScrollLock={false}
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                getContentAnchorEl={null}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                open={!!anchorEl}
                                onClick={() => setAnchorEl(null)}
                                keepMounted
                            >
                                <div>
                                    {!calendar?.is_default &&
                                        <CustomMenuItem
                                            label="Mark as default"
                                            onClick={() => setShowMarkCalendarAsDefaultPopup(true)}
                                        />
                                    }
                                    <CustomMenuItem
                                        label="Delete"
                                        onClick={() => {
                                            if (calendar.is_default) {
                                                setShowDeleteDefaultCalendarPopup(true)
                                            }
                                            else if (calendar.users?.length) {
                                                setShowDeleteAssignedCalendarPopup(true)
                                            }
                                            else {
                                                setShowDeleteCalendarPopup(true)
                                            }
                                        }}
                                    />
                                </div>
                            </Menu>
                        </div>
                        <div className="events-side-panel-header-years">
                            {yearsOptions.length ?
                                <CarouselFilter
                                    options={yearsOptions}
                                    selectedOption={selectedYear}
                                    onOptionChange={setSelectedYear}
                                    disableOptions={disableYearSelector}
                                /> : <></>
                            }
                        </div>
                    </div>
                    {calendarLoading || duplicatingEventsLoading ? (
                        <StyledCircularProgress/>
                    ) : events?.filter(event => event.year === selectedYear).length === 0 ? (
                        <div className="events-side-panel-content-empty">
                            <div className="t-s1">Year is empty</div>
                            <div className="t-s4">Add your first
                                holiday{hasPreviousYearEvents() ? " or copy from previous year" : ""}</div>
                            <div className="events-side-panel-content-empty-buttons">
                                {hasPreviousYearEvents() && (
                                    <SmallButton loading={duplicatingEventsLoading} onClick={handleCopyPrevious} btnType="secondary">
                                        Copy previous
                                    </SmallButton>
                                )}
                                <SmallButton onClick={() => handleAddEvent(selectedYear)}>Add holiday</SmallButton>
                            </div>
                            {footer}
                        </div>
                    ) : (
                        <>
                            <div className={`events-side-panel-content-events ${isDatePickerOpen ? "no-scroll" : ""}`}>
                                {events?.length && events.map((event, i) =>
                                    ((event.year === selectedYear) ?
                                        <CalendarEventItem
                                            key={event.id}
                                            index={i}
                                            selectedYear={selectedYear}
                                            event={event}
                                            setEvents={setEvents}
                                            handleRemoveEvent={handleRemoveEvent}
                                            setDisableSave={setDisableSave}
                                            setIsDatePickerOpen={setIsDatePickerOpen}
                                            refreshData={refreshData}
                                            setRefreshData={setRefreshData}
                                        /> : <></>
                                    )
                                )}
                                {selectedYear >= currentYear &&
                                    <span
                                        className="events-side-panel-content-events-add-event t-b4"
                                        onClick={() => handleAddEvent(selectedYear)}
                                    >+ holiday</span>
                                }
                            </div>
                            {footer}
                        </>
                    )}
                </div>
            </div>
            <Popup open={showDeleteCalendarPopup} closeOnDocumentClick={false} onClose={() => setShowDeleteCalendarPopup(false)} modal>
                {close => (
                    <DeleteCalendarPopup
                        calendar={calendar}
                        setUserMessage={setUserMessage}
                        close={close}
                        onSubmit={onClose}
                    />
                )}
            </Popup>
            <Popup open={showDeleteDefaultCalendarPopup} closeOnDocumentClick={false} onClose={() => setShowDeleteDefaultCalendarPopup(false)} modal>
                {close => (
                    <DeleteDefaultCalendarPopup
                        calendar={calendar}
                        close={close}
                    />
                )}
            </Popup>
            <Popup open={showDeleteAssignedCalendarPopup} closeOnDocumentClick={false} onClose={() => setShowDeleteAssignedCalendarPopup(false)} modal>
                {close => (
                    <DeleteAssignedCalendarPopup
                        calendar={calendar}
                        setUserMessage={setUserMessage}
                        close={close}
                        onSubmit={onClose}
                    />
                )}
            </Popup>
            <Popup open={showMarkCalendarAsDefaultPopup} closeOnDocumentClick={false} onClose={() => setShowMarkCalendarAsDefaultPopup(false)} modal>
                {close => (
                    <MarkCalendarAsDefaultPopup
                        calendar={calendar}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default EventsSidePanel