import {
    GET_OVERTIME_EARN_SUCCESS,
    GET_OVERTIME_EARN_REQUEST,
    GET_OVERTIME_EARN_FAILURE
} from "../const"

const initialState = {
    overtimeEarn: [],
    loading: false
}

const handlers = {
    [GET_OVERTIME_EARN_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_OVERTIME_EARN_SUCCESS]: (state, { payload: { overtimeEarn } }) => ({
        ...state,
        overtimeEarn: overtimeEarn,
        loading: false,
    }),
    [GET_OVERTIME_EARN_FAILURE]: (state) => ({ ...state, loading: false }),

    DEFAULT: (state) => state,
}

const OvertimeEarnReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default OvertimeEarnReducer