import React from "react"


const OurRulesFirstPopup = () => (
    <div className="greetings-onboarding-content-text t-b5">
        <div>
            <div className="greetings-onboarding-content-heading t-s5">Payments</div>
            <p>All salary payments are processed by the last day of the month following your work period. On the day your salary transfer is initiated, you will receive an email providing detailed information about your earnings for that particular month.</p>
        </div>
        <br />
        <div>
            <div className="greetings-onboarding-content-heading t-s5">Meeting Rooms</div>
            <p>Bigger rooms are used for big meetings with several team members who take part in a meeting as well. Smaller rooms are used for individual meetings.</p>
        </div>
    </div>
)

export default OurRulesFirstPopup
