import React from "react"
import Popup from "reactjs-popup"

import Protected from "../../../components/Protected/Protected"
import InfoPopup from "../../../components/InfoPopup"
import DeleteCategoryPopup from "./DeleteCategoryPopup"
import CreateUpdateCategoryPopup  from "./CreateUpdateCategoryPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"


import {PERMISSIONS} from "../../../constants/permissions"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"


export const EquipmentCategoriesTable = ({equipmentCategories, setUserMessage, refreshTable, i, empty}) => {
    
    const categoriesList = equipmentCategories.map((ep) => {
        const popupParams = {
            category: ep,
            setUserMessage: setUserMessage,
            refreshTable: refreshTable,
        }
        const managerActions = (
            <>
                <Protected permissions={[PERMISSIONS.WRITE_EQUIPMENT]}>
                    <Popup trigger={<span className="custom-scrollable-table-link"> edit </span>} modal>
                        {close => (
                            <CreateUpdateCategoryPopup {...popupParams} close={close}/>
                        )}
                    </Popup>

                    <Popup trigger={<span className="custom-scrollable-table-link"> delete </span>} modal>
                        {close => (
                            <DeleteCategoryPopup {...popupParams} close={close}/>
                        )}
                    </Popup>
                </Protected>
            </>
        )

        return (
            <tr key={`${i} ${ep.id}`} className="custom-scrollable-table-row equipment-categories-content-table-row">
                <td>
                    <Popup trigger={
                        <span className="cell-limited-text equipment-categories-content-table-first-column t-s1">{ep.name}</span>
                    } modal>
                        {close => (
                            <InfoPopup
                                title="Category"
                                value={ep.name}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    {managerActions}
                </td>
            </tr>
        )
    })
    
    return (
        <CustomScrollableTable>
            <table className="equipment-categories-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span">CATEGORY</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {categoriesList}
                    { empty && <EmptyResult/> }
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default EquipmentCategoriesTable
