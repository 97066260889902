import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const productsApi = createApi({
    reducerPath: "productsApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["Products", "ProductCategories", "ProductVariants"],
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (params) => ({
                path: "products/",
                params,
            }),
            providesTags: ["Products"]
        }),
        getProductCategories: builder.query({
            query: () => ({path: "product-categories/"}),
            providesTags: ["ProductCategories"]
        }),
        createProduct: builder.mutation({
            query: ({data}) => ({path: "products/", method: "POST", data}),
            invalidatesTags: ["Products"]
        }),
        updateProduct: builder.mutation({
            query: ({productId, data}) => ({path: `products/${productId}`, method: "PUT", data}),
            invalidatesTags: ["Products"]
        }),
        updateProductVariants: builder.mutation({
            query: ({productId, data}) => ({path: `products/${productId}/variants`, method: "PUT", data}),
            invalidatesTags: ["ProductVariants"]
        }),
        deleteProduct: builder.mutation({
            query: ({productId, params}) => ({path: `products/${productId}`, method: "DELETE", params}),
            invalidatesTags: ["Products"]
        }),
        createProductCategory: builder.mutation({
            query: ({data}) => ({path: "product-categories/", method: "POST", data}),
            invalidatesTags: ["ProductCategories"]
        }),
    })
})

export const {
    useGetProductsQuery,
    useGetProductCategoriesQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useUpdateProductVariantsMutation,
    useDeleteProductMutation ,
    useCreateProductCategoryMutation,
} = productsApi
