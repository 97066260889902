import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveBonusInfo} from "../actions/BonusEarnAction"

import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"

import {formatDate} from "../../../utils/datetime.utils"
import {DD_MM_YYYY} from "../../../constants/other"


const BonusesInfoPopup = ({close, userId, dateRange}) => {
    const dispatch = useDispatch()

    const {
        one_time_bonuses,
        loading
    } = useSelector((state) => state.bonusInfo)
    
    useEffect(() => {
        dispatch(receiveBonusInfo(userId, dateRange))
    }, [])

    return (
        <CustomPopup
            text_title="Bonuses Info"
            className="bonus-info-popup"
        >
            {loading ? <StyledCircularProgress/> :
                <div className="bonus-info-popup-wrapper">
                    {one_time_bonuses?.length > 0 &&
                        <div className="bonus-info-popup-box">
                            {one_time_bonuses.map((bonus, j) =>
                                <div key={`one_time_bonus_${j}`} className="bonus-info-popup-one-time">
                                    <span className="t-s1"><SensitiveInfo value={`${bonus.amount} ${bonus.currency.iso_code}`}/></span>
                                    <span className="t-s4">({formatDate(bonus.as_of_date, DD_MM_YYYY)})</span>
                                </div>
                            )}
                        </div>
                    }
                </div>
            }

            <ClosePopupIcon onClick={close}/>
        </CustomPopup>
    )
}

export default BonusesInfoPopup
