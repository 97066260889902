import {formatDate} from "../../../utils/datetime.utils"
import React from "react"


const DateColumn = ({ date }) => {
    return (
        <span style={{ overflow: "hidden", textOverflow: "ellipsis"}}>{formatDate(date, "DD.MM.YYYY")}</span>
    )
}

export default DateColumn