import React from "react"
import {KeyboardArrowRight} from "@mui/icons-material"
import {PurchaseRequestsRoute} from "../../../routes" // 4
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {useTranslation} from "react-i18next"

const PurchaseHistoryCard = ({user}) => {
    const {t} = useTranslation("common")

    const history = useCustomHistory()

    return (
        <div onClick={() => history.push(`/purchase-history/${user.id}`, {title: t(PurchaseRequestsRoute.name)})} className="purchase-history-card">
            <span className="t-s6">{t("Purchase History")}</span>
            <KeyboardArrowRight />
        </div>
    )
}

export default PurchaseHistoryCard