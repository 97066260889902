import {useMemo} from "react"
import { useGetUserTimeRecordDetailsQuery } from "../api/timeRecords"


const useTimeRecordDetails = ({ userId, params, skip } = {}) => {
    const {data, isLoading} = useGetUserTimeRecordDetailsQuery({userId, params}, { skip })

    const result = useMemo(() => {
        return {
            data: data || {},
            isLoading,
        }

    }, [data, isLoading])

    return result
}

export default useTimeRecordDetails