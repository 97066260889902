import "./ClosePopupIcon.scss"

import React from "react"

import CloseButtonIcon from "@mui/icons-material/Close"

const ClosePopupIcon = ({onClick, className}) => {
    return (
        <CloseButtonIcon className={`close-popup-icon ${className}`} onClick={onClick}/>
    )
}

export default ClosePopupIcon
