import UserImage from "../../../assets/images/user_photo.png"

import React from "react"
import Popup from "reactjs-popup"
import {useSelector} from "react-redux"

import Protected from "../../../components/Protected/Protected"
import ApproveTimeOffRequestPopup from "./ApproveTimeOffRequestPopup"
import EditTimeOffRequestPopup from "./EditTimeOffRequestPopup"
import DeleteTimeOffRequestPopup from "./DeleteTimeOffRequestPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"

import InfoPopup from "../../../components/InfoPopup"

import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {ProfileRoute} from "../../../routes"
import {PERMISSIONS} from "../../../constants/permissions"
import {getColorByHoursType} from "../../../utils/other.utils"
import {getRangeTimeOff} from "../../../utils/timeoff.utils"


const TimeOffRequestsTable = ({timeOffRequests, refreshTable, setUserMessage, empty}) => {
    const history = useCustomHistory()

    const auth = useSelector((state) => state.auth)
    const {hasPermission} = useSelector((state) => state.profile)

    const profileClick = (id) => id === auth.user.uid ? history.push(ProfileRoute.path) : history.push(`/profile/${id}`)

    const timeOffRequestsList = timeOffRequests.map((timeOff, index) => {
        const popupParams = {
            time_off_request: timeOff,
            refreshTable: refreshTable,
            setUserMessage: setUserMessage,
        }

        const { period, days } = getRangeTimeOff(timeOff.start_date, timeOff.end_date, timeOff.count)

        const userActions = (
            <>
                <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                    {close => (
                        <EditTimeOffRequestPopup
                            {...popupParams}
                            close={close}
                        />
                    )}
                </Popup>

                <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                    {close => (
                        <DeleteTimeOffRequestPopup
                            {...popupParams}
                            close={close}
                        />
                    )}
                </Popup>
            </>
        )

        const managerActions = (
            <>
                <Popup trigger={<span className="custom-scrollable-table-link"> decline </span>} modal>
                    {close => (
                        <ApproveTimeOffRequestPopup
                            {...popupParams}
                            approve={false}
                            close={close}
                        />
                    )}
                </Popup>
                
                <Popup trigger={<span className="custom-scrollable-table-link"> approve </span>} modal>
                    {close => (
                        <ApproveTimeOffRequestPopup
                            {...popupParams}
                            approve={true}
                            close={close}
                        />
                    )}
                </Popup>
            </>
        )

        return (
            <tr key={`${timeOff.id}_${index}`} className="custom-scrollable-table-row-user">
                <Protected permissions={[PERMISSIONS.READ_TIME_OFF_REQUEST]}>
                    <td>
                        <div className="custom-scrollable-table-employee">
                            <span className="custom-scrollable-table-employee-image clickable" onClick={() => profileClick(timeOff.user.id)}>
                                <img src={timeOff.user?.image_url ? timeOff.user.image_url : UserImage} alt="employee"/>
                            </span>
                            <span className="custom-scrollable-table-employee-name clickable" onClick={() => profileClick(timeOff.user.id)}>
                                {timeOff.user.first_name} {timeOff.user.last_name}
                            </span>
                        </div>
                    </td>
                </Protected>
                <td
                    className="time-off-requests-content-table-first-column"
                >
                    {period}
                </td>
                <td>
                    <li style={{backgroundColor: getColorByHoursType(timeOff.type, timeOff.approved)}} className="dot-types"></li>{timeOff.type}
                </td>
                <td>
                    {days}
                </td>
                <td className="time-off-requests-content-table-multi-line">
                    <Popup trigger={<span className="cell-limited-text clickable"> {timeOff.note} </span>} modal>
                        {close => (
                            <InfoPopup
                                title="Note"
                                value={timeOff.note}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    {timeOff.approved === null ? (hasPermission(PERMISSIONS.WRITE_TIME_OFF_REQUEST)
                        ? managerActions
                        : !hasPermission(PERMISSIONS.READ_TIME_OFF_REQUEST)
                            ? userActions
                            : null
                    ) :
                        timeOff.approved ? <span className="approved-time-off-requests-span t-h3">APPROVED</span> :
                            !timeOff.approved && <span className="declined-time-off-requests-span t-h3">DECLINED</span>
                    }
                </td>
            </tr>)
    })

    return (
        <CustomScrollableTable>
            <table className="time-off-requests-content-table">
                <thead>
                    <tr>
                        <Protected permissions={[PERMISSIONS.READ_TIME_OFF_REQUEST]}>
                            <th><span className="custom-scrollable-table-head-span-first">EMPLOYEE</span></th>
                        </Protected>
                        <th><span className="custom-scrollable-table-head-span time-off-requests-content-table-first-column"
                        >DATE</span>
                        </th>
                        <th><span className="custom-scrollable-table-head-span">TIME OFF TYPE</span></th>
                        <th><span className="custom-scrollable-table-head-span">DURATION</span></th>
                        <th><span className="custom-scrollable-table-head-span">NOTE</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody className="time-off-requests-content-table-body">
                    {timeOffRequestsList}
                    {empty && <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default TimeOffRequestsTable
