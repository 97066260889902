import "./Card.scss"

import React from "react"

const Card = ({children, className, ...rest}) => {
    return (
        <div className={`${className} ns-card`} {...rest}>
            {children}
        </div>
    )
}

export default Card
