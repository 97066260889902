import React, {useEffect, useMemo} from "react"
import {Helmet} from "react-helmet"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import InfiniteScroll from "react-infinite-scroll-component"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {DESKTOP_WIDTH, PRODUCT_STATUS, PRODUCTS_SORT_OPTIONS} from "../../constants/other"
import "./index.scss"
import ProductsTable from "./components/ProductsTable"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import CustomLink from "../../toolkits/CustomLink"
import {CreateProductRoute} from "../../routes"
import {useMediaQuery} from "react-responsive"
import MultipleSelect from "../../toolkits/Multiselect"
import {receiveProductCategories} from "../CreateProduct/actions/createProductActions"
import DDFilter from "../../toolkits/DDFilter"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetProductsQuery} from "../../api/products"
import {useTranslation} from "react-i18next"
import SelectPanel from "../../components/Inputs/SelectPanel"

const Products = () => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    const [sortBy, setSortBy] = useState(PRODUCTS_SORT_OPTIONS.NEWEST_FIRST)

    const [searchKey, setSearchKey] = useState()
    const [userMessage, setUserMessage] = useState(null)
    const [statusFilter, setStatusFilter] = useState(PRODUCT_STATUS.ALL)
    const {categories} = useSelector((state) => state.createProduct)
    const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([])
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    useEffect(() => {
        dispatch(receiveProductCategories())
    }, [])

    const params = useMemo(() => ({
        name: searchKey,
        status: statusFilter,
        category_ids: selectedCategoriesIds.length > 0 ? selectedCategoriesIds.join(",") : null,
        order_by: sortBy.key,
    }), [searchKey, statusFilter, selectedCategoriesIds, sortBy])

    const { loadNextPage, items, loading, hasMore, reset, empty, changeParams } = usePaginatedQuery(useGetProductsQuery, { defaultState: { params } })

    useEffect(() => {
        changeParams(params)
    }, [params])

    const statusesSelection = [
        {id: undefined, name: t("All")},
        ...(Object.values(PRODUCT_STATUS).map((status) => ({id: status, name: t(status)}))),
    ]

    return (
        <>
            <Helmet>
                <title> Products - Newsoft Inside </title>
            </Helmet>

            {userMessage && <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>}

            <div className="products">
                <div className="products-header">
                    <div className="products-header-search">
                        <SearchForm onChange={setSearchKey} debounceMs={500}/>
                        {isDesktop && (
                            <CustomLink to={{pathname: CreateProductRoute.path}}>
                                <SmallButton>{t("New Product")}</SmallButton>
                            </CustomLink>
                        )}
                    </div>
                    <div className="products-header-filters">
                        <SelectPanel
                            title={t("Status")}
                            options={statusesSelection}
                            selectedOption={statusFilter}
                            setOptionFilter={setStatusFilter}
                        />
                        <div className="products-header-filters-category">
                            <span className="t-s4 products-header-filters-category-title">{t("Category")}:</span>
                            <MultipleSelect
                                loading={loading}
                                placeholder={t("All")}
                                options={categories}
                                selectedOptionIds={selectedCategoriesIds}
                                setSelectedOptionIds={setSelectedCategoriesIds}
                            />
                        </div>
                        <div className="products-header-filters-sort">
                            <DDFilter
                                title={t("Sort by")}
                                defaultValue={{label: t(sortBy.label), value: sortBy.key}}
                                options={(Object.values(PRODUCTS_SORT_OPTIONS)).map((o) => ({label: t(o.label), value: o.key}))}
                                onChange={({ label, value }) => setSortBy({label, key: value})}
                            />
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<StyledCircularProgress/>}
                    dataLength={items.length}
                >
                    <div className="products-content">
                        <ProductsTable
                            products={items}
                            setUserMessage={setUserMessage}
                            refreshTable={reset}
                            isDesktop={isDesktop}
                            empty={empty}
                        />
                    </div>
                </InfiniteScroll>
            </div>
        </>
    )
}

export default Products