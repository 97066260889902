import React, {useEffect, useState} from "react"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import TextInput from "../../../toolkits/TextInput/TextInput"
import Textarea from "../../../toolkits/Textarea/Textarea"
import {useDispatch, useSelector} from "react-redux"
import {receiveProductCategories, receiveProductLabels} from "../actions/createProductActions"
import AttributesCard from "./AttributesCard"
import DDListCRUD from "../../../toolkits/DDListCRUD"
import {
    createProductLabel,
    deleteProductCategory,
    deleteProductLabel, updateProductCategory,
    updateProductLabel
} from "../api"
import Newcoin from "../../../toolkits/Newcoin"
import {PRODUCT_LABEL_COLORS, PRODUCT_OPTION_TYPE, ERROR_CODE} from "../../../constants/other"
import DeleteCategoryPopup from "./DeleteCategoryPopup"
import Popup from "reactjs-popup"
import {useCreateProductCategoryMutation} from "../../../api/products"

const DetailsCard = ({loading, setLoading, product, setProduct, setProductLabels, productLabelsSelected, setProductLabelsSelected, attributes, setAttributes, setUserMessage}) => {
    const {
        categories,
        labels
    } = useSelector((state) => state.createProduct)
    const [productCategory, setProductCategory] = useState(categories.find((cat) => cat.id === product.category_id)?.name || "")
    const dispatch = useDispatch()
    const [showDeleteCategoryPopup, setShowDeleteCategoryPopup] = useState(false)
    const [categoryToDelete, setCategoryToDelete] = useState({})
    const [createProductCategory] = useCreateProductCategoryMutation()

    const handleCreateProductCategory = (category_name) => {
        createProductCategory({data: {name: category_name}})
            .then(() => {
                setProductCategory(category_name)
                setUserMessage({message: "Product Category created successfully", code: 200})
                dispatch(receiveProductCategories())
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const handleDeleteProductCategory = (category) => {
        deleteProductCategory(categories.find((cat) => cat.name === category.value).id)
            .then(() => {
                setProductCategory(null)
                setUserMessage({message: "Product Category deleted successfully", code: 200})
                dispatch(receiveProductCategories())
            })
    }

    const handleUpdateProductCategory = (category) => {
        updateProductCategory(categories.find((cat) => cat.name === category.prev_label).id, {name: category.value})
            .then(() => {
                setProductCategory(category.value)
                setUserMessage({message: "Category updated successfully", code: 200})
                dispatch(receiveProductCategories())
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const handleCreateProductLabel = (label_name, color) => {
        createProductLabel({name: label_name, color: color})
            .then(() => {
                setProductLabelsSelected([...productLabelsSelected, {value: label_name, label: label_name, color}])
                setUserMessage({message: "Product Label created successfully", code: 200})
                dispatch(receiveProductLabels())
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const handleDeleteProductLabel = (label) => {
        deleteProductLabel(labels.find((lab) => lab.name === label.value).id)
            .then(() => {
                setUserMessage({message: "Product Label deleted successfully", code: 200})
                dispatch(receiveProductLabels())
            })
    }

    const handleUpdateProductLabel = (label) => {
        updateProductLabel(label.id, {name: label.value, color: label.color})
            .then(() => {
                setUserMessage({message: "Product Label updated successfully", code: 200})
                dispatch(receiveProductLabels())
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const handleAttributeChange = (id, field, value) => {
        setAttributes(prevAttributes =>
            prevAttributes.map(attr =>
                attr.id === id ? {...attr, [field]: value} : attr
            )
        )
    }

    const handleAttributeRemove = (id) => {
        setAttributes(prevAttributes => prevAttributes.filter(attr => attr.id != id))
    }

    useEffect(() => {
        dispatch(receiveProductCategories())
        dispatch(receiveProductLabels())
    }, [])

    useEffect(() => {
        if (productCategory) {
            setProduct(prevProduct => ({
                ...prevProduct,
                category_id: categories.find((cat) => cat.name === productCategory)?.id,
            }))
        }
    }, [productCategory, categories, setProduct])

    useEffect(() => {
        setProductLabels(productLabelsSelected.map((lab) => lab.id))
    }, [productLabelsSelected])

    useEffect(() => {
        if (categories.length > 0 && product.category_id) {
            const categoryName = categories.find((cat) => cat.id === product.category_id)?.name || ""
            setProductCategory(categoryName)
            setLoading(false)
        }
    }, [product.category_id, categories, setLoading])


    return (
        <div className="create-product-left-details">
            <div className="create-product-left-details-form">
                <div className="create-product-left-details-form-title t-s6">Details</div>
                <TextInput
                    text_label="Name"
                    placeholder={"Write item's name..."}
                    defaultValue={product.name}
                    onChange={(e) => setProduct(prevProduct => ({...prevProduct, name: e.target.value}))}
                />
                <div className="create-product-left-details-form-description">
                    <Textarea
                        text_label="Description (optional)"
                        placeholder={"Write something about an item..."}
                        value={product.description}
                        onChange={(e) => setProduct(prevProduct => ({...prevProduct, description: e.target.value}))}
                    />
                </div>
                <div className="create-product-left-details-form-inputs">
                    {!loading &&
                    <DDListCRUD
                        text_label="Category"
                        placeholder={"Choose or create a category"}
                        listOptions={categories.map((cat) => ({value: cat.name, label: cat.name, prev_label: cat.name}))}
                        defaultValue={productCategory ? { value: productCategory, label: productCategory } : null}
                        value={productCategory ? { value: productCategory, label: productCategory } : null}
                        onChange={(cat) => setProductCategory(cat.value)}
                        onCreateOption={handleCreateProductCategory}
                        onEditOption={(cat) => {handleUpdateProductCategory(cat)}}
                        onDeleteOption={(cat) => {setCategoryToDelete(cat), setShowDeleteCategoryPopup(true)}}
                    />}
                    <TextInput
                        text_label="Pre-order Delivery Time (optional)"
                        placeholder={"F.e. “12 days” or “1 month”"}
                        value={product.pre_order_delivery_time}
                        onChange={(e) => setProduct(prevProduct => ({...prevProduct, pre_order_delivery_time: e.target.value}))}
                    />
                </div>
                <div className="create-product-left-details-form-inputs">
                    <div className="create-product-left-details-form-inputs-price">
                        <TextInput
                            text_label="Price"
                            placeholder={"Write a price..."}
                            value={product.raw_price}
                            type={"number"}
                            onChange={(e) => setProduct(prevProduct => ({...prevProduct, raw_price: e.target.value}))}
                        />
                        <Newcoin type="active"/>
                    </div>
                    {!loading &&
                    <DDListCRUD
                        text_label="Label (optional)"
                        placeholder={"Choose or create a label"}
                        defaultValue={productLabelsSelected.map((lab) => ({id: lab.id, value: lab.name, label: lab.name, color: lab.color}))}
                        listOptions={labels.sort((a,b) => a.id - b.id).map((lab) => ({id: lab.id, value: lab.name, label: lab.name, color: lab.color}))}
                        colored={true}
                        isMulti={true}
                        onChange={(selectedLabels) => {
                            setProductLabelsSelected(selectedLabels)
                        }}
                        onCreateOption={(label_name) => handleCreateProductLabel(label_name, PRODUCT_LABEL_COLORS[Math.floor(Math.random() * (PRODUCT_LABEL_COLORS.length))])}
                        onEditOption={(label) => handleUpdateProductLabel(label)}
                        onDeleteOption={handleDeleteProductLabel}
                    />}
                </div>
            </div>
            {attributes.length > 0 && (
                <>
                    <div className="create-product-left-attributes">
                        <div className="create-product-left-attributes-title t-s6">Attributes</div>
                        {attributes.map((attribute) =>
                            <AttributesCard attribute={attribute} key={attribute.id} handleAttributeChange={handleAttributeChange} handleAttributeRemove={handleAttributeRemove}/>
                        )}
                    </div>
                </>)
            }
            <SmallButton onClick={() => setAttributes([...attributes, { id: attributes.length + 1, name: "", value: "", type: PRODUCT_OPTION_TYPE.TEXT}])} btnType="secondary">Add {attributes.length > 0 ? "Product" : "New"} Attribute</SmallButton>
            <Popup open={showDeleteCategoryPopup} closeOnDocumentClick={false} onClose={() => setShowDeleteCategoryPopup(false)} modal>
                {close => (
                    <DeleteCategoryPopup
                        category={categoryToDelete}
                        handleSubmit={handleDeleteProductCategory}
                        close={close}
                    />
                )}
            </Popup>
        </div>
    )
}

export default DetailsCard