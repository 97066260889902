import {CLEAR_STATE, POP_STATE, PUSH_STATE} from "../const"
import {ProfileRoute} from "../../../routes"


const initialState = {
    navigationStack: new Map([
        [ProfileRoute.path, [{
            path: ProfileRoute.path,
            state: {},
        }]],
    ])
}

const handlers = {
    [PUSH_STATE]: (state, {payload: {tab, path, pageState}}) => {
        if(state.navigationStack.has(tab)){
            state.navigationStack.get(tab).push({
                path,
                state: pageState,
            })
        } else {
            state.navigationStack.set(tab, [{
                path,
                state,
            }])
        }
        return state
    },
    [POP_STATE]: (state, {payload: {tab}}) => {
        state.navigationStack.get(tab).pop()
        return state
    },
    [CLEAR_STATE]: () => ({
        navigationStack: new Map([
            [ProfileRoute.path, [{
                path: ProfileRoute.path,
                state: {},
            }]],
        ])
    }
    ),
    DEFAULT: (state) => state,
}

const navigationReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default navigationReducer
