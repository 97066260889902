import {API_V1_URL} from "../../constants/other"
import http from "../../http"

const PRODUCTS_URL = `${API_V1_URL}/products/`
const CATEGORIES_URL = `${API_V1_URL}/product-categories/`
const LABELS_URL = `${API_V1_URL}/product-labels/`
const UTILS_URL = `${API_V1_URL}/utils/`

export const getProductCategories = () => http.get(CATEGORIES_URL)
export const updateProductCategory = (categoryId, data) => http.put(`${CATEGORIES_URL}${categoryId}`, {data})
export const deleteProductCategory = (categoryId) => http.delete(`${CATEGORIES_URL}${categoryId}`)

export const getProductLabels = () => http.get(LABELS_URL)
export const createProductLabel = (data) => http.post(LABELS_URL, {data})
export const updateProductLabel = (labelId, data) => http.put(`${LABELS_URL}${labelId}`, {data})
export const deleteProductLabel = (labelId) => http.delete(`${LABELS_URL}${labelId}`)

export const createProductLabels = (productId, data) => http.post(`${PRODUCTS_URL}${productId}/labels`, {data})
export const createProductAttributes = (productId, data) => http.post(`${PRODUCTS_URL}${productId}/attributes`, {data})
export const createProductOptions = (productId, data) => http.post(`${PRODUCTS_URL}${productId}/options`, {data})

export const updateProductLabels = (productId, data) => http.put(`${PRODUCTS_URL}${productId}/labels`, {data})
export const updateProductAttributes = (productId, data) => http.put(`${PRODUCTS_URL}${productId}/attributes`, {data})
export const updateProductOptions = (productId, data) => http.put(`${PRODUCTS_URL}${productId}/options`, {data})

export const updateProductImages = (data) => {
    const formData = new FormData()
    formData.append("product_id", data.product_id)
    data.images_to_add.forEach(image => formData.append("images_to_add", image))
    data.images_to_remove.forEach(image => formData.append("images_to_remove", image))
    data.is_main_list.forEach(image => formData.append("is_main_list", image))
    return http.put(`${UTILS_URL}upload-product-images`, {data: formData})
}

export const uploadImage = (data) => {
    const formData = new FormData()
    formData.append("image", data.image)
    formData.append("existing_image_url", data.existing_image_url)
    return http.post(`${UTILS_URL}upload-image`, {data: formData})
}
