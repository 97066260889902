import {getSettings} from "../api"

import {extractSettingsByName} from "../../../utils/other.utils"

import {SETTINGS} from "../../../constants/other"
import {
    GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE,
} from "../const"


const request = () => ({type: GET_SETTINGS_REQUEST})

export const successSettings = (payload) => ({
    type: GET_SETTINGS_SUCCESS,
    payload: payload,
})
const failure = () => ({type: GET_SETTINGS_FAILURE})

export const receiveSettings = () => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveSettingsData}  = await getSettings()

        dispatch(successSettings({
            accessToTimeTracker: extractSettingsByName(receiveSettingsData, SETTINGS.ACCESS_TO_TIME_TRACKER),
            maxTimeOffsToTransfer: extractSettingsByName(receiveSettingsData, SETTINGS.MAX_TIME_OFFS_TO_TRANSFER),
            defaultTimeOffsPerYear: extractSettingsByName(receiveSettingsData, SETTINGS.DEFAULT_TIME_OFFS_PER_YEAR),
            maxTimeOffsPerYear: extractSettingsByName(receiveSettingsData, SETTINGS.MAX_TIME_OFFS_PER_YEAR),
            timeOffForEachWorkingYear: extractSettingsByName(receiveSettingsData, SETTINGS.TIME_OFF_FOR_EACH_WORKING_YEAR),
            upcomingPerformanceReview: extractSettingsByName(receiveSettingsData, SETTINGS.UPCOMING_PERFORMANCE_REVIEW),
            maxDaysStoreNotifications: extractSettingsByName(receiveSettingsData, SETTINGS.MAX_DAYS_STORE_NOTIFICATIONS),
            accessToEditBonus: extractSettingsByName(receiveSettingsData, SETTINGS.ACCESS_TO_EDIT_BONUS),
            websiteTheme: extractSettingsByName(receiveSettingsData, SETTINGS.WEBSITE_THEME),
            newcoinsEnabled: extractSettingsByName(receiveSettingsData, SETTINGS.NEWCOINS_ENABLED),
            shopEnabled: extractSettingsByName(receiveSettingsData, SETTINGS.SHOP_ENABLED),
            autoGiftBirthday: extractSettingsByName(receiveSettingsData, SETTINGS.AUTO_GIFT_BIRTHDAY),
            autoGiftAnniversary: extractSettingsByName(receiveSettingsData, SETTINGS.AUTO_GIFT_ANNIVERSARY),
            autoGiftLoggedHours: extractSettingsByName(receiveSettingsData, SETTINGS.AUTO_GIFT_LOGGED_HOURS),
        }))
    }
    catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
