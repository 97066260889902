import http from "../../http"
import {API_V1_URL} from "../../constants/other"


const NOTIFICATIONS_URL = `${API_V1_URL}/notifications`
const DASHBOARD_URL = `${API_V1_URL}/notifications/unread/dashboard`


export const getDashboardNotifications = (cancelToken) => http.get(DASHBOARD_URL, {}, {}, cancelToken)

export const getUserNotifications = (userId) => http.get(`${NOTIFICATIONS_URL}/${userId}`)

export const markAsReadUserNotifications = (data) => http.put(`${NOTIFICATIONS_URL}/mark-as-read`, {data})
