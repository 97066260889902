import {initializeApp} from "firebase/app"
import {
    GoogleAuthProvider,
    getAuth,
    sendPasswordResetEmail,
    signInWithPopup,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
} from "firebase/auth"


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)

const auth = getAuth(firebaseApp)

const googleSSO = new GoogleAuthProvider()

// App needs verification to use this
// googleSSO.addScope("https://www.googleapis.com/auth/calendar.readonly")

// TODO: Remove this later when multi tenancy implemented
googleSSO.setCustomParameters({"company": "Newsoft"})

export const signInWithGoogle = () => {
    return signInWithPopup(auth, googleSSO)
}


export const signInWithPassword = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password)
}

export const signOut_ = () => {
    return signOut(auth)
}

export const onAuthStateChanged_ = (callback) => {
    return onAuthStateChanged(auth, callback)
}

export const sendPasswordResetEmail_ = (email) => {
    return sendPasswordResetEmail(auth, email)
}

export default  {
    auth,
    signInWithGoogle,
    signInWithPassword,
    signOut: signOut_,
    onAuthStateChanged: onAuthStateChanged_,
    sendPasswordResetEmail: sendPasswordResetEmail_,
}