import React, {useRef, useState} from "react"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../toolkits/TextInput/TextInput"
import DefaultOptionDetailsImage from "../../../assets/images/defaultOptionDetailsImage.svg"
import {
    ALLOWED_IMAGE_FORMATS,
    ERROR_CODE,
    NOT_ALLOWED_IMAGE_FORMAT_ERROR_MESSAGE,
    SUCCESS_CODE
} from "../../../constants/other"
import CancelIcon from "@mui/icons-material/Cancel"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import {uploadImage} from "../api"

const AddOptionDetailsPopup = ({option, handleOptionChange, setUserMessage, close}) => {
    const inputRef = useRef()
    const [imageUrl, setImageUrl] = useState(option.details_image_url || DefaultOptionDetailsImage)
    const [image, setImage] = useState("")
    const [detailsName, setDetailsName] = useState(option.details_name)
    const [showRemove, setShowRemove] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const isEdit = option.details_name && option.details_image_url

    const handleSubmit = () => {
        setSubmitDisabled(true)
        handleOptionChange(option.position, "details_name", detailsName)
        uploadImage({image: image ? image : "", existing_image_url: option.details_image_url}).then(
            (response) => {
                const newImageUrl = response.data.image_url
                handleOptionChange(option.position, "details_image_url", newImageUrl)
                setUserMessage({
                    message: "Option details uploaded successfully",
                    code: SUCCESS_CODE
                })
                close()
            }
        ).catch(() => {
            setUserMessage({
                message: "Option details upload failed",
                code: ERROR_CODE
            })
        }
        )
    }

    const handleUploadImage = (e) => {
        const file = e.target.files[0]
        if (file && ALLOWED_IMAGE_FORMATS.includes(file.type)){
            setImageUrl(URL.createObjectURL(file))
            setImage(file)
            inputRef.current.value = ""
            setSubmitDisabled(detailsName === "")
        } else {
            setUserMessage({
                message: NOT_ALLOWED_IMAGE_FORMAT_ERROR_MESSAGE,
                code: ERROR_CODE
            })
            setSubmitDisabled(true)
        }
    }

    const handleRemoveImage = () => {
        setShowRemove(false)
        setImageUrl(DefaultOptionDetailsImage)
        setImage(null)
        setSubmitDisabled(true)
    }

    const handleDetailsNameChange = (e) => {
        setDetailsName(e.target.value)
        setSubmitDisabled(
            e.target.value === option.details_name ||
            e.target.value === "" ||
            imageUrl === DefaultOptionDetailsImage
        )
    }

    return (
        <CustomPopup text_title={`${isEdit ? "Edit" : "Add"} Optional Details`} close={close}>
            <TextInput
                text_label={"Name"}
                placeholder={"Write some name"}
                value={detailsName}
                onChange={(e) => handleDetailsNameChange(e)}
            />
            <div onMouseLeave={() => setShowRemove(false)} className="add-option-details-popup-image">
                <img
                    src={imageUrl}
                    alt={"default-image"}
                    onMouseEnter={() => {if (imageUrl !== DefaultOptionDetailsImage) setShowRemove(true)}}
                    className={imageUrl !== DefaultOptionDetailsImage ? "uploaded" : ""}
                    onClick={() => inputRef.current.click()}
                />
                <input
                    type="file"
                    accept={ALLOWED_IMAGE_FORMATS.join(",")}
                    ref={inputRef}
                    onChange={(e) => handleUploadImage(e)}
                />
                {showRemove &&
                    <CancelIcon
                        className="add-option-details-popup-image-remove"
                        onClick={() => handleRemoveImage()}
                    />
                }
            </div>
            <div className="add-option-details-popup-buttons">
                <SmallButton btnType={"secondary"} onClick={close}>Cancel</SmallButton>
                <SmallButton disabled={submitDisabled} onClick={() => handleSubmit()}>{isEdit ? "Save" : "Add"}</SmallButton>
            </div>
        </CustomPopup>
    )
}

export default AddOptionDetailsPopup