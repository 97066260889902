import http from "../../http"

import {API_V1_URL} from "../../constants/other"


const SETTINGS_URL = `${API_V1_URL}/settings`
const TIME_OFF_BALANCES_URL = `${API_V1_URL}/time-off-balance`


export const getSettings = () => http.get(`${SETTINGS_URL}/`)

export const updateSettingById = (props) => {
    const {setting_id, ...data} = props
    return http.put(`${SETTINGS_URL}/${setting_id}`, {data: data})
}

export const refreshTimeOffBalances = () => http.post(`${TIME_OFF_BALANCES_URL}/refresh`)
