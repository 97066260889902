import React from "react"

import {OUR_TEAM_MEDIA} from "../../../constants/onboarding"

const OurTeamPopup = () => {
    const videoSrcWithAutoplayAndMute = `${OUR_TEAM_MEDIA.video.src}?autoplay=1&mute=1`

    return (
        <div className="our-team-popup">
            <div className="our-team-popup-featured-media">
                <iframe
                    loading="lazy"
                    src={videoSrcWithAutoplayAndMute}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    style={{ border: "none" }}
                ></iframe>
            </div>
            {OUR_TEAM_MEDIA.images.map((item, index) => (
                <div key={index} className="our-team-popup-media-thumbnail">
                    <img loading="lazy" src={item.src} alt={item.alt} />
                </div>
            ))}
        </div>
    )
}

export default OurTeamPopup