import React, {useContext, useState} from "react"
import InfoIcon from "@mui/icons-material/Pending"
import {getColorByHoursType} from "../../../../utils/other.utils"
import {changeDateFormat} from "../../../../utils/datetime.utils"
import TimeOffUsageInfoPopup from "../../../TimeOffBalances/components/TimeOffUsageInfoPopup"
import Popup from "reactjs-popup"
import {ThemeContext} from "../../../../styles/theme-context"
import {useTranslation} from "react-i18next"

const TimeOffLeftCard = ({user}) => {
    const { t } = useTranslation("common")

    const [showTimeOffUsagePopup, setShowTimeOffUsagePopup] = useState(false)
    const theme = useContext(ThemeContext)

    return (
        <>
            <div
                className={`profile-info-card clickable ${theme.profileCardHover}`}
                onClick={() => setShowTimeOffUsagePopup(true)}
            >
                <InfoIcon className="profile-info-card-improvements clickable"/>
                <h3 className="t-h3 profile-font-leading">{t("TIME OFF LEFT")}</h3>
                <h1 className="t-h1"> {t("{{count}} days", { count: user.time_off_left})} </h1>

                {user?.next_time_off_request &&
                    <div className="profile-info-vacation-wrapper">
                        <li style={{backgroundColor: getColorByHoursType(user.next_time_off_request.type, user.next_time_off_request.approved)}} className="dot-types">
                        </li>
                        <span className="t-s4 profile-info-vacation-period">
                            {`${changeDateFormat(user.next_time_off_request.start_date, "YYYY-MM-DD", "DD.MM")}${
                                user.next_time_off_request.start_date !== user.next_time_off_request.end_date
                                    ? `-${changeDateFormat(user.next_time_off_request.end_date, "YYYY-MM-DD", "DD.MM")}`
                                    : ""
                            }`} ({t("{{count}} days", { count: user.next_time_off_request.count})})
                        </span>
                        <span
                            className={`t-s4 profile-info-vacation-${
                                user.next_time_off_request.approved === null
                                    ? "in-review"
                                    : (user.next_time_off_request.approved === true)
                                        ? "approved"
                                        : "declined"
                            }`}
                        >
                            {user.next_time_off_request.approved === null
                                ? "In review"
                                : user.next_time_off_request.approved === true
                                    ? "Approved"
                                    : "Declined"
                            }
                        </span>
                    </div>
                }
            </div>

            <Popup open={showTimeOffUsagePopup} onClose={() => setShowTimeOffUsagePopup(false)} modal>
                {close => (
                    <TimeOffUsageInfoPopup
                        year={new Date().getFullYear()}
                        employee={user}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default TimeOffLeftCard