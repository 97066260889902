import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const googleIntegrationApi = createApi({
    reducerPath: "googleIntegrationApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["GoogleCalendarIntegration", "GoogleCalendarIntegrationCalendars"],
    endpoints: (builder) => ({
        getGoogleCalendarIntegration: builder.query({
            query: (params) => ({
                path: "integrations/google-calendar/",
                params,
            }),
            providesTags: ["GoogleCalendarIntegration"]
        }),
        setupGoogleCalendarIntegration: builder.mutation({
            query: ({integrationType, code}) => ({path: "integrations/google-calendar/", method: "POST", data: { integration_type: integrationType, code }}),
            invalidatesTags: ["GoogleCalendarIntegration", "GoogleCalendarIntegrationCalendars"]
        }),
        completeGoogleCalendarIntegration: builder.mutation({
            query: ({integrationType, setupKey, calendarId, calendarName}) => ({
                path: "integrations/google-calendar/complete",
                method: "POST",
                data: {
                    integration_type: integrationType,
                    setup_key: setupKey,
                    calendar_id: calendarId,
                    calendar_name: calendarName,
                }}
            ),
            invalidatesTags: ["GoogleCalendarIntegration", "GoogleCalendarIntegrationCalendars"]
        }),
        removeGoogleCalendarIntegration: builder.mutation({
            query: (integrationType) => ({path: "integrations/google-calendar/", method: "DELETE", data: { integration_type: integrationType }}),
            invalidatesTags: ["GoogleCalendarIntegration"]
        }),
        getGoogleCalendars: builder.query({
            query: (params) => ({
                path: "integrations/google-calendar/calendars",
                params,
            }),
            providesTags: ["GoogleCalendarIntegrationCalendars"]
        }),
        getOOOCalendarLink: builder.query({
            query: (params) => ({path: "integrations/google-calendar/connection-url", params}),
        }),
    })
})

export const {
    useGetGoogleCalendarIntegrationQuery,
    useRemoveGoogleCalendarIntegrationMutation,
    useSetupGoogleCalendarIntegrationMutation,
    useCompleteGoogleCalendarIntegrationMutation,
    useGetGoogleCalendarsQuery,
    useGetOOOCalendarLinkQuery,
} = googleIntegrationApi
