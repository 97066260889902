import React, {useEffect, useRef, useState} from "react"
import DefaultMainProductImage from "../../../assets/images/defaultMainProductImage.svg"
import {ALLOWED_IMAGE_FORMATS, ERROR_CODE, NOT_ALLOWED_IMAGE_FORMAT_ERROR_MESSAGE} from "../../../constants/other"
import CancelIcon from "@mui/icons-material/Cancel"


const MainImageCard = ({product, setImages, setSubmitDisabled, isEdit, setUserMessage}) => {
    const mainImage = product.images?.length > 0 && product.images.find((image) => image.is_main === true)

    const inputRef = useRef()
    const [imageUrl, setImageUrl] = useState(mainImage ? mainImage.image_url : DefaultMainProductImage)
    const [showRemove, setShowRemove] = useState(false)

    useEffect(() => {
        if (mainImage) {
            setImageUrl(mainImage.image_url)
        }
    }, [mainImage])

    const handleImageChange = (e) => {
        const file = e.target.files[0]
        if (file && ALLOWED_IMAGE_FORMATS.includes(file.type)){
            setImageUrl(URL.createObjectURL(file))
            setImages(prevImages => ({
                images_to_add: [...prevImages.images_to_add, file],
                images_to_remove: prevImages.images_to_remove,
                is_main_list: [...prevImages.is_main_list, true]
            }))
            inputRef.current.value = ""
        } else {
            setUserMessage({
                message: NOT_ALLOWED_IMAGE_FORMAT_ERROR_MESSAGE,
                code: ERROR_CODE
            })
        }
    }

    const handleImageRemove = () => {
        setShowRemove(false)
        setSubmitDisabled(true)
        setImages(prevImages => ({
            images_to_add: prevImages.images_to_add.filter(image => {URL.createObjectURL(image) != imageUrl}),
            images_to_remove: isEdit ? [...prevImages.images_to_remove, mainImage.image_url ? mainImage.image_url : mainImage] : prevImages.images_to_remove,
            is_main_list: prevImages.is_main_list.filter((_, index) => {prevImages.images_to_add[index] != imageUrl})
        }))
        setImageUrl(DefaultMainProductImage)
    }

    return (
        <div
            onMouseLeave={() => setShowRemove(false)}
            className="create-product-right-main-image">
            <div className="t-s6">Main Image</div>
            <img
                src={imageUrl} alt={"default-image"}
                onMouseEnter={() => {
                    if (imageUrl !== DefaultMainProductImage) {
                        setShowRemove(true)
                    }
                }}
                onClick={() => {
                    if (imageUrl === DefaultMainProductImage) {
                        inputRef.current.click()
                    }
                }}
            />
            <input
                type="file"
                accept={ALLOWED_IMAGE_FORMATS.join(",")}
                ref={inputRef}
                onChange={(e) => handleImageChange(e)}
            />
            {showRemove &&
                <CancelIcon className="create-product-right-main-image-remove" onClick={() => handleImageRemove()}/>
            }
        </div>
    )
}

export default MainImageCard