import React, {useState} from "react"
import TextInput from "../../../toolkits/TextInput/TextInput"
import CancelIcon from "@mui/icons-material/Cancel"

const AttributesCard = ({attribute, handleAttributeChange, handleAttributeRemove}) => {
    const [showRemove, setShowRemove] = useState(false)

    return (
        <div onMouseLeave={() => setShowRemove(false)} className="create-product-left-attributes-inputs">
            <TextInput
                text_label="Name"
                placeholder="F.e. 'Color' or 'Size'"
                value={attribute.name}
                onChange={(e) => handleAttributeChange(attribute.id, "name", e.target.value)}
                onMouseOver={() => setShowRemove(true)}
            />
            {showRemove &&
            <CancelIcon onClick={() => handleAttributeRemove(attribute.id)} className="create-product-left-attributes-inputs-remove-name"/>}
            <TextInput
                onMouseOver={() => setShowRemove(false)}
                text_label="Value"
                placeholder="F.e. 'Cotton 95%, Spandex 5%'"
                value={attribute.value}
                onChange={(e) => handleAttributeChange(attribute.id, "value", e.target.value)}
            />
        </div>
    )
}

export default AttributesCard