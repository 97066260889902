import React from "react"

import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"


const ProjectsConflictPopup = ({title, value, close}) => {
    return (
        <div className="projects-conflict-popup">
            <div className="projects-conflict-popup-title t-h2">{title}</div>
            <span className="projects-conflict-popup-body t-b1"> {value} </span>
            <ClosePopupIcon onClick={close}/>
        </div>
    )
}

export default ProjectsConflictPopup