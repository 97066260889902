import http from "../../http"
import {API_V1_URL} from "../../constants/other"

/**
 * @desc [Variable] Variable that contain `users` route
 * @return {string}
 */
const USERS_URL = `${API_V1_URL}/users/`

/**
 * @desc API method for updating employees.
 * Method [PUT]
 * @param {object} payload - object that contain new data about the employee
 * @return {function} - function for making request
 */
export const updateUserById = (userId, data) => http.put(`${USERS_URL}${userId}`, {data})

/**
 * @desc API method for updating archive status of employees.
 * Method [POST]
 * @param {object} payload - object that contain updated archive status of the employee
 * @return {function} - function for making request
 */
export const archiveUserById = (userId, data) => http.post(`${USERS_URL}${userId}/archive/`, {data})

/**
 * @desc API method for deleting employees from the list.
 * Method [DELETE]
 * @param {object} user_id - object that contain new data about the employee
 * @return {function} - function for making request
 */
export const deleteUserById = (user_id) => http.delete(`${USERS_URL}${user_id}`)
