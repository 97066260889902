import {
    GET_NEXT_PAGE_USER_SALARIES_REQUEST,
    GET_USER_SALARIES_FAILURE,
    GET_USER_SALARIES_REQUEST,
    GET_USER_SALARIES_SUCCESS,
} from "../const"


const initialState = {
    salaries: [],
    total: 0,
    tableLoading: true,
}

const handlers = {
    [GET_USER_SALARIES_REQUEST]: (state) => ({...state, tableLoading: true, salaries: [], total: 0}),
    [GET_USER_SALARIES_SUCCESS]: (state, {payload: {salaries}}) => ({
        ...state,
        salaries: [...state.salaries, ...salaries.items],
        total: salaries.total,
        tableLoading: false,
    }),
    [GET_USER_SALARIES_FAILURE]: (state) => ({...state, tableLoading: false}),

    [GET_NEXT_PAGE_USER_SALARIES_REQUEST]: (state) => ({...state}),

    DEFAULT: (state) => state,
}

const salaryHistoryReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default salaryHistoryReducer
