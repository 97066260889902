import React from "react"

import UserImage from "../../../assets/images/user_photo.png"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"


const UserRolePopup = ({role, permissions, close}) => {
    return (
        <CustomPopup text_title={`${role.role_name.toUpperCase()} ${permissions ? "Permissions" : "Role"}`} className="roles-popup">
            <div className="popup-content">
                <span className="roles-popup-label t-s3">{permissions ? "Permissions" : "Assigned employees"}</span>
                <div className="roles-popup-box-ext">
                    <div className="roles-popup-table-ext">
                        {(role && !permissions) ? role.users.map((user, i) => (
                            <div key={`role_employees_${i}`} className="roles-popup-table-row-ext">
                                <div className="roles-popup-table-row-ext-img-wrap">
                                    <img src={user.image_url ? user.image_url : UserImage}/>
                                </div>
                                <span className="t-s3">{`${user.first_name} ${user.last_name}`}</span>
                            </div>
                        )) : permissions && role.permissions.map((perm, i) => (
                            <div key={`role_permission_${i}`} className="roles-popup-table-row-ext">
                                
                                <span className="t-s3">{perm.permission_name}</span>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}>OK</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default UserRolePopup
