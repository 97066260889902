import React from "react"
import {useSelector} from "react-redux"
import Popup from "reactjs-popup"

import UserImage from "../../../assets/images/user_photo.png"
import TimeOffUsageInfoPopup from "./TimeOffUsageInfoPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {ProfileRoute} from "../../../routes"
import {formatDate} from "../../../utils/datetime.utils"
import {DD_MM_YYYY} from "../../../constants/other"


const TimeOffBalanceTable = ({year, data, empty}) => {
    const history = useCustomHistory()

    const auth = useSelector((state) => state.auth)

    const profileClick = (id) => id === auth.user.uid ? history.push(ProfileRoute.path) : history.push(`/profile/${id}`)

    const employeesList = data.map((employee) => {
        const tableRow = (
            <tr key={employee.id + Math.random()} className="time-offs-balance-content-table-row custom-scrollable-table-row-user">
                <td>
                    <div className="custom-scrollable-table-employee">
                        <span className="custom-scrollable-table-employee-image clickable" onClick={() => profileClick(employee.id)}>
                            <img src={employee.image_url ? employee.image_url : UserImage} alt="employee"/>
                        </span>
                        <span className="custom-scrollable-table-employee-name clickable" onClick={() => profileClick(employee.id)}>
                            {employee.first_name} {employee.last_name}
                        </span>
                    </div>
                </td>
                <td>{formatDate(employee.hiring_date, DD_MM_YYYY)}</td>
                <td>{employee.last_date ? formatDate(employee.last_date, DD_MM_YYYY) : ""}</td>
                <td>{employee.total_per_year} days <span className="transferred">{ employee.transferred ? `(${employee.transferred} days)` : ""}</span></td>
                <td>{employee.accrued} days</td>
                <td>{employee.used} days</td>
                <td><span className={`${employee.balance < 0 ? "warning" : ""}`}>{employee.balance} days</span></td>
                <td>{employee.need_to_pay.amount ? <span className={`t-s1 ${employee.need_to_pay.amount < 0 ? "warning" : ""}`}>{employee.need_to_pay.amount.toFixed(2)} {employee.need_to_pay.currency.iso_code}</span> : <></>}</td>
            </tr>
        )

        return (
            <Popup key={employee.id} trigger={tableRow} modal>
                {close =>
                    <TimeOffUsageInfoPopup year={year} employee={employee} close={close}/>
                }
            </Popup>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="time-offs-balance-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">EMPLOYEE</span></th>
                        <th><span className="custom-scrollable-table-head-span">FIRST DAY</span></th>
                        <th><span className="custom-scrollable-table-head-span">LAST DAY</span></th>
                        <th><span className="custom-scrollable-table-head-span">TOTAL PER YEAR</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACCRUED</span></th>
                        <th><span className="custom-scrollable-table-head-span">USED</span></th>
                        <th><span className="custom-scrollable-table-head-span">BALANCE</span></th>
                        <th><span className="custom-scrollable-table-head-span">NEED TO PAY</span></th>
                    </tr>
                </thead>
                <tbody>
                    {employeesList}
                    {empty && <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default TimeOffBalanceTable
