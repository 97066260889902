import React from "react"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"
import {isTrue} from "../../../utils/other.utils"


const SettingsFeatureFlag = ({title, setting, updateGlobalSetting}) => {
    const active = isTrue(setting.value)

    return (
        <div className="settings-column-feature-flag">
            <div className="settings-column-feature-flag-title t-h3">
                {title}
            </div>
            <div onClick={() => updateGlobalSetting({
                value: !active,
                setting: setting,
                message: "The feature has been successfully updated",
            })}>
                {active
                    ? <ToggleOnIcon className="settings-column-feature-flag-toggle-active"/>
                    : <ToggleOffIcon className="settings-column-feature-flag-toggle-inactive"/>}
            </div>
        </div>
    )
}

export default SettingsFeatureFlag
