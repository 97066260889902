import React from "react"


const AccordionContainer = ({ columns, children }) => {
    return (
        <tr className="ns-table-accordion">
            <td colSpan={columns.filter((c) => !c.hidden).length + 1} className="ns-table-accordion-cell">
                {children}
            </td>
        </tr>

    )
}

export default AccordionContainer