import React from "react"
import "./index.scss"

const Newcoin = ({ type }) => {
    const className = `newcoin ${type}`

    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Newsoft_coin" clipPath="url(#clip0_16700_15567)">
                <path id="Vector" d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z" stroke="#5F6C72"/>
                <path id="Vector_2" d="M6.5504 10.1268L4.08289 7.06475V9.3638C4.08289 9.5719 4.11096 9.70568 4.16712 9.76514C4.21336 9.8246 4.32237 9.85763 4.49414 9.86424H4.53873V10.0129H3.36443V9.86424H3.39416C3.55932 9.86424 3.66998 9.83121 3.72614 9.76514C3.77569 9.69908 3.80046 9.5653 3.80046 9.3638V6.7526L3.72614 6.6535C3.5841 6.46192 3.45362 6.36612 3.3347 6.36612H3.28516V6.21252H4.01352L6.29274 9.00705V6.85665C6.29274 6.66506 6.26632 6.53294 6.21347 6.46026C6.15401 6.3975 6.0417 6.36612 5.87654 6.36612H5.8369V6.21252H7.00624V6.36612H6.96165C6.79649 6.36612 6.68748 6.3975 6.63463 6.46026C6.58508 6.51972 6.557 6.65185 6.5504 6.85665V10.1268ZM7.58832 10.1268H7.27617L9.58512 6.10352H9.90223L7.58832 10.1268ZM11.2838 10.1268C10.9337 10.1268 10.61 10.0756 10.3127 9.97324L10.2632 9.14083H10.4514L10.4564 9.17552C10.5059 9.43317 10.6083 9.62971 10.7636 9.76514C10.9122 9.90388 11.1087 9.97324 11.3532 9.97324C11.5712 9.97324 11.7545 9.90057 11.9032 9.75523C12.0551 9.60989 12.1344 9.43317 12.141 9.22506C12.141 8.96741 11.9428 8.70315 11.5464 8.43229L11.1699 8.17464C10.8032 7.92029 10.5621 7.72375 10.4464 7.58501C10.3341 7.44297 10.278 7.27451 10.278 7.07962C10.278 6.79224 10.3952 6.55441 10.6298 6.36612C10.8742 6.19105 11.1897 6.10352 11.5761 6.10352C11.8867 6.10352 12.1955 6.15637 12.5027 6.26207L12.582 6.97557H12.3887L12.3739 6.93593C12.2451 6.48008 11.9461 6.25216 11.4771 6.25216C11.269 6.25216 11.0955 6.31492 10.9568 6.44044C10.8214 6.56597 10.7536 6.72617 10.7536 6.92106C10.7536 7.1655 10.9238 7.40168 11.264 7.62961L11.8041 8.00617C12.1542 8.24731 12.3772 8.43229 12.473 8.56112C12.5787 8.70315 12.6315 8.87657 12.6315 9.08137C12.6315 9.39848 12.5093 9.65283 12.2649 9.84442C12.0336 10.0261 11.7066 10.1202 11.2838 10.1268Z" fill="#5F6C72" stroke="#5F6C72" strokeWidth="0.5"/>
            </g>
            <defs>
                <clipPath id="clip0_16700_15567">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )}

export default Newcoin