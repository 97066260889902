import {timeRelatedApi} from "./base"


const INVALIDATION_TAGS = [
    "TimeRecords",
    "UserTimeRecords",
    "UserTimeRecordDetails",
    "UserTimeRecordGroupedReports",
    "UserTimeTrackerChart",
    "UserCalendarData",
    "TimeOffsUsageInfo",
    "TimeOffBalances",
    "UserReport",
]

export const timeRecordsApi = timeRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        getTimeRecords: builder.query({
            query: (params) => ({
                path: "time-records/",
                params,
            }),
            providesTags: ["TimeRecords"]
        }),
        getUserTimeRecords: builder.query({
            query: ({userId, params}) => ({
                path: `users/${userId}/time-records`,
                params,
            }),
            providesTags: (result, error, arg) => [{type: "UserTimeRecords", id: arg.userId}]
        }),
        getUserTimeRecordDetails: builder.query({
            query: ({userId, params}) => ({
                path: `time-records/time-tracker-details/${userId}`,
                params,
            }),
            providesTags: (result, error, arg) => [{type: "UserTimeRecordDetails", id: arg.userId}]
        }),
        getUserTimeRecordsGroupedReports: builder.query({
            query: ({userId, params}) => ({
                path: `time-records/grouped-reports/${userId}`,
                params,
            }),
            providesTags: (result, error, arg) => [{type: "UserTimeRecordGroupedReports", id: arg.userId}]
        }),
        getUserTimedTrackerChart: builder.query({
            query: ({userId, params}) => ({
                path: `time-records/time-tracker-chart/${userId}`,
                params,
            }),
            providesTags: (result, error, arg) => [{type: "UserTimeTrackerChart", id: arg.userId}]
        }),
        getUserCalendarData: builder.query({
            query: ({userId, params}) => ({
                path: `time-records/calendar-data/${userId}`,
                params,
            }),
            providesTags: (result, error, arg) => [{type: "UserCalendarData", id: arg.userId}]
        }),
        // This doesn't necessarily belong here but no better place to invalidate it
        getUserReport: builder.query({
            query: ({userId, params}) => ({
                path: `user-report/${userId}`,
                params,
            }),
            providesTags: (result, error, arg) => [{type: "UserReport", id: arg.userId}]
        }),
        createTimeRecord: builder.mutation({
            query: (data) => ({path: "time-records/", method: "POST", data}),
            invalidatesTags: INVALIDATION_TAGS,
        }),
        duplicateTimeRecord: builder.mutation({
            query: (data) => ({path: "time-records/duplicate-time-records", method: "POST", data}),
            invalidatesTags: INVALIDATION_TAGS,
        }),
        updateTimeRecord: builder.mutation({
            query: ({id, data}) => ({path: `time-records/${id}`, method: "PUT", data}),
            invalidatesTags: INVALIDATION_TAGS,
        }),
        deleteTimeRecord: builder.mutation({
            query: (id) => ({path: `time-records/${id}`, method: "DELETE"}),
            invalidatesTags: INVALIDATION_TAGS,
        }),

        getTimeRecordTemplates: builder.query({
            query: (params) => ({
                path: "time-record-templates/",
                params,
            }),
            providesTags: ["TimeRecordTemplates"]
        }),
        createTimeRecordTemplate: builder.mutation({
            query: (data) => ({path: "time-record-templates/", method: "POST", data}),
            invalidatesTags: ["TimeRecordTemplates"]
        }),
        deleteTimeRecordTemplate: builder.mutation({
            query: (id) => ({path: `time-record-templates/${id}`, method: "DELETE"}),
            invalidatesTags: ["TimeRecordTemplates"]
        }),
    })
})

export const {
    useGetTimeRecordsQuery,
    useGetUserTimeRecordsQuery,
    useGetUserTimeRecordDetailsQuery,
    useGetUserTimeRecordsGroupedReportsQuery,
    useGetUserTimedTrackerChartQuery,
    useGetUserCalendarDataQuery,
    useGetUserReportQuery,
    useCreateTimeRecordMutation,
    useDuplicateTimeRecordMutation,
    useUpdateTimeRecordMutation,
    useDeleteTimeRecordMutation,

    useGetTimeRecordTemplatesQuery,
    useCreateTimeRecordTemplateMutation,
    useDeleteTimeRecordTemplateMutation,
} = timeRecordsApi
