import React from "react"

import {archiveUserById} from "../../../Employees/api"
import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE} from "../../../../constants/other"
import {EmployeesRoute} from "../../../../routes"
import {useCustomHistory} from "../../../../hooks/useCustomHistory"


const ArchiveEmployeePopup = ({employee, setUserMessage, close}) => {
    const history = useCustomHistory()
    const fullName = `${employee.first_name} ${employee.last_name}`

    const handleSubmit = () => {
        archiveUserById(employee.id, {archived: !employee.archived})
            .then(() => {
                history.push(EmployeesRoute.path)
            })
            .catch((error) => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to ${!employee.archived ? "archive" : "unarchive"} ${fullName} account?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}>Cancel</SmallButton>
                    <SmallButton onClick={handleSubmit}>{!employee.archived ? "Archive" : "Unarchive"}</SmallButton>
                </div>
            </div>                    
        </CustomPopup>
    )
}

export default ArchiveEmployeePopup
