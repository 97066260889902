import "./index.scss"

import React, {useEffect, useRef, useState} from "react"
import Creatable from "react-select/creatable"
import {components} from "react-select"
import {useMediaQuery} from "react-responsive"
import ArrowDown from "@mui/icons-material/KeyboardArrowDown"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import {DESKTOP_WIDTH, PRODUCT_LABEL_COLORS} from "../../constants/other"
import CheckIcon from "@mui/icons-material/Check"
import InvertColorsIcon from "@mui/icons-material/InvertColors"
import Popover from "@mui/material/Popover"

const EditableOption = ({children, setMenuIsOpen, ...props}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editedValue, setEditedValue] = useState(props.data.label)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleEditClick = (e) => {
        e.stopPropagation()
        setIsEditing(true)
    }

    const handleConfirmEdit = (e) => {
        e.stopPropagation()
        setIsEditing(false)
        props.selectProps.onEditOption({...props.data, label: editedValue, value: editedValue})
    }

    const handleInputChange = (e) => {
        setEditedValue(e.target.value)
    }

    const handleColorClick = (e, color) => {
        e.stopPropagation()
        setAnchorEl(null)
        props.selectProps.onEditOption({ ...props.data, color })
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleConfirmEdit(e)
        } else if (e.key === "Backspace" && editedValue.length > 0) {
            e.stopPropagation()
        } else if (e.key == " ") {
            e.preventDefault()
            e.stopPropagation()
            setEditedValue(editedValue + " ")
        }
    }

    return (
        <components.Option {...props}>
            <div className="editable-option">
                {isEditing ? (
                    <input
                        type="text"
                        value={editedValue}
                        onChange={handleInputChange}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={handleKeyDown}
                        autoFocus={true}
                        style={{border: "none", outline: "none", background: "transparent"}}
                    />
                ) : (
                    <span className={`${props.selectProps.colored ? "option-color t-b3" : ""}`} style={{backgroundColor: props.data.color || "transparent", color: props.data.color ? "#FFFFFF" : "#5F6C72"}}>
                        <span className="option-text">{children}</span>
                    </span>
                )}
                <span className="option-icons">
                    {isEditing ? (
                        <CheckIcon onClick={handleConfirmEdit} />
                    ) : (
                        <>
                            {props.selectProps.colored && <InvertColorsIcon
                                onMouseDown={(e) => {
                                    e.stopPropagation()
                                    setAnchorEl(e.currentTarget)
                                    setMenuIsOpen(true)
                                }}
                            />}
                            <EditIcon onClick={handleEditClick} />
                            <DeleteIcon onClick={(e) => {
                                e.stopPropagation()
                                props.selectProps.onDeleteOption(props.data)
                            }} />
                        </>
                    )}
                </span>
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div className="color-picker-popover-text t-s3">Color</div>
                <div className="color-picker-popover">
                    {PRODUCT_LABEL_COLORS.map((color) => (
                        <div
                            key={color}
                            className="color-swatch"
                            style={{ backgroundColor: color }}
                            onClick={(e) => handleColorClick(e, color)}
                        />
                    ))}
                </div>
            </Popover>
        </components.Option>
    )
}

const DDListCRUD = ({
    listOptions,
    onChange,
    text_label,
    isMulti=false,
    defaultValue,
    onEditOption,
    onDeleteOption,
    onCreateOption,
    ...props
}) => {
    const isDesktop = useMediaQuery({maxWidth: DESKTOP_WIDTH})
    const inputRef = useRef()
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const handleKeyDown = (event) => {
        if (event.key === "Backspace" && !event.target.value) {
            onChange({value: null})
        }
    }

    useEffect(() => {
        if (isDesktop) {
            inputRef.current.blur()
        }
    }, [isDesktop])

    const InputIcon = () => {
        return (
            <div className="toolkit-dd-list-select__indicators-wrapper" onClick={() => setMenuIsOpen((prev) => !prev)}>
                <ArrowDown  className="arrow"/>
            </div>
        )
    }

    return (
        <div className="toolkit-dd-list-wrapper">
            {text_label ? <label className="toolkit-dd-list-label t-s3">{text_label}</label> : <></>}
            <Creatable
                ref={inputRef}
                options={listOptions}
                defaultValue={defaultValue}
                classNamePrefix="t-s4 toolkit-dd-list-select"
                isMulti={isMulti}
                components={{DropdownIndicator: () => <InputIcon/>, Option: (props) => <EditableOption {...props} setMenuIsOpen={setMenuIsOpen}/>}}
                onChange={(option) => {onChange(option); inputRef.current.blur()}}
                onEditOption={onEditOption}
                onDeleteOption={onDeleteOption}
                onCreateOption={onCreateOption}
                menuIsOpen={menuIsOpen}
                onKeyDown={handleKeyDown}
                onFocus={() => setMenuIsOpen(true)}
                onBlur={(e) => {
                    if (e.relatedTarget && e.relatedTarget.closest(".MuiPopover-root") || e.relatedTarget?.tagName === "INPUT") {
                        e.stopPropagation()
                        return
                    }
                    setMenuIsOpen(false)
                }}
                styles={{
                    multiValue: (base, state) => {
                        const color = state.data.color || "#000000"
                        return {
                            ...base,
                            backgroundColor: color,
                        }
                    }
                }}
                {...props}
            />
        </div>
    )
}

export default DDListCRUD
