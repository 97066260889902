import "./index.scss"

import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import {Switch} from "@material-ui/core"

const useStyles = makeStyles({
    switch: {
        "& .MuiSwitch-thumb": {
            marginTop: "6px",
            marginLeft: "5px",
            width: "10px",
            height: "10px",
        },
        "& .MuiSwitch-track": {
            height: "16px",
            width: "52px",
        },
    },
})

const Slider = ({...props}) => {
    const classes = useStyles()

    return (
        <div className="toolkit-slider">
            <Switch
                {...props}
                classes={{
                    root: classes.switch,
                }}
                disableRipple
            />
        </div>
    )
}

export default Slider
