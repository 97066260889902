import React, { useState, useEffect} from "react"
import CustomDate from "../../../components/CustomDate"
import { saveTimeRecordsRange, getSavedTimeRecordsRange } from "../../../utils/localstorage.utils"
import { mapDateRangeFilters } from "../../../utils/datetime.utils"
import { FILTER_TYPES } from "../../../constants/other"

export const TimeRecordsPeriodFilter = ({ setPeriod }) => 
{
    const [date, setDate] = useState()
    const savedTimeRecordsRange = getSavedTimeRecordsRange()    
    const [periodType, setPeriodType] = useState(Object.values(FILTER_TYPES).includes(savedTimeRecordsRange) ? savedTimeRecordsRange : FILTER_TYPES.THIS_MONTH)
    const [active, setActive] = useState(false)

    const handleFilter = (filter) => {
        setPeriod(mapDateRangeFilters(filter, date))
        setPeriodType(filter)

        if (filter !== FILTER_TYPES.CUSTOM) {
            setActive(false)
            saveTimeRecordsRange(filter)
        }
    }

    useEffect(() => {
        if (date) {
            handleFilter(FILTER_TYPES.CUSTOM)
        }
    }, [date])
    return (
        <>
            <div className="time-records-filters-period-wrapper">
                <div className="time-records-filters-period">
                    <>  
                        <span className="time-records-filters-period-label t-s4">Period: </span>

                        <span
                            className={periodType === FILTER_TYPES.THIS_WEEK ? "time-records-filters-period-selected t-s3" : "t-s4"}
                            onClick={() => handleFilter(FILTER_TYPES.THIS_WEEK)}
                        >
                            This week
                        </span>
                              
                        <span
                            className={periodType === FILTER_TYPES.THIS_MONTH ? "time-records-filters-period-selected t-s3" : "t-s4"}
                            onClick={() => handleFilter(FILTER_TYPES.THIS_MONTH)}
                        >
                            This month
                        </span>

                        <span
                            className={periodType === FILTER_TYPES.LAST_MONTH ? "time-records-filters-period-selected t-s3" : "t-s4"}
                            onClick={() => handleFilter(FILTER_TYPES.LAST_MONTH)}
                        >
                            Last month
                        </span>

                        <span
                            className={periodType === FILTER_TYPES.THIS_YEAR ? "time-records-filters-period-selected t-s3" : "t-s4"}
                            onClick={() => handleFilter(FILTER_TYPES.THIS_YEAR)}
                        >
                            This year
                        </span>

                        <span
                            className={periodType === FILTER_TYPES.LAST_YEAR ? "time-records-filters-period-selected t-s3" : "t-s4"}
                            onClick={() => handleFilter(FILTER_TYPES.LAST_YEAR)}
                        >
                            Last year
                        </span>

                        <CustomDate name='Custom' setCustom={setDate} active={active} setActive={setActive}/>
                    </>
                </div>
            </div>
        </>
    )
}