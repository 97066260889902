import {useGetProjectsQuery} from "../api/projects"


const useProjects = ({ params, skip } = {}) => {
    const {data, isLoading} = useGetProjectsQuery(params, { skip })

    return {
        projects: data?.items || [],
        total: data?.total,
        isLoading,
    }
}

export default useProjects