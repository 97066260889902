// import {useEffect} from "react"
// import {popState, pushState} from "./actions/navigationActions"
// import {useDispatch, useSelector} from "react-redux"
// import {useCustomHistory} from "../../hooks/useCustomHistory"

const NavigationWrapper = ({children}) => {
    // const {navigationStack} = useSelector((state) => state.navigation)
    // const dispatch = useDispatch()
    // const history = useCustomHistory()
    //
    // useEffect(() => {
    //     const currentLocation = history.location
    //     const activeTab = currentLocation.state?.activeTab || currentLocation.pathname
    //     const screenStack = navigationStack.get(activeTab)
    //
    //     if (!screenStack || screenStack.length === 0) {
    //         dispatch(pushState(activeTab, currentLocation.pathname, currentLocation.state))
    //     }
    //
    //     return history.listen(async (location, action) => {
    //
    //         if(action === "POP"){
    //             let screenStack = navigationStack.get(location.state?.activeTab)
    //             if(screenStack?.length > 1){
    //                 await dispatch(popState(location.state?.activeTab))
    //                 screenStack = navigationStack.get(location.state?.activeTab)
    //                 const lastScreen = screenStack[screenStack.length - 1]
    //                 history.push(lastScreen.path, lastScreen.state, lastScreen.state?.activeTab)
    //             }
    //         }
    //
    //         // If we are moving forward the history
    //         // To avoid recursion after `history.push` called inside this function
    //         if (location.state?.shouldUpdate === false){
    //             location.state.shouldUpdate = true
    //             return
    //         }
    //
    //         if (action === "PUSH") {
    //             const screenStack = navigationStack.get(location.pathname)
    //
    //             // If it is a root path and stack is not empty
    //             if (screenStack?.length > 0){
    //                 // Double-click on the root path
    //                 if(history.location.state?.activeTab === location.pathname){
    //                     screenStack.length = 1
    //                     location.state.shouldUpdate = false
    //                     history.push(location.pathname, location.state, location.pathname)
    //                 } else {
    //                     // Single click on the root tab
    //                     const lastScreen = screenStack[screenStack.length - 1]
    //                     lastScreen.state.shouldUpdate = false
    //                     history.push(lastScreen.path, lastScreen.state, location.pathname)
    //                 }
    //             } else {
    //                 // If it is a root path with empty stack or no-root path, then just push the next state to the stack
    //                 dispatch(pushState(location.state?.activeTab || location.pathname, location.pathname, location.state))
    //             }
    //         }
    //     })
    // }, [history])

    return children
}

export default NavigationWrapper
