import {useCallback, useMemo} from "react"
import {PRODUCT_STATUS, PRODUCTS_SORT_OPTIONS} from "../../../constants/other"
import {useGetProductCategoriesQuery, useGetProductsQuery} from "../../../api/products"
import {useTableData} from "../../../hooks/common/useTableData"
import {useTranslation} from "react-i18next"


export const useProducts = () => {
    const { t } = useTranslation("common")
    const {data: categories} = useGetProductCategoriesQuery({})

    const categoryStatuses = useMemo(() => [
        {id: "", name: t("All")},
        ...(categories?.map((category) => ({id: category.id, name: category.name})) || []),
    ], [categories])

    const transformParams = useCallback((params) => ({
        category_ids: params.categoryFilter || undefined,
        excluded_statuses: PRODUCT_STATUS.DELETED,
    }), [categories])

    const {
        items,
        params,
        changeParams,
        sort,
        changeSort,
        loading,
        empty,
        loadNextPage,
        total,
        hasMore,
    } = useTableData(useGetProductsQuery, "shopProducts", {
        defaultState: {sort: PRODUCTS_SORT_OPTIONS.NEWEST_FIRST},
        transformParams,
    })

    return {
        products: items,
        total,
        hasMore,
        loading,
        empty,
        loadNextPage,
        categoryStatuses,
        categoryFilter: params.categoryFilter,
        setCategoryFilter: (categoryFilter) => changeParams({categoryFilter}),
        sortBy: sort,
        setSortBy: changeSort
    }
}
