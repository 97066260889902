import React from "react"

import {deleteUserById} from "../../../Employees/api"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE} from "../../../../constants/other"
import {useCustomHistory} from "../../../../hooks/useCustomHistory"
import {EmployeesRoute} from "../../../../routes"


const DeleteEmployeePopup = ({employee, setUserMessage, close}) => {
    const history = useCustomHistory()
    const fullName = `${employee.first_name} ${employee.last_name}`

    const handleSubmit = () => {
        deleteUserById(employee.id)
            .then(() => {
                history.push(EmployeesRoute.path)
            })
            .catch((error) => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup isSubtitle={true} text_title={`Do you want to delete ${fullName} account?`} isAgreePopup={true}>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}>CANCEL</SmallButton>
                    <SmallButton onClick={handleSubmit}>DELETE</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteEmployeePopup
