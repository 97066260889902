import "./index.scss"

import React from "react"

import UserImage from "../../assets/images/user_photo.png"

import CustomPopup from "../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../toolkits/SmallButton/SmallButton"


const EmployeesBoxPopup = ({employees, title, label, close}) => (
    <CustomPopup text_title={title} className="roles-popup">
        <div className="popup-content">
            <span className="roles-popup-label t-s3">{label}</span>
            <div className="employees-box-popup-wrapper">
                <div className="employees-box-popup-table">
                    {employees.map((employee, i) => (
                        <div key={`employee_${i}`} className="employees-box-popup-row">
                            <div className="employees-box-popup-row-img">
                                <img src={employee.image_url ? employee.image_url : UserImage}/>
                            </div>
                            <span className="t-s3">{`${employee.first_name} ${employee.last_name}`}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="popup-bottom">
            <div className="popup-bottom-button-container">
                <SmallButton btnType="secondary" onClick={close}>OK</SmallButton>
            </div>
        </div>
    </CustomPopup>
)

export default EmployeesBoxPopup
