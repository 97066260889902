import {coinsRelatedApi} from "./base"


export const coinsTransactionsApi = coinsRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        createCoinsTransaction: builder.mutation({
            query: (data) => ({path: "newcoins-transactions/", method: "POST", data}),
            invalidatesTags: ["CoinsTransactions"]
        }),
        getCoinsBalances: builder.query({
            query: (params) => ({path: "newcoins-transactions/newcoins-balances", params}),
            providesTags: ["BalanceAvailable", "BalanceSpent"],
        })
    })
})

export const {
    useCreateCoinsTransactionMutation,
    useGetCoinsBalancesQuery,
} = coinsTransactionsApi
