import {formatDate, getTime} from "../../../utils/datetime.utils"
import React from "react"


const DateTimeColumn = ({ date }) => {
    return (
        <div style={{display: "flex", gap: 10, overflow: "hidden" }}>
            <span>{formatDate(date)}</span> <span style={{ overflow: "hidden", textOverflow: "ellipsis"}}>{getTime(date)}</span>
        </div>
    )
}

export default DateTimeColumn