import {
    GET_SALARY_EARN_REQUEST,
    GET_SALARY_EARN_SUCCESS,
    GET_SALARY_EARN_FAILURE
} from "../const"

const initialState = {
    earn: [],
    loading: false
}

const handlers = {
    [GET_SALARY_EARN_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_SALARY_EARN_SUCCESS]: (state, { payload: { earn } }) => ({
        ...state,
        earn: earn,
        loading: false,
    }),
    [GET_SALARY_EARN_FAILURE]: (state) => ({ ...state, loading: false }),

    DEFAULT: (state) => state,
}

const SalaryEarnReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default SalaryEarnReducer