import React from "react"
import {formatDate, minutesToString} from "../../../utils/datetime.utils"
import {TimeRecordsRoute} from "../../../routes"
import CloseIcon from "@mui/icons-material/Close"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import { getColorByHoursType } from "../../../utils/other.utils"
import moment from "moment"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import useTimeRecords from "../../../hooks/useTimeRecords"


const DayTimeRecordsPopup = ({userId, date, close}) => {
    const history = useCustomHistory()

    const redirectTimeRecords = () => history.push(TimeRecordsRoute.path, {
        userId: userId,
    })

    const { timeRecords, total, isLoading } = useTimeRecords({userId, created_at: formatDate(date)})

    return (
        <FlexiblePopup>
            <div className="day-time-record-popup">
                <div className="day-time-record-popup-title t-h2">Time Records ({moment(date).format("ddd, DD MMM YYYY")})</div>
                <table className="day-time-record-popup-table">
                    <thead>
                        <tr className="day-time-record-popup-table-row heading-color t-h3">
                            <th className="day-time-record-popup-table-row-hours-type">Hours Type</th>
                            <th className="day-time-record-popup-table-row-project">Project</th>
                            <th className="day-time-record-popup-table-row-duration">Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <StyledCircularProgress/>
                            :
                            <>
                                {timeRecords.map((record, i) => (
                                    (<>
                                        <tr key={i} className="day-time-record-popup-table-row t-s4">
                                            <td className="day-time-record-popup-table-row-hours-type"><li style={{backgroundColor: getColorByHoursType(record.hours_type)}} className="dot"></li>{record.hours_type}</td>
                                            <td className="day-time-record-popup-table-row-project">{record.project?.name}</td>
                                            <td className="day-time-record-popup-table-row-duration">{minutesToString(record.duration)}</td>
                                        </tr>
                                        <hr className="day-time-record-popup-table-divider"/>
                                    </>)
                                ))}
                                <tr className="day-time-record-popup-table-row t-b1">
                                    <td className="day-time-record-popup-table-row-hours-type">Total:</td>
                                    <td className="day-time-record-popup-table-row-project"></td>
                                    <td className="day-time-record-popup-table-row-duration">{minutesToString(total)}</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
                <button onClick={redirectTimeRecords} className="day-time-record-popup-button t-button">All Records</button>
                <CloseIcon onClick={close} className="day-time-record-popup-button-close"/>
            </div>
        </FlexiblePopup>
    )
}

export default DayTimeRecordsPopup