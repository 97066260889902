import {GET_SETTINGS_REQUEST, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE} from "../const"


const initialState = {
    defaultTimeOffsPerYear: {id: 0, name: "", value: 0},
    maxTimeOffsToTransfer: {id: 0, name: "", value: 0},
    maxTimeOffsPerYear: {id:0, name: "", value: 0},
    timeOffForEachWorkingYear: {id: 0, name: "", value: 0},
    upcomingPerformanceReview: {id: 0, name: "", value: 0},
    maxDaysStoreNotifications: {id: 0, name: "", value: 0},
    websiteTheme: {id: 0, name: "", value: ""},
    accessToTimeTracker: localStorage.getItem("settings") ? JSON.parse(localStorage.getItem("settings")).accessToTimeTracker : {id: 0, name: "", value: 0},
    accessToEditBonus: {id: 0, name: "", value: 0},
    newcoinsEnabled: {id: 0, name: "", value: "false"},
    shopEnabled: {id: 0, name: "", value: "false"},
    autoGiftBirthday: {id: 0, name: "", value: "false"},
    autoGiftAnniversary: {id: 0, name: "", value: "false"},
    autoGiftLoggedHours: {id: 0, name: "", value: "false"},
    loading: true,
}

const handlers = {
    [GET_SETTINGS_REQUEST]: (state) => ({...state, loading: true}),
    [GET_SETTINGS_SUCCESS]: (state, {payload}) => ({
        ...state,
        ...payload,
        loading: false
    }),
    [GET_SETTINGS_FAILURE]: (state) => ({...state, loading: false}),

    DEFAULT: (state) => state,
}

const settingsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default settingsReducer
