import React from "react"
import NavigationWrapper from "../../layouts/Navs/NavigationWrapper"

const PageContainer = ({children}) => {
    return (
        <div className="page-container">
            <NavigationWrapper>
                {children}
            </NavigationWrapper>
        </div>
    )
}

export default PageContainer
