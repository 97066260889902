import {
    GET_USER_NEWCOINS_TRANSACTIONS_SUCCESS,
    GET_USER_NEWCOINS_TRANSACTIONS_FAILURE,
    GET_USER_NEWCOINS_TRANSACTIONS_REQUEST,
    GET_NEXT_PAGE_USER_NEWCOINS_TRANSACTIONS_REQUEST
} from "../const"


const initialNewcoinsTransactionsState = {
    newcoinsTransactions: [],
    total: 0,
    tableLoading: true,
}

const newcoinsTransactionsHandlers = {
    [GET_USER_NEWCOINS_TRANSACTIONS_REQUEST]: (state) => ({...state, tableLoading: true, newcoinsTransactions: [], total: 0}),
    [GET_USER_NEWCOINS_TRANSACTIONS_SUCCESS]: (state, {payload: {newcoinsTransactions}}) => ({
        ...state,
        newcoinsTransactions: [...state.newcoinsTransactions, ...newcoinsTransactions.items],
        total: newcoinsTransactions.total,
        tableLoading: false,
    }),
    [GET_USER_NEWCOINS_TRANSACTIONS_FAILURE]: (state) => ({...state, tableLoading: false}),

    [GET_NEXT_PAGE_USER_NEWCOINS_TRANSACTIONS_REQUEST]: (state) => ({...state}),

    DEFAULT: (state) => state,
}

export const newcoinsTransactionsReducer = (state = initialNewcoinsTransactionsState, action) => {
    const handle = newcoinsTransactionsHandlers[action.type] || newcoinsTransactionsHandlers.DEFAULT
    return handle(state, action)
}

