import "./index.scss"

import React from "react"
import Select from "react-select"


const DDListYear = (props) => {
    return (
        <Select
            isSearchable={false}
            components={{
                IndicatorSeparator: () => null
            }}
            classNamePrefix="t-s1 toolkit-dd-list-year-react-select"
            {...props}
        />
    )
}

export default DDListYear
