import {
    GET_DASHBOARD_NOTIFICATION_SUCCESS,
    GET_DASHBOARD_NOTIFICATION_REQUEST,
} from "../const"


const initialState = {
    loading: true,
    performanceReviewCount: null,
    timeOffRequestsCount: null,
    timeRecordRequestsCount: null,
    onboardingRequestsCount: null,
    purchaseRequestsCount: null,
    total_count: 0,
}

const handlers = {
    [GET_DASHBOARD_NOTIFICATION_REQUEST]: (state) => ({...state, loading: true}),
    [GET_DASHBOARD_NOTIFICATION_SUCCESS]: (state, {payload}) => ({
        ...state,
        ...payload,
        loading: false,
    }),

    DEFAULT: (state) => state,
}

const dashboardNotificationsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default dashboardNotificationsReducer
