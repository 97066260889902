import {isEqual, isUndefined, omitBy} from "lodash"
import {useCallback, useEffect, useMemo, useState} from "react"
import {PAGE_SIZE} from "../../constants/other"


const EMPTY_DATA_STATE = {hasMore: true}

const reduceItems = (pageItems) => (
    Object.entries(pageItems)
        .sort(([key1], [key2]) => key1 > key2 ? 1 : -1)
        .reduce((acc, [, value]) => [...acc, ...value], [])
)

export const usePaginatedQuery = (useQuery, {skip, defaultState, defaultData, transformParams, itemIdField} = {}) => {
    const [mainState, setMainState] = useState({page: 1, sort: {}, params: {}, ...defaultState})

    const defaultDataState = useMemo(() => ({
        ...EMPTY_DATA_STATE,
        ...defaultData,
        items: defaultData?.pageItems ? reduceItems(defaultData.pageItems) : [],
    }), [defaultData])

    const [dataState, setDataState] = useState(defaultDataState)

    const queryParams = useMemo(() => ({
        size: PAGE_SIZE,
        page: mainState.page,
        order_by: mainState.sort?.key,
        asc: mainState.sort?.asc,
        ...(transformParams ? transformParams(mainState.params) : mainState.params),

    }), [mainState, transformParams])

    const {data, error, isLoading, isFetching, refetch} = useQuery(queryParams, {skip})

    const total = useMemo(() => data?.total, [data])

    const size = useMemo(() => data?.size, [data])

    const maxPage = useMemo(() => total ? Math.ceil(total / size) : null, [size, total])

    useEffect(() => {
        const loading = isLoading || isFetching

        setDataState((prevState) => {
            // In case request is loading and there is no pageItems we should not use previously set data.
            let actualData = !prevState?.pageItems && loading ? null : data

            const pageItems  = actualData ? {...prevState?.pageItems, [actualData.page]: actualData.items} : prevState?.pageItems || {}
            const items = reduceItems(pageItems)

            return {
                pageItems,
                items,
                loading,
                // The initial state here is that there is potentially more records until we make our first request and only when maxPage
                // is available after the first request we can calculate it for sure.
                hasMore: maxPage === undefined || loading || mainState.page < maxPage,
                empty: !loading && items.length === 0,
            }})

    }, [isFetching, isLoading, mainState.page, maxPage, data])

    // This should be used after some action on the table that can change the state of the data.
    // Only resets the data but keeps the previous state of inputs.
    // Except the page, page resets to the first one so the data is loaded properly
    const reset = useCallback(() => {
        setDataState({})
        setMainState((prev) => ({...prev, page: 1}))
        refetch()
    }, [])

    const fullReset = useCallback(() => {
        setMainState({
            page: 1,
            params: {},
            sort: {},
            pageItems: {},
        })
        refetch()
    }, [])

    const loadNextPage = useCallback(() => {
        if (!dataState.loading && mainState.page < maxPage) {
            setMainState((prev) => ({...prev, page: prev.page + 1}))
        }
    },
    [mainState.page, maxPage, dataState.loading],
    )

    const changeSort = useCallback((newSort) => {
        if (!isEqual(omitBy(newSort, isUndefined), omitBy(mainState.sort, isUndefined))) {
            setDataState(EMPTY_DATA_STATE)
            setMainState((prev) => ({...prev, sort: newSort, page: 1}))
        }
    }, [mainState.sort])

    const changeParams = useCallback((params, partial=true) => {
        const baseParams = omitBy(mainState.params, isUndefined)

        let newParams = null
        if (partial) {
            newParams = omitBy({...baseParams, ...params}, isUndefined)
        } else {
            newParams = omitBy(params, isUndefined)
        }

        if (!isEqual(newParams, baseParams)) {
            setDataState(EMPTY_DATA_STATE)
            setMainState((prev) => ({...prev, params: newParams, page: 1}))
        }
    }, [mainState.params])


    // When item is deleted we can use this function to remove it from the view without making API calls. With single page pagination
    // it's really problematic to remove the item using redux as the removed item might not belong to the current page
    const onItemRemoved = useCallback((itemId) => {
        if (!itemIdField) {
            console.warn("itemIdField should be passed to remove item")
            return
        }

        let itemPage = null
        let itemIndex = null

        Object.entries(dataState.pageItems).forEach(([page, pageItems]) => {
            if (itemIndex !== null) return null

            const foundItemIndex = pageItems.findIndex((item) => item?.[itemIdField] === itemId)
            if (foundItemIndex !== -1) {
                itemPage = page
                itemIndex = foundItemIndex
                return null
            }
        })

        if (itemPage !== null && itemIndex !== null) {
            setDataState((previousData) => {
                const updatedPageItems = { ...previousData.pageItems }
                updatedPageItems[itemPage] = [
                    ...updatedPageItems[itemPage].slice(0, itemIndex),
                    ...updatedPageItems[itemPage].slice(itemIndex + 1)
                ]
                return {
                    ...previousData,
                    pageItems: updatedPageItems,
                }
            })
        }
    }, [dataState, itemIdField])

    return {
        items: dataState.items || [],
        onItemRemoved,
        loading: dataState.loading,
        empty: dataState.empty,
        hasMore: dataState.hasMore,
        params: mainState.params,
        changeParams,
        sort: mainState.sort,
        changeSort,
        page: mainState.page,
        reset,
        fullReset,
        total,
        error,
        maxPage,
        loadNextPage,
        pageItems: dataState.pageItems,
    }
}
