import React, {useState} from "react"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import {Helmet} from "react-helmet"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import {PURCHASE_REQUEST_STATUS} from "../../constants/other"
import PurchaseRequestsTable from "./components/PurchaseRequestsTable"
import "./index.scss"
import FilterPanel from "../../toolkits/FilterPanel"

const PurchaseRequests = () => {
    const [userMessage, setUserMessage] = useState(null)
    const [searchKey, setSearchKey] = useState("")
    const [statusFilter, setStatusFilter] = useState(PURCHASE_REQUEST_STATUS.ALL)

    return (
        <>
            <Helmet>
                <title>Purchase Requests - Newsoft Inside</title>
            </Helmet>
            <div>
                {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                }
            </div>
            <div className="purchase-requests-header">
                <div className="purchase-requests-header-search">
                    <SearchForm onChange={setSearchKey} debounceMs={500}/>
                    <FilterPanel
                        title={"Type"}
                        options={PURCHASE_REQUEST_STATUS}
                        selectedOption={statusFilter}
                        setOptionFilter={setStatusFilter}
                    />
                </div>
            </div>
            <PurchaseRequestsTable
                searchKey={searchKey}
                statusFilter={statusFilter === PURCHASE_REQUEST_STATUS.ALL ? null : statusFilter}
                setUserMessage={setUserMessage}
            />
        </>
    )
}

export default PurchaseRequests