import "./AuditLogsAccordionContent.scss"

import React from "react"
import TextSnippet from "../../../components/typography/TextSnippet"
import {DD_MM_YYYY, INFO_ALERT, MIN_ALERT_DURATION, SUCCESS_CODE} from "../../../constants/other"
import {formatDate, getTime} from "../../../utils/datetime.utils"
import Typography from "../../../components/typography/Typography"
import {getAction, getObject} from "../utils"
import CopyButton from "../../../components/Miscellaneous/CopyButton"
import {useTranslation} from "react-i18next"


export const AuditLogsAccordionContent = ({row, setUserMessage}) => {
    const {t} = useTranslation("common")

    return (
        <div className="audit-logs-accordion">
            <div className="audit-logs-accordion-data">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Typography variant="supporting2">{t("Date")}: </Typography>
                            </td>
                            <td>
                                <div className="audit-logs-accordion-data-value">
                                    <Typography variant="leading2">{formatDate(row.date, DD_MM_YYYY)} {getTime(row.date)}</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="supporting2">{t("Source")}: </Typography>
                            </td>
                            <td>
                                <div className="audit-logs-accordion-data-value">
                                    <Typography variant="leading2">{row.user_name}</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="supporting2">{t("Action")}: </Typography>
                            </td>
                            <td>
                                <div className="audit-logs-accordion-data-value">
                                    <Typography variant="leading2">{t(getAction(row))}</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="supporting2">{t("Object")}: </Typography>
                            </td>
                            <td>
                                <div className="audit-logs-accordion-data-value">
                                    <Typography variant="leading2">{t(getObject(row))}</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="supporting2">{t("Object ID")}: </Typography>
                            </td>
                            <td>
                                <div className="audit-logs-accordion-data-value">
                                    <Typography variant="leading2">{row.object_id}</Typography>
                                    {
                                        row.object_id &&
                                    <CopyButton
                                        value={row.object_id}
                                        onCopy={() => setUserMessage({
                                            message: t("Object ID copied!"),
                                            code: SUCCESS_CODE,
                                            type: INFO_ALERT,
                                            duration: MIN_ALERT_DURATION
                                        })}
                                    />
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="supporting2">{t("Object Owner ID")}: </Typography>
                            </td>
                            <td>
                                <div className="audit-logs-accordion-data-value">
                                    <Typography variant="leading2">{row.object_owner_id || "-"}</Typography>{row.object_owner_id &&
                                <CopyButton
                                    value={row.object_owner_id}
                                    onCopy={() => setUserMessage({
                                        message: t("Object Owner ID copied!"),
                                        code: SUCCESS_CODE,
                                        type: INFO_ALERT,
                                        duration: MIN_ALERT_DURATION
                                    })}
                                />}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="audit-logs-accordion-event-content">
                <TextSnippet
                    title={t("Details")}
                    content={JSON.stringify(row.event, null, 2)}
                    canCopy
                    onCopy={() => setUserMessage({
                        message: t("Details copied!"),
                        code: SUCCESS_CODE,
                        type: INFO_ALERT,
                        duration: MIN_ALERT_DURATION
                    })}
                />
            </div>
        </div>
    )
}

export default AuditLogsAccordionContent
