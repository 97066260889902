import {
    GET_BONUS_EARN_FAILURE,
    GET_BONUS_EARN_REQUEST,
    GET_BONUS_EARN_SUCCESS,
} from "../const"

const initialState = {
    one_time_bonuses: [],
    loading: false
}

const handlers = {
    [GET_BONUS_EARN_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_BONUS_EARN_SUCCESS]: (state, { payload: { bonuses } }) => ({
        ...state,
        one_time_bonuses: bonuses.one_time_bonuses,
        loading: false,
    }),
    [GET_BONUS_EARN_FAILURE]: (state) => ({ ...state, loading: false }),

    DEFAULT: (state) => state,
}

const BonusEarnReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default BonusEarnReducer