import React, {useEffect, useState} from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {OOO_CALENDAR_LINK, USEFUL_INFO} from "../../../../constants/onboarding"
import {useMediaQuery} from "react-responsive"
import {DESKTOP_WIDTH, INTEGRATION_TYPES} from "../../../../constants/other"
import Popup from "reactjs-popup"
import OnboardingPopup from "../../../../components/Greetings/Onboarding/OnboardingPopup"
import {useGetOOOCalendarLinkQuery} from "../../../../api/googleIntegration"
import {useTranslation} from "react-i18next"

const UsefulInfoCard = () => {
    const { t } = useTranslation("common")

    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})
    const [showUsefulInfoPopup, setShowUsefulInfoPopup] = useState(false)
    const [showUsefulInfo, setShowUsefulInfo] = useState(false)
    const [usefulInfoStep, setUsefulInfoStep] = useState(null)
    const {data: calendarLink} = useGetOOOCalendarLinkQuery({integration_type: INTEGRATION_TYPES.GOOGLE_OOO})
    const [filteredUsefulInfo, setFilteredUsefulInfo] = useState([])

    useEffect(() => {
        const updatedUsefulInfo = Object.entries(USEFUL_INFO).filter(([key, stepData]) => {
            return !((key === USEFUL_INFO.OOO_COMPANY_CALENDAR || stepData.link === OOO_CALENDAR_LINK) && !calendarLink?.url)
        })
        setFilteredUsefulInfo(updatedUsefulInfo)
    }, [calendarLink])

    const handleClick = (stepData) => {
        if (stepData.isLink) {
            if (stepData.link === OOO_CALENDAR_LINK){
                window.open(calendarLink.url, "_blank")
            } else {
                window.open(stepData.link, "_blank")
            }
        } else {
            setShowUsefulInfoPopup(true)
            setUsefulInfoStep(stepData.step)
        }
    }

    return (
        <>
            {isDesktop ?
                <div className="profile-right-useful-info">
                    <div onClick={() => setShowUsefulInfo(!showUsefulInfo)} className="profile-right-useful-info-title">
                        <span className="t-s6">{t("Useful info")}</span>
                        <KeyboardArrowDownIcon
                            className={`arrow-down-icon ${showUsefulInfo ? "expanded" : ""}`}
                            onClick={() => setShowUsefulInfo(!showUsefulInfo)}
                        />
                    </div>
                    {showUsefulInfo &&
                        <div className="profile-right-useful-info-body">
                            {filteredUsefulInfo.map(([key, stepData]) => (
                                <span
                                    onClick={() => handleClick(stepData)}
                                    key={key}
                                    className="t-b1"
                                >
                                    {stepData.title}
                                </span>
                            ))}
                        </div>
                    }
                </div> : <></>
            }

            <Popup open={showUsefulInfoPopup && isDesktop} onClose={() => setShowUsefulInfoPopup(false)} modal>
                {close => (
                    <OnboardingPopup
                        close={close}
                        step={usefulInfoStep}
                    />
                )}
            </Popup>
        </>
    )
}

export default UsefulInfoCard