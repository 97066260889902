import "./index.scss"

import React, {useEffect, useState} from "react"

import SearchForm from "../SearchForm/SearchForm"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import DeleteIcon from "@mui/icons-material/Cancel"
import AddIcon from "@mui/icons-material/AddCircleRounded"


const DynamicBoxList = ({
    className,
    mainLabel,
    items,
    defaultValues,
    loading,
    onChange,
    searchFunc,
    getContent,
    getAdditionalInfo,
}) => {
    const [searchKey, setSearchKey] = useState("")
    const [selectedIds, setSelectedIds] = useState(defaultValues || [])
    const [currentItems, setCurrentItems] = useState([])

    const updateSelectedItems = () => {
        const selectedItems = items.filter(item => selectedIds.includes(item.id))
            .map(x => ({...x, selected: true}))
        const notSelectedItems = items.filter(item => !selectedIds.includes(item.id))
            .map(x => ({...x, selected: false}))

        const result = selectedItems.concat(notSelectedItems)

        setCurrentItems(searchFunc
            ? result.filter(item => searchFunc(item, searchKey))
            : result
        )
    }

    useEffect(() => {
        updateSelectedItems()
    }, [searchKey, selectedIds.length, items.length])

    useEffect(() => {
        onChange && onChange(selectedIds)
    }, [selectedIds.length])

    return <>
        {mainLabel &&
            <span className="dynamic-box-list-label t-s3">{mainLabel}</span>
        }
        <div className={`dynamic-box-list${!searchFunc ? "-ext" : ""} ${className ?? ""}`}>
            {loading ? <div className="dynamic-box-list-progress">
                <StyledCircularProgress/>
            </div> : <>
                {searchFunc &&
                    <div className="dynamic-box-list-search">
                        <SearchForm onChange={setSearchKey} />
                    </div>
                }
                <div className="dynamic-box-list-table">
                    {currentItems && currentItems.map((item, i) => (
                        <div
                            key={`dynamic_box_list_item_${i}`}
                            className="dynamic-box-list-table-row"
                        >
                            {getContent(item).length <= 35 ?
                                <span className={`${!item.selected ? "t-s4" : "t-s3"} `}>
                                    {getContent(item)}
                                </span> :
                                <span
                                    title={getContent(item)}
                                    className={`${!item.selected ? "t-s4" : "t-s3"} `}
                                >
                                    {getContent(item).slice(0, 35)}...
                                </span>
                            }

                            {getAdditionalInfo && getAdditionalInfo(item) &&
                                <div className="dynamic-box-list-table-row-additional t-b4">
                                    {getAdditionalInfo(item)}
                                </div>
                            }

                            {item.selected ?
                                <DeleteIcon
                                    onClick={() => {
                                        setSelectedIds(arr => arr.filter(id => id !== item.id))
                                    }}
                                    className="delete-icon"
                                />
                                :
                                <AddIcon
                                    onClick={() => {
                                        setSelectedIds(arr => !arr.includes(item.id) && [...arr, item.id])
                                    }}
                                    className="add-icon"
                                />
                            }
                        </div>
                    ))
                    }
                </div>
            </>
            }
        </div>
    </>
}

export default DynamicBoxList
