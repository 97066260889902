import "./index.scss"

import React, {createRef, useEffect} from "react"
import {useMediaQuery} from "react-responsive"

import {DESKTOP_WIDTH} from "../../constants/other"


const TextInput = ({text_label = false, ...props}) => {
    const inputRef = createRef()
    const isDesktop = useMediaQuery({maxWidth: DESKTOP_WIDTH})

    const handlePhoneNumberInput = (e) => {
        const groups = e.target.value.replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,4})/)

        if (!groups[1]) {
            e.target.value = "+"
        } else if (!groups[2]) {
            e.target.value = `+${groups[1]}`
        } else {
            e.target.value = `+${groups[1]} (${groups[2]}`
                + (groups[3] ? `) ${groups[3]}` : "")
                + (groups[4] ? ` ${groups[4]}` : "")
        }
    }

    useEffect(() => {
        const inputElement = inputRef.current

        if (isDesktop) {
            inputElement.blur()
        }

        const handleWheel = (e) => {
            if (document.activeElement === inputElement) {
                e.preventDefault()
            }
        }

        inputElement.addEventListener("wheel", handleWheel)

        return () => {
            inputElement.removeEventListener("wheel", handleWheel)
        }
    }, [])

    useEffect(() => {

    }, [])

    return (
        <div className="toolkit-text-input-wrapper">
            {text_label && <label className="toolkit-text-input-label t-s3">{text_label}</label>}
            {props?.type === "tel"
                ?
                <input
                    name="phone_number"
                    className="toolkit-text-input t-s4"
                    ref={inputRef}
                    placeholder="+000 (00) 00 00 000"
                    onInput={handlePhoneNumberInput}
                    {...props}
                />
                :
                <input
                    className="toolkit-text-input t-s4"
                    ref={inputRef}
                    {...props}
                />
            }
        </div>
    )
}

export default TextInput
