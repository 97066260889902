import "./Loading.scss"

import React, { useContext } from "react"
import { ThemeContext } from "../../styles/theme-context"


export const Loading = () => {
    const theme = useContext(ThemeContext)
    return (
        <div className="loader">
            <img src={theme.loadingLogo}
                className={theme.loaderClassName} id="newsoft-logo"
                alt="newsoft logo"/>
        </div>
    )
}
