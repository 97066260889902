import "./index.scss"

import React, {useEffect, useRef, useState} from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import {useSelector} from "react-redux"

import {PERMISSIONS} from "../../constants/permissions"
import {OOO_CALENDAR_LINK} from "../../constants/onboarding"
import {useGetOOOCalendarLinkQuery} from "../../api/googleIntegration"
import {INTEGRATION_TYPES} from "../../constants/other"


const ExtendedCheckbox = ({
    key,
    label,
    checked,
    onChange,
    description,
    openInNew,
    setShowWelcomePopup,
    locked,
    ...props
}) => {
    const [showDescription, setShowDescription] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const descriptionDivRef = useRef(null)
    const {data: calendarLink} = useGetOOOCalendarLinkQuery({integration_type: INTEGRATION_TYPES.GOOGLE_OOO})

    const handleToggleDescription = (e) => {
        e.preventDefault()
        if (!locked && description) {
            setShowDescription(!showDescription)
        }
        if (openInNew) {
            setShowWelcomePopup(true)
        }
    }

    const handleChange = (e) => {
        if (onChange && !locked && hasPermission(PERMISSIONS.WRITE_OWN_ONBOARDING)) {
            onChange(e)
        }
    }

    useEffect(() => {
        if (descriptionDivRef.current) {
            if (showDescription) {
                const animation = descriptionDivRef.current.animate(
                    [
                        { maxHeight: "0px", opacity: 1, overflow: "hidden" },
                        { maxHeight: "500px", opacity: 1, overflow: "hidden" }
                    ],
                    {
                        duration: 2000,
                        fill: "forwards",
                        easing: "ease-out"
                    }
                )
                animation.play()
            }
        }
    }, [showDescription])

    return (
        <div>
            <div className={`checkbox-container t-b1 ${locked ? "locked" : ""}`}>
                <label className="checkbox-container-label">
                    <input
                        key={key}
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                        disabled={locked}
                        {...props}
                    />
                    <span className={`checkbox ${locked ? "checkbox-locked" : ""}`}/>
                </label>
                <span className={`${showDescription ? "label-expanded" : ""}`} onClick={handleToggleDescription}>{label}</span>
            </div>
            {openInNew && (
                <OpenInNewIcon onClick={() => setShowWelcomePopup(true)} className="arrow-down-icon" />
            )}
            {description && !locked && (
                <KeyboardArrowDownIcon
                    className={`arrow-down-icon ${showDescription ? "expanded" : ""}`}
                    onClick={handleToggleDescription}
                />
            )}
            {showDescription && description && (
                <div ref={descriptionDivRef} className={`description-div t-b4 ${showDescription ? "description-div-expanded" : ""}`}>
                    {description.map((segment, index) => {
                        switch (segment.type) {
                        case "paragraph":
                            return <p className="description-div-paragraph" key={index}>{segment.content}</p>
                        case "list-title":
                            return <p className="description-div-list-title" key={index}>{segment.content}</p>
                        case "list":
                            return (
                                <ul key={index}>
                                    {segment.content.map((item, listIndex) => {
                                        return (
                                            <li key={listIndex} className={`description-div-list-element ${item.href ? "description-div-link" : ""}`}>
                                                {item.href ? (
                                                    <a className="description-div-link" href={item.href} target="_blank" rel="noopener noreferrer">
                                                        {item.content}
                                                    </a>
                                                ) : (
                                                    item.content
                                                )}
                                            </li>
                                        )
                                    })}
                                </ul>
                            )
                        case "link":
                            return (
                                <a key={index} href={segment.href === OOO_CALENDAR_LINK ? calendarLink?.url : segment.href} className="description-div-link">
                                    {segment.content}
                                </a>
                            )
                        default:
                            return null
                        }
                    })}
                </div>
            )}
        </div>
    )
}

export default ExtendedCheckbox
