import {useGetSpentCoinsQuery} from "../api/balance"


const useSpentCoins = ({ userId, skip }) => {
    const {data, isLoading} = useGetSpentCoinsQuery(userId, { skip })

    return {
        spent: data?.spent,
        isLoading,
    }
}

export default useSpentCoins