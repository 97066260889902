import React from "react"

import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import DefaultProductImage from "../../../assets/images/user_photo.png"
import {DD_MM_YYYY, PURCHASE_REQUEST_STATUS} from "../../../constants/other"
import Newcoin from "../../../toolkits/Newcoin"
import {formatDate} from "../../../utils/datetime.utils"
import Popup from "reactjs-popup"
import InfoPopup from "../../../components/InfoPopup"


const PersonalPurchaseHistoryTable = ({
    purchases,
}) => {
    const renderPurchaseStatus = (purchase) => {
        if (purchase.status === PURCHASE_REQUEST_STATUS.APPROVED) {
            return <span className="personal-purchases-content-table-green-span t-h3">APPROVED</span>
        }
        if (purchase.status === PURCHASE_REQUEST_STATUS.DECLINED) {
            return <span className="personal-purchases-content-table-red-span t-h3">DECLINED</span>
        }
        return <span className="t-h3">PENDING</span>
    }


    const purchasesList = purchases.map((purchase, i) => {
        const mainImage = purchase?.product_variant.product.images.length > 0 && purchase.product_variant.product.images.find((image) => image.is_main === true)

        return (
            <tr key={`${i} ${purchase.id}`}
                className="custom-scrollable-table-row personal-purchases-content-table-row">
                <td className="personal-purchases-content-table-first-column">
                    <img src={mainImage?.image_url || DefaultProductImage} alt="Product Image"/>
                    <span className="personal-purchases-content-table-first-column-product t-s1">{purchase.product_variant.product.name}</span>
                </td>
                <td className="personal-purchases-content-table-description">
                    <span className="t-b2">{purchase.product_variant.formatted_options.replace(/^\(|\)$/g, "")}</span>
                </td>
                <td className="personal-purchases-content-table-date">
                    <span className="t-b2">{formatDate(purchase.created_at, DD_MM_YYYY)}</span>
                </td>
                <td className="personal-purchases-content-table-amount">
                    <span className="t-b2">{purchase.quantity}</span>
                </td>
                <td className="personal-purchases-content-table-price">
                    <span className="t-b2">{purchase.total_price} <Newcoin type={"default"}/></span>
                </td>
                <td className="personal-purchases-content-table-reason">
                    <span className="t-b2">
                        <Popup trigger={<span className="cell-limited-text clickable"> {purchase.reason} </span>} modal>
                            {close => (
                                <InfoPopup
                                    title="Purchase Request Reason"
                                    value={purchase.reason}
                                    close={close}
                                />
                            )}
                        </Popup>
                    </span>
                </td>
                <td className="personal-purchases-content-table-last-column">
                    {renderPurchaseStatus(purchase)}
                </td>
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="personal-purchases-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">PURCHASE</span></th>
                        <th className="personal-purchases-content-table-description"><span className="custom-scrollable-table-head-span">DESCRIPTION</span></th>
                        <th className="personal-purchases-content-table-date"><span className="custom-scrollable-table-head-span">DATE</span></th>
                        <th className="personal-purchases-content-table-amount"><span className="custom-scrollable-table-head-span">AMOUNT</span></th>
                        <th className="personal-purchases-content-table-price"><span className="custom-scrollable-table-head-span">TOTAL PRICE</span></th>
                        <th className="personal-purchases-content-table-reason"><span className="custom-scrollable-table-head-span">REASON</span></th>
                        <th className="personal-purchases-content-table-last-column"><span className="custom-scrollable-table-head-span">STATUS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {purchasesList.length > 0 ? purchasesList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default PersonalPurchaseHistoryTable
