import React, {useState} from "react"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import TextInput from "../../../toolkits/TextInput/TextInput"
import {
    SUCCESS_CODE,
    ERROR_CODE,
} from "../../../constants/other"
import StaticBoxList from "../../../toolkits/StaticBoxList"
import UserImage from "../../../assets/images/user_photo.png"
import IntegerInput from "../../../components/Inputs/IntegerInput"
import {useCreateCoinsTransactionMutation} from "../../../api/coinsTransactions"


const GiveTakeNewcoinsPopup = ({userId, give, refreshTable, setUserMessage, selectedUsers, close}) => {
    const [createCoinsTransaction, { isLoading }] = useCreateCoinsTransactionMutation()
    const [amount, setAmount] = useState()
    const [reason, setReason] = useState(null)

    const isDisabledSubmit = () => {
        return !reason || !amount || amount < 0
    }

    const handleSubmit = () => {
        const userIds = selectedUsers?.length > 0 ? selectedUsers.map(user => user.id) : [userId]

        const dataNewcoinsTransaction = {
            users: userIds,
            amount: give ? amount : -amount,
            reason: reason,
        }

        createCoinsTransaction(dataNewcoinsTransaction)
            .unwrap()
            .then(() => {
                refreshTable()
                close()
                setUserMessage({message: "Newcoins Transaction was successfully created!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })

    }

    return (
        <>
            <CustomPopup
                text_title={give ? "Give Newcoins" : "Take Newcoins"}
                className="update-salary-popup"
            >
                <span className="newcoins-balance-popup-give-sign">{give ? "+" : "-"}</span>
                <IntegerInput
                    label="Amount"
                    placeholder="0"
                    onChange={setAmount}
                    positiveOnly
                    style={{"padding-left": "30px"}}
                />
                <TextInput
                    text_label="Reason"
                    placeholder="Text"
                    defaultValue={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
                {selectedUsers?.length > 0 && <StaticBoxList
                    mainLabel="Employees"
                    items={selectedUsers}
                    getContent={user => (
                        <div className="newcoins-balance-popup-give">
                            <img className="newcoins-balance-popup-give-image" src={user.image_url || UserImage} alt="Profile image"/>
                            <span className="newcoins-balance-popup-give-username t-s3">{`${user.first_name} ${user.last_name}`}</span>
                        </div>
                    )}
                />}
                <div className="popup-bottom">
                    <div className="popup-bottom-button-container-with-link">
                        <SmallButton disabled={isLoading} btnType="secondary" onClick={() => close()}>CANCEL</SmallButton>
                        <SmallButton loading={isLoading} type="submit" onClick={handleSubmit} disabled={isDisabledSubmit()}>{give ? "GIVE" : "TAKE"}</SmallButton>
                    </div>
                </div>
            </CustomPopup>
        </>
    )

}

export default GiveTakeNewcoinsPopup
