import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const employeePositionsApi = createApi({
    reducerPath: "employeePositionsApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["EmployeePositions"],
    endpoints: (builder) => ({
        getEmployeePositions: builder.query({
            query: (params) => ({
                path: "user-positions/",
                params,
            }),
            providesTags: ["EmployeePositions"],
        }),
        createEmployeePosition: builder.mutation({
            query: (data) => ({path: "user-positions/", method: "POST", data}),
            invalidatesTags: ["EmployeePositions"]
        }),
        updateEmployeePosition: builder.mutation({
            query: ({id, data}) => ({path: `user-positions/${id}`, method: "PUT", data}),
            invalidatesTags: ["EmployeePositions"]
        }),
        deleteEmployeePosition: builder.mutation({
            query: (id) => ({path: `user-positions/${id}`, method: "DELETE"}),
            invalidatesTags: ["EmployeePositions"]
        }),

    })
})

export const {
    useGetEmployeePositionsQuery,
    useGetEmployeePositionsMutation,
    useCreateEmployeePositionMutation,
    useUpdateEmployeePositionMutation,
    useDeleteEmployeePositionMutation,
} = employeePositionsApi
