import "../index.scss"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import AlertMessage from "../../../toolkits/AlertMessage/AlertMessage"
import {
    AUTO_GIVEN_NEWCOINS,
    ERROR_CODE,
    MOBILE_WIDTH,
    PAGE_SIZE,
    SUCCESS_CODE,
    USER_TO_GLOBAL_NEWCOINS_SETTINGS
} from "../../../constants/other"
import InfiniteScroll from "react-infinite-scroll-component"
import CircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import Protected from "../../../components/Protected/Protected"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {PERMISSIONS} from "../../../constants/permissions"
import UserImage from "../../../assets/images/user_photo.png"
import Newcoin from "../../../toolkits/Newcoin"
import NewcoinsHistoryTable from "./NewcoinsHistoryTable"
import {
    receiveNextPageUserNewcoinsTransactions,
    receiveUserNewcoinsTransactions
} from "../actions/newcoinsHistoryAction"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"
import {updateUserNewcoinsSettings} from "../api"
import Popup from "reactjs-popup"
import GiveTakeNewcoinsPopup from "../../NewcoinsBalances/components/GiveTakeNewcoinsPopup"
import {useMediaQuery} from "react-responsive"
import {receiveCurrentUser} from "../../Profile/actions/userActions"
import {isTrue} from "../../../utils/other.utils"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import useCoinsBalance from "../../../hooks/useCoinsBalance"


const NewcoinsHistory = () => {
    const {location: {state}} = useCustomHistory()
    const {
        tableLoading,
        newcoinsTransactions,
        total,
    } = useSelector((state) => state.newcoinsTransactions)
    const isMobile = useMediaQuery({maxWidth:MOBILE_WIDTH})
    const {myInfo} = useSelector((state) => state.profile)
    const dispatch = useDispatch()
    const [userMessage, setUserMessage] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const currentUser = useSelector((state) => state.profile.currentUser)
    const userId = state?.user.id || myInfo.id
    const [coinsSettings, setCoinsSettings] = useState(userId === myInfo.id ? myInfo.coins_settings : state?.user.coins_settings)
    const username = state ?  `${state.user.first_name} ${state.user.last_name}` : `${myInfo.first_name} ${myInfo.last_name}`
    const imageUrl = state?.user?.image_url || (!state?.user && myInfo.image_url)
    const [giveOrTakeNewcoinsPopup, setGiveOrTakeNewcoinsPopup] = useState({open: false, give: true})
    const {balance}= useCoinsBalance({userId})
    const {
        autoGiftBirthday,
        autoGiftAnniversary,
        autoGiftLoggedHours,
    } = useSelector((state) => state.settings)
    const globalNewcoinsSettings = [autoGiftBirthday, autoGiftAnniversary, autoGiftLoggedHours]

    const getParams = (page) => ({
        page: page || currentPage,
        size: PAGE_SIZE,
        user_id: userId,
    })

    const checkGlobalNewcoinsSettings = (settingName) => {
        return isTrue(globalNewcoinsSettings.find(set => set.name && set.name === USER_TO_GLOBAL_NEWCOINS_SETTINGS[settingName])?.value)
    }

    const getUserNewcoinsTransactions = (page) => {
        const params = getParams(page)

        dispatch(receiveUserNewcoinsTransactions(params))
        setCurrentPage((page || currentPage) + 1)
    }

    const getNextUserNewcoinsTransactions = () => {
        const params = getParams()

        dispatch(receiveNextPageUserNewcoinsTransactions(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        dispatch(receiveCurrentUser(userId))
        getUserNewcoinsTransactions(1)
    }, [])

    useEffect(() => {
        if (currentUser){
            setCoinsSettings(currentUser.coins_settings)
        }
    }, [currentUser])

    const AutoGivenNewcoinsCard = ({ amount, title, reason, settingName }) => {
        const [active, setActive] = useState(settingName in coinsSettings ? coinsSettings[settingName] : checkGlobalNewcoinsSettings(settingName))
        const handleUpdateUserNewcoinsSettings = () => {
            const data = {
                [settingName]: !active
            }
            updateUserNewcoinsSettings(userId, data).then(() => {
                setActive(!active)
                coinsSettings[settingName] = !active
                setUserMessage({message: "User coins settings were successfully updated!", code: SUCCESS_CODE})
            }).catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
        }

        return (
            <div className="autogiven-newcoins-card">
                <div className="t-b4">
                    Auto-given Newcoins
                </div>
                {active ? <ToggleOnIcon onClick={handleUpdateUserNewcoinsSettings} className="autogiven-newcoins-card-slider"/> : <ToggleOffIcon onClick={handleUpdateUserNewcoinsSettings} className="autogiven-newcoins-card-slider-inactive"/>}
                <span className="autogiven-newcoins-card-body t-s6">{title}</span>
                <div className="autogiven-newcoins-card-body">
                    <div className="autogiven-newcoins-card-amount">
                        <span className="autogiven-newcoins-card-amount-number t-h1">+{amount}</span>
                        <Newcoin type="active" />
                    </div>
                    <div className="autogiven-newcoins-card-reason">
                        <div className="t-s6">Reason:</div>
                        <div className="t-s4">{reason}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div>
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="newcoins-history-header">
                    <div className="salary-history-header-user">
                        <img alt="User image" src={imageUrl || UserImage}/>
                        <span className="t-s1">{username}</span>
                    </div>
                    <div className="newcoins-history-header-button">
                        <Protected permissions={[PERMISSIONS.WRITE_NEWCOINS]}>
                            {!isMobile ?
                                <>
                                    <div className="newcoins-history-header-balance t-s1">
                                        <span>BALANCE:</span>
                                        <SensitiveInfo className="sensitive-info">
                                            {balance} <Newcoin />
                                        </SensitiveInfo>
                                    </div>
                                    <SmallButton onClick={() => setGiveOrTakeNewcoinsPopup({open: true, give: false})} btnType={"secondary"}>TAKE NEWCOINS</SmallButton>
                                </>
                                :
                                <></>}
                            <SmallButton onClick={() => setGiveOrTakeNewcoinsPopup({open: true, give: true})}>GIVE NEWCOINS</SmallButton>
                        </Protected>
                    </div>
                </div>
                <Protected permissions={[PERMISSIONS.READ_NEWCOINS]}>
                    <div className="newcoins-history-content-cards">
                        {AUTO_GIVEN_NEWCOINS.map((item, index) => (
                            <AutoGivenNewcoinsCard key={index} amount={item.amount} title={item.title} reason={item.reason} settingName={item.setting_name}/>
                        ))}
                    </div>
                    <span className="newcoins-history-content-table-label t-s1">Newcoins History</span>
                    {tableLoading ? <CircularProgress key="circular-progress"/> :
                        <div>
                            <InfiniteScroll
                                pageStart={0}
                                next={getNextUserNewcoinsTransactions}
                                hasMore={!tableLoading && newcoinsTransactions.length < total}
                                loader={<CircularProgress key="circular-progress"/>}
                                dataLength={newcoinsTransactions.length}
                                useWindow={false}
                            >
                                {tableLoading ? <StyledCircularProgress/> :
                                    <div className="salary-history-content">
                                        <NewcoinsHistoryTable
                                            newcoinsTransactions={newcoinsTransactions}
                                            setUserMessage={setUserMessage}
                                            refreshTable={() => getUserNewcoinsTransactions(1)}
                                        />
                                    </div>
                                }
                            </InfiniteScroll>
                        </div>}
                </Protected>
            </div>
            <Popup open={giveOrTakeNewcoinsPopup.open} onClose={(prevState) => setGiveOrTakeNewcoinsPopup({...prevState, open: false})}>
                {close => (
                    <GiveTakeNewcoinsPopup
                        give={giveOrTakeNewcoinsPopup.give}
                        userId={userId}
                        refreshTable={() => {getUserNewcoinsTransactions(1)}}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>

    )
}

export default NewcoinsHistory
