import React, {useState} from "react"

import {updateUserById} from "../../../Employees/api"

import EmployeePositionsDDList from "../../../EmployeePositions/components/EmployeePositionsDDList"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../../toolkits/TextInput/TextInput"

import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import DDList from "../../../../toolkits/DDList/DDList"
import Checkbox from "../../../../toolkits/Checkbox/Checkbox"

import {EMPLOYMENT_TYPES, ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"
import DeprecatedSingleDatePicker from "../../../../toolkits/Calendar/DeprecatedSingleDatePicker"


const EditEmployeePopup = ({employee, setUserMessage, receiveUserInfo, close}) => {
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [employmentType, setEmploymentType] = useState(null)
    const [position, setPosition] = useState(null)
    const [birthdate, setBirthdate] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState(null)
    const [hidden, setHidden] = useState(employee.hidden)

    const handleSubmit = () => {   
        const data = {
            first_name: firstName,
            last_name: lastName,
            employment_type: employmentType,
            job_position_id: position?.value,
            birthdate: birthdate?.split(".").reverse().join("-"),
            phone_number: phone,
            email: email,
            hidden: hidden,
        }
        updateUserById(employee.id, data)
            .then(() => {
                close()
                receiveUserInfo()
                setUserMessage({
                    message: "The user was successfully changed!",
                    code: SUCCESS_CODE
                })
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const isDisabledSubmit = () => {
        return [
            firstName ? firstName !== employee.first_name : false,
            lastName ? lastName !== employee.last_name : false,
            employmentType ? employmentType !== employee.employment_type : false,
            position ? position?.value !== employee.job_position?.id : false,
            phone ? phone !== employee.phone_number : false,
            email ? email !== employee.email : false,
            birthdate ? birthdate?.split(".").reverse().join("-") !== employee.birthdate : false,
            hidden !== employee.hidden,
        ].every((x) => !x)
    }

    return (
        <CustomPopup
            className="edit-employee-popup"
            text_title="Edit Personal Information"
        >
            <div className="edit-employee-popup-content">
                <TextInput
                    text_label="First name"
                    type="text"
                    defaultValue={employee.first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextInput
                    text_label="Last name"
                    type="text"
                    defaultValue={employee.last_name}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <DDList
                    text_label="Employment Type"
                    placeholder=""
                    defaultValue={({value: employee.employment_type, label: employee.employment_type})}
                    listOptions={EMPLOYMENT_TYPES.map((tp) => ({value: tp, label: tp}))}
                    onChange={tp => setEmploymentType(tp.value)}
                />
                <EmployeePositionsDDList
                    value={position
                        ? {value: position.id, label: position.label}
                        : {value: employee.job_position?.id, label: employee.job_position?.name}}
                    setPosition={setPosition}
                    setUserMessage={setUserMessage}
                />
                <DeprecatedSingleDatePicker
                    label="Birth date"
                    className="single-datepicker-field"
                    defaultValue={employee.birthdate}
                    setNewDate={(value) => setBirthdate(value)}
                /> 
                <TextInput
                    text_label="Phone"
                    placeholder="+38.."
                    type="tel"
                    defaultValue={employee.phone_number}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <TextInput
                    text_label="Email"
                    type="email"
                    defaultValue={employee.email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Checkbox
                    label="Hidden"
                    checked={hidden}
                    onChange={e => {setHidden(e.target.checked) }}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} disabled={isDisabledSubmit()}> SAVE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default EditEmployeePopup
