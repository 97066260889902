import React, {useState} from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import DDList from "../../../toolkits/DDList/DDList"
import TextInput from "../../../toolkits/TextInput/TextInput"

import {
    SUCCESS_CODE,
    ERROR_CODE,
} from "../../../constants/other"
import {useCreateBonusMutation, useUpdateBonusMutation} from "../../../api/bonuses"
import DeprecatedSingleDatePicker from "../../../toolkits/Calendar/DeprecatedSingleDatePicker"


const CreateUpdateBonusPopup = ({userId, currentBonus, refreshTable, setUserMessage, close}) => {
    const [createBonus, {isLoading: createLoading}] = useCreateBonusMutation()
    const [updateBonus, {isLoading: updateLoading}] = useUpdateBonusMutation()
    const isLoading = createLoading || updateLoading

    const currencyOptions = [
        {value: 1, label: "USD"},
        {value: 2, label: "UAH"},
    ]

    const [amount, setAmount] = useState(currentBonus?.amount ? currentBonus.amount : null)
    const [reason, setReason] = useState(currentBonus?.reason ? currentBonus.reason : null)
    const [startDate, setStartDate] = useState(currentBonus?.start_date ? currentBonus.start_date : currentBonus?.as_of_date)
    const [currency, setCurrency] = useState({
        value: currentBonus?.currency?.id || null,
        label: currentBonus?.currency?.iso_code || null
    })
    
    const isDisabledSubmit = () => {
        const result = !reason || !startDate || !currency.value
        return result || !amount
    }


    const handleSubmit = () => {
        const formattedStartDate = startDate.split(".").reverse().join("-")

        const dataBonus = {
            user_id: userId,
            amount: amount,
            reason: reason,
            as_of_date: formattedStartDate,
            currency_id: currency.value,
        }

        if(!currentBonus){
            createBonus(dataBonus)
                .unwrap()
                .then(() => {
                    refreshTable()
                    close(false)
                    setUserMessage({message: "Bonus was successfully created!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        } else {
            updateBonus({id: currentBonus.id, data: dataBonus})
                .unwrap()
                .then(() => {
                    refreshTable()
                    close(false)
                    setUserMessage({message: "Bonus was successfully changed!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <>
            <CustomPopup
                text_title={!currentBonus ? "Create Bonus" : "Edit Bonus"}
                className="update-salary-popup"
            >
                <TextInput
                    text_label="Amount"
                    placeholder=""
                    type="number"
                    defaultValue={amount}
                    min={0}
                    onChange={(e) => setAmount(e.target.value)}
                />
                <DDList
                    text_label="Currency"
                    placeholder=""
                    defaultValue={currency}
                    listOptions={currencyOptions.map((code) => ({value: code.value, label: code.label}))}
                    onChange={setCurrency}
                    isDisabled={!!currentBonus}
                />
                <TextInput
                    text_label="Reason"
                    placeholder="Text"
                    defaultValue={reason}
                    onChange={(e) => setReason(e.target.value)}
                />

                <DeprecatedSingleDatePicker
                    label="Date"
                    className="single-datepicker-field"
                    defaultValue={startDate ? startDate : null}
                    setNewDate={setStartDate}
                />

                <div className="popup-bottom">
                    <div className="popup-bottom-button-container-with-link">
                        <SmallButton disabled={isLoading} btnType="secondary" onClick={() => close(false)}>CANCEL</SmallButton>
                        <SmallButton loading={isLoading} type="submit" onClick={handleSubmit} disabled={isDisabledSubmit()}>{currentBonus ? "SAVE" : "CREATE"}</SmallButton>
                    </div>
                </div>
            </CustomPopup>
        </>
    )

}

export default CreateUpdateBonusPopup
