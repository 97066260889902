import React from "react"
import {useMediaQuery} from "react-responsive"
import {MOBILE_WIDTH} from "../../constants/other"

export const EmptyResult = () => {
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})

    return (
        <div className="empty-result">
            <p className={isMobile ? "t-h2" : "t-h1"}>Empty Result</p>
        </div>
    )
}
