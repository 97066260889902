import {
    GET_PRODUCT_CATEGORIES_FAILURE,
    GET_PRODUCT_CATEGORIES_REQUEST,
    GET_PRODUCT_CATEGORIES_SUCCESS,
    GET_PRODUCT_LABELS_FAILURE,
    GET_PRODUCT_LABELS_REQUEST,
    GET_PRODUCT_LABELS_SUCCESS,
} from "../const"
import {getProductCategories, getProductLabels} from "../api"

const requestCategories = () => ({type: GET_PRODUCT_CATEGORIES_REQUEST})
const successCategories = (categories) => ({type: GET_PRODUCT_CATEGORIES_SUCCESS, payload: {categories}})
const failureCategories = () => ({type: GET_PRODUCT_CATEGORIES_FAILURE})

const requestLabels = () => ({type: GET_PRODUCT_LABELS_REQUEST})
const successLabels = (labels) => ({type: GET_PRODUCT_LABELS_SUCCESS, payload: {labels}})
const failureLabels = () => ({type: GET_PRODUCT_LABELS_FAILURE})

export const receiveProductCategories = () => async (dispatch) => {
    dispatch(requestCategories())
    try {
        const {data: categories} = await getProductCategories()
        dispatch(successCategories(categories))
    } catch (e) {
        console.log(e)
        dispatch(failureCategories())
    }
}

export const receiveProductLabels = () => async (dispatch) => {
    dispatch(requestLabels())
    try {
        const {data: labels} = await getProductLabels()
        dispatch(successLabels(labels))
    } catch (e) {
        console.log(e)
        dispatch(failureLabels())
    }
}

