import {timeRelatedApi} from "./base"


export const timeOffBalancesApi = timeRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        getTimeOffBalances: builder.query({
            query: (params) => ({
                path: "time-off-balance/",
                params,
            }),
            providesTags: ["TimeOffBalances"],
        }),
        getTimeOffBalancesUniqueYears: builder.query({
            query: (params) => ({
                path: "time-off-balance/years",
                params,
            }),
            providesTags: ["TimeOffBalancesUniqueYears"],
        }),
        getTimeOffsUsageInfo: builder.query({
            query: ({userId, params}) => ({
                path: `time-off-balance/time-offs-usage-info/${userId}`,
                params,
            }),
            providesTags: (result, error, {userId}) => [{type: "TimeOffsUsageInfo", id: userId}],
        }),
    }),
})

export const {
    useGetTimeOffBalancesQuery,
    useGetTimeOffBalancesUniqueYearsQuery,
    useGetTimeOffsUsageInfoQuery,
} = timeOffBalancesApi
