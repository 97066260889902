import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const performanceReviewsApi = createApi({
    reducerPath: "performanceReviewsApi",
    baseQuery,
    tagTypes: ["PerformanceReviews"],
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    endpoints: (builder) => ({
        getPerformanceReviews: builder.query({
            query: (params) => ({
                path: "performance-reviews/",
                params,
            }),
            providesTags: ["PerformanceReviews"],
        }),
        createPerformanceReview: builder.mutation({
            query: (data) => ({path: "performance-reviews/", method: "POST", data}),
            invalidatesTags: ["PerformanceReviews"]
        }),
        updatePerformanceReview: builder.mutation({
            query: ({id, data}) => ({path: `performance-reviews/${id}`, method: "PUT", data}),
            invalidatesTags: ["PerformanceReviews"]
        }),
        deletePerformanceReview: builder.mutation({
            query: (id) => ({path: `performance-reviews/${id}`, method: "DELETE"}),
            invalidatesTags: ["PerformanceReviews"]
        }),
    })
})

export const {
    useGetPerformanceReviewsQuery,
    useCreatePerformanceReviewMutation,
    useUpdatePerformanceReviewMutation,
    useDeletePerformanceReviewMutation,
} = performanceReviewsApi
