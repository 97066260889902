import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const auditLogsApi = createApi({
    reducerPath: "auditLogs",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    endpoints: (builder) => ({
        getAuditLogs: builder.query({
            query: (params) => ({
                path: "audit-logs/",
                params,
            }),
        }),
    })
})

export const {
    useGetAuditLogsQuery,
} = auditLogsApi
