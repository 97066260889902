import {Link, useLocation} from "react-router-dom"
import React from "react"
import {ProfileRoute} from "../../routes"


const CustomLink = ({ children, to, activeTab, ...props }) => {
    const location = useLocation()

    const linkState = {
        ...(typeof to === "object" && to.state ? to.state : {}),
        activeTab: activeTab || location.state?.activeTab || ProfileRoute.path,
    }

    const toWithoutState =
        typeof to === "object" ? { ...to, state: undefined } : to

    return (
        <Link to={toWithoutState} state={linkState} {...props}>
            {children}
        </Link>
    )
}

export default CustomLink
