import React from "react"
import "./index.scss"

const Counter = ({count, setCount}) => {
    const increaseCount = () => {
        setCount(prevCount => prevCount + 1)
    }

    const decreaseCount = () => {
        setCount(prevCount => (prevCount > 1 ? prevCount - 1 : 1))
    }

    return (
        <div className="counter">
            <button onClick={decreaseCount} className="counter-button">-</button>
            <div className="counter-display">{count}</div>
            <button onClick={increaseCount} className="counter-button">+</button>
        </div>
    )
}

export default Counter