import React from "react"
import Popup from "reactjs-popup"

import InfoPopup from "../../../components/InfoPopup"
import DeleteCouponPopup from "./DeleteCouponPopup"
import CreateUpdateCouponPopup from "./CreateUpdateCouponPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"

import Protected from "../../../components/Protected/Protected"
import Newcoin from "../../../toolkits/Newcoin"

import {PERMISSIONS} from "../../../constants/permissions"
import { COUPON_STATUSES, COUPON_TYPE } from "../../../constants/other"
import {useTranslation} from "react-i18next"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"


export const CouponsTable = ({coupons, setUserMessage, refreshTable, i, empty}) => {
    const { t } = useTranslation("common")

    const couponsList = coupons.map((c) => {
        const popupParams = {
            coupon: c,
            setUserMessage: setUserMessage,
            refreshTable: refreshTable,
        }
        const managerActions = (
            <>
                <Protected permissions={[PERMISSIONS.WRITE_COUPONS]}>
                    <Popup trigger={<span className="custom-scrollable-table-link"> {t("Edit")} </span>} modal>
                        {close => <CreateUpdateCouponPopup {...popupParams} close={close}/>}
                    </Popup>
                    <Popup trigger={<span className="custom-scrollable-table-link"> {t("Deactivate")} </span>} modal>
                        {close => <DeleteCouponPopup {...popupParams} close={close}/>}
                    </Popup>
                </Protected>
            </>
        )

        return (
            <tr key={`${i} ${c.id}`} className="custom-scrollable-table-row coupons-content-table-row">
                <td className="coupons-content-table-first-column">
                    <span className="t-s1">{c.code}</span>
                </td>
                <td className="coupons-content-table-amount">
                    <span>{c.coins_reward}</span>
                    <Newcoin type={"inactive"} /> 
                </td> 
                <td className="t-b2">
                    {t(COUPON_TYPE[c.type]?.label)}
                </td>
                <td className="t-b2" style={{color: COUPON_STATUSES[c.status]?.color}}>
                    {t(COUPON_STATUSES[c.status]?.label)}
                </td>
                <td>
                    <Popup trigger={
                        <span className="cell-limited-text">{c.reason}</span>
                    } modal>
                        {close => (
                            <InfoPopup
                                title={t("Reason")}
                                value={c.reason}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    {c.status== COUPON_STATUSES.ACTIVE.value && managerActions}
                </td>
            </tr>
        )
    })
    
    return (
        <CustomScrollableTable>
            <table className="coupons-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span">{t("Coupon Code")}</span></th>
                        <th><span className="custom-scrollable-table-head-span">{t("Amount")}</span></th>
                        <th><span className="custom-scrollable-table-head-span">{t("Type")}</span></th>
                        <th><span className="custom-scrollable-table-head-span">{t("Status")}</span></th>
                        <th><span className="custom-scrollable-table-head-span">{t("Reason")}</span></th>
                        <th><span className="custom-scrollable-table-head-span">{t("Actions")}</span></th>
                    </tr>
                </thead>

                <tbody>
                    {couponsList}
                    {empty && <EmptyResult />}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default CouponsTable
