import {salaryRelatedApi} from "./base"


export const overtimeApi = salaryRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        createOvertime: builder.mutation({
            query: ({id, data}) => ({path: `overtime/${id}`, method: "POST", data}), // here id is actually salary id. That's bad
            invalidatesTags: ["Overtimes"]
        }),
        updateOvertime: builder.mutation({
            query: ({id, data}) => ({path: `overtime/${id}`, method: "PUT", data}),
            invalidatesTags: ["Overtimes"]
        }),
        deleteOvertime: builder.mutation({
            query: (id) => ({path: `overtime/${id}`, method: "DELETE"}),
            invalidatesTags: ["Overtimes"]
        }),
    })
})

export const {
    useCreateOvertimeMutation,
    useUpdateOvertimeMutation,
    useDeleteOvertimeMutation,
} = overtimeApi
