import React from "react"
import {Alert} from "@material-ui/lab"
import {ALERT_DURATION, INFO_ALERT, SUCCESS_CODE} from "../../constants/other"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {Snackbar} from "@material-ui/core"

import "./index.scss"


const AlertMessage = ({code, message, setMessage, style, type, duration}) => {
    const successIcon = <CheckCircleOutlineIcon fontSize="inherit" />
    
    return (
        <div>      
            <Snackbar
                anchorOrigin={{ horizontal:"center", vertical:"top" }}
                open={!!message}
                autoHideDuration={duration || ALERT_DURATION}
                onClose={() => setMessage(null)}
            >
                <Alert
                    className={type === INFO_ALERT ? "info-alert-message" : "custom-alert-message"}
                    style={{
                        ...style
                    }}
                    variant="filled"
                    severity={code === SUCCESS_CODE ? "success" : "error"}
                    iconMapping={{
                        success: successIcon
                    }}
                >
                    <span className="t-s3">{message}</span>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default AlertMessage
