import React, {useEffect} from "react"
import ReactGA from "react-ga"
import { useLocation } from "react-router-dom"

import {CACHED_IMAGES} from "../../constants/other"


const AuthLayout = ({children}) => {
    const currentYear = new Date().getFullYear()
    const location = useLocation()

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    },[location.pathname])

    return (
        <div className="auth-wrapper">
            <img className="auth-wrapper-logo" src={CACHED_IMAGES.LogoNS} alt="Newsoft Inside Logo"/>
            <div className="auth-wrapper-content">
                {children}
            </div>
            <div className="auth-wrapper-version">
                <p>© 2018-{currentYear} Newsoft | All Rights Reserved</p>
            </div>
        </div>
    )
}

export default AuthLayout
