import "../index.scss"

import React, {useState} from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import SliderInput from "../../../toolkits/SliderInput"
import {AUTO_GIVEN_NEWCOINS, ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {updateSettingById} from "../../Settings/api"
import {receiveSettings} from "../../Settings/actions/settingsActions"
import {useDispatch, useSelector} from "react-redux"
import {isTrue} from "../../../utils/other.utils"


export const EditAutoGivenSettingsPopup = ({setUserMessage, close}) => {
    const dispatch = useDispatch()
    const {
        autoGiftBirthday,
        autoGiftAnniversary,
        autoGiftLoggedHours,
    } = useSelector((state) => state.settings)
    const [values, setValues] = useState({
        birthday: isTrue(autoGiftBirthday.value),
        anniversary:  isTrue(autoGiftAnniversary.value),
        logged_hours: isTrue(autoGiftLoggedHours.value),
    })

    const updateAutoGivenSetting = async ({ setting, value }) => {
        try {
            await updateSettingById({ setting_id: setting.id, value: value.toString() })
            return { success: true }
        } catch (error) {
            return { success: false, message: error.response.data.message }
        }
    }

    const onSave = () => {
        const settingsUpdates = [
            {
                setting: autoGiftBirthday,
                value: values.birthday,
            },
            {
                setting: autoGiftAnniversary,
                value: values.anniversary,
            },
            {
                setting: autoGiftLoggedHours,
                value: values.logged_hours,
            },
        ]

        Promise.all(settingsUpdates.map(settingUpdate =>
            updateAutoGivenSetting(settingUpdate)
        )).then(results => {
            const anyFailed = results.some(result => !result.success)
            if (!anyFailed) {
                setUserMessage({ message: "Auto-given settings updated successfully", code: SUCCESS_CODE })
                close()
            } else {
                const firstError = results.find(result => !result.success)
                setUserMessage({ message: firstError.message, code: ERROR_CODE })
            }
            dispatch(receiveSettings())
        }).catch(() => {
            setUserMessage({ message: "Failed to update settings due to an unexpected error", code: ERROR_CODE })
        })
    }

    return (
        <CustomPopup text_title={"Edit Auto-given Settings"}>
            <SliderInput
                text_label={"Employees birthday"}
                value={AUTO_GIVEN_NEWCOINS.find(item => item.setting_name === "birthday").amount}
                active={values.birthday}
                setActive={(value) => setValues({...values, birthday: value})}
            />
            <SliderInput
                text_label={"Employees anniversary"}
                value={AUTO_GIVEN_NEWCOINS.find(item => item.setting_name === "anniversary").amount}
                active={values.anniversary}
                setActive={(value) => setValues({...values, anniversary: value})}
            />
            <SliderInput
                text_label={"For the each working hour"}
                value={AUTO_GIVEN_NEWCOINS.find(item => item.setting_name === "logged_hours").amount}
                active={values.logged_hours}
                setActive={(value) => setValues({...values, logged_hours: value})}
            />

            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> Cancel </SmallButton>
                    <SmallButton type="submit" onClick={() => onSave(values)}> Save </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default EditAutoGivenSettingsPopup
