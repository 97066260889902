import React, {useMemo} from "react"

import moment from "moment"

import {formatDate} from "./utils/datetime.utils"

import {DAY_TYPE, DAYS, ERROR_CODE} from "./constants/other"
import {
    getLoggedClass,
} from "./utils/other.utils"

import {
    getTimeOffClass,
    getTimeOffColor,
    isApprovedOrRequestedTimeOff,
    isRequestedOrDeclinedTimeOff,
    mapTimeOffTypeToTitle,
} from "./utils/timeoff.utils"
import useCalendarData from "./hooks/useCalendarData"


function findAncestor (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el
}

export const useCalendar = ({ userId, startDate, endDate }) => {
    const calendarDataParams = useMemo(() => {
        if (startDate && endDate) {
            return {
                start_date: startDate,
                end_date: endDate,
            }
        }
    }, [startDate, endDate])

    const {timeOffs, loggedDays, inReviewDays} = useCalendarData({ userId, params: calendarDataParams, skip: !calendarDataParams })

    
    const normalizeDate = (date) => formatDate(date)

    const isPublicDayOff = (date) => {
        date = normalizeDate(date)
        if (timeOffs && timeOffs.has(date) && timeOffs.get(date).type === DAY_TYPE.PUBLIC_DAY_OFF) {
            return timeOffs.get(date)
        }
        return null
    }

    const isUserDayOff = (date) => {
        date = normalizeDate(date)
        return timeOffs.has(date) && timeOffs.get(date).type === DAY_TYPE.APPROVED_DAY_OFF
    }

    const isUnpaidDayOff = (date) => {
        date = normalizeDate(date)
        return timeOffs.has(date) && timeOffs.get(date).type === DAY_TYPE.APPROVED_UNPAID_DAY_OFF
    }

    const isIllness = (date) => {
        date = normalizeDate(date)
        return timeOffs.has(date) && timeOffs.get(date).type === DAY_TYPE.APPROVED_ILLNESS
    }

    const isTimeOff = (date, missDayOff) => {
        return (isPublicDayOff(date) || (!missDayOff && (isUserDayOff(date) || isUnpaidDayOff(date) || isIllness(date))) ||
            moment(date).weekday() === DAYS.SATURDAY || moment(date).weekday() === DAYS.SUNDAY)
    }

    const getApprovedTimeOff = (date) => {
        date = normalizeDate(date)
        if (isTimeOff(date)) {
            return timeOffs.get(date)
        }
        return null
    }

    const getTypeOfDay = (date, today) => {

        let currentDay = moment(date).format("YYYY-MM-DD")

        if (!!timeOffs && timeOffs.has(currentDay)) {
            currentDay = timeOffs.get(currentDay)

            switch (currentDay.type) {
            case DAY_TYPE.REQUESTED_DAY_OFF:
                return "REQUESTED DAY OFF"
            case DAY_TYPE.DECLINED_DAY_OFF:
                return "DECLINED DAY OFF"
            case DAY_TYPE.APPROVED_DAY_OFF:
                return "APPROVED DAY OFF"
            case DAY_TYPE.PUBLIC_DAY_OFF:
                return "HOLIDAY"
            case DAY_TYPE.REQUESTED_ILLNESS:
                return "REQUESTED ILLNESS"
            case DAY_TYPE.DECLINED_ILLNESS:
                return "DECLINED ILLNESS"
            case DAY_TYPE.APPROVED_ILLNESS:
                return "APPROVED ILLNESS"
            case DAY_TYPE.REQUESTED_UNPAID_DAY_OFF:
                return "REQUESTED UNPAID DAY OFF"
            case DAY_TYPE.DECLINED_UNPAID_DAY_OFF:
                return "DECLINED UNPAID DAY OFF"
            case DAY_TYPE.APPROVED_UNPAID_DAY_OFF:
                return "APPROVED UNPAID DAY OFF"
            }
        }

        if (moment(today).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) {
            return "TODAY"
        }

        if ([0, 6].includes(new Date(date).getDay())) {
            return "WEEKEND DAY"
        }

        return "WORK DAY"

    }

    const fillCalendar = (
        date,
        today,
        selectedDate,
        isSameDate,
        day = new Date(),
        disableDays = false,
        showTimeOffsAndLoggedDays = false,
        withPublicDayOffs = false,
        data,
        setData,
        rangeDates,
        setUserMessage
    ) => {
        let props = {}
        props.style = {
            borderRadius: "0.3125rem",
        }

        let currentDay = date.format("YYYY-MM-DD")
        let isSelected = isSameDate(date, day) || isSameDate(date, selectedDate)
        let isToday = isSameDate(date, today)
        let isWeekend = [0, 6].includes(date.weekDay.index) && currentDay?.type !== DAY_TYPE.PUBLIC_DAY_OFF
        let isInRange =  (rangeDates && (rangeDates[0] <= date) && (rangeDates[1] ? rangeDates[1] >= date : rangeDates[0] >= date)) && !isApprovedOrRequestedTimeOff(timeOffs?.get(currentDay)?.type)

        const getTitle = (currentDay) => {
            const publicDayOff = isPublicDayOff(currentDay)
            return publicDayOff ? publicDayOff.description : mapTimeOffTypeToTitle(timeOffs.get(currentDay).type)
        }

        const handleShowDayTooltip = (e, currentDay, setData) => {
            e.preventDefault()

            const dayElement = findAncestor(e.currentTarget, "rmdp-day")
            if (!dayElement) {
                return
            }

            const dayRect = dayElement.getBoundingClientRect()

            const title = timeOffs.has(currentDay) ? getTitle(currentDay) : "Today"
            setData({
                left: dayRect.left,
                top: dayRect.top,
                width: dayRect.width,
                height: dayRect.height,
                visible: !!title,
                title,
            })
        }

        const handleHideDayToolTip = (setData) => {
            setData({
                visible: false,
                title: "",
            })
        }
        const createDayContent = (backgroundColor) => (
            <div
                className="day-content"
                onMouseOver={(e) => (timeOffs.has(currentDay) || isToday) && setData && handleShowDayTooltip(e, currentDay, setData)}
                onMouseLeave={() => setData && handleHideDayToolTip(setData)}
            >
                <div className={`${!isInRange ? "hat" : ""} ${isSelected ? "selected" : ""}`} style={{ backgroundColor, color: timeOffs.has(currentDay) && isRequestedOrDeclinedTimeOff(timeOffs.get(currentDay).type) ? "#5F6C72" : "#FFFFFF" }}>
                    {isSelected && <div>{date.format("D")}</div>}
                </div>
                {!isSelected && <div>{date.format("D")}</div>}
            </div>
        )

        if (isWeekend) {
            props.class = "highlight-gray"
            props.disabled = disableDays
        }
        if (isToday && !showTimeOffsAndLoggedDays && (!selectedDate || selectedDate.length === 0)) {
            props.class = "rmdp-selected"
        } else if (isToday) {
            props.class = "rmdp-today"
            props.children = createDayContent(getTimeOffColor("TODAY"))
        }

        if (showTimeOffsAndLoggedDays && !isWeekend && !!timeOffs && timeOffs.has(currentDay)) {
            const timeOff = timeOffs.get(currentDay)
            props.class = getTimeOffClass(timeOff.type)
            props.children = createDayContent(getTimeOffColor(timeOff.type))
            if (isApprovedOrRequestedTimeOff(timeOff.type)) {
                props.disabled = disableDays
                props.onClick = () => {disableDays && setUserMessage && setUserMessage({message: "You cannot select a public holiday or existing time off. Please choose a different date.", code: ERROR_CODE})}
            }
        } else if (withPublicDayOffs && currentDay?.type === DAY_TYPE.PUBLIC_DAY_OFF) {
            props.class = "rmdp-public-day-off"
            props.disabled = disableDays
            props.children = createDayContent(getTimeOffColor(DAY_TYPE.PUBLIC_DAY_OFF))
        }

        if (isSelected && (!props?.class || props.class === "highlight-gray")) props.class += " rmdp-selected"
        if (!props?.class) props.class = "rmdp-default"

        if (showTimeOffsAndLoggedDays && !!loggedDays && loggedDays.has(currentDay)) {
            const loggedDay = loggedDays.get(currentDay)
            props.children = (
                <div className="day-content">
                    {props.children ? props.children : <div>{date.format("D")}</div>}
                    <div className={getLoggedClass(loggedDay.type)}></div>
                </div>
            )
        }

        if (showTimeOffsAndLoggedDays && inReviewDays.has(currentDay)) {
            props.children = (
                <div>
                    {props.children
                        ? props.children
                        : <div>{date.format("D")}</div>}
                    <div className="rmdp-in-review"></div>
                </div>
            )
        }

        return props
    }

    return {
        normalizeDate,
        isPublicDayOff,
        isUserDayOff,
        isUnpaidDayOff,
        isTimeOff,
        getApprovedTimeOff,
        getTypeOfDay,
        fillCalendar,
    }
}