import React from "react"
import "../index.scss"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import {useDeleteBonusMutation} from "../../../api/bonuses"


const DeleteBonusPopup = ({currentBonus, setUserMessage, refreshTable, close}) => {
    const [deleteBonus, {isLoading}] = useDeleteBonusMutation()
    const handleSubmit = () => {

        deleteBonus(currentBonus.id)
            .unwrap()
            .then(() => {
                refreshTable()
                setUserMessage({message: "Bonus was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={"Delete Bonus"} isAgreePopup>
            <div className="delete-bonus-popup-body">
                <span className="t-s3">Do you want to delete this bonus</span>
                <SensitiveInfo>
                    <span className="t-s3">{currentBonus.amount} {currentBonus.currency.iso_code}</span>
                </SensitiveInfo>
                <span className="t-s3">{`(Reason: ${currentBonus.reason})?`}</span>
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteBonusPopup
