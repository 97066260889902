import React, {useEffect} from "react"
import {Helmet} from "react-helmet"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import {useState} from "react"
import "./index.scss"
import DetailsCard from "./components/DetailsCard"
import OptionsCard from "./components/OptionsCard"
import ItemCard from "./components/ItemCard"
import MainImageCard from "./components/MainImageCard"
import GalleryCard from "./components/GalleryCard"
import {
    createProductAttributes,
    createProductLabels,
    createProductOptions,
    updateProductAttributes,
    updateProductImages,
    updateProductLabels,
    updateProductOptions,
} from "./api"
import {
    ERROR_CODE,
    PRODUCT_OPTION_TYPE,
    SUCCESS_CODE
} from "../../constants/other"
import {useCustomHistory} from "../../hooks/useCustomHistory"
import {ProductsRoute} from "../../routes"
import {useParams} from "react-router-dom"
import {receiveProduct} from "../ViewProduct/actions/viewProductActions"
import {useDispatch, useSelector} from "react-redux"
import {Loading} from "../../components/Loading/Loading"
import {useCreateProductMutation, useDeleteProductMutation, useUpdateProductMutation} from "../../api/products"

const CreateProduct = () => {
    const {id} = useParams()
    const [createProduct] = useCreateProductMutation()
    const [updateProduct] = useUpdateProductMutation()
    const [deleteProduct] = useDeleteProductMutation()

    const isEdit = !!id
    const dispatch = useDispatch()
    const {product : existingProduct} = useSelector(state => state.viewProduct)
    const [userMessage, setUserMessage] = useState(null)
    const [product, setProduct] = useState({
        name: "",
        description: "",
        category_id: "",
        pre_order_delivery_time: "",
        raw_price: "",
        public: false,
    })
    const [labels, setLabels] = useState([])
    const [attributes, setAttributes] = useState([])
    const [options, setOptions] = useState([{position:  1, name: "", type: PRODUCT_OPTION_TYPE.TEXT, values: [""], details_name: "", details_image_url: "", errors: []}])
    const [images, setImages] = useState({images_to_add: [], images_to_remove: [], is_main_list: []})

    const [productLabelsSelected, setProductLabelsSelected] = useState([])
    const [loading, setLoading] = useState(true)
    const [optionsError, setOptionsError] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)

    const history = useCustomHistory()

    const handleCreateProduct = async () => {
        let productId = null
        setSubmitDisabled(true)
        setButtonLoading(true)

        try {
            const data = await createProduct({data: product}).unwrap()
            const productId = data.id
            await Promise.all([
                createProductLabels(productId, labels),
                createProductAttributes(productId, attributes.filter(attr => attr.name && attr.value)),
                createProductOptions(
                    productId,
                    options.filter(option => option.name && option.values.some(val => val !== ""))
                        .map(option => ({...option, values: option.values.filter(val => val !== "")}))),
                updateProductImages({
                    product_id: productId,
                    images_to_add: images.images_to_add,
                    images_to_remove: images.images_to_remove,
                    is_main_list: images.is_main_list
                })
            ])
            setUserMessage({message: "Product created successfully", code: SUCCESS_CODE})
            setTimeout(() => history.push(ProductsRoute.path), 1000)
            setSubmitDisabled(false)
            setButtonLoading(false)
        } catch (error) {
            deleteProduct({productId, params: {force: true}})
            setUserMessage({message: "Failed to create product", code: ERROR_CODE})
            setSubmitDisabled(false)
            setButtonLoading(false)
        }
    }

    const handleEditProduct = () => {
        const productId = id
        setSubmitDisabled(true)
        setButtonLoading(true)
        const updatedProduct = existingProduct.name !== product.name || existingProduct.description !== product.description || existingProduct.category?.id != product.category_id || existingProduct.pre_order_delivery_time !== product.pre_order_delivery_time || existingProduct.raw_price !== product.raw_price || existingProduct.status !== product.status || existingProduct.public !== product.public
        const updatedLabels = labels.filter(label => !existingProduct.labels?.some(exLabel => exLabel.id === label))
        const updatedAttributes = attributes.filter(attr => !existingProduct.attributes?.some(exAttr => exAttr.name === attr.name && exAttr.value === attr.value))
        const updatedOptions = options.filter(option => !existingProduct.options?.some(exOption => exOption.type === option.type && exOption.details_name === option.details_name && exOption.details_image_url === option.details_image_url && exOption.name === option.name && JSON.stringify(exOption.values.map((value) => value.value)) === JSON.stringify(option.values))).length || existingProduct.options?.length !== options.length
        const updatedImages = images.images_to_add.length || images.images_to_remove.length || images.is_main_list.length

        Promise.all([
            updatedProduct && updateProduct({productId, data: product}).unwrap(),
            updatedLabels && updateProductLabels(productId, labels),
            updatedAttributes.length && updateProductAttributes(productId, attributes.filter(attr => attr.name && attr.value)),
            updatedOptions && updateProductOptions(
                productId,
                options.filter(option => option.name && option.values.some(val => val !== ""))
                    .map(option => ({...option, values: option.values.filter(val => val !== "")}))),
            updatedImages && updateProductImages({
                product_id: productId,
                images_to_add: images.images_to_add,
                images_to_remove: images.images_to_remove,
                is_main_list: images.is_main_list
            })
        ])
            .then(() => {
                setUserMessage({message: "Product updated successfully", code: SUCCESS_CODE})
                setTimeout(() => history.push(ProductsRoute.path), 1000)
                setSubmitDisabled(false)
                setButtonLoading(false)
            })
            .catch(error => {
                console.error("Error updating product:", error)
                setUserMessage({message: "Failed to update product", code: ERROR_CODE})
                setSubmitDisabled(false)
                setButtonLoading(false)
            })
    }

    useEffect(() => {
        const isMainImageRemoved = existingProduct?.images
            ?.find(image => image.is_main === true && images.images_to_remove.filter(i => i === image.image_url).length > 0)

        const isMainImageGoingToBeAdded = images.is_main_list.filter((value) => value === true)?.length > 0

        const hasMainImage = isMainImageGoingToBeAdded ||
            (existingProduct?.images?.find((image) => image.is_main === true) && !isMainImageRemoved)

        if (product.name && product.category_id && product.raw_price && (isEdit ? hasMainImage : isMainImageGoingToBeAdded) && !optionsError) {
            setSubmitDisabled(false)
        } else {
            setSubmitDisabled(true)
        }
    }, [product, images, existingProduct, optionsError])


    useEffect(() => {
        if (isEdit) {
            dispatch(receiveProduct(id))
        }
    }, [id])

    useEffect(() => {
        if (!isEdit) {
            setLoading(false)
        }
        if (isEdit) {
            setTimeout(() => {
                setLoading(false)
            }, 600)
        }
    }, [isEdit])

    useEffect(() => {
        if (isEdit && existingProduct) {
            setProduct({...existingProduct, category_id: existingProduct.category?.id})
            setProductLabelsSelected(existingProduct.labels?.map((label) => ({name: label.name, color: label.color, id: label.id})) || [])
            setOptions(existingProduct.options?.map((option) => ({...option, values: option.values.map((val) => val.value), })) || options)
            setAttributes(existingProduct.attributes || attributes)
        }
    }, [existingProduct])

    return (
        <>
            <Helmet>
                <title> Create Product - Newsoft Inside </title>
            </Helmet>
            <div className="create-product">
                {isEdit && loading ?
                    <div className="create-product-loading"><Loading /></div>
                    :
                    <>
                        <div>
                            {userMessage &&
                                <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                            }
                        </div>
                        <div className="create-product-left">
                            <DetailsCard loading={loading} setLoading={setLoading} product={product} setProduct={setProduct} setProductLabels={setLabels} productLabelsSelected={productLabelsSelected} setProductLabelsSelected={setProductLabelsSelected} attributes={attributes} setAttributes={setAttributes} setUserMessage={setUserMessage}/>
                            <OptionsCard
                                isEdit={isEdit}
                                options={options}
                                setOptions={setOptions}
                                setUserMessage={setUserMessage}
                                setOptionsError={setOptionsError}
                            />
                        </div>
                        <div className="create-product-right">
                            <ItemCard
                                submitDisabled={submitDisabled}
                                loading={buttonLoading}
                                isEdit={isEdit}
                                product={product}
                                setProduct={setProduct}
                                handleSubmitProduct={isEdit ? handleEditProduct : handleCreateProduct}
                            />
                            <MainImageCard product={product} setImages={setImages} setSubmitDisabled={setSubmitDisabled} isEdit={isEdit} setUserMessage={setUserMessage}/>
                            <GalleryCard product={product} setImages={setImages} isEdit={isEdit} setUserMessage={setUserMessage}/>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default CreateProduct