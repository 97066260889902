import React from "react"


const OurPrinciplesPopup = () => (
    <div className="greetings-onboarding-content-text t-b5">
        <div className="greetings-onboarding-content-paragraph">
            <div className="greetings-onboarding-content-heading t-s5">Creating Value</div>
            <p>Our goal is to deliver value to all our stakeholders, ensuring they clearly see the benefits of working with us, and we continuously seek ways to enhance and expand that value.</p>
        </div>
        <div>
            <div className="greetings-onboarding-content-heading t-s5">Win-Win cooperation</div>
            <p>Our approach is to solve tasks in a mutually beneficial manner, ensuring everyone involved benefits from the process, and we believe this is the key to building enduring, successful relationships with all our partners.</p>
        </div>
    </div>
)

export default OurPrinciplesPopup
