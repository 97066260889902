import React, {Suspense} from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import store from "./redux/store"
import "./i18n"

import "bootstrap/dist/css/bootstrap.min.css"

import App from "./App"
import {Loading} from "./components/Loading/Loading"

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<div className="loading-div"><Loading/></div>}>
            <App/>
        </Suspense>
    </Provider>,
    document.getElementById("root")
)
